import { Vector2 } from 'three';
import { Curve } from './curve';

export class CurveLnIna extends Curve {
    a = 0.0;
    b = 0.0;
    c = 0.0;
    constructor(start: THREE.Vector2, public xzCos: number, a: number, b: number, c: number) {
        super(start, xzCos);
        this.a = a;
        this.b = b;
        this.c = c;
    }

    getCurveLength() {
        return super.getCurveLength();
    }

    getNumberOfPoints(pointsPerUnit: number) {
        return Math.max(this.getCurveLength() * pointsPerUnit, 3);
    }

    getCurvePoint(t: number) {
        return super.getCurvePoint(t);
    }

    getCurvePoint3(t: number) {
        return this.applyCos(this.getCurvePoint(t));
    }

    getMirroredClone(): Curve {
        throw new Error('CurveStraightLine.getMirroredClone is not implmeented yet');
    }
}

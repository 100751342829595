import { ModelElement } from '../../cae-model/model-element';
import { BearinxModelObject } from '../bearinx-model-object';
import { BearinxBinder } from '../binders/bearinx-binder';
import { BearinxStandardBinder } from '../binders/bearinx-standard-binder';
import { isOfType } from '../functions/utils';
import { BxObjectInterface } from '../interfaces/bx-object-interface';

export abstract class BasicMediator {
    private _bearinxModelObject: BearinxModelObject;
    private _binders: BearinxBinder[];
    private _modelElement: ModelElement;
    private _loadCase: number;

    constructor() {
        this._binders = [];
        this._createBasicBinder();
    }

    getBearinxOjectUpdate(bearinxModelObject: BearinxModelObject, modelElement: ModelElement): BxObjectInterface {
        this._bearinxModelObject = bearinxModelObject;
        this._modelElement = modelElement;
        this._binders.forEach(binder => this._setBearixProperties(binder));
        const properties = this._bearinxModelObject['properties'].map((prop: { [x: string]: any }) => ({
            name: prop['name'],
            value: prop['value'],
        }));

        return {
            objectId: modelElement.id,
            properties: properties,
            requiresUpdate: true,
            requiresInitialization: false,
        };
    }

    convert(bearinxModelObject: BearinxModelObject, loadCase: number): ModelElement | null {
        const hasThisType = this.bearinxObjectTypes.some(e => isOfType(bearinxModelObject, e));
        if (!hasThisType) {
            return null;
        }
        this._loadCase = loadCase;
        this._bearinxModelObject = bearinxModelObject;
        this._modelElement = this.createModelElement();
        this._modelElement.id = this._bearinxModelObject.id;
        this._binders.forEach(binder => this._setModelProperties(binder));

        this.doConvert();

        return this._modelElement;
    }

    // can be overriden by derived classes
    protected doConvert() {}

    protected abstract createModelElement(): ModelElement;
    abstract get bearinxObjectTypes(): string[];

    protected addBinder(binder: BearinxBinder) {
        this._binders.push(binder);
    }

    protected get bearinxModelObject(): BearinxModelObject {
        return this._bearinxModelObject;
    }

    protected get modelElement(): ModelElement {
        return this._modelElement;
    }

    private _setModelProperties(binder: BearinxBinder) {
        binder.objectLeft = this._modelElement;
        binder.objectRight = this._bearinxModelObject;
        binder.loadCase = this._loadCase;
        binder.setLeftVariables();
    }

    private _setBearixProperties(binder: BearinxBinder) {
        binder.objectLeft = this._modelElement;
        binder.objectRight = this._bearinxModelObject;
        binder.setRightVariables();
    }

    private _createBasicBinder(): void {
        const binder = new BearinxStandardBinder();
        binder.properties = [{ left: 'name', right: 'IDCO_DESIGNATION' }];
        this.addBinder(binder);
    }
}

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { BearinxClipboardService } from 'src/modules/clipboard/bearinx-clipboard.service';
import { environment } from '../../environments/environment';
import { AuthModule } from '../../modules/auth/auth.module';
import { AuthenticationService } from '../../modules/auth/authentication.service';
import { DataModelModule } from '../../modules/data-model/data-model.module';
import { ErrorHandlingModule } from '../../modules/error-handling/error-handling.module';
import { GlobalHotkeyService } from '../../modules/hotkey/global-hotkey.service';
import { LoadingIndicatorModule } from '../../modules/loading-indicator/loading-indicator.module';
import { LockingModule } from '../../modules/locking/locking.module';
import { ModelModule } from '../../modules/model/model.module';
import { SettingsModule } from '../../modules/settings/settings.module';
import { SettingsService } from '../../modules/settings/settings.service';
import { SpinnerModule } from '../../modules/spinner/spinner.module';
import { ToastModule } from '../../modules/toast/toast.module';
import { AppContainerModule } from './app-container/app-container.module';
import { AppComponent } from './app.component';
import { BearinxRoutingModule } from './bearinx.routing';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/');
}

export function AuthConfigFactory(): OAuthModuleConfig {
    return {
        resourceServer: {
            allowedUrls: [environment.baseUrl.toLocaleLowerCase(), environment.settingsUrl.toLocaleLowerCase()],
            sendAccessToken: true,
        },
    };
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,

        BearinxRoutingModule,

        AuthModule.forRoot(),
        DataModelModule.forRoot(),
        ErrorHandlingModule.forRoot(),
        OAuthModule.forRoot(),
        SpinnerModule.forRoot(),
        ModelModule.forRoot(),
        SettingsModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        LockingModule.forRoot(),

        AppContainerModule,
        ToastModule,
        LoadingIndicatorModule,
    ],
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    providers: [
        { provide: OAuthModuleConfig, useFactory: AuthConfigFactory },

        // Edge problem: https://github.com/manfredsteyer/angular-oauth2-oidc/issues/255
        { provide: OAuthStorage, useValue: localStorage },
    ],
})
export class BearinxModule {
    constructor(
        oauth: OAuthService,
        auth: AuthenticationService,
        globalHotkeyService: GlobalHotkeyService,
        settingsService: SettingsService,
        translateService: TranslateService,
        clipboardService: BearinxClipboardService,
    ) {
        oauth.configure(environment.oauth);
        auth.start();

        globalHotkeyService.initialize();
        translateService.addLangs(settingsService.languageIds);
        translateService.setDefaultLang(settingsService.defaultLanguageId);

        settingsService.currentLanguage$.subscribe(language => translateService.use(language));

        clipboardService.permissionTest();
    }
}

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SpinnerInterceptor } from './spinner.interceptor';

@NgModule({})
export class SpinnerModule {
    public static forRoot(): ModuleWithProviders<SpinnerModule> {
        return {
            ngModule: SpinnerModule,
            providers: [{ provide: HTTP_INTERCEPTORS, multi: true, useClass: SpinnerInterceptor }],
        };
    }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Model } from '../model.model';
import { ModelObject } from '../model-object.model';
import { ExportConstants } from './export.constants';
import { DataProvider } from './data.provider';
import { hasProperty } from './has-property';

@Injectable()
export class Vg2Json2Vg2JsonDataprovider implements DataProvider {
    public readonly inputFormats = [ExportConstants.vg2Json];

    public readonly outputFormat = ExportConstants.vg2Json;

    /**
     * Converts the given data to the target format
     * @param input Input data to be converted
     * @returns Output data or null if not supported
     */
    public convert(input: any): Observable<any> {
        return new Observable<any>(observer => {
            const model = <Model<ModelObject>>input;
            if (model) {
                const vg2Json = ['id', 'name', 'project', 'method'];
                if (vg2Json.every(attr => hasProperty(model, attr))) {
                    observer.next(model);
                    observer.complete();
                }
            } else {
                observer.error();
                observer.complete();
            }
        });
    }
}

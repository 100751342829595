/* eslint-disable max-len */

export const IDO_SHAFT = 'IDO_SHAFT';
export const IDO_SHAFT_SYSTEM = 'IDO_SHAFT_SYSTEM';

// Bearings
export enum BearingConstructionType {
    LB_RADIAL_PENDEL_KUGEL_LAGER_EINREIHIG = 'LB_RADIAL_PENDEL_KUGEL_LAGER_EINREIHIG',
    LB_RADIAL_PENDEL_KUGEL_LAGER_ZWEIREIHIG = 'LB_RADIAL_PENDEL_KUGEL_LAGER_ZWEIREIHIG',
    LB_RADIAL_PENDEL_ROLLENLAGER_EINREIHIG = 'LB_RADIAL_PENDEL_ROLLENLAGER_EINREIHIG',
    LB_RADIAL_PENDEL_ROLLENLAGER_ZWEIREIHIG = 'LB_RADIAL_PENDEL_ROLLENLAGER_ZWEIREIHIG',
    LB_RADIAL_PENDEL_ROLLENLAGER_FAG_E1_E1A_LAGER = 'LB_RADIAL_PENDEL_ROLLENLAGER_FAG_E1_E1A_LAGER',
    LB_RADIAL_PENDEL_ROLLENLAGER_FAG_E_EA_LAGER = 'LB_RADIAL_PENDEL_ROLLENLAGER_FAG_E_EA_LAGER',
    LB_RADIAL_PENDEL_ROLLENLAGER_FAG_HL_LAGER = 'LB_RADIAL_PENDEL_ROLLENLAGER_FAG_HL_LAGER',
    LB_RADIAL_PENDEL_ROLLENLAGER_FAG_A_LAGER = 'LB_RADIAL_PENDEL_ROLLENLAGER_FAG_A_LAGER',
    LB_RADIAL_PENDEL_ROLLENLAGER_FAG_B_LAGER = 'LB_RADIAL_PENDEL_ROLLENLAGER_FAG_B_LAGER',
    LB_RADIAL_PENDEL_ROLLENLAGER_GEMISCHTREIHIG = 'LB_RADIAL_PENDEL_ROLLENLAGER_GEMISCHTREIHIG',
    LB_TOROIDAL_ROLLENLAGER = 'LB_TOROIDAL_ROLLENLAGER',
    LB_AXIAL_KREUZROLLENLAGER = 'LB_AXIAL_KREUZROLLENLAGER',
    LB_CROSSED_ROLLER_SLEWING_BEARING_SINGLE_ROW = 'LB_CROSSED_ROLLER_SLEWING_BEARING_SINGLE_ROW',
    LB_CROSSED_ROLLER_SLEWING_BEARING_DOUBLE_ROW = 'LB_CROSSED_ROLLER_SLEWING_BEARING_DOUBLE_ROW',
    LB_RADIAL_KREUZROLLENLAGER = 'LB_RADIAL_KREUZROLLENLAGER',
    LB_RADIAL_SCHRAEG_KUGELLAGER_EINREIHIG = 'LB_RADIAL_SCHRAEG_KUGELLAGER_EINREIHIG',
    LB_RADIAL_ANGULAR_CONTACT_BALL_BEARING_SINGLE_ROW_SPINDLE_TYPE = 'LB_RADIAL_ANGULAR_CONTACT_BALL_BEARING_SINGLE_ROW_SPINDLE_TYPE',
    LB_RADIAL_SCHRAEG_KUGELLAGER_ZWEIREIHIG_X_ANORDNUNG = 'LB_RADIAL_SCHRAEG_KUGELLAGER_ZWEIREIHIG_X_ANORDNUNG',
    LB_RADIAL_SCHRAEG_KUGELLAGER_ZWEIREIHIG_MIT_FUELLNUT_X_ANORDNUNG = 'LB_RADIAL_SCHRAEG_KUGELLAGER_ZWEIREIHIG_MIT_FUELLNUT_X_ANORDNUNG',
    LB_RADIAL_SCHRAEG_KUGELLAGER_ZWEIREIHIG_O_ANORDNUNG = 'LB_RADIAL_SCHRAEG_KUGELLAGER_ZWEIREIHIG_O_ANORDNUNG',
    LB_RADIAL_SCHRAEG_KUGELLAGER_ZWEIREIHIG_MIT_FUELLNUT_O_ANORDNUNG = 'LB_RADIAL_SCHRAEG_KUGELLAGER_ZWEIREIHIG_MIT_FUELLNUT_O_ANORDNUNG',
    LB_RADIAL_SCHRAEG_KUGELLAGER_ZWEIREIHIG_TANDEM = 'LB_RADIAL_SCHRAEG_KUGELLAGER_ZWEIREIHIG_TANDEM',
    LB_THREE_POINT_CONTACT_BALL_BEARING_WTIH_DOUBLE_INNER_RING_CONTACT = 'LB_THREE_POINT_CONTACT_BALL_BEARING_WTIH_DOUBLE_INNER_RING_CONTACT',
    LB_THREE_POINT_CONTACT_BALL_BEARING_WTIH_DOUBLE_OUTER_RING_CONTACT = 'LB_THREE_POINT_CONTACT_BALL_BEARING_WTIH_DOUBLE_OUTER_RING_CONTACT',
    LB_RADIAL_VIERPUNKTLAGER = 'LB_RADIAL_VIERPUNKTLAGER',
    LB_RADIAL_FOUR_POINT_CONTACT_BEARING_WITH_EQUILIBRIUM = 'LB_RADIAL_FOUR_POINT_CONTACT_BEARING_WITH_EQUILIBRIUM',
    LB_AXIAL_SCHRAEG_KUGELLAGER_EINREIHIG = 'LB_AXIAL_SCHRAEG_KUGELLAGER_EINREIHIG',
    LB_THRUST_ANGULAR_CONTACT_BALL_BEARING_SINGLE_ROW_SPINDLE_TYPE = 'LB_THRUST_ANGULAR_CONTACT_BALL_BEARING_SINGLE_ROW_SPINDLE_TYPE',
    LB_AXIAL_SCHRAEG_KUGELLAGER_ZWEIREIHIG_X_ANORDNUNG = 'LB_AXIAL_SCHRAEG_KUGELLAGER_ZWEIREIHIG_X_ANORDNUNG',
    LB_AXIAL_SCHRAEG_KUGELLAGER_ZWEIREIHIG_O_ANORDNUNG = 'LB_AXIAL_SCHRAEG_KUGELLAGER_ZWEIREIHIG_O_ANORDNUNG',
    LB_THRUST_ANGULAR_CONTACT_BALL_BEARING_DOUBLE_ROW_O_ARRANGEMENT_SPINDLE_TYPE = 'LB_THRUST_ANGULAR_CONTACT_BALL_BEARING_DOUBLE_ROW_O_ARRANGEMENT_SPINDLE_TYPE',
    LB_AXIAL_VIERPUNKTLAGER = 'LB_AXIAL_VIERPUNKTLAGER',
    LB_THRUST_FOUR_POINT_CONTACT_BEARING_WITH_EQUILIBRIUM = 'LB_THRUST_FOUR_POINT_CONTACT_BEARING_WITH_EQUILIBRIUM',
    LB_AXIAL_SCHRAEG_ROLLENLAGER_EINREIHIG = 'LB_AXIAL_SCHRAEG_ROLLENLAGER_EINREIHIG',
    LB_AXIAL_SCHRAEG_NADELLAGER_EINREIHIG = 'LB_AXIAL_SCHRAEG_NADELLAGER_EINREIHIG',
    LB_AXIAL_SCHRAEG_ROLLENLAGER_ZWEIREIHIG_O_ANORDNUNG = 'LB_AXIAL_SCHRAEG_ROLLENLAGER_ZWEIREIHIG_O_ANORDNUNG',
    LB_AXIAL_SCHRAEG_NADELLAGER_ZWEIREIHIG_O_ANORDNUNG = 'LB_AXIAL_SCHRAEG_NADELLAGER_ZWEIREIHIG_O_ANORDNUNG',
    LB_AXIAL_SCHRAEG_ROLLENLAGER_ZWEIREIHIG_X_ANORDNUNG = 'LB_AXIAL_SCHRAEG_ROLLENLAGER_ZWEIREIHIG_X_ANORDNUNG',
    LB_AXIAL_SCHRAEG_NADELLAGER_ZWEIREIHIG_X_ANORDNUNG = 'LB_AXIAL_SCHRAEG_NADELLAGER_ZWEIREIHIG_X_ANORDNUNG',
    LB_RADIAL_SCHRAEG_ROLLENLAGER_EINREIHIG = 'LB_RADIAL_SCHRAEG_ROLLENLAGER_EINREIHIG',
    LB_RADIAL_SCHRAEG_NADELLAGER_EINREIHIG = 'LB_RADIAL_SCHRAEG_NADELLAGER_EINREIHIG',
    LB_RADIAL_KEGEL_ROLLENLAGER_EINREIHIG = 'LB_RADIAL_KEGEL_ROLLENLAGER_EINREIHIG',
    LB_RADIAL_SCHRAEG_ROLLENLAGER_ZWEIREIHIG_O_ANORDNUNG = 'LB_RADIAL_SCHRAEG_ROLLENLAGER_ZWEIREIHIG_O_ANORDNUNG',
    LB_RADIAL_SCHRAEG_NADELLAGER_ZWEIREIHIG_O_ANORDNUNG = 'LB_RADIAL_SCHRAEG_NADELLAGER_ZWEIREIHIG_O_ANORDNUNG',
    LB_RADIAL_KEGEL_ROLLENLAGER_ZWEIREIHIG_O_ANORDNUNG = 'LB_RADIAL_KEGEL_ROLLENLAGER_ZWEIREIHIG_O_ANORDNUNG',
    LB_RADIAL_KEGEL_ROLLENLAGER_ZWEIREIHIG_O_ANORDNUNG_DEVIANT_TO_ISO = 'LB_RADIAL_KEGEL_ROLLENLAGER_ZWEIREIHIG_O_ANORDNUNG_DEVIANT_TO_ISO',
    LB_RADIAL_SCHRAEG_ROLLENLAGER_ZWEIREIHIG_X_ANORDNUNG = 'LB_RADIAL_SCHRAEG_ROLLENLAGER_ZWEIREIHIG_X_ANORDNUNG',
    LB_RADIAL_SCHRAEG_NADELLAGER_ZWEIREIHIG_X_ANORDNUNG = 'LB_RADIAL_SCHRAEG_NADELLAGER_ZWEIREIHIG_X_ANORDNUNG',
    LB_RADIAL_KEGEL_ROLLENLAGER_ZWEIREIHIG_X_ANORDNUNG = 'LB_RADIAL_KEGEL_ROLLENLAGER_ZWEIREIHIG_X_ANORDNUNG',
    LB_RADIAL_KEGEL_ROLLENLAGER_ZWEIREIHIG_X_ANORDNUNG_DEVIANT_TO_ISO = 'LB_RADIAL_KEGEL_ROLLENLAGER_ZWEIREIHIG_X_ANORDNUNG_DEVIANT_TO_ISO',
    LB_RADIAL_SCHRAEG_ROLLENLAGER_ZWEIREIHIG_TANDEM = 'LB_RADIAL_SCHRAEG_ROLLENLAGER_ZWEIREIHIG_TANDEM',
    LB_RADIAL_SCHRAEG_NADELLAGER_ZWEIREIHIG_TANDEM = 'LB_RADIAL_SCHRAEG_NADELLAGER_ZWEIREIHIG_TANDEM',
    LB_RADIAL_KEGEL_ROLLENLAGER_ZWEIREIHIG_TANDEM = 'LB_RADIAL_KEGEL_ROLLENLAGER_ZWEIREIHIG_TANDEM',
    LB_RADIAL_KEGEL_ROLLENLAGER_DOPPEL_O_ANORDNUNG = 'LB_RADIAL_KEGEL_ROLLENLAGER_DOPPEL_O_ANORDNUNG',
    LB_RADIAL_KEGEL_ROLLENLAGER_DOPPEL_O_ANORDNUNG_DEVIANT_TO_ISO = 'LB_RADIAL_KEGEL_ROLLENLAGER_DOPPEL_O_ANORDNUNG_DEVIANT_TO_ISO',
    LB_RADIAL_KEGEL_ROLLENLAGER_DOPPEL_X_ANORDNUNG = 'LB_RADIAL_KEGEL_ROLLENLAGER_DOPPEL_X_ANORDNUNG',
    LB_RADIAL_KEGEL_ROLLENLAGER_DOPPEL_X_ANORDNUNG_DEVIANT_TO_ISO = 'LB_RADIAL_KEGEL_ROLLENLAGER_DOPPEL_X_ANORDNUNG_DEVIANT_TO_ISO',
    LB_ZKLN = 'LB_ZKLN',
    LB_ZKLN_2AP = 'LB_ZKLN_2AP',
    LB_ZKLF = 'LB_ZKLF',
    LB_ZKLF_2AP = 'LB_ZKLF_2AP',
    LB_ZKLFA = 'LB_ZKLFA',
    LB_ZARN = 'LB_ZARN',
    LB_ZARN_L = 'LB_ZARN_L',
    LB_ZARF = 'LB_ZARF',
    LB_ZARF_L = 'LB_ZARF_L',
    LB_NKXR = 'LB_NKXR',
    LB_NKX = 'LB_NKX',
    LB_NX = 'LB_NX',
    LB_YRT = 'LB_YRT',
    LB_YRT_LRB = 'LB_YRT_LRB',
    LB_YRTS = 'LB_YRTS',
    LB_Y_SERIES_SPLIT_INNER_RING = 'LB_Y_SERIES_SPLIT_INNER_RING',
    LB_Y_SERIES_SPLIT_OUTER_RING = 'LB_Y_SERIES_SPLIT_OUTER_RING',
    LB_NKIA = 'LB_NKIA',
    LB_NKIB = 'LB_NKIB',
    LB_DKLFA = 'LB_DKLFA',
    LB_BSB2ROWO = 'LB_BSB2ROWO',
    LB_BSB2ROWX = 'LB_BSB2ROWX',
    LB_BSB3ROWO = 'LB_BSB3ROWO',
    LB_BSB3ROWX = 'LB_BSB3ROWX',
    LB_BSB4ROWO = 'LB_BSB4ROWO',
    LB_BSB4ROWX = 'LB_BSB4ROWX',
    LB_AXIAL_KEGEL_ROLLENLAGER_EINREIHIG = 'LB_AXIAL_KEGEL_ROLLENLAGER_EINREIHIG',
    LB_AXIAL_KEGEL_ROLLENLAGER_EINREIHIG_FACEPLATE_IS_SHAFT_WASHER = 'LB_AXIAL_KEGEL_ROLLENLAGER_EINREIHIG_FACEPLATE_IS_SHAFT_WASHER',
    LB_AXIAL_KEGEL_ROLLENLAGER_EINREIHIG_FACEPLATE_IS_HOUSING_WASHER = 'LB_AXIAL_KEGEL_ROLLENLAGER_EINREIHIG_FACEPLATE_IS_HOUSING_WASHER',
    LB_AXIAL_KEGEL_ROLLENLAGER_ZWEIREIHIG_X_ANORDNUNG_WS_MITTIG = 'LB_AXIAL_KEGEL_ROLLENLAGER_ZWEIREIHIG_X_ANORDNUNG_WS_MITTIG',
    LB_AXIAL_KEGEL_ROLLENLAGER_ZWEIREIHIG_O_ANORDNUNG_WS_MITTIG = 'LB_AXIAL_KEGEL_ROLLENLAGER_ZWEIREIHIG_O_ANORDNUNG_WS_MITTIG',
    LB_AXIAL_PENDEL_ROLLENLAGER = 'LB_AXIAL_PENDEL_ROLLENLAGER',
    LB_AXIAL_PENDEL_ROLLENLAGER_E1 = 'LB_AXIAL_PENDEL_ROLLENLAGER_E1',
    LB_AXIAL_NADELKRANZ_58_EINSEITIG = 'LB_AXIAL_NADELKRANZ_58_EINSEITIG',
    LB_AXIAL_NADELKRAENZE_58_ZWEISEITIG_GS_MITTIG = 'LB_AXIAL_NADELKRAENZE_58_ZWEISEITIG_GS_MITTIG',
    LB_AXIAL_NADELKRAENZE_58_ZWEISEITIG_WS_MITTIG = 'LB_AXIAL_NADELKRAENZE_58_ZWEISEITIG_WS_MITTIG',
    LB_AXIAL_NADELLAGER_EINSEITIG = 'LB_AXIAL_NADELLAGER_EINSEITIG',
    LB_AXIAL_NADELLAGER_ZWEISEITIG_GS_MITTIG = 'LB_AXIAL_NADELLAGER_ZWEISEITIG_GS_MITTIG',
    LB_AXIAL_NADELLAGER_ZWEISEITIG_WS_MITTIG = 'LB_AXIAL_NADELLAGER_ZWEISEITIG_WS_MITTIG',
    LB_AXIAL_ZYLINDERROLLENLAGER_EINSEITIG = 'LB_AXIAL_ZYLINDERROLLENLAGER_EINSEITIG',
    LB_AXIAL_ZYLINDERROLLENLAGER_ZWEISEITIG_GS_MITTIG = 'LB_AXIAL_ZYLINDERROLLENLAGER_ZWEISEITIG_GS_MITTIG',
    LB_AXIAL_ZYLINDERROLLENLAGER_ZWEISEITIG_WS_MITTIG = 'LB_AXIAL_ZYLINDERROLLENLAGER_ZWEISEITIG_WS_MITTIG',
    LB_ROLLENHUELSEN_MIT_KAEFIG = 'LB_ROLLENHUELSEN_MIT_KAEFIG',
    LB_RADIAL_RILLENKUGELLAGER = 'LB_RADIAL_RILLENKUGELLAGER',
    LB_NADELKRANZ_58HRC = 'LB_NADELKRANZ_58HRC',

    LB_AXIAL_RILLENKUGELLAGER_ZWEISEITIG_GS_MITTIG = 'LB_AXIAL_RILLENKUGELLAGER_ZWEISEITIG_GS_MITTIG',
    LB_AXIAL_RILLENKUGELLAGER_ZWEISEITIG_WS_MITTIG = 'LB_AXIAL_RILLENKUGELLAGER_ZWEISEITIG_WS_MITTIG',
}

export const IDO_BEARING = 'IDO_BEARING';

export enum BearingsTypesIdos {
    IDO_RADIAL_ANGULAR_CONTACT_BALL_BEARING = 'IDO_RADIAL_ANGULAR_CONTACT_BALL_BEARING',
    IDO_THRUST_ANGULAR_CONTACT_BALL_BEARING = 'IDO_THRUST_ANGULAR_CONTACT_BALL_BEARING',
    IDO_RADIAL_ANGULAR_CONTACT_ROLLER_BEARING = 'IDO_RADIAL_ANGULAR_CONTACT_ROLLER_BEARING',
    IDO_THRUST_ANGULAR_CONTACT_ROLLER_BEARING = 'IDO_THRUST_ANGULAR_CONTACT_ROLLER_BEARING',
    IDO_RADIAL_TAPERED_ROLLER_BEARING = 'IDO_RADIAL_TAPERED_ROLLER_BEARING',
    IDO_THRUST_TAPERED_ROLLER_BEARING = 'IDO_THRUST_TAPERED_ROLLER_BEARING',
    IDO_RADIAL_ROLLER_BEARING = 'IDO_RADIAL_ROLLER_BEARING',
    IDO_THRUST_ROLLER_BEARING = 'IDO_THRUST_ROLLER_BEARING',
    IDO_RADIAL_BALL_BEARING = 'IDO_RADIAL_BALL_BEARING',
    IDO_THRUST_BALL_BEARING = 'IDO_THRUST_BALL_BEARING',
    IDO_RADIAL_SELF_ALIGNING_ROLLER_BEARING = 'IDO_RADIAL_SELF_ALIGNING_ROLLER_BEARING',
    IDO_THRUST_SELF_ALIGNING_ROLLER_BEARING = 'IDO_THRUST_SELF_ALIGNING_ROLLER_BEARING',
    IDO_RADIAL_SELF_ALIGNING_BALL_BEARING = 'IDO_RADIAL_SELF_ALIGNING_BALL_BEARING',
    IDO_THRUST_ROLLER_RADIAL_ROLLER_BEARING = 'IDO_THRUST_ROLLER_RADIAL_ROLLER_BEARING',
    IDO_THRUST_BALL_RADIAL_ROLLER_BEARING = 'IDO_THRUST_BALL_RADIAL_ROLLER_BEARING',
    IDO_THRUST_ANGULAR_CONTACT_BALL_RADIAL_ROLLER_BEARING = 'IDO_THRUST_ANGULAR_CONTACT_BALL_RADIAL_ROLLER_BEARING',
    IDO_THRUST_ANGULAR_CONTACT_BALL_BEARING_WITH_MIXED_ROWS = 'IDO_THRUST_ANGULAR_CONTACT_BALL_BEARING_WITH_MIXED_ROWS',
    IDO_THRUST_ROLLER_BEARING_WITH_MIXED_ROWS = 'IDO_THRUST_ROLLER_BEARING_WITH_MIXED_ROWS',
    IDO_BALL_SCREW_DRIVE_WITH_TWO_POINT_CONTACT = 'IDO_BALL_SCREW_DRIVE_WITH_TWO_POINT_CONTACT',
    IDO_BALL_SCREW_DRIVE_WITH_FOUR_POINT_CONTACT = 'IDO_BALL_SCREW_DRIVE_WITH_FOUR_POINT_CONTACT',
    IDO_SLEWING_BALL_BEARING = 'IDO_SLEWING_BALL_BEARING ',
    IDO_SLEWING_ROLLER_BEARING = 'IDO_SLEWING_ROLLER_BEARING ',
    IDO_DOUBLE_DIRECTION_AXIAL_RADIAL_SLEWING_BEARING = 'IDO_DOUBLE_DIRECTION_AXIAL_RADIAL_SLEWING_BEARING ',
}

export enum IdDef {
    DIVERGENT = 'DIVERGENT',
    CONVERGENT = 'CONVERGENT',
}

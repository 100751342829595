import { ModelElement } from '../model-element';

export enum XSide {
    UNDEFINED = 'UNDEFINED',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
}

export interface PositionData {
    xPos: number;
    side: XSide;
}

export abstract class Notch extends ModelElement {
    // shaft coordinate system
    xInShaftCS = 0;

    /// function returning x of the shaft segment in shaft cs
    getSegmentXFunc: () => number;

    /// function returning xEnd of the shaft segment in shaft cs
    getSegmentXEndFunc: () => number;

    // returns the current radius of the segment, corresponding to this x where x is the x-coordinate in the shaft coordinate system
    getCurrentOuterRadius: (xInShaftCS: number) => number;

    // returns the current radius of the segment, corresponding to this x where x is the x-coordinate in the shaft coordinate system
    getCurrentInnerRadius: (xInShaftCS: number) => number;

    getSide(): XSide {
        if (this.dependsOnSegmentSide()) {
            if (this.xInShaftCS - this.getSegmentXFunc() <= this.getSegmentXEndFunc() - this.xInShaftCS) {
                return XSide.LEFT;
            } else {
                return XSide.RIGHT;
            }
        } else {
            return XSide.UNDEFINED;
        }
    }

    protected abstract dependsOnSegmentSide(): boolean;

    updateX() {
        this.x = this.xInShaftCS - this.getSegmentXFunc();
    }
}

import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectService } from '../project.service';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Project } from '../project.model';
import { ErrorMessagesService, HttpStatusCode } from '../../../modules/error-handling';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'bx-project-rename',
    templateUrl: './project-rename.component.html',
    styleUrls: ['./project-rename.component.scss'],
})
export class ProjectRenameComponent implements OnInit {
    public projectCtrl = new FormControl();
    public disabled = true;

    public project: Project;

    constructor(
        private readonly _projectService: ProjectService,
        private readonly _router: Router,
        private readonly _location: Location,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _errorService: ErrorMessagesService,
        private readonly _translateService: TranslateService,
    ) {}

    public ngOnInit() {
        const id = this._activatedRoute.snapshot.paramMap.get('id');
        if (id != null) {
            this._projectService.get(id).subscribe(project => {
                this.project = project;
                this.projectCtrl.patchValue(project);
            });
        }
        this.projectCtrl.valueChanges.subscribe((value: any) => (this.disabled = !value?.name || value.name.trim().length <= 0));
    }

    public onConfirm(): void {
        const project = this.projectCtrl.value;
        const id = this._activatedRoute.snapshot.paramMap.get('id');
        if (id != null) {
            this._projectService.update(id, project).subscribe(
                _ => this._router.navigate(['models', 'library']),
                error => {
                    if (error.status === HttpStatusCode.Conflict) {
                        const errorMessage = this._translateService.instant('SHARED.PROJECT.NAME.ERROR_MESSAGE_DUPLICATE');
                        this._errorService.displayErrorMessage(errorMessage);
                    } else {
                        this._errorService.displayErrorMessage(error.error);
                        this._router.navigate(['models', 'library']);
                    }
                },
            );
        }
    }

    public onCancel(): void {
        this._location.back();
    }
}

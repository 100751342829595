import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AppBarService {
    private readonly _toggleDashboard = new Subject<void>();
    private readonly _toggleObjectTree = new Subject<void>();
    private readonly _toggleReadOnly = new Subject<void>();
    private readonly _readOnlyActive = new BehaviorSubject<boolean>(true);
    private readonly _objectTreeOpen = new BehaviorSubject<boolean>(false);
    private readonly _loadCaseCaption = new BehaviorSubject<string>('');

    public readonly toggleDashboard$ = this._toggleDashboard.asObservable();
    public readonly toggleObjectTree$ = this._toggleObjectTree.asObservable();
    public readonly toggleReadOnly$ = this._toggleReadOnly.asObservable();
    public readonly readOnlyActive$ = this._readOnlyActive.asObservable();
    public readonly objectTreeOpen$ = this._objectTreeOpen.asObservable().pipe(delay(0));
    public readonly loadCaseCaption$ = this._loadCaseCaption.asObservable();

    public toggleDashboard(): void {
        this._toggleDashboard.next();
    }

    public toggleObjectTree(): void {
        this._toggleObjectTree.next();
    }

    public toggleObjectTreeOpen(value: boolean): void {
        this._objectTreeOpen.next(value);
    }

    public toggleReadOnly(value?: boolean): void {
        if (value == null || value !== this._readOnlyActive.value) {
            this._toggleReadOnly.next();
        }
    }

    public toggleReadOnlyActive(value: boolean): void {
        this._readOnlyActive.next(value);
    }

    public setLoadCaseCaption(caption?: string): void {
        this._loadCaseCaption.next(caption ?? '');
    }
}

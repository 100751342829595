import Konva from 'konva';
import {
    DASH_STROKE_SYMMETRY_LINE,
    FIXED_SCALE_ATTRIBUTE,
    LINE_CAP_2D,
    LINE_JOIN_2D,
    STROKE_COLOR_2D,
    STROKE_WIDTH,
    SYMMETRY_LINE_LENGTH,
    NOT_IN_STAGE_BOUNDING_BOX,
    SYMMETRY_LINE_NAME,
} from '../elements-view/view-2d-constants';

export class SymmetryLine {
    private _line: Konva.Line;
    private _group: Konva.Group;

    constructor() {
        this._createLine();
        this._group = new Konva.Group({
            name: SYMMETRY_LINE_NAME,
            transformable: false,
        });
        this._group.add(this._line);
    }

    get group(): Konva.Group {
        return this._group;
    }

    private _createLine(): void {
        const startingPoint = [-SYMMETRY_LINE_LENGTH / 2, 0];
        const endingPoint = [SYMMETRY_LINE_LENGTH / 2, 0];
        this._line = new Konva.Line({
            points: startingPoint.concat(endingPoint),
            stroke: STROKE_COLOR_2D,
            strokeWidth: STROKE_WIDTH,
            strokeScaleEnabled: false,
            lineCap: LINE_CAP_2D,
            lineJoin: LINE_JOIN_2D,
            dash: DASH_STROKE_SYMMETRY_LINE,
            draggable: false,
        });
        this._line.setAttr(FIXED_SCALE_ATTRIBUTE, true);
        this._line.setAttr(NOT_IN_STAGE_BOUNDING_BOX, true);
    }
}

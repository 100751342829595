import Konva from 'konva';
import { IdDef } from '../../../bearinx-mediator/bearinx-ids';
import { BevelGearBase } from '../../../cae-model/transmission-elements/bevel-gear-base';
import { BevelGearBaseGeometry } from '../../../views-foundation/geometries/bevel-gear-base-geometry';
import { DISPLAYGREEN, DISPLAYRED } from '../view-2d-constants';
import { TransmissionElementView2D } from './transmission-element-view-2d';

export abstract class BevelGearBaseView2D extends TransmissionElementView2D {
    protected abstract updateBevelGear(group: Konva.Group): void;

    constructor(bevelGear: BevelGearBase) {
        super(bevelGear);
    }

    protected geometry: BevelGearBaseGeometry;

    protected createShapes(): Konva.Group {
        return new Konva.Group({
            name: 'bevel-gear-base-view-2d-group',
            transformable: true,
        });
    }

    protected paintElement(group: Konva.Group): void {
        this.updateBevelGear(group);
    }

    protected paintPitchLines(group: Konva.Group): void {
        this._updatePitchLine(group);
    }

    private _updatePitchLine(group: Konva.Group): void {
        const { tB, tDI, tDO, tDM, isOnShaft, tForm } = this.geometry;
        const width = tB * 1.5;

        if (tForm === IdDef.DIVERGENT) {
            super.paintDiagonalPitchLines(group, isOnShaft, width, tDI, tDO, tDM);
        } else {
            super.paintDiagonalPitchLines(group, isOnShaft, width, tDO, tDI, tDM);
        }
    }

    protected getBevelGearInput(): {
        leftX: number;
        rightX: number;
        inDia: number;
        outDia: number;
        color: string;
    } {
        const { tB, tDI, tDO, isOnShaft, tForm } = this.geometry;
        const leftX: number = -tB / 2.0;
        const rightX: number = tB / 2.0;
        const { inDia, outDia } =
            tForm === IdDef.DIVERGENT
                ? {
                      inDia: tDO,
                      outDia: tDI,
                  }
                : {
                      inDia: tDI,
                      outDia: tDO,
                  };

        const color = isOnShaft ? DISPLAYGREEN : DISPLAYRED;
        return { leftX, rightX, inDia, outDia, color };
    }
}

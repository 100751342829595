import { Vector2 } from 'three';
import { Curve } from './curve';

export class CurveStraightLine extends Curve {
    constructor(start: Vector2, public xzCos: number) {
        super(start, xzCos);
    }

    getMirroredClone() {
        const clone = new CurveStraightLine(new Vector2(-this.endPoint.x, this.endPoint.y), this.xzCos);
        clone.setEndPoint(new Vector2(-this.startPoint.x, this.startPoint.y));
        return clone;
    }

    getCurvePoint3(t: number) {
        return this.applyCos(super.getCurvePoint(t));
    }

    getNumberOfPoints(pointsPerUnit: number) {
        return 2;
    }

    getBoundingBox() {
        return super.getBoundingBox();
    }

    getCurveLength() {
        return super.getCurveLength();
    }
}

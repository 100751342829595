import { Component, Inject } from '@angular/core';
import { BasePrompt } from '../../../prompt/base.prompt';
import { PROMPT_DATA } from '../../../prompt/prompt.service';

export interface ModelLockedPromptTranslateData {
    userName: string;
}

@Component({
    templateUrl: './model-locked.prompt.html',
})
export class ModelLockedPrompt extends BasePrompt {
    public translateData: ModelLockedPromptTranslateData;

    constructor(@Inject(PROMPT_DATA) data: ModelLockedPromptTranslateData) {
        super();
        this.translateData = data;
    }
}

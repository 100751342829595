import Konva from 'konva';
import { InternalSpurGear } from '../../../cae-model/transmission-elements/internal-spur-gear';
import { SpurGear } from '../../../cae-model/transmission-elements/spur-gear';
import { InternalSpurGearGeometry } from '../../../views-foundation/geometries/internal-spur-gear-geometry';
import { SpurGearGeometry } from '../../../views-foundation/geometries/spur-gear-geometry';
import { TransmissionElementView2D } from './transmission-element-view-2d';

export abstract class SpurGearBaseView2D extends TransmissionElementView2D {
    protected abstract updateSpurGear(group: Konva.Group): void;

    constructor(spurGear: SpurGear | InternalSpurGear) {
        super(spurGear);
    }

    protected geometry: SpurGearGeometry | InternalSpurGearGeometry;

    protected createShapes(): Konva.Group {
        return new Konva.Group({
            name: 'spur-gear-base-view-2d-group',
            transformable: true,
        });
    }

    protected paintElement(group: Konva.Group): void {
        this.updateSpurGear(group);
    }

    protected paintPitchLines(group: Konva.Group): void {
        this._updatePitchLine(group);
    }

    private _updatePitchLine(group: Konva.Group): void {
        const { tB, tDM, isOnShaft } = this.geometry;
        const width = tB * 1.5;

        super.paintHorizontalPitchLines(group, isOnShaft, width, tDM);
    }
}

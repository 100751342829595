import { Mesh, BufferGeometry, Material } from 'three';
import { BevelGear } from '../../../cae-model/transmission-elements/bevel-gear';
import { BevelGearGeometry } from '../../../views-foundation/geometries/bevel-gear-geometry';
import { createGearGeometry } from '../../functions/create-gear-geometry';
import { getOpeningAngle, getStartAngle, resolveOverlapDisplayOrCreateDefaultBufferGeometry } from '../../functions/utils-3d';
import { GearParameters } from '../../interfaces/gear.parameters';
import { getUnitSetScaleValue } from '../../functions/utils-3d';
import { ClipMode, TransmissionElementView3D } from './transmission-element-view-3d';
import { GEOMETRICAL_FORM } from '../../../cae-model/transmission-elements/bevel-gear-base';

export class BevelGearView3D extends TransmissionElementView3D {
    constructor(private _bevelGear: BevelGear) {
        super(_bevelGear);
        this.clipMode = ClipMode.ClipOnPlane;
    }

    protected setMesh(geometry: BufferGeometry, material: Material): Mesh {
        const mesh = new Mesh(geometry, material);
        const flipOrientation = this._bevelGear.geometricalForm === GEOMETRICAL_FORM.DIVERGENT;
        if (flipOrientation) {
            mesh.scale.x = -1;
        }
        return mesh;
    }

    protected get clipMeshEnabled(): boolean {
        return true;
    }

    private _createGearParameters(): GearParameters {
        const geometry = new BevelGearGeometry(this._bevelGear, getUnitSetScaleValue(this.input.settings.unitSet));
        this.isOnShaft = geometry.isOnShaft;

        const view3dSetting = this.input.settings;
        const teethCount = Math.min(Math.max(Math.round(geometry.tDO * 0.6), 25), 300);
        const normalModule = geometry.tDO / teethCount;
        return {
            faceWidth: geometry.tB,
            helixAngleReferenceDiameter: 0,
            normalModule: normalModule,
            numberOfTeeth: teethCount,
            diameter: geometry.tBore,
            startAngle: getStartAngle(view3dSetting),
            openingAngle: getOpeningAngle(view3dSetting.clippingType),
            isRingGear: false,
            clippingType: this.input.settings.clippingType,
            clippingPlaneType: this.input.settings.clippingPlane,
            flipClippingPlane: this.input.settings.flipClippingPlane,
            offsetDiameter: geometry.tDO - geometry.tDI,
        };
    }

    protected setBufferGeometry(): BufferGeometry {
        const gearParameters = this._createGearParameters();
        const gearGeometry = createGearGeometry(gearParameters);
        return resolveOverlapDisplayOrCreateDefaultBufferGeometry(this.input.settings, gearGeometry);
    }
}

import Konva from 'konva';
import { TransmissionElement } from '../../../cae-model/transmission-elements/transmission-element';
import { generateUpperAndLowerParts } from '../../functions/utils-2d';
import { ElementView2D } from '../element-view-2d';
import {
    BOUNDING_BOX,
    DASH_STROKE_TRANSMISSION_ELEMENT,
    DISPLAYBLACK,
    DISPLAYRED,
    FIXED_SCALED_VALUE_ZOOM_TO_FIT,
    FONT_FAMILY_2D,
    TEXT_FONT_SIZE,
    LINE_CAP_2D,
    LINE_JOIN_2D,
    NO_DASH,
    STROKE_WIDTH,
    TRANSPARENT,
} from '../view-2d-constants';

interface PitchLine {
    width: number;
    isOnShaft: boolean;
    y1: number;
    y2: number;
}

export abstract class TransmissionElementView2D extends ElementView2D {
    protected group: Konva.Group | null = null;
    protected abstract paintElement(group: Konva.Group): void;
    protected abstract paintPitchLines(group: Konva.Group): void;
    protected abstract createShapes(): Konva.Group;
    protected abstract setGeometry(): void;

    constructor(transmissionElement: TransmissionElement) {
        super(transmissionElement);
    }

    protected get draggable() {
        return true;
    }

    protected get isStandardSelector() {
        return true;
    }

    protected updateGroupWithoutChildren(): void {
        this.setGeometry();

        // override basis class
        this.group?.destroy();
        this.group = this.createShapes();
        this.group.add(this.paintCommonTransmission());

        const boundingRectForUTSelection = new Konva.Rect({
            ...this.group.getClientRect({
                skipStroke: true,
            }),
            fill: TRANSPARENT,
            name: BOUNDING_BOX,
        });
        this.group.add(boundingRectForUTSelection);

        this.groupWithoutChildren.add(this.group);
    }

    protected paintCommonTransmission(): Konva.Group {
        const group: Konva.Group = new Konva.Group();
        this.paintElement(group);
        this.paintPitchLines(group);
        return group;
    }

    protected paintHorizontalPitchLines(group: Konva.Group, isOnShaft: boolean, width: number, dm: number): void {
        const y = dm > 0.001 / this.input.unitScaleFactor ? -dm / 2.0 : 0;

        const pitchLine = this._createPitchLine({
            width,
            isOnShaft,
            y1: y,
            y2: y,
        });

        group.add(generateUpperAndLowerParts(pitchLine, 'horizonal-pitch-line'));
    }

    protected paintDiagonalPitchLines(
        group: Konva.Group,
        isOnShaft: boolean,
        width: number,
        dOut: number,
        dIn: number,
        dMid: number,
    ): void {
        if (dMid < 0.2) {
            dMid = 0.0;
        }

        const gradient = (dIn - dOut) / 2.0 / (width / 1.5);
        const leftY = gradient * (-width / 2.0);
        const rightY = gradient * (width / 2.0);

        const y1 = leftY + dMid / 2.0;
        const y2 = rightY + dMid / 2.0;
        const pitchLine = this._createPitchLine({ width, y1, y2, isOnShaft });

        group.add(generateUpperAndLowerParts(pitchLine, 'diagonal-pitch-line'));
    }

    private _createPitchLine(pitchLine: PitchLine): Konva.Line {
        const { width, isOnShaft, y1, y2 } = pitchLine;
        return new Konva.Line({
            points: [-width / 2.0, y1, width / 2.0, y2],
            stroke: isOnShaft ? DISPLAYBLACK : DISPLAYRED,
            strokeWidth: STROKE_WIDTH,
            strokeScaleEnabled: false,
            lineCap: LINE_CAP_2D,
            lineJoin: LINE_JOIN_2D,
            dash: isOnShaft ? DASH_STROKE_TRANSMISSION_ELEMENT : NO_DASH,
            draggable: false,
        });
    }

    protected createText(text: string, color: string, x: number, y: number): Konva.Text {
        const transmissionText = new Konva.Text({
            fontFamily: FONT_FAMILY_2D,
            fontSize: TEXT_FONT_SIZE / this.input.unitScaleFactor,
            fill: color,
            x: x,
            y: y,
            text: text,
        });
        transmissionText.setAttr(FIXED_SCALED_VALUE_ZOOM_TO_FIT, true);
        return transmissionText;
    }
}

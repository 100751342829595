import Konva from 'konva';
import { ModelElement } from '../../cae-model/model-element';
import { ElementView } from '../../views-foundation/element-view';
import { ClickType } from '../../views-foundation/interfaces/selected-in-viewer-element-interface';
import { HammerTouchEvent } from '../../views-foundation/views-foundation-constants';
import { TranslationalTransformer } from '../2d-controls/translational-transformer';
import { ElementView2DInput } from './element-view-2d-input';
import { VIEW_WITHOUT_CHILDREN_SUFFIX } from './view-2d-constants';

export enum PartType {
    Up,
    Down,
}

export abstract class ElementView2D extends ElementView {
    protected groupWithChildren: Konva.Group;
    protected groupWithoutChildren: Konva.Group;
    protected elementView2DInput: ElementView2DInput;
    protected groupCreated = false;
    private _isSelected = false;
    private _editable = false;
    private _translationalTransformer: TranslationalTransformer;
    private _hammer: HammerManager;

    protected setZLevel() {}

    protected determineSelectedElement(): void {
        if (this._isSelected) {
            this._updateTransformers();
        }
    }

    public updateAfterLayerIsSet(): void {
        this.setZLevel();
        this.determineSelectedElement();
    }

    constructor(modelElement: ModelElement) {
        super(modelElement);
        this._createGroups();
        this._setPosition();
    }

    public get input(): ElementView2DInput {
        return this.elementView2DInput;
    }

    createGroupWithChildren(input: ElementView2DInput): Konva.Group {
        this.groupCreated = true;
        this.elementView2DInput = input;
        this.updateGroups();
        this.elementView2DInput.getSelectedElement().subscribe(selectedInterface => this._updateIsSelected(selectedInterface.elementID));
        this.elementView2DInput.isModelEditable().subscribe(val => this._updateIsEditable(val));

        this.groupWithoutChildren.on('contextmenu', event => this._setSelectedElementEvent(ClickType.RIGHT_CLICK));
        const touchEvents = [HammerTouchEvent.TAP, HammerTouchEvent.PRESS, HammerTouchEvent.DOUBLE_TAP];
        touchEvents.forEach((eventName: string) => {
            let clickType = ClickType.LEFT_CLICK;

            if (eventName === HammerTouchEvent.PRESS) {
                clickType = ClickType.RIGHT_CLICK;
            } else if (eventName === HammerTouchEvent.DOUBLE_TAP) {
                clickType = ClickType.DOUBLE_LEFT_CLICK;
            }

            this.groupWithoutChildren.on(eventName, () => {
                this._setSelectedElementEvent(clickType);
            });
        });
        this.groupWithoutChildren.on('contextmenu', event => {
            event.evt.preventDefault();
        });
        this.groupWithChildren.on('dragmove', () => this._onDragMove());
        return this.groupWithChildren;
    }

    get editable(): boolean {
        return this._editable;
    }

    getGroupWithChildren(): Konva.Group {
        return this.groupWithChildren;
    }

    getGroupWithoutChildren(): Konva.Group {
        return this.groupWithoutChildren;
    }

    protected doUpdateTransformer(): void {}
    protected abstract updateGroupWithoutChildren(): void;
    protected abstract get draggable(): boolean;
    protected abstract get isStandardSelector(): boolean;

    protected onDestroy(): void {
        const clickEvents: string[] = ['click', 'contextmenu'];
        clickEvents.forEach((eventName: string) => {
            this.groupWithoutChildren.off(eventName);
        });

        const touchEvents: string[] = [HammerTouchEvent.TAP, HammerTouchEvent.PRESS, HammerTouchEvent.DOUBLE_TAP];
        touchEvents.forEach((eventName: string) => {
            this.groupWithoutChildren.off(eventName);
        });
        this._hammer.destroy();

        this.groupWithChildren.off('dragmove');

        this.groupWithoutChildren.destroy();

        if (this._translationalTransformer != null) {
            this._translationalTransformer.destroy();
        }
    }

    protected updateGroups(): void {
        // override basis class
        if (!this.groupCreated) {
            return;
        }
        this.updateGroupWithoutChildren();
        if (this.isStandardSelector) {
            this._translationalTransformer.updateTransformer({
                visible: this._isSelected,
                dragable: this._editable,
            });
        }
        this._setPosition();
        this._layer?.batchDraw();
    }

    private _updateIsSelected(selectedElementId: string): void {
        const isSelected = this.modelElement && this.modelElement.id === selectedElementId;
        if (isSelected === this._isSelected) {
            return; // nothing to do
        }
        this._isSelected = isSelected;
        this._updateTransformers();
    }

    private _updateIsEditable(value: boolean): void {
        this._editable = value;
        this._updateTransformers();
    }

    private _updateTransformers(): void {
        const draggable = this.draggable && this._isSelected && this._editable;
        this.groupWithChildren.draggable(draggable);
        if (this.isStandardSelector) {
            this._translationalTransformer.updateTransformer({
                visible: this._isSelected,
                dragable: this._editable,
            });
        }
        this.doUpdateTransformer();
        this._layer?.batchDraw();
    }

    private get _layer(): Konva.Layer | null {
        return this.groupWithChildren.getLayer();
    }

    protected get isSelected(): boolean {
        return this._isSelected;
    }

    private _onDragMove() {
        this.modelElement.x = this.groupWithChildren.x();
        this.modelElement.update();
    }

    private _createGroups() {
        this.groupWithChildren = new Konva.Group({
            name: this.modelElement.name,
            draggable: false,
            y: 0,
            dragBoundFunc: function(pos) {
                return {
                    x: pos.x,
                    y: this.absolutePosition().y,
                };
            },
        });
        this.groupWithoutChildren = new Konva.Group();
        this._hammer = new Hammer(this.groupWithoutChildren as any, { domEvents: true });
        this.groupWithoutChildren.name(this.modelElement.name + VIEW_WITHOUT_CHILDREN_SUFFIX);
        this.groupWithChildren.add(this.groupWithoutChildren);
        if (this.isStandardSelector) {
            this._translationalTransformer = new TranslationalTransformer([this.groupWithoutChildren], this.groupWithChildren);
        }
    }

    private _setPosition() {
        this.groupWithChildren.x(this.modelElement.x);
    }

    private _setSelectedElementEvent(event: ClickType) {
        const stage = this.groupWithoutChildren.getStage();
        if (!stage) {
            return;
        }

        const position = stage.getPointerPosition()!;
        const containerRect = stage.container().getBoundingClientRect();
        this.elementView2DInput.setSelected({
            clickType: event,
            elementID: this.modelElement.id,
            clientX: containerRect.left + position.x,
            clientY: containerRect.top + position.y,
        });
    }
}

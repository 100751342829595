import { ModelElement } from '../../../cae-model/model-element';
import { VNotch } from '../../../cae-model/notches/v-notch';
import { BearinxStandardBinder } from '../../binders/bearinx-standard-binder';
import { NotchMediator } from './notch-mediator';

// mediator, used for the convergence of v-notches
export class VNotchMediator extends NotchMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_V_GROOVE'];
    }

    protected createModelElement(): ModelElement {
        return new VNotch();
    }

    private _createBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [{ left: 'depth', right: 'IDN_VGROOVE_DEPTH' }];
        this.addBinder(binder);
    }
}

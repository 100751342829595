import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CopyService } from '../copy/copy.service';
import { WindowRef } from '../util/window-ref';
import { ShareService } from './share.service';

@Component({
    templateUrl: 'share-dialog.component.html',
    styleUrls: ['share-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareDialogComponent {
    public url: string;

    constructor(private readonly _shareService: ShareService, private readonly _copyService: CopyService, windowRef: WindowRef) {
        this.url = windowRef.nativeWindow.location.href;
    }

    public share(): void {
        this._shareService.share('My model:', 'I want to show a Bearinx model to you.', this.url);
    }

    public copy(): void {
        this._copyService.copy(this.url);
    }
}

import { PartType } from '../element-view-2d';
/* eslint-disable no-shadow */
import Konva from 'konva';
import { BOUNDING_BOX, OPACITY_SHAFT, SEGMENT_COLOR_2D, STROKE_COLOR_2D, STROKE_WIDTH } from '../view-2d-constants';
import { ElementView2D } from '../element-view-2d';
import { RectTransformer } from '../../2d-controls/rect-transformer';
import { PipeSegment } from '../../../cae-model/shaft-segments/pipe-segment';
import { ShaftSegmentCoordinates } from '../../../cae-model/shaft-segments/shaft-segment';

export class PipeSegmentView2D extends ElementView2D {
    private _boundary: Konva.Rect;
    private _pipeUp: Konva.Rect;
    private _pipeDown: Konva.Rect;
    private _transformer: RectTransformer;

    constructor(pipe: PipeSegment) {
        super(pipe);
        this._createShapes();
        this._transformer = new RectTransformer(this._boundary, this.groupWithoutChildren);
        this._transformer.onTransform = () => this._onTransform();
    }

    protected doUpdateTransformer(): void {
        this._transformer.appearance = {
            visible: this.isSelected,
            transformable: this.editable,
        };
        this._transformer.unitScaleFactor = this.elementView2DInput.unitScaleFactor;
    }

    protected get draggable() {
        return false;
    }

    protected get isStandardSelector() {
        return false;
    }

    setZLevel() {
        this.groupWithoutChildren.moveToBottom();
    }

    private _onTransform() {
        this._pipe.outerDiameter = this._boundary.height();

        const xStartNew = this._pipe.x + this._boundary.x();
        const coors: ShaftSegmentCoordinates = {
            xStart: xStartNew,
            xEnd: xStartNew + this._boundary.width(),
        };
        this._pipe.setCoordinates(coors);

        this._pipe.update();
        this._transformer.update();
    }

    private get _pipe(): PipeSegment {
        return this.modelElement as PipeSegment;
    }

    private _createShapes(): void {
        this._boundary = this._createBoundary();
        this._updateBoundary();

        this._pipeUp = this._createPipePart();
        this._pipeDown = this._createPipePart();
        this._updatePipe(PartType.Up);
        this._updatePipe(PartType.Down);
        this.groupWithoutChildren.add(this._pipeUp);
        this.groupWithoutChildren.add(this._pipeDown);
        this.groupWithoutChildren.add(this._boundary);
    }

    protected updateGroupWithoutChildren(): void {
        // override basis class
        this._updateBoundary();
        this._updatePipe(PartType.Up);
        this._updatePipe(PartType.Down);
    }

    private _createPipePart(): Konva.Rect {
        const pipePart = new Konva.Rect();
        pipePart.stroke(STROKE_COLOR_2D);
        pipePart.opacity(OPACITY_SHAFT);
        pipePart.fill(SEGMENT_COLOR_2D);

        return pipePart;
    }

    private _updatePipe(pipePartType: PartType) {
        const pipePart = pipePartType === PartType.Up ? this._pipeUp : this._pipeDown;
        pipePart.width(this._pipe.length);
        pipePart.height(this._getPipeHeight());
        pipePart.strokeWidth(this._pipe.innerDiameter > 0 ? STROKE_WIDTH : 0);
        pipePart.strokeScaleEnabled(false);
        pipePart.y(pipePartType === PartType.Up ? -this._pipe.outerDiameter / 2 : this._pipe.outerDiameter / 2 - this._getPipeHeight());
    }

    private _createBoundary(): Konva.Rect {
        return new Konva.Rect({
            stroke: STROKE_COLOR_2D,
            strokeWidth: STROKE_WIDTH,
            strokeScaleEnabled: false,
            fillEnabled: false,
            scaleX: 1,
            scaleY: 1,
        });
    }

    private _updateBoundary(): void {
        const shape = this._boundary;
        const height = this._pipe.outerDiameter;
        shape.width(this._pipe.length);
        shape.height(height);

        shape.x(0);
        shape.y(-shape.height() / 2);
        shape.name(BOUNDING_BOX);
    }

    private _getPipeHeight(): number {
        return (this._pipe.outerDiameter - this._pipe.innerDiameter) / 2;
    }
}

import { ModelElement } from '../../../cae-model/model-element';
import { GearAbstract } from '../../../cae-model/transmission-elements/gear-abstract';
import { BearinxStandardBinder } from '../../binders/bearinx-standard-binder';
import { BasicMediator } from '../basic-mediator';
export class GearAbstractMediator extends BasicMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_GEAR_ABSTRACT'];
    }

    protected createModelElement(): ModelElement {
        return new GearAbstract();
    }

    private _createBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [
            { left: 'x', right: 'IDCO_X' },
            { left: 'outerDiameter', right: 'IDTE_D' },
            { left: 'width', right: 'IDTE_B' },
            { left: 'xOffsetLoad', right: 'IDGA_X_OFFSET_LOAD' },
            { left: 'effectiveDiameter', right: 'IDGA_DM' },
        ];
        this.addBinder(binder);
    }
}

import { Component, HostBinding, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'bx-form-field',
    templateUrl: './form-field.component.html',
    styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent implements AfterViewInit, OnDestroy {
    public isFocus = false;
    private _stop$ = new Subject();

    constructor(private element: ElementRef) {}

    ngAfterViewInit() {
        const inputElement = this.element.nativeElement.querySelector('input[bxInput],textarea[bxInput]');
        if (inputElement) {
            fromEvent<any>(inputElement, 'focus')
                .pipe(takeUntil(this._stop$))
                .subscribe(() => (this.isFocus = true));
            fromEvent<any>(inputElement, 'blur')
                .pipe(takeUntil(this._stop$))
                .subscribe(() => (this.isFocus = false));
        }
    }
    public ngOnDestroy(): void {
        this._stop$.next();
        this._stop$.complete();
    }

    @HostBinding('class.focus')
    get focus() {
        return this.isFocus;
    }
}

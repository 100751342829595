<div class="cookies">
    <h2>{{ 'BEARINX.COOKIE_POLICY.TITLE' | translate }}</h2>
    <h3>{{ 'BEARINX.COOKIE_POLICY.TYPE_COOKIE' | translate }}</h3>
    <ul class="list">
        <li>
            <p>{{ 'BEARINX.COOKIE_POLICY.INFO' | translate }}</p>
        </li>
        <li>
            <p>{{ 'BEARINX.COOKIE_POLICY.INFO_1' | translate }}</p>
        </li>
        <li>
            <p>{{ 'BEARINX.COOKIE_POLICY.NECESSARY' | translate }}</p>
            <ul class="list">
                <li>{{ 'BEARINX.COOKIE_POLICY.INFO_TECH' | translate }}</li>
            </ul>
        </li>
    </ul>
    <h3>{{ 'BEARINX.COOKIE_POLICY.MANAGING' | translate }}</h3>
    <ul class="list">
        <li>
            <p>{{ 'BEARINX.COOKIE_POLICY.TEXT_MANAG' | translate }}</p>
        </li>
        <li>
            <p>{{ 'BEARINX.COOKIE_POLICY.TEXT_MANAG_2' | translate }}</p>
        </li>
        <li>
            <p>{{ 'BEARINX.COOKIE_POLICY.BROWSERS' | translate }}</p>
            <ul class="list">
                <li>
                    Internet Explorer
                    <a href="https://support.microsoft.com/help/17442/windows-internet-explorer-delete-manage-cookies"
                        >https://support.microsoft.com/help/17442/windows-internet-explorer-delete-manage-cookies</a
                    >
                </li>
                <li>
                    Mozilla Firefox
                    <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
                        >https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a
                    >
                </li>
                <li>
                    Google Chrome
                    <a href="https://support.google.com/accounts/answer/61416">https://support.google.com/accounts/answer/61416</a>
                </li>
                <li>Safari <a href="https://support.apple.com/kb/PH21411">https://support.apple.com/kb/PH21411</a></li>
            </ul>
        </li>
        <li>
            <p>
                {{ 'BEARINX.COOKIE_POLICY.NETWORK' | translate }} (<a href="http://www.networkadvertising.org/managing/opt_out.asp"
                    >http://www.networkadvertising.org/managing/opt_out.asp</a
                >) {{ 'BEARINX.COOKIE_POLICY.DIGITAL' | translate }} (<a href="http://www.aboutads.info/choices"
                    >http://www.aboutads.info/choices</a
                >).
            </p>
        </li>
        <li>
            <p>
                {{ 'BEARINX.COOKIE_POLICY.CONTACT' | translate }}
                <a
                    class="icon icon-mail"
                    data-widget="GATrakken"
                    data-ar-event-category="Engagement"
                    data-ar-event-action="Content/Mailto"
                    data-ar-event-label="Datenschutz@schaeffler.com mailto:Datenschutz@schaeffler.com"
                    href="mailto:Datenschutz@schaeffler.com"
                    target="_self"
                    title="Datenschutz@schaeffler.com "
                    >Datenschutz@schaeffler.com
                </a>
            </p>
        </li>
    </ul>
    <p>
        <strong>{{ 'BEARINX.DATA_PRIVACY.STATUS' | translate }}</strong>
    </p>
</div>

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MousePositionInterface } from '../interfaces/mouse-position-interface';

@Injectable()
export class MousePositionService {
    private readonly _mousePosition = new BehaviorSubject<MousePositionInterface>({ x: 0, y: 0 });

    getMousePosition(): Observable<MousePositionInterface> {
        return this._mousePosition.asObservable();
    }

    setMousePosition(mousePosition: MousePositionInterface): void {
        // somehow undefined ends up in the mousePosition, ensure we definitely have numeric values
        this._mousePosition.next({
            x: mousePosition?.x ?? 0,
            y: mousePosition?.y ?? 0,
        });
    }
}

import { AfterViewInit, ContentChild, Directive, ElementRef } from '@angular/core';
import { InputDirective } from './input.directive';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'bx-input-addons',
})
export class InputAddonsDirective {
    constructor(public readonly element: ElementRef) {}
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'bx-input-container',
})
export class InputContainerDirective implements AfterViewInit {
    @ContentChild(InputDirective)
    public input: InputDirective;

    @ContentChild(InputAddonsDirective)
    public addons: InputAddonsDirective;

    public ngAfterViewInit(): void {
        if (!this.input) {
            throw new Error('Cannot find InputDirective in InputContainer');
        }
        if (!this.addons) {
            return;
        }

        this._updateInputPadding();
    }

    private _updateInputPadding(): void {
        setTimeout(() => {
            const base = 12;
            const clientWidth = this.addons.element.nativeElement.clientWidth;
            const margin = clientWidth > 0 ? 8 : 0;
            this.input.paddingRight = `${clientWidth + margin + base}px`;
        });
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '../icon/icon.module';
import { TitleBarComponent } from './title-bar.component';

@NgModule({
    imports: [CommonModule],
    declarations: [TitleBarComponent],
    exports: [TitleBarComponent, IconModule],
})
export class TitleBarModule {}

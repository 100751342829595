import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { HttpStatusCode } from '../../error-handling';

@Injectable({
    providedIn: 'root',
})
export class ModelHttpResponseTranslatorService {
    constructor(private _translateService: TranslateService) {}

    translate(error: HttpErrorResponse): string {
        switch (error.status) {
            case HttpStatusCode.BadRequest:
                return this._badRequest(error.error);
            case HttpStatusCode.NotFound:
                return this._notFound(error.error);
            case HttpStatusCode.Forbidden:
                return this._forbidden();
            default:
                return this._translateService.instant('ERRORS.GENERAL');
        }
    }

    private _badRequest(error: HttpErrorResponse): string {
        const resultMessage: string[] = [];
        if (error.hasOwnProperty('id')) {
            resultMessage.push(this._translateService.instant('SHARED.MODEL.WRONG_ID'));
        }
        if (error.hasOwnProperty('groupId')) {
            resultMessage.push(this._translateService.instant('GROUP.WRONG_ID'));
        }
        return resultMessage.join(' ');
    }

    private _notFound(error: { toString: () => string }): string {
        return error.toString();
    }

    private _forbidden(): string {
        return this._translateService.instant('FORBIDDEN.MESSAGE');
    }
}

import { Injectable } from '@angular/core';
import { WebStorageService } from '../storage/web-storage.service';
import { LockToken } from './lock-token.model';

@Injectable({ providedIn: 'root' })
export class LockTokenStorageService {
    private readonly _lockTokenMap = new Map<string, LockToken>();
    private readonly _storageKey = 'LOCK_TOKENS';

    get(modelId: string): LockToken | null {
        return this._lockTokenMap.get(modelId) ?? null;
    }

    set(modelId: string, lockToken: LockToken | null) {
        if (lockToken == null) {
            this._lockTokenMap.delete(modelId);
        } else {
            this._lockTokenMap.set(modelId, lockToken);
        }

        this._store();
    }

    constructor(private readonly _webStorageService: WebStorageService) {
        this._restore();
    }

    private _restore(): void {
        const restoredLockTokens = this._webStorageService.getValue<{ [modelId: string]: LockToken }>(this._storageKey, true);
        if (restoredLockTokens) {
            Object.entries(restoredLockTokens).forEach(([key, value]) => this._lockTokenMap.set(key, value));
        }
    }

    private _store(): void {
        this._webStorageService.setValue(this._storageKey, Object.fromEntries(this._lockTokenMap.entries()), true);
    }
}

import { WMF_RadialBallBearing } from './wmf-images/wmf-radial-ball-bearing';
import { RadialBall, RADIAN_BALL_PROPERTIES } from '../../../../cae-model/supports/bearings/radial-ball';
import { SupportGeometry } from '../../../../views-foundation/geometries/support-geometry';
import { BearingView2D } from './bearing-view-2d';
import { WMFImage } from './wmf-images/wmf-image';
import { Group } from 'konva/lib/Group';
import { Line } from 'konva/lib/shapes/Line';
import { getColor } from '../../../functions/utils-2d';
import { PRELOAD_SPRING_NAME, STROKE_WIDTH } from '../../view-2d-constants';

export class RadialBallView2D extends BearingView2D {
    constructor(radialBall: RadialBall) {
        super(radialBall);
    }

    private get _radialBall(): RadialBall {
        return this.modelElement as RadialBall;
    }

    protected updateElement(group: Group, geometry: SupportGeometry): void {
        if (this._radialBall.idlWithPreloadSpring === false) {
            return;
        }

        const preloadSpringBottom = this._createPreloadSpring(geometry);
        const preloadSpringTop = preloadSpringBottom.clone();
        preloadSpringTop.scaleY(-1);

        [preloadSpringBottom, preloadSpringTop].forEach((preloadSpring: Line) => {
            group.add(preloadSpring);
        });
    }

    private _createPreloadSpring(geometry: SupportGeometry): Line {
        const { sB: width, sDI: innerDiameter, sDO: outerDiameter, color } = geometry;
        const isInnerSpring = this._radialBall.idlPreloadRing === RADIAN_BALL_PROPERTIES.LB_INNERRING;
        const bearingHeight = (outerDiameter - innerDiameter) / 2;
        const springHeight = isInnerSpring ? bearingHeight / 3 : bearingHeight / 2;
        const halfSpringHeight = springHeight / 2;
        const springGap = 1 / this.input.unitScaleFactor;
        const offsetY = isInnerSpring ? halfSpringHeight : -halfSpringHeight;
        const y = (isInnerSpring ? innerDiameter : outerDiameter) / 2 + offsetY;

        const preloadSpring = new Line({
            strokeWidth: STROKE_WIDTH,
            stroke: getColor(color),
            name: PRELOAD_SPRING_NAME,
            points: [
                width,
                y,
                width + springGap,
                y,
                width + springGap * 2,
                y - halfSpringHeight,
                width + springGap * 3,
                y + halfSpringHeight,
                width + springGap * 4,
                y - halfSpringHeight,
                width + springGap * 5,
                y + halfSpringHeight,
                width + springGap * 6,
                y - halfSpringHeight,
            ],
            strokeScaleEnabled: false,
        });

        if (this._radialBall.idlPreloadDirection === RADIAN_BALL_PROPERTIES.LB_AXIAL_NEGATIVE_DIRECTION) {
            preloadSpring.scaleX(-1);
            preloadSpring.offsetX(width);
        }

        return preloadSpring;
    }

    // Create image manually. Overrides basic class.
    protected createWMFImage(geometry: SupportGeometry): WMFImage {
        const image = new WMF_RadialBallBearing();
        image.withoutInnerRing_ = false;
        image.numberOfRows_ = this._radialBall.nbRows;
        image.dy_ = (geometry.sDO - geometry.sDI) / 2;
        image.dx_ = geometry.sB;
        image.gapDistance_ = 1;
        return image;
    }
}

import {
    BEARING_STROKE_WIDTH_DEFAULT,
    BOUNDING_BOX,
    DISPLAYBRIGHTGREEN,
    DISPLAYGREEN,
    DISPLAYRED,
    NOT_IN_STAGE_BOUNDING_BOX,
    TRANSPARENT,
} from './../elements-view/view-2d-constants';
import { SupportGeometry, SupportGeometryColor } from '../../views-foundation/geometries/support-geometry';
import { Stage } from 'konva/lib/Stage';
import { Group } from 'konva/lib/Group';
import { IRect } from 'konva/lib/types';
import { Shape } from 'konva/lib/Shape';
import { WMFImage } from '../elements-view/supports/bearing/wmf-images/wmf-image';
import { Rect } from 'konva/lib/shapes/Rect';

export function generateUpperAndLowerParts(upperPart: Shape, partName: string): Group {
    const group = new Group();

    const lowerPart = upperPart.clone();
    upperPart.name(`${partName}`);
    group.add(upperPart);

    lowerPart.name(`${partName}`);
    lowerPart.y(-lowerPart.y());
    lowerPart.scaleY(-1);
    group.add(lowerPart);

    return group;
}

export function getColor(colorEnum: SupportGeometryColor): string {
    if (colorEnum === SupportGeometryColor.DISPLAYGREEN) {
        return DISPLAYGREEN;
    } else if (colorEnum === SupportGeometryColor.DISPLAYBRIGHTGREEN) {
        return DISPLAYBRIGHTGREEN;
    } else if (colorEnum === SupportGeometryColor.UNDIFINED) {
        return DISPLAYRED;
    } else {
        throw new Error('Unknown type of SupportGeometryColor');
    }
}

export function getBoundingBoxSize(view: Stage | Group): IRect {
    const nodesNotInBoundingBox = view.find(
        (node: { getAttr: (arg0: string) => boolean }) => node.getAttr(NOT_IN_STAGE_BOUNDING_BOX) === true,
    );
    nodesNotInBoundingBox.forEach(element => element.hide());
    const clientRect = view.getClientRect({ skipTransform: true });

    nodesNotInBoundingBox.forEach(element => element.show());
    return clientRect;
}

export function getBoundingBoxWithoutName(group: Group, names: string[]): IRect {
    const notInMainModel = group.find(
        (node: { getAttr: (arg0: string) => boolean; name: () => string; parent: { name: () => string } }) =>
            node.getAttr(NOT_IN_STAGE_BOUNDING_BOX) === true || (node.name() && names.some(name => name === node.name())),
    );
    notInMainModel.forEach(element => element.hide());
    const clientRect = group.getClientRect({ skipTransform: true, skipShadow: true, skipStroke: true });
    notInMainModel.forEach(element => element.show());

    return clientRect;
}

export function createBoundingBox(group: Group): Rect {
    const { x, y, width, height } = group.getClientRect({
        skipStroke: true,
    });
    return new Rect({
        width,
        height,
        x,
        y,
        fill: TRANSPARENT,
        name: BOUNDING_BOX,
        strokeScaleEnabled: false,
    });
}

export function getSupportDimensions(geometry: SupportGeometry): { supportWidth: number; supportHeight: number } {
    const { sDO, sDI, sX, sB } = geometry;
    return { supportWidth: sX + sB / 2.0 - (sX - sB / 2.0), supportHeight: (sDO - sDI) / 2.0 };
}

export function getSupportGroupFromWMF(image: WMFImage, geometry: SupportGeometry): Group {
    const imageGroup = image.paint();

    const width = image.width;
    const height = image.heigth;
    const { supportHeight: bearingHeight, supportWidth: bearingWidth } = getSupportDimensions(geometry);
    const scaleX = bearingWidth / width;
    const scaleY = bearingHeight / height;

    imageGroup.scaleX(scaleX);
    imageGroup.scaleY(scaleY);

    const shapes = imageGroup.find('Shape');
    const strokeWidth = BEARING_STROKE_WIDTH_DEFAULT;
    shapes.forEach(shapeFrom => {
        const shape = shapeFrom as Shape;
        shape.stroke(getColor(geometry.color));
        shape.strokeWidth(strokeWidth);
        shape.strokeScaleEnabled(false);
    });

    const imageGroupWrap = new Group();
    imageGroupWrap.add(imageGroup);
    return imageGroupWrap;
}

import { animate, state, style, transition, trigger } from '@angular/animations';

export const enterLeaveAnimation = trigger('enterLeaveAnimation', [
    state('void', style({ opacity: 0, maxHeight: 0 })),
    transition(':enter,:leave', animate('300ms ease-in-out')),
]);

export const enterLeaveAnimationWithStaticHeight = trigger('enterLeaveAnimationWithStaticHeight', [
    state('void', style({ opacity: 0 })),
    transition(':enter,:leave', animate('300ms ease-in-out')),
]);

import { Notch } from './notch';

export class RectangularGroove extends Notch {
    radius = 0;
    depth = 0;
    width = 0;

    protected dependsOnSegmentSide(): boolean {
        return false;
    }
}

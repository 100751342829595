/* eslint-disable sonarjs/cognitive-complexity */
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { DatePipe } from '@angular/common';
import * as JSZip from 'jszip';
import { ExportDataType } from './data-types/export.data.type';
import { DataProviderService } from './data.provider.service';
import { ExportOptions } from './export.options';

export abstract class BaseExporter {
    /**
     * C'tor
     * @param _dataProviderService DataProviderService instance
     * @param _datePipe Date pipe instance
     * @param outputFormat Name of output format
     * @param exportOptions List of available export options
     */
    protected constructor(
        protected readonly _dataProviderService: DataProviderService,
        protected readonly _datePipe: DatePipe,
        public readonly outputFormat: string,
        public readonly exportOptions: ExportOptions[] = [],
    ) {}

    /**
     * Gets the name of the exporter instance
     */
    public getExporterName(): string {
        return this.outputFormat;
    }

    /**
     * Exports the given input data
     * @param input Input data to be converted
     * @param inputFormat Format of the given data
     * @param options Optional options for converting
     */
    public export(input: any, inputFormat: string, options?: ExportOptions): void {
        const providers = this._dataProviderService.getDataProviders(this.outputFormat, [inputFormat]);
        if (providers && providers.length > 0) {
            const finished$ = new Subject<void>();
            providers[0]
                .convert(input, options)
                .pipe(takeUntil(finished$))
                .subscribe(data =>
                    this.createExportData(data, options)
                        .pipe(takeUntil(finished$))
                        .subscribe(exportData => {
                            finished$.next();
                            finished$.complete();
                            this.saveData(exportData, options);
                        }),
                );
        }
    }

    /**
     * Returns the default extension of the uncompressed export file
     * @returns Default extension without dot
     */
    protected abstract getExtension(): string;

    /**
     * Creates the export data from given data
     * @param data Data to be exported
     * @param options Optional options for converting
     * @returns A single data object or a list of data objects with name to be stored in a ZIP file
     */
    protected abstract createExportData(data: any, options?: ExportOptions): Observable<ExportDataType>;

    /**
     * Creates a filename for the file to be downloaded
     * @param options Optional options for converting
     * @param fileName Optional filename
     * @returns a file name
     */
    protected createFileName(options?: ExportOptions, fileName?: string): string {
        const extension = options && options.createZip ? 'zip' : this.getExtension();
        const date = this._datePipe.transform(Date.now(), 'yyyyMMdd-HHmmss');

        const outputName = fileName && fileName.length > 0 ? fileName : this.outputFormat;
        return `${outputName}_${date}.${extension}`;
    }

    /**
     * Saves the data for downloading in browser
     * @param exportData Data to be exported
     * @param options Optional options for converting
     */
    private saveData(exportData: ExportDataType, options?: ExportOptions) {
        if ((options && options.createZip) || Array.isArray(exportData)) {
            const zip = new JSZip();
            const files = Array.isArray(exportData) ? exportData : [exportData];

            files.forEach(file => {
                if (!file.fileName || file.fileName.length <= 0) {
                    throw new Error('Filename missing!');
                }
                zip.file(file.fileName, file.data);
            });

            zip.generateAsync({ type: 'blob' }).then(blob => {
                const fileNameOptions = <ExportOptions>Object.assign(options || {}, new ExportOptions('', true));
                FileSaver.saveAs(blob, this.createFileName(fileNameOptions));
            });
        } else {
            let blob;
            if (exportData.data && exportData.data.size && exportData.data.size > 2) {
                blob = exportData.data;
            } else {
                blob = new Blob([exportData.data], { type: exportData.mimeType });
            }
            FileSaver.saveAs(blob, options && options.filename ? options.filename : this.createFileName(options, exportData.fileName));
        }
    }
}

/* eslint-disable max-len */
import { WMFImage } from './wmf-image';

export class WMF_AngularSphericalPlainBearing extends WMFImage {
    constructor() {
        super(1001, 1001);
    }

    protected doPaint() {
        const gc = this.gc;

        gc.moveTo(0, 0);
        gc.lineTo(999, 0);
        gc.lineTo(999, 999);
        gc.lineTo(0, 999);
        gc.lineTo(0, 0);

        gc.moveTo(0, 199);
        gc.lineTo(173, 212);
        gc.lineTo(342, 248);
        gc.lineTo(500, 307);
        gc.lineTo(642, 387);
        gc.lineTo(766, 485);
        gc.lineTo(866, 599);
        gc.lineTo(939, 726);
        gc.lineTo(984, 861);
        gc.lineTo(999, 999);
    }
}

import { SpurGear } from '../../../cae-model/transmission-elements/spur-gear';
import { SpurGearBaseView3D } from './spur-gear-base-view-3d';
import { SpurGearGeometry } from '../../../views-foundation/geometries/spur-gear-geometry';
import { getUnitSetScaleValue } from '../../functions/utils-3d';

export class SpurGearView3D extends SpurGearBaseView3D {
    private _spurGeometry: SpurGearGeometry;

    constructor(gear: SpurGear) {
        super(gear);
    }

    private get _gear(): SpurGear {
        return this.modelElement as SpurGear;
    }

    protected updateGearGeometryParameters(): void {
        this._spurGeometry = new SpurGearGeometry(this._gear, this._getUnitScaleValue());
        this.isOnShaft = this._spurGeometry.isOnShaft;
    }

    protected get diameter(): number {
        return this._spurGeometry.tDI;
    }

    protected get pitchDiameter(): number {
        return this._spurGeometry.tDO;
    }

    protected get helixAngleReferenceDiameter(): number {
        return this._gear.helixAngleReferenceDiameter;
    }

    protected get faceWidth(): number {
        return this._spurGeometry.tB;
    }

    protected get isOuterGear(): boolean {
        return false;
    }

    private _getUnitScaleValue(): number {
        return this.input ? getUnitSetScaleValue(this.input.settings.unitSet) : 1;
    }
}

import { BearinxStandardBinder } from '../../binders/bearinx-standard-binder';

export function createBevelGearBaseBinder(): BearinxStandardBinder {
    const binder = new BearinxStandardBinder();
    binder.properties = [
        { left: 'x', right: 'IDCO_X' },
        { left: 'outerDiameter', right: 'IDTE_D' },
        { left: 'innerDiameter', right: 'IDTE_DI' },
        { left: 'width', right: 'IDTE_B' },
        { left: 'workingPitchDiameter', right: 'IDBG_DM' },
        { left: 'angleDelta', right: 'IDBG_DELTA' },
        { left: 'geometricalForm', right: 'IDBG_GEOMETRICAL_FORM' },
        { left: 'toothCount', right: 'IDBG_NB_TOOTH_CONTACTS' },
    ];
    return binder;
}

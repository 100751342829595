import { Group } from 'konva/lib/Group';
import { Line } from 'konva/lib/shapes/Line';
import { Text } from 'konva/lib/shapes/Text';
import { Vector2d } from 'konva/lib/types';
import { AxisArrowType } from '../../bearinx-mediator/functions/loads-properties';
import {
    ARROW_BODY_NAME,
    ARROW_HEAD_LENGTH_2D,
    ARROW_HEAD_NAME,
    ARROW_HEAD_WIDTH_2D,
    ARROW_NAME,
    FONT_FAMILY_2D,
    ARROW_DEFAULT_COLOR,
    TEXT_FONT_SIZE,
    TEXT_HEIGHT_2D,
    TEXT_WIDTH_2D,
    FIXED_SCALED_VALUE_ZOOM_TO_FIT,
} from '../elements-view/view-2d-constants';
import { ArrowInput2DInterface } from './arrow-input-2d-interface';

export class Arrow {
    private _group: Group;
    private _arrowBody: Line;
    private _arrrowHead: Line;
    private _label: Text | null = null;

    constructor(arrowInput: ArrowInput2DInterface) {
        this._createShapes(arrowInput);
    }

    get group(): Group {
        return this._group;
    }

    public updateShapes(arrowInput: ArrowInput2DInterface): void {
        this._updateArrow(arrowInput);
        this._createOrUpdateLabel(arrowInput);
    }

    private _createShapes(arrowInput: ArrowInput2DInterface): void {
        this._group = new Group();
        this._group.name(`${arrowInput.name ? arrowInput.name : ARROW_NAME}`);
        this._group.x(arrowInput.positionX || 0);
        this._group.y(arrowInput.positionY || 0);

        this._arrowBody = new Line({ name: ARROW_BODY_NAME });
        this._arrrowHead = new Line({ name: ARROW_HEAD_NAME });
        this._updateArrow(arrowInput);
        this._createOrUpdateLabel(arrowInput);

        [this._arrowBody, this._arrrowHead].forEach((arrowPart: Line) => this._group.add(arrowPart));
    }

    private _updateArrow(arrowInput: ArrowInput2DInterface): void {
        const {
            stroke,
            strokeWidth,
            opacity,
            startingPoint,
            endingPoint,
            axisArrowType,
            fillColor,
            visibleArrowHead,
            isDoubleArrow,
            unitScaleFactor,
            isNegative,
        } = arrowInput;

        [this._arrowBody, this._arrrowHead].forEach((arrowPart: Line) => {
            arrowPart.stroke(stroke);
            arrowPart.strokeWidth(strokeWidth);
            arrowPart.strokeScaleEnabled(false);
            arrowPart.opacity(opacity);
        });

        this._arrowBody.points(startingPoint.concat(endingPoint));

        if (visibleArrowHead) {
            const arrowHeadLength = (ARROW_HEAD_LENGTH_2D / unitScaleFactor) * (isNegative ? -1 : 1);

            this._arrrowHead.position(this._getArrowHeadPosition(endingPoint, axisArrowType, arrowHeadLength));
            this._arrrowHead.points(this._getArrowHeadPoints(axisArrowType, arrowHeadLength, isDoubleArrow, unitScaleFactor));
            this._arrrowHead.closed(true);
            this._arrrowHead.fill(fillColor);
        } else {
            this._arrrowHead.position({
                x: 0,
                y: 0,
            });
            this._arrrowHead.points([]);
            this._arrrowHead.closed(false);
            this._arrrowHead.fill(ARROW_DEFAULT_COLOR);
        }
    }

    private _getArrowHeadPosition(endingPoint: number[], axisArrowType: AxisArrowType, arrowHeadLength: number): Vector2d {
        const arrowHeadPosition: Vector2d = { x: endingPoint[0], y: endingPoint[1] };

        if (axisArrowType === AxisArrowType.X) {
            arrowHeadPosition.x = endingPoint[0] - arrowHeadLength;
        } else {
            arrowHeadPosition.y = endingPoint[1] - arrowHeadLength;
        }

        return arrowHeadPosition;
    }

    private _getArrowHeadPoints(
        axisArrowType: AxisArrowType,
        arrowHeadLength: number,
        isDoubleArrow: boolean | undefined,
        unitScaleFactor: number,
    ): number[] {
        const halfArrowHeadWidth = ARROW_HEAD_WIDTH_2D / unitScaleFactor / 2;
        let mainHeadArrow: number[] = [0, 0, 0, halfArrowHeadWidth, arrowHeadLength, 0, 0, -halfArrowHeadWidth, 0, 0];
        let subHeadArrow: number[] = [-arrowHeadLength, halfArrowHeadWidth, -arrowHeadLength, -halfArrowHeadWidth];

        if (axisArrowType === AxisArrowType.Y) {
            mainHeadArrow = [0, 0, halfArrowHeadWidth, 0, 0, arrowHeadLength, -halfArrowHeadWidth, 0, 0, 0];
            subHeadArrow = [halfArrowHeadWidth, -arrowHeadLength, -halfArrowHeadWidth, -arrowHeadLength];
        }

        if (isDoubleArrow) {
            return mainHeadArrow.concat(subHeadArrow);
        }

        return mainHeadArrow;
    }

    private _createOrUpdateLabel(arrowInput: ArrowInput2DInterface): void {
        if (arrowInput.text) {
            if (this._label === null) {
                this._label = new Text({
                    fontFamily: FONT_FAMILY_2D,
                    fontSize: TEXT_FONT_SIZE / arrowInput.unitScaleFactor,
                    width: TEXT_WIDTH_2D / arrowInput.unitScaleFactor,
                    height: TEXT_HEIGHT_2D / arrowInput.unitScaleFactor,
                });
                this._label.setAttr(FIXED_SCALED_VALUE_ZOOM_TO_FIT, true);
                this._group.add(this._label);
            }
            this._label.text(arrowInput.text);
            this._label.x(arrowInput.textPositionX || 0);
            this._label.y(arrowInput.textPositionY || 0);
            this._label.fill(arrowInput.stroke);
            this._label.opacity(arrowInput.opacity);
        } else if (this._label !== null) {
            this._label.destroy();
            this._label = null;
        }
    }
}

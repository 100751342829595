import { ModelElementFinder3DService } from './services/model-element-finder-3d.service';
import { NgModule } from '@angular/core';
import {
    View3DService,
    View3DFactoryService,
    View3DSettingsService,
    CameraService,
    View3DAxesRendererService,
    View3DVisualizationService,
} from './services';
import { View3DComponent } from './view3D.component';
import { Picker } from './helper/picker';

@NgModule({
    declarations: [View3DComponent],
    exports: [View3DComponent],
    imports: [],
    providers: [
        View3DService,
        View3DFactoryService,
        View3DSettingsService,
        ModelElementFinder3DService,
        Picker,
        CameraService,
        View3DVisualizationService,
        View3DAxesRendererService,
    ],
})
export class View3DModule {}

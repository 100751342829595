import { TINY_DIAMETER } from './../views-foundation-constants';
import { DirectionalSupportInterface, DIRECTIONS } from '../../cae-model/supports/directional-support-interface';
import { DEFAULT_SEGMENT_DIAMETER } from '../views-foundation-constants';
import { SupportGeometry } from '../geometries/support-geometry';

export function setDirectionalSupportGeometry(
    directionalSupport: DirectionalSupportInterface,
    geometry: SupportGeometry,
    unitScale: number,
): void {
    geometry.sDI = directionalSupport.innerDiameter;
    geometry.sDO = directionalSupport.outerDiameter;
    geometry.sB = directionalSupport.width;
    geometry.sTransY = directionalSupport.DirYTrans === DIRECTIONS.NO_DIRECTION;
    geometry.sRad =
        directionalSupport.DirYTrans !== DIRECTIONS.POSITIVE_AND_NEGATIVE_DIRECTION ||
        directionalSupport.DirZTrans !== DIRECTIONS.POSITIVE_AND_NEGATIVE_DIRECTION;
    geometry.sRot =
        directionalSupport.DirYRot !== DIRECTIONS.POSITIVE_AND_NEGATIVE_DIRECTION ||
        directionalSupport.DirZRot !== DIRECTIONS.POSITIVE_AND_NEGATIVE_DIRECTION;

    switch (directionalSupport.DirXTrans) {
        case DIRECTIONS.POSITIVE_AND_NEGATIVE_DIRECTION:
            geometry.sAxNeg = false;
            geometry.sAxPos = false;
            break;
        case DIRECTIONS.NEGATIVE_DIRECTION:
            geometry.sAxNeg = false;
            geometry.sAxPos = true;
            break;
        case DIRECTIONS.POSITIVE_DIRECTION:
            geometry.sAxNeg = true;
            geometry.sAxPos = false;
            break;
        case DIRECTIONS.NO_DIRECTION:
            geometry.sAxNeg = true;
            geometry.sAxPos = true;
            break;
    }

    const tinyDiameter = TINY_DIAMETER / unitScale;
    if (geometry.sDI < tinyDiameter) {
        geometry.sDI = geometry.outerShaftDiameter;
        if (geometry.sDI < tinyDiameter) {
            geometry.sDI = DEFAULT_SEGMENT_DIAMETER / unitScale;
        }
    }
    if (geometry.sDO < tinyDiameter) {
        geometry.sDO = geometry.innerShaftDiameter;
        if (geometry.sDO < tinyDiameter) {
            geometry.sDO = geometry.sDI * 2.0;
        }
        if (geometry.sDO < geometry.sDI) {
            geometry.sB = geometry.sDI;
            geometry.sDI = geometry.sDO;
            geometry.sDO = geometry.sB;
        }
    }

    if (geometry.sB < tinyDiameter) {
        if (geometry.sDO === geometry.sDI) {
            geometry.sB = geometry.sDO;
        } else {
            geometry.sB = (geometry.sDO - geometry.sDI) / 2.0;
        }
    }
}

import { Component, Inject } from '@angular/core';
import { BasePrompt } from '../../../prompt/base.prompt';
import { PROMPT_DATA } from '../../../prompt/prompt.service';

export interface OverrideLockPromptTranslateData {
    userName: string;
}

@Component({
    templateUrl: './override-lock.prompt.html',
})
export class OverrideLockPrompt extends BasePrompt {
    public translateData: OverrideLockPromptTranslateData;

    constructor(@Inject(PROMPT_DATA) data: OverrideLockPromptTranslateData) {
        super();
        this.translateData = data;
    }
}

<div class="data-privacy">
    <h2>{{ 'SHARED.NAVIGATION.DATA_PRIVACY' | translate }}</h2>
    <p>{{ 'BEARINX.DATA_PRIVACY.HEADER' | translate }}</p>
    <h2>{{ 'BEARINX.DATA_PRIVACY.GENERAL_INFO' | translate }}</h2>
    <h3>{{ 'BEARINX.DATA_PRIVACY.PERSONAL_DATA.FIRST_SECTION.TEXT' | translate }}</h3>
    <ul class="list">
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.PERSONAL_DATA.FIRST_SECTION.INFO' | translate }}</p>
        </li>
    </ul>
    <h3>{{ 'BEARINX.DATA_PRIVACY.PERSONAL_DATA.SECOND_SECTION.TEXT' | translate }}</h3>
    <ul class="list">
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.PERSONAL_DATA.SECOND_SECTION.INFO_1' | translate }}</p>
        </li>
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.PERSONAL_DATA.SECOND_SECTION.INFO_2' | translate }}</p>
        </li>
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.PERSONAL_DATA.SECOND_SECTION.INFO_3' | translate }}</p>
        </li>
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.PERSONAL_DATA.SECOND_SECTION.INFO_4' | translate }}</p>
        </li>
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.PERSONAL_DATA.SECOND_SECTION.INFO_5' | translate }}</p>
        </li>
    </ul>
    <h3>{{ 'BEARINX.DATA_PRIVACY.PERSONAL_DATA.THIRD_SECTION.TEXT' | translate }}</h3>
    <ul class="list">
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.PERSONAL_DATA.THIRD_SECTION.INFO' | translate }}</p>
        </li>
    </ul>
    <h3>{{ 'BEARINX.DATA_PRIVACY.PERSONAL_DATA.FOUR_SECTION.TEXT' | translate }}</h3>
    <ul class="list">
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.PERSONAL_DATA.FOUR_SECTION.INFO' | translate }}</p>
        </li>
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.PERSONAL_DATA.FOUR_SECTION.INFO_1' | translate }}</p>
        </li>
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.PERSONAL_DATA.FOUR_SECTION.INFO_2' | translate }}</p>
        </li>
    </ul>
    <h3>{{ 'BEARINX.DATA_PRIVACY.PERSONAL_DATA.FIVE_SECTION.TEXT' | translate }}</h3>
    <ul class="list">
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.PERSONAL_DATA.FIVE_SECTION.INFO' | translate }}</p>
        </li>
    </ul>
    <h3>{{ 'BEARINX.DATA_PRIVACY.PERSONAL_DATA.SIX_SECTION.TEXT' | translate }}</h3>
    <ul class="list">
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.PERSONAL_DATA.SIX_SECTION.INFO' | translate }}</p>
        </li>
    </ul>
    <h3>{{ 'BEARINX.DATA_PRIVACY.PERSONAL_DATA.SEVEN_SECTION.TEXT' | translate }}</h3>
    <ul class="list">
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.PERSONAL_DATA.SEVEN_SECTION.INFO' | translate }}</p>
        </li>
    </ul>
    <h3>{{ 'BEARINX.DATA_PRIVACY.PERSONAL_DATA.EIGHT_SECTION.TEXT' | translate }}</h3>
    <ul class="list">
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.PERSONAL_DATA.EIGHT_SECTION.INFO' | translate }}</p>
        </li>
    </ul>
    <h3>{{ 'BEARINX.DATA_PRIVACY.PERSONAL_DATA.NINE_SECTION.TEXT' | translate }}</h3>
    <ul class="list">
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.PERSONAL_DATA.NINE_SECTION.INFO' | translate }}</p>
        </li>
    </ul>
    <h2>{{ 'BEARINX.DATA_PRIVACY.DATA_PROCESSING.TITLE' | translate }}</h2>
    <ul class="list">
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.DATA_PROCESSING.INFO_1' | translate }}</p>
        </li>
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.DATA_PROCESSING.INFO_2' | translate }}</p>
        </li>
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.DATA_PROCESSING.INFO_3' | translate }}</p>
        </li>
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.DATA_PROCESSING.INFO_4' | translate }}</p>
        </li>
    </ul>
    <h2>{{ 'BEARINX.DATA_PRIVACY.DATA_PROCESSING_OP.TITLE' | translate }}</h2>
    <ul class="list">
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.DATA_PROCESSING_OP.INFO' | translate }}</p>
        </li>
    </ul>
    <h2>{{ 'BEARINX.DATA_PRIVACY.COOKIES.TITLE' | translate }}</h2>
    <ul class="list">
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.COOKIES.INFO' | translate }}</p>
        </li>
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.COOKIES.INFO_1' | translate }}</p>
        </li>
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.COOKIES.INFO_2' | translate }}</p>
        </li>
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.COOKIES.INFO_3' | translate }}</p>
        </li>
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.COOKIES.INFO_4' | translate }}</p>
        </li>
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.COOKIES.INFO_5' | translate }}</p>
        </li>
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.COOKIES.INFO_6' | translate }}</p>
        </li>
    </ul>
    <h2>{{ 'BEARINX.DATA_PRIVACY.RIGHTS.TITLE' | translate }}</h2>
    <p>{{ 'BEARINX.DATA_PRIVACY.RIGHTS.INFO' | translate }}</p>
    <h3>{{ 'BEARINX.DATA_PRIVACY.RIGHTS.RIGHT_1' | translate }}</h3>
    <ul class="list">
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.RIGHTS.TEXT_1' | translate }}</p>
        </li>
    </ul>
    <h3>{{ 'BEARINX.DATA_PRIVACY.RIGHTS.RIGHT_2' | translate }}</h3>
    <ul class="list">
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.RIGHTS.TEXT_2' | translate }}</p>
        </li>
    </ul>
    <h3>{{ 'BEARINX.DATA_PRIVACY.RIGHTS.RIGHT_3' | translate }}</h3>
    <ul class="list">
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.RIGHTS.TEXT_3' | translate }}</p>
        </li>
    </ul>
    <h3>{{ 'BEARINX.DATA_PRIVACY.RIGHTS.RIGHT_4' | translate }}</h3>
    <ul class="list">
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.RIGHTS.TEXT_4' | translate }}</p>
        </li>
    </ul>
    <h3>{{ 'BEARINX.DATA_PRIVACY.RIGHTS.RIGHT_5' | translate }}</h3>
    <ul class="list">
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.RIGHTS.TEXT_5' | translate }}</p>
        </li>
    </ul>
    <h3>{{ 'BEARINX.DATA_PRIVACY.RIGHTS.RIGHT_6' | translate }}</h3>
    <ul class="list">
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.RIGHTS.TEXT_6' | translate }}</p>
        </li>
    </ul>
    <h3>{{ 'BEARINX.DATA_PRIVACY.RIGHTS.RIGHT_7' | translate }}</h3>
    <ul class="list">
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.RIGHTS.TEXT_7' | translate }}</p>
        </li>
        <li>
            <p>{{ 'BEARINX.DATA_PRIVACY.RIGHTS.TEXT_7_1' | translate }}</p>
        </li>
    </ul>
    <h2>{{ 'BEARINX.DATA_PRIVACY.ADDRESS.TITLE' | translate }}</h2>
    <p>{{ 'BEARINX.IMPRINT.ADDRESS.NAME' | translate }}</p>
    <p>{{ 'BEARINX.IMPRINT.ADDRESS.STREET' | translate }}</p>
    <p>{{ 'BEARINX.IMPRINT.ADDRESS.CITY' | translate }}</p>
    <p>{{ 'BEARINX.IMPRINT.ADDRESS.COUNTRY' | translate }}</p>
    <p>{{ 'BEARINX.IMPRINT.PHONE' | translate }}</p>
    <p>Fax: +49 9132 82-49 50</p>
    <p>
        E-Mail:
        <a
            class="icon icon-mail"
            data-widget="GATrakken"
            data-ar-event-category="Engagement"
            data-ar-event-action="Content/Mailto"
            data-ar-event-label="info.de@schaeffler.com mailto:info.de@schaeffler.com"
            href="mailto:info.de@schaeffler.com"
            target="_self"
            title="info.de@schaeffler.com "
            >info.de@schaeffler.com
        </a>
    </p>

    <h2>{{ 'BEARINX.DATA_PRIVACY.ADDRESS.TITLE_1' | translate }}</h2>
    <p>{{ 'BEARINX.IMPRINT.REGISTER.PARTHNER.NAME' | translate }}</p>
    <p>{{ 'BEARINX.DATA_PRIVACY.ADDRESS.INFO' | translate }}</p>
    <p>{{ 'BEARINX.IMPRINT.ADDRESS.STREET' | translate }}</p>
    <p>{{ 'BEARINX.IMPRINT.ADDRESS.CITY' | translate }}</p>
    <p>{{ 'BEARINX.IMPRINT.ADDRESS.COUNTRY' | translate }}</p>
    <p>{{ 'BEARINX.IMPRINT.PHONE' | translate }}</p>
    <p>Fax: +49 9132 82-49 50</p>
    <p>
        E-Mail:
        <a
            class="icon icon-mail"
            data-widget="GATrakken"
            data-ar-event-category="Engagement"
            data-ar-event-action="Content/Mailto"
            data-ar-event-label="Datenschutz@schaeffler.com mailto:Datenschutz@schaeffler.com"
            href="mailto:Datenschutz@schaeffler.com"
            target="_self"
            title="Datenschutz@schaeffler.com "
            >Datenschutz@schaeffler.com
        </a>
    </p>
    <p>
        <strong>{{ 'BEARINX.DATA_PRIVACY.STATUS' | translate }}</strong>
    </p>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, empty } from 'rxjs';
import { distinctUntilChanged, filter, map, reduce, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Group } from '../../modules/group/group.model';
import { GroupService } from '../../modules/group/group.service';
import { sortByName } from '../../modules/util/util';
import { ApiProject, SelectProject, Project } from './project.model';
import { RoleService } from '../../modules/role/role.service';
import { Roles } from '../../modules/role/role.model';

const isProject = (project: any): project is Project => (project as Project)?.id !== undefined;
const isProjectId = (project: any): project is string => typeof project === 'string';

@Injectable({
    providedIn: 'root',
})
export class ProjectService {
    private readonly _baseUrl = `${environment.baseUrl}${environment.apiVersion}projects/`;
    private readonly _projectsChanged$ = new BehaviorSubject<boolean>(false);
    private readonly _currentlySelected: BehaviorSubject<Project | undefined | null> = new BehaviorSubject(undefined);

    readonly projectsChanged$ = this._projectsChanged$.asObservable();
    readonly currentlySelected = this._currentlySelected.asObservable();

    readonly projects$ = combineLatest([this._groupService.group$, this.projectsChanged$]).pipe(
        filter((pair): pair is [Group, boolean] => !!pair[0]),
        distinctUntilChanged(([group1], [group2, projectsChanged]) => group1.id === group2.id && !projectsChanged),
        switchMap(([_]) =>
            this._roleService
                .userHasRoles([Roles.ProjectList])
                .pipe(switchMap(listProject => (listProject ? this._getAllWithGroupFilter() : empty()))),
        ),
        map(projects => projects.sort(sortByName)),
    );

    constructor(
        private readonly _httpClient: HttpClient,
        private readonly _groupService: GroupService,
        private readonly _roleService: RoleService,
    ) {}

    public get(id: string): Observable<Project> {
        return this._httpClient.get<Project>(`${this._baseUrl}${id}`);
    }

    public create(project: ApiProject): Observable<string> {
        return this._httpClient.post<string>(`${this._baseUrl}`, project).pipe(tap(() => this._projectsChanged$.next(true)));
    }

    public update(id: string, project: ApiProject): Observable<void> {
        return this._httpClient.put<void>(`${this._baseUrl}${id}`, project).pipe(tap(() => this._projectsChanged$.next(true)));
    }

    public move(id: string, project: SelectProject): Observable<void> {
        return this._httpClient.put<void>(`${this._baseUrl}${id}/move`, project).pipe(tap(() => this._projectsChanged$.next(true)));
    }

    public delete(id: string): Observable<void> {
        return this._httpClient.delete<void>(`${this._baseUrl}${id}`).pipe(tap(() => this._projectsChanged$.next(true)));
    }

    selectProject(project?: Project | string | null) {
        if (isProjectId(project)) {
            this.projects$
                .pipe(
                    take(1),
                    reduce((result, projects) => (result ? result : projects.find(entry => entry.id === project)), undefined),
                )
                .subscribe(selectedProject => this._currentlySelected.next(selectedProject));
        } else if (isProject(project)) {
            this._currentlySelected.next(project);
        } else {
            this._currentlySelected.next(project);
        }
    }

    private _getAllWithGroupFilter(): Observable<Project[]> {
        return this._httpClient.get<Project[]>(this._baseUrl);
    }
}

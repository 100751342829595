import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthenticationService } from '../auth/authentication.service';
import { WebStorageService } from '../storage/web-storage.service';
import { TenantService } from '../tenant/tenant.service';
import { Group } from './group.model';

// TODO remove code duplication from (TenantService)
@Injectable({
    providedIn: 'root',
})
export class GroupService {
    private static readonly _CURRENT_GROUP_KEY = 'GROUP_CURRENT';

    private readonly _groups = new BehaviorSubject<Group[]>([]);
    private readonly _group = new BehaviorSubject<Group | undefined>(undefined);

    public readonly groups$ = this._groups.asObservable();
    public readonly group$ = this._group.asObservable();

    constructor(
        private readonly _webStorageService: WebStorageService,
        private readonly _authService: AuthenticationService,
        private readonly _tenantService: TenantService,
    ) {
        const tenant$ = this._tenantService.tenant$;
        const groups$ = this._authService.user$.pipe(map(({ groups }) => groups || []));

        combineLatest([tenant$, groups$])
            .pipe(
                map(([tenant, groups]) => groups.filter(group => group.tenantId === tenant?.id)),
                tap(this._groups),
            )
            .subscribe(groups => this._applyCurrentGroup(groups));
    }

    public get groups(): Group[] {
        return this._groups.value;
    }

    public get group(): Group | undefined {
        return this._group.value;
    }

    public getGroup(id: string): Group | undefined {
        return this.groups.find(group => group.id === id);
    }

    public setGroup(group: Group | undefined): void {
        // TODO: it seems that setGroup is called
        // several times although the group did not
        // actually change. The issue here is that the
        // cookie login will be performed on each call.
        // We could mitigate this issue by either eliminate
        // the redundant calls to setGroup, or we could
        // check if the passed group is identical to the
        // current one.

        if (!group || !this.getGroup(group.id)) {
            this._clearGroup();
            return;
        }

        this._group.next(group);
        this._webStorageService.setValue(GroupService._CURRENT_GROUP_KEY, group.id);
    }

    private _clearGroup(): void {
        this._group.next(undefined);
        this._webStorageService.removeValue(GroupService._CURRENT_GROUP_KEY);
    }

    private _applyCurrentGroup(groups: Group[]) {
        const defaultGroup = groups[0];
        const groupId = this._webStorageService.getValue(GroupService._CURRENT_GROUP_KEY);

        const group = groups.find(({ id }) => id === groupId) || defaultGroup;
        this.setGroup(group);
    }
}

import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { MousePositionInterface } from '../../views-foundation/interfaces/mouse-position-interface';
import { MousePositionService } from '../../views-foundation/services/mouse-position.service';

@Component({
    selector: 'bx-mouse-position-2d',
    templateUrl: './mouse-position.component.html',
    styleUrls: ['./mouse-position.component.scss'],
})
export class MousePositionComponent implements OnInit {
    public mousePosition$ = of<MousePositionInterface>({ x: 0, y: 0 });

    constructor(private _mousePositionService: MousePositionService) {}

    ngOnInit(): void {
        this.mousePosition$ = this._mousePositionService.getMousePosition();
    }
}

import { UnitSet } from '../../views-foundation/view-foundation-settings';

export enum DimensionsType2D {
    Off,
    DimensionsOfElement,
}

export interface View2DSettings {
    showDimensions: DimensionsType2D;
    unitSet: UnitSet;
}

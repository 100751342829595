import { Shaft } from '../../cae-model/shaft';
import { createCoordinateSystem, getUnitSetScaleValue } from '../functions/utils-3d';
import { ARROW_BODY_HEIGHT, ARROW_BODY_WIDTH, ARROW_HEAD_HEIGHT, ARROW_HEAD_WIDTH } from '../settings/view-3d-constants';
import { ElementView3D } from './element-view-3d';

export class ShaftView3D extends ElementView3D {
    constructor(shaft: Shaft) {
        super(shaft);
    }

    protected updateGroupWithoutChildren(): void {
        const unitScale = getUnitSetScaleValue(this.input.settings.unitSet);
        this.groupWithChildren.add(
            createCoordinateSystem({
                arrowBodyWidth: ARROW_BODY_WIDTH / unitScale,
                arrowBodyHeight: ARROW_BODY_HEIGHT / unitScale,
                arrowHeadWidth: ARROW_HEAD_WIDTH / unitScale,
                arrowHeadHeight: ARROW_HEAD_HEIGHT / unitScale,
                shaftId: this.modelElement.id,
                enableLabel: false,
            }),
        );
    }
}

import { SpurGear } from '../../cae-model/transmission-elements/spur-gear';
import { DEFAULT_SEGMENT_DIAMETER, STANDARD_GEAR_WIDTH, TINY_DIAMETER } from '../views-foundation-constants';
import { SpurGearBaseGeometry } from './spur-gear-base-geometry';

export class SpurGearGeometry extends SpurGearBaseGeometry {
    constructor(spurGear: SpurGear, protected unitScale: number) {
        super(spurGear, unitScale);
    }

    protected setGeometry(): void {
        const spurGear: SpurGear = this.spurGear as SpurGear;
        const { x: tX, workingPitchDiameter: tDM, toothCount: tTooth } = spurGear;
        let { outerDiameter: tDO, width: tB } = spurGear;
        let outerDiameter = spurGear.getShaftCurrentOuterDiameter(tX);
        let isOnShaft = false;

        const tinyDiameter = TINY_DIAMETER / this.unitScale;
        if (outerDiameter > tinyDiameter) {
            isOnShaft = true;
        } else {
            outerDiameter = DEFAULT_SEGMENT_DIAMETER / this.unitScale;
        }

        if (tDO < tinyDiameter) {
            tDO = tDM > 0.1 ? tDM + 4.0 / this.unitScale : outerDiameter + 10.0 / this.unitScale;
        }

        tB = this._calculateSpurWidth(outerDiameter, tB);
        const tDI = tDO < outerDiameter || !isOnShaft ? 0.0 : outerDiameter;

        this._tB = tB;
        this._tDM = tDM;
        this._tDO = tDO;
        this._tDI = tDI;
        this._tTooth = tTooth;
        this._tBore = 0;
        this._isOnShaft = isOnShaft;
    }

    private _calculateSpurWidth(outerDiameter: number, tB: number): number {
        const gearWidth = STANDARD_GEAR_WIDTH / this.unitScale;
        return tB < 0.001 / this.unitScale
            ? outerDiameter < gearWidth
                ? outerDiameter * 0.5
                : 0.25 * (outerDiameter - gearWidth) + gearWidth / 2
            : tB;
    }
}

import { InternalSpurGear } from '../../cae-model/transmission-elements/internal-spur-gear';
import {
    DEFAULT_SEGMENT_DIAMETER,
    STANDARD_GEAR_WIDTH,
    INNER_OUTER_DIAMETER_COMPARATOR,
    TINY_DIAMETER,
} from '../views-foundation-constants';
import { SpurGearBaseGeometry } from './spur-gear-base-geometry';

export class InternalSpurGearGeometry extends SpurGearBaseGeometry {
    constructor(gear: InternalSpurGear, protected unitScale: number) {
        super(gear, unitScale);
    }

    protected setGeometry(): void {
        const gear: InternalSpurGear = this.spurGear as InternalSpurGear;
        const { x: tX, workingPitchDiameter: tDM, toothCount: tTooth } = gear;
        let { innerDiameter: tDI, width: tB } = gear;

        let outerDiameter = gear.getShaftCurrentOuterDiameter(tX);
        let innerDiameter = 0.0;
        let isOnShaft = false;

        const tinyDiameter = TINY_DIAMETER / this.unitScale;

        if (outerDiameter > tinyDiameter) {
            innerDiameter = gear.getShaftCurrentInnerDiameter(tX);
            if (innerDiameter > tinyDiameter) {
                isOnShaft = true;
            }
        } else {
            outerDiameter = DEFAULT_SEGMENT_DIAMETER / this.unitScale;
        }

        if (tDI < tinyDiameter) {
            if (tDM > 0.1) {
                tDI = tDM - 4.0 / this.unitScale;
            } else {
                if (innerDiameter < INNER_OUTER_DIAMETER_COMPARATOR / this.unitScale) {
                    tDI = outerDiameter - 10.0 / this.unitScale;
                } else {
                    tDI = innerDiameter - 10.0 / this.unitScale;
                }
            }
        }

        const tBore = innerDiameter < INNER_OUTER_DIAMETER_COMPARATOR / this.unitScale ? tDI + 20.0 / this.unitScale : innerDiameter;

        tB = this._calculateInternalSpurGearWidth(tB, innerDiameter, outerDiameter);

        this._tB = tB;
        this._tDM = tDM;
        this._tDO = 0;
        this._tDI = tDI;
        this._tTooth = tTooth;
        this._tBore = tBore;
        this._isOnShaft = isOnShaft;
    }

    private _calculateInternalSpurGearWidth(tB: number, innerDiameter: number, outerDiameter: number): number {
        const tinyDiameter = TINY_DIAMETER / this.unitScale;
        const gearWidth = STANDARD_GEAR_WIDTH / this.unitScale;
        if (tB < tinyDiameter) {
            tB = innerDiameter < INNER_OUTER_DIAMETER_COMPARATOR / this.unitScale ? outerDiameter : innerDiameter;
            tB = tB < gearWidth ? tB * 0.5 : 0.25 * (tB - gearWidth) + gearWidth / 2;
            if (tB > gearWidth) {
                tB = gearWidth;
            }
        }

        return tB;
    }
}

import { Injectable, OnDestroy } from '@angular/core';
import { Group } from 'konva/lib/Group';
import { Vector2d } from 'konva/lib/types';
import { Line } from 'konva/lib/shapes/Line';
import {
    RULER_HEIGHT,
    RULER_HORIZONTAL_TRIANGLE_NAME,
    RULER_TRIANGLE_COLOR,
    RULER_VERTICAL_TRIANGLE_NAME,
} from '../elements-view/view-2d-constants';
import { createRuler } from '../functions/create-ruler';

enum TrianglePointsType {
    Horizontal,
    Vertical,
}

@Injectable()
export class RulerService implements OnDestroy {
    private _rulerGroup: Group | null;
    private _horizontalTriangle: Line;
    private _verticalTriangle: Line;
    private _scale: Vector2d;

    constructor() {}

    // group, icluding horizontal and vertical rulers and position markers on the rulers
    get rulerGroup(): Group | null {
        return this._rulerGroup;
    }

    initRuler(): void {
        this._rulerGroup = new Group();
    }

    ngOnDestroy(): void {
        if (this._rulerGroup != null) {
            this._rulerGroup.destroy();
            this._rulerGroup = null;
        }
    }

    updateRulerGroup(containerSize: Vector2d, rulerPosition: Vector2d, scale: Vector2d): void {
        if (this._rulerGroup != null) {
            this._rulerGroup.destroyChildren();
            this._scale = scale;
            const ruler: Group = createRuler(containerSize, rulerPosition, this._scale);

            this._createMousePositionMarkers(ruler);
            this._rulerGroup.add(ruler);
        }
    }

    setMousePosition(mousePos: Vector2d) {
        if (this._horizontalTriangle == null) {
            return;
        }

        const x = mousePos.x / this._scale.x - this._rulerHeightHorizontal;
        const y = mousePos.y / this._scale.y - this._rulerHeightVertical;

        if (x >= 0) {
            this._horizontalTriangle.x(x);
        }
        if (y >= 0) {
            this._verticalTriangle.y(y);
        }
    }

    private get _rulerHeightHorizontal(): number {
        return RULER_HEIGHT / this._scale.x;
    }

    private get _rulerHeightVertical(): number {
        return RULER_HEIGHT / this._scale.y;
    }

    private _getTrianglePoints(trianglePointsType: TrianglePointsType): number[] {
        const rulerHeight = trianglePointsType === TrianglePointsType.Horizontal ? this._rulerHeightHorizontal : this._rulerHeightVertical;
        return [
            rulerHeight * 0.75,
            rulerHeight * 0.75,
            rulerHeight,
            rulerHeight,
            rulerHeight * (trianglePointsType === TrianglePointsType.Horizontal ? 1.25 : 0.75),
            rulerHeight * (trianglePointsType === TrianglePointsType.Horizontal ? 0.75 : 1.25),
        ];
    }

    private _createMousePositionMarkers(ruler: Group): void {
        const colorTriangle = RULER_TRIANGLE_COLOR;

        this._horizontalTriangle = new Line({
            points: this._getTrianglePoints(TrianglePointsType.Horizontal),
            fill: colorTriangle,
            closed: true,
            name: RULER_HORIZONTAL_TRIANGLE_NAME,
        });
        this._verticalTriangle = this._horizontalTriangle.clone();
        this._verticalTriangle.name(RULER_VERTICAL_TRIANGLE_NAME);
        this._verticalTriangle.points(this._getTrianglePoints(TrianglePointsType.Vertical));

        ruler.add(this._horizontalTriangle);
        ruler.add(this._verticalTriangle);
    }
}

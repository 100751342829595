import { Injectable } from '@angular/core';
import { Group } from 'konva/lib/Group';
import { IRect } from 'konva/lib/types';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { SelectedElementInterface } from '../../views-foundation/interfaces/selected-element-interface';
import { SelectedElementService } from '../../views-foundation/services/selected-element.service';
import { UnitSet } from '../../views-foundation/view-foundation-settings';
import { FPS_UNIT_SCALE, SI_UNIT_SCALE } from '../../views-foundation/views-foundation-constants';
import { ElementView2D } from '../elements-view/element-view-2d';
import { DIMENSIONS_WRAPPER_NAME, FPS_UNIT_FRACTION_DIGITS, SI_UNIT_FRACTION_DIGITS } from '../elements-view/view-2d-constants';
import { getBoundingBoxSize } from '../functions/utils-2d';
import { ModelElementFinder2DService } from '../services/model-element-finder2D.service';
import { View2DSettingsService } from '../services/view-2d-settings.service';
import { ZoomToFitService } from '../services/zoom-to-fit-service';
import { DimensionsType2D, View2DSettings } from '../settings/view-2d-settings';
import { Dimensions2D } from './dimensions-2d';
import { Dimensions2DFactory } from './dimensions-2d.factory';
import { fixedScaleValueZoomToFit } from './dimensions-utils';

@Injectable()
export class DimensionVisualizerService {
    private _elementView2D: ElementView2D | null;
    private _modelView: Group | null;
    private _modelViewClientRect: IRect;
    private _dimensionsWrapper: Group | null;
    private _settings: View2DSettings;

    constructor(
        private _selectedElementService: SelectedElementService,
        private _modelElementFinder2DService: ModelElementFinder2DService,
        private _view2DSettingsService: View2DSettingsService,
        private _zoomToFitService: ZoomToFitService,
    ) {
        combineLatest([
            this._view2DSettingsService.getSettings(),
            this._selectedElementService.getSelectedElement().pipe(
                tap((selectedElement: SelectedElementInterface) => {
                    if (selectedElement == null) {
                        this._hide();
                    }
                }),
                filter((selectedElement: SelectedElementInterface) => selectedElement != null),
                distinctUntilChanged((previous, current) => previous.elementID === current.elementID),
            ),
        ]).subscribe(([settings, selectedElement]) => {
            this._settings = settings;

            if (settings.showDimensions === DimensionsType2D.DimensionsOfElement) {
                this._updateDimensionsView(selectedElement.elementID);
            } else {
                this._hide();
            }
        });
    }

    public setModelView(modelView: Group): void {
        this._modelView = modelView;

        this._dimensionsWrapper = new Group();
        this._dimensionsWrapper.name(DIMENSIONS_WRAPPER_NAME);
        this._modelView.add(this._dimensionsWrapper);

        if (this._elementView2D != null && this._settings.showDimensions === DimensionsType2D.DimensionsOfElement) {
            this._updateDimensionsView(this._elementView2D.modelElement.id);
        }
    }

    public destroy(): void {
        if (this._dimensionsWrapper != null) {
            this._dimensionsWrapper.destroy();
            this._dimensionsWrapper = null;
        }
        if (this._elementView2D != null) {
            this._elementView2D = null;
        }
        if (this._modelView != null) {
            this._modelView.destroy();
            this._modelView = null;
        }
    }

    private _updateDimensionsView(id: string): void {
        const elementView2D = this._modelElementFinder2DService.getView(id);
        if (elementView2D != null) {
            if (this._elementView2D) {
                this._hide();
            }
            this._setElementView2D(elementView2D);
            this._show();
        }
    }

    private _setElementView2D(elementView2D: ElementView2D): void {
        this._elementView2D = elementView2D;
    }

    private _show(): void {
        const standardDimensions2D = this._generateDimensions2D();

        const dimensionsGroup = standardDimensions2D.createVisualization();

        fixedScaleValueZoomToFit(dimensionsGroup, this._zoomToFitService.scale / this._getUnitScale(this._settings.unitSet));
        this._dimensionsWrapper!.add(dimensionsGroup);
    }

    private _hide(): void {
        if (this._dimensionsWrapper) {
            this._dimensionsWrapper.destroyChildren();
            this._dimensionsWrapper.getLayer()?.batchDraw();
        }
    }

    private _generateDimensions2D(): Dimensions2D {
        this._modelViewClientRect = getBoundingBoxSize(this._modelView!);
        return new Dimensions2DFactory().createDimensions2D(
            this._elementView2D!,
            this._modelViewClientRect,
            this._getUnitFractionDigits(this._settings.unitSet),
        );
    }

    private _getUnitFractionDigits(unitSet: UnitSet): number {
        if (unitSet === UnitSet.FPS) {
            return FPS_UNIT_FRACTION_DIGITS;
        } else {
            return SI_UNIT_FRACTION_DIGITS;
        }
    }

    private _getUnitScale(unitSet: UnitSet): number {
        if (unitSet === UnitSet.FPS) {
            return FPS_UNIT_SCALE;
        } else {
            return SI_UNIT_SCALE;
        }
    }
}

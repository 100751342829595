import { CoordinatesInterface } from '../interfaces/coordinates-interface';

// create coordinates from array of string [x, y,z, eps_x, eps_y, eps_z]
export function parseCoordinates(visData: string[]): CoordinatesInterface {
    return {
        x: parseFloat(visData[0]),
        y: parseFloat(visData[1]),
        z: parseFloat(visData[2]),
        eps_x: parseFloat(visData[3]),
        eps_y: parseFloat(visData[4]),
        eps_z: parseFloat(visData[5]),
    };
}

import { MathUtils, Object3D, PerspectiveCamera, Plane, Ray, Vector3 } from 'three';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';
import { ZOOM_PERSPECTIVE_OFFSET } from '../settings/view-3d-constants';

interface Bounds {
    isEmpty: boolean;
    // Arbitrary point, basically the first point
    anchor: Vector3;
    // Plane parallel to Camera plane passing by the anchor
    plane: Plane;
    // Max distance from the anchor to any point intersecting the plane through _viewProjection.top direction
    top: number;
    // Min distance from the anchor to any point intersecting the plane through _viewProjection.left direction
    left: number;
    // Max distance from the anchor to any point intersecting the plane through _viewProjection.bottom direction
    bottom: number;
    // Max distance from the anchor to any point intersecting the plane through _viewProjection.right direction
    right: number;
}

interface ViewBasis {
    x: Vector3;
    y: Vector3;
    z: Vector3;
}

interface ViewProjection {
    top: Vector3;
    left: Vector3;
    bottom: Vector3;
    right: Vector3;
}

interface InputViewData {
    isEmpty: boolean;
    bounds: Bounds;
    viewBasis: ViewBasis;
    viewProjection: ViewProjection;
}

interface ViewData {
    verticalTanFov: number;
    horizontalTanFov: number;
    viewBasis: ViewBasis;
    viewProjection: ViewProjection;
}

function createEmptyBounds(): Bounds {
    return {
        isEmpty: true,
        anchor: new Vector3(),
        plane: new Plane(),
        top: -Infinity,
        left: +Infinity,
        bottom: +Infinity,
        right: -Infinity,
    };
}

function getViewDataFromBasis(x: Vector3, y: Vector3, z: Vector3, fov: number, aspect: number): ViewData {
    const v = new Vector3();
    const vFov = MathUtils.degToRad(fov);
    const verticalTanFov = 2 * Math.tan(vFov / 2);

    const hFov = 2 * Math.atan((verticalTanFov / 2) * aspect);
    const horizontalTanFov = 2 * Math.tan(hFov / 2);

    const viewBasis = { x, y, z };

    const viewProjection: ViewProjection = {
        top: new Vector3(0, 0, 0),
        bottom: new Vector3(0, 0, 0),
        left: new Vector3(0, 0, 0),
        right: new Vector3(0, 0, 0),
    };

    v.copy(z).multiplyScalar(-Math.cos(vFov / 2));
    viewProjection.top.add(v);
    viewProjection.bottom.add(v);
    v.copy(y).multiplyScalar(Math.sin(vFov / 2));
    viewProjection.top.add(v);
    viewProjection.bottom.add(v.negate());

    v.copy(z).multiplyScalar(-Math.cos(hFov / 2));
    viewProjection.left.add(v);
    viewProjection.right.add(v);
    v.copy(x).multiplyScalar(Math.sin(hFov / 2));
    viewProjection.right.add(v);
    viewProjection.left.add(v.negate());

    return {
        verticalTanFov,
        horizontalTanFov,
        viewBasis,
        viewProjection,
    };
}

function getViewDataFromCamera(camera: PerspectiveCamera): ViewData {
    const x = new Vector3();
    const y = new Vector3();
    const z = new Vector3();

    camera.updateMatrixWorld(true);

    camera.matrixWorld.extractBasis(x, y, z);

    return getViewDataFromBasis(x, y, z, camera.fov, camera.aspect);
}

function expandByPoint(point: Vector3, input: InputViewData): void {
    const { bounds, viewBasis, viewProjection, isEmpty } = input;
    const ray = new Ray();
    const projectedPoint = new Vector3();
    let distance;

    if (isEmpty) {
        // If empty, then set the anchor and the plane

        bounds.anchor.copy(point);
        bounds.plane.setFromNormalAndCoplanarPoint(viewBasis.z, bounds.anchor);

        input.isEmpty = false;
    }

    ray.origin.copy(point);

    // TOP
    ray.direction.copy(viewProjection.top);
    if (ray.intersectPlane(bounds.plane, projectedPoint) === null) {
        ray.direction.negate();
        ray.intersectPlane(bounds.plane, projectedPoint);
    }
    distance = projectedPoint.sub(bounds.anchor).dot(viewBasis.y);
    bounds.top = Math.max(bounds.top, distance);

    // BOTTOM
    ray.direction.copy(viewProjection.bottom);
    if (ray.intersectPlane(bounds.plane, projectedPoint) === null) {
        ray.direction.negate();
        ray.intersectPlane(bounds.plane, projectedPoint);
    }
    distance = projectedPoint.sub(bounds.anchor).dot(viewBasis.y);
    bounds.bottom = Math.min(bounds.bottom, distance);

    // LEFT
    ray.direction.copy(viewProjection.left);
    if (ray.intersectPlane(bounds.plane, projectedPoint) === null) {
        ray.direction.negate();
        ray.intersectPlane(bounds.plane, projectedPoint);
    }
    distance = projectedPoint.sub(bounds.anchor).dot(viewBasis.x);
    bounds.left = Math.min(bounds.left, distance);

    // RIGHT
    ray.direction.copy(viewProjection.right);
    if (ray.intersectPlane(bounds.plane, projectedPoint) === null) {
        ray.direction.negate();
        ray.intersectPlane(bounds.plane, projectedPoint);
    }
    distance = projectedPoint.sub(bounds.anchor).dot(viewBasis.x);
    bounds.right = Math.max(bounds.right, distance);
}

function expandByObject(object: Object3D, input: InputViewData): void {
    // Computes the world-axis-aligned bounding box of an object (including its children),
    // accounting for both the object's, and children's, world transforms

    let i: number, l: number;
    const v1 = new Vector3();

    const traverse = (node: any) => {
        const geometry = node.geometry;
        if (node.geometry !== undefined) {
            if (geometry.isGeometry) {
                const vertices = geometry.vertices;

                for (i = 0, l = vertices.length; i < l; i++) {
                    v1.copy(vertices[i]);
                    v1.applyMatrix4(node.matrixWorld);

                    expandByPoint(v1, input);
                }
            } else if (geometry.isBufferGeometry) {
                const attribute = geometry.attributes.position;

                if (attribute !== undefined) {
                    for (i = 0, l = attribute.count; i < l; i++) {
                        v1.fromBufferAttribute(attribute, i).applyMatrix4(node.matrixWorld);

                        expandByPoint(v1, input);
                    }
                }
            }
        }
    };

    object.updateMatrixWorld(true);
    object.traverse(traverse);
}

function expandByObjects(objects: Object3D[], input: InputViewData): void {
    for (let i = 0; i < objects.length; i++) {
        expandByObject(objects[i], input);
    }
}

function setFromObjects(objects: Object3D[], input: InputViewData): void {
    expandByObjects(objects, input);
}

// I copied this class and convert to Typescript. Here is the source: https://github.com/mrdoob/three.js/pull/14526/files
export function zoomToFitForPerspectiveCamera(camera: PerspectiveCamera, controls: TrackballControls, object3Ds: Object3D[]): void {
    const bounds = createEmptyBounds();

    const viewData = getViewDataFromCamera(camera);
    /**
     * Camera basis
     *   - x: horizontal pointing to right
     *   - y: vertical pointing to top
     *   - z: normal to camera plane pointing backward
     */
    const viewBasis = viewData.viewBasis;

    /**
     * Tangent of the camera vertical field of view angle
     */
    const verticalTanFov = viewData.verticalTanFov;
    /**
     * Tangent of the camera horizontal field of view angle
     */
    const horizontalTanFov = viewData.horizontalTanFov;

    /**
     * Coefficient to scale the view box
     */
    const fitRatio = 2 - ZOOM_PERSPECTIVE_OFFSET;

    /**
     * Directional vectors that defines the view projection
     *    - top:
     *      - Colinear to the top plane camera viewing frustrum
     *      - Colinear to the plane defined by the normal _viewBasis.x
     *      - Inverse way to _viewBasis.z (_viewBasis.z.dot(top) < 0)
     *    - bottom:
     *      - Colinear to the bottom plane camera viewing frustrum
     *      - Colinear to the plane defined by the normal _viewBasis.x
     *      - Inverse way to _viewBasis.z (_viewBasis.z.dot(bottom) < 0)
     *    - left:
     *      - Colinear to the left plane camera viewing frustrum
     *      - Colinear to the plane defined by the normal _viewBasis.y
     *      - Inverse way to _viewBasis.z (_viewBasis.z.dot(left) < 0)
     *    - right:
     *      - Colinear to the right plane camera viewing frustrum
     *      - Colinear to the plane defined by the normal _viewBasis.y
     *      - Inverse way to _viewBasis.z (_viewBasis.z.dot(right) < 0)
     */
    const viewProjection: ViewProjection = viewData.viewProjection;

    const input: InputViewData = {
        isEmpty: true,
        bounds,
        viewBasis,
        viewProjection,
    };
    setFromObjects(object3Ds, input);

    const position = camera.position;
    const target = controls.target;
    const v = new Vector3();
    const center = new Vector3();

    center.copy(bounds.anchor);

    v.copy(viewBasis.y).multiplyScalar((bounds.top + bounds.bottom) / 2);
    center.add(v);

    v.copy(viewBasis.x).multiplyScalar((bounds.left + bounds.right) / 2);
    center.add(v);

    const distance = Math.max((bounds.top - bounds.bottom) / verticalTanFov, (bounds.right - bounds.left) / horizontalTanFov);

    v.copy(viewBasis.z).multiplyScalar(distance * fitRatio);

    position.copy(center).add(v);
    target.copy(center);
}

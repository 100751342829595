import { Support } from './support';

export enum DIRECTIONS {
    NO_DIRECTION = 'NO_DIRECTION',
    POSITIVE_DIRECTION = 'POSITIVE_DIRECTION',
    NEGATIVE_DIRECTION = 'NEGATIVE_DIRECTION',
    POSITIVE_AND_NEGATIVE_DIRECTION = 'POSITIVE_AND_NEGATIVE_DIRECTION',
}

export interface DirectionalSupportInterface extends Support {
    outerDiameter: number; // IDSU_DA
    innerDiameter: number; // IDSU_DI
    width: number; // IDSU_B
    DirXTrans: string; // IDSU_DIRECTION_X_TRANSLATORY
    DirYTrans: string; // IDSU_DIRECTION_Y_TRANSLATORY
    DirZTrans: string; // IDSU_DIRECTION_Z_TRANSLATORY
    DirYRot: string; // IDSU_DIRECTION_Y_ROTATORY
    DirZRot: string; // IDSU_DIRECTION_Z_ROTATORY
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CameraType } from '@caeonline/viewer';
import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, map, skip, switchMap, switchMapTo, tap } from 'rxjs/operators';
import { LogMessageLevel } from '../../app/editor/dialogs/dashboard/logbook/log-message-level.model';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../auth/authentication.service';
import { DataModelType } from '../data-model/data-model-type.model';
import { DataModelService } from '../data-model/data-model.service';
import { SchaefflerLanguage, SchaefflerUnitSet } from '../data-model/schaeffler-models.model';
import { Notification, NotificationType } from '../notification/notification.model';
import { NotificationsService } from '../notification/notifications.service';
import { WebStorageService } from '../storage/web-storage.service';
import { WindowRef } from '../util/window-ref';
import { ClippingPlaneType } from './clipping-plane-type.model';
import { SettingsErrorHandlerService } from './errors/settings-error-handler.service';
import { DEFAULT_SETTINGS_VALUE, Settings } from './settings.model';

export enum SettingsMode {
    Online,
    Offline,
}

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    private static readonly _SETTINGS_KEY = 'SETTINGS';
    private static readonly _LOG_FILTER_KEY = 'APP_LOG_FILTER';
    private static readonly _CLIENT_LANGUAGES = ['LANGUAGE_ENGLISH', 'LANGUAGE_GERMAN'];
    private static readonly _LOCALES = ['en-US', 'de-DE'];

    public readonly mode$ = new BehaviorSubject<SettingsMode>(SettingsMode.Online);

    public readonly clippingPlaneTypes = Object.keys(ClippingPlaneType).map(type => ClippingPlaneType[type]);
    public readonly cameraTypes = Object.keys(CameraType).map(type => CameraType[type]);

    public readonly settings$ = new BehaviorSubject<Settings>(DEFAULT_SETTINGS_VALUE);
    public readonly currentLanguage$ = this.settings$.pipe(map(settings => settings.language!));
    public readonly currentUnitSet$ = this.settings$.pipe(map(settings => settings.unitSet!));
    public readonly warningSettings$ = this.settings$.pipe(map(settings => settings.warnings!));
    public readonly scene3dSettings$ = this.settings$.pipe(map(settings => settings.scene3d!));

    private _mismatchNotification: Notification;
    private _offlineNotification: Notification;

    private _applicationLocale: string;

    constructor(
        private readonly _notificationService: NotificationsService,
        private readonly _webStorageService: WebStorageService,
        private readonly _dataModelService: DataModelService,
        private readonly _httpClient: HttpClient,
        private readonly _errorHandler: SettingsErrorHandlerService,
        private readonly _windowRef: WindowRef,
        private readonly _authService: AuthenticationService,
    ) {
        this._mismatchNotification = this._makeMismatchNotification();
        this._offlineNotification = this._makeOfflineNotification();
    }

    public start(): void {
        this._authService.user$
            .pipe(
                skip(1),
                switchMap(() => this._fetchSettings()),
            )
            .subscribe();
    }

    public get values(): Settings {
        return this.settings$.value;
    }

    public get languages(): SchaefflerLanguage[] {
        return (this._dataModelService.getModel(DataModelType.Languages) || []).filter(lang =>
            SettingsService._CLIENT_LANGUAGES.includes(lang.item),
        );
    }

    public get languageIds(): string[] {
        return this.languages.map(lang => lang.item);
    }

    public get defaultLanguageId(): string {
        return DEFAULT_SETTINGS_VALUE.language;
    }

    public get unitSets(): SchaefflerUnitSet[] {
        return this._dataModelService.getModel(DataModelType.UnitSets) || [];
    }

    public get locales(): string[] {
        return SettingsService._LOCALES;
    }

    public get locale(): string {
        return this.values.locale;
    }

    public get applicationLocale(): string {
        return this._applicationLocale;
    }

    public getLogFilters(): LogMessageLevel[] {
        return this._webStorageService.getValue<LogMessageLevel[]>(SettingsService._LOG_FILTER_KEY) || [];
    }

    public setLogFilters(levels: LogMessageLevel[]) {
        this._webStorageService.setValue(SettingsService._LOG_FILTER_KEY, levels);
    }

    public setLocalValue(key: string, value: string): void {
        this._webStorageService.setValue(key, value);
    }

    public getLocalValue(key: string): string | null {
        return this._webStorageService.getValue<string>(key);
    }

    public prefetch(): Promise<void> {
        return this._fetchSettings().toPromise();
    }

    public saveSettings(settings: Partial<Settings>): Observable<void> {
        const currentSettings = this.values;
        const updated = { ...currentSettings, ...settings };

        this._webStorageService.setValue(SettingsService._SETTINGS_KEY, updated);

        if (this.mode$.value === SettingsMode.Offline) {
            if (updated.locale !== this._applicationLocale) {
                this._notificationService.add(this._mismatchNotification);
            } else {
                this._notificationService.dismiss(this._mismatchNotification.id);
            }
            this.settings$.next(updated);
            return EMPTY;
        }

        return this._httpClient.post(this._getSettingsUrl(), updated).pipe(
            catchError(error => {
                this._offline();
                return this._errorHandler.handleError(error);
            }),
            tap(() => {
                if (updated.locale !== this._applicationLocale) {
                    this._notificationService.add(this._mismatchNotification);
                } else {
                    this._notificationService.dismiss(this._mismatchNotification.id);
                }
                this.settings$.next(updated);
            }),
        );
    }

    public online(): void {
        if (this.mode$.value === SettingsMode.Online) {
            return;
        }
        this.mode$.next(SettingsMode.Online);
        this.prefetch();
    }

    private _getSettingsUrl(): string {
        return `${environment.settingsUrl}`;
    }

    private _fetchSettings(): Observable<void> {
        const defaultValues = DEFAULT_SETTINGS_VALUE;
        const stored = this._webStorageService.getValue<Partial<Settings>>(SettingsService._SETTINGS_KEY) ?? {};

        return this._httpClient.get<Partial<Settings>>(this._getSettingsUrl()).pipe(
            catchError((error: HttpErrorResponse) => (error.status === 404 ? of({}) : throwError(error))),
            tap((settings: Partial<Settings>) => {
                const current = { ...defaultValues };
                this._mergeSettings(current, settings);
                this._applicationLocale = current.locale;
                this.settings$.next(current);

                this._online();
            }),
            catchError(() => {
                const current = { ...defaultValues };
                this._mergeSettings(current, stored);
                this._applicationLocale = current.locale;
                this.settings$.next(current);

                this._offline();
                return EMPTY;
            }),
            switchMapTo(EMPTY),
        );
    }

    private _mergeSettings(current: Partial<Settings>, updates: Partial<Settings>): void {
        Object.keys(updates).forEach(key => {
            if (!current.hasOwnProperty(key) || typeof updates[key] !== 'object') {
                current[key] = updates[key];
            } else {
                this._mergeSettings(current[key], updates[key]);
            }
        });
    }

    private _online(): void {
        this._notificationService.dismiss(this._offlineNotification.id);
        this.mode$.next(SettingsMode.Online);
    }

    private _offline(): void {
        this._notificationService.add(this._offlineNotification);
        this.mode$.next(SettingsMode.Offline);
    }

    private _makeMismatchNotification(): Notification {
        const label = 'GLOBALS.RELOAD';
        return this._notificationService
            .create(NotificationType.System, 'SHARED.SETTINGS.APP.LOCALE_MISMATCH_WARNING')
            .actions([{ label, actionFn: () => this._windowRef.nativeWindow.location.reload() }])
            .get();
    }

    private _makeOfflineNotification(): Notification {
        const label = 'SHARED.SETTINGS.BUTTON_CONNECT';
        return this._notificationService
            .create(NotificationType.System, 'SHARED.SETTINGS.OFFLINE')
            .actions([{ label, actionFn: () => this.online() }])
            .get();
    }
}

import { Mesh, BufferGeometry, TorusGeometry, BoxGeometry, MathUtils, SphereGeometry, Material, CylinderGeometry } from 'three';
import { CSG } from 'three-csg-ts';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils';
import { WormGear } from '../../../cae-model/transmission-elements/worm-gear';
import { WormGearGeometry } from '../../../views-foundation/geometries/worm-gear-geometry';
import { createCylinderWithHole, CylinderWithHoleInterface, getUnitSetScaleValue } from '../../functions/utils-3d';
import { WormGearGeometryInput } from '../../interfaces/worm-gear-interface';
import { COUNT_SEGMENTS_CYLINDER, RADIAL_SEGMENTS, TOOTH_STEPS_DEFAULT, TUBULAR_SEGMENTS } from '../../settings/view-3d-constants';
import { ClippingPlaneType, ClippingType, View3DSettings } from '../../settings/view-3d-settings';
import { ClipMode, TransmissionElementView3D } from './transmission-element-view-3d';

export class WormGearView3D extends TransmissionElementView3D {
    constructor(private _wormGear: WormGear) {
        super(_wormGear);
        this.clipMode = ClipMode.ClipOnPlane;
    }

    protected setMesh(geometry: BufferGeometry, material: Material): Mesh {
        return new Mesh(geometry, material);
    }

    protected get clipMeshEnabled(): boolean {
        return true;
    }

    private _createGearGeometry(): BufferGeometry {
        const geometry = new WormGearGeometry(this._wormGear, getUnitSetScaleValue(this.input.settings.unitSet));
        this.isOnShaft = geometry.isOnShaft;

        const { tDM, tDO, tDI, tGR, tB, gamma_m } = geometry;
        const gearRadius = tDO / 2.0;
        const gearHoleRadius = tDI / 2.0;
        const wormGearGeometryInput: WormGearGeometryInput = {
            gearWidth: tB,
            gearRadius,
            gearHoleRadius,
            grooveRadius: tGR,
            pitchDiameter: tDM,
        };

        const gearTorsoGeometry = this._generateTorso(wormGearGeometryInput);

        const teethGeometry = this._generateTeeth({ ...wormGearGeometryInput, meanPitchAngle: gamma_m });

        const wormGearGeometry = BufferGeometryUtils.mergeBufferGeometries([gearTorsoGeometry, teethGeometry], false);
        wormGearGeometry.rotateZ(Math.PI * 0.5);

        if (this.input.settings.clippingType === ClippingType.Off) {
            return wormGearGeometry;
        } else {
            const cuttingPlanesGeo = this._generateCuttingPlane(this.input.settings, wormGearGeometryInput);
            return BufferGeometryUtils.mergeBufferGeometries([wormGearGeometry, cuttingPlanesGeo], false);
        }
    }

    private _generateTorso(wormGearInput: WormGearGeometryInput): BufferGeometry {
        const { gearWidth, gearRadius, gearHoleRadius, grooveRadius } = wormGearInput;
        const cylinderWithHoleInterface: CylinderWithHoleInterface = {
            outerRadiusStart: gearRadius,
            outerRadiusEnd: gearRadius,
            innerRadiusStart: gearHoleRadius,
            innerRadiusEnd: gearHoleRadius,
            length: gearWidth,
        };
        const gearTorsoBaseGeometry = createCylinderWithHole(cylinderWithHoleInterface);
        const gearTorsoBaseMesh = new Mesh(gearTorsoBaseGeometry);

        const gearTorsoGrooveGeometry = new TorusGeometry(gearRadius, grooveRadius, RADIAL_SEGMENTS, TUBULAR_SEGMENTS).rotateX(
            Math.PI * 0.5,
        );
        const gearTorsoGrooveMesh = new Mesh(gearTorsoGrooveGeometry);

        return CSG.subtract(gearTorsoBaseMesh, gearTorsoGrooveMesh).geometry;
    }

    private _generateTeeth(wormGearInput: WormGearGeometryInput): BufferGeometry {
        const { gearWidth, gearRadius, gearHoleRadius, grooveRadius, pitchDiameter, meanPitchAngle } = wormGearInput;
        const innerDiameter = gearHoleRadius * 2;
        const toothHeight = gearRadius / 6;
        const fullGearRadius = gearRadius + toothHeight;
        const toothThickness = (Math.PI * 2 * gearRadius) / 2 / (TOOTH_STEPS_DEFAULT * 2);
        const skewAngle = meanPitchAngle ? meanPitchAngle : 0;

        let numberOfTeeth = 16;

        if (pitchDiameter <= innerDiameter) {
            numberOfTeeth = Math.abs(Math.round((2 * pitchDiameter) / Math.abs(innerDiameter - pitchDiameter)));
        } else {
            numberOfTeeth = 16;
        }

        if (numberOfTeeth < 16) {
            numberOfTeeth = 16;
        } else if (numberOfTeeth > 300) {
            numberOfTeeth = 300;
        }

        const toothBaseGeometry = new BoxGeometry(toothThickness, gearWidth * 2, gearRadius - gearHoleRadius + toothHeight);

        const toothBaseGeometries = Array.from({ length: numberOfTeeth }, (_, i) => {
            const angle = (i / numberOfTeeth) * Math.PI * 2;
            const x = Math.sin(angle) * gearRadius;
            const y = 0;
            const z = Math.cos(angle) * gearRadius;

            const skewValue = (skewAngle * Math.PI) / 180;
            const toothBaseGeometryClone = toothBaseGeometry.clone();
            toothBaseGeometryClone.rotateZ(skewValue);
            toothBaseGeometryClone.rotateY(angle);
            toothBaseGeometryClone.translate(x, y, z);

            return toothBaseGeometryClone;
        });

        const teethBaseGeometry = BufferGeometryUtils.mergeBufferGeometries(toothBaseGeometries, false);
        const teethBaseMesh = new Mesh(teethBaseGeometry);

        const teethGrooveGeometry = new TorusGeometry(fullGearRadius, grooveRadius, RADIAL_SEGMENTS, TUBULAR_SEGMENTS).rotateX(
            Math.PI * 0.5,
        );
        const teethGrooveMesh = new Mesh(teethGrooveGeometry);
        const teethBaseWithGrooveMesh = CSG.subtract(teethBaseMesh, teethGrooveMesh);

        const cuttingTeethBaseGeometry = new CylinderGeometry(fullGearRadius, fullGearRadius, gearWidth, COUNT_SEGMENTS_CYLINDER);
        const cuttingTeethBaseMesh = new Mesh(cuttingTeethBaseGeometry);
        return CSG.intersect(teethBaseWithGrooveMesh, cuttingTeethBaseMesh).geometry;
    }

    private _generateCuttingPlane(settings: View3DSettings, wormGearInput: WormGearGeometryInput): BufferGeometry {
        const { gearWidth, gearRadius, gearHoleRadius, grooveRadius } = wormGearInput;
        const gearThickness = gearRadius - gearHoleRadius;
        const halfGearThickness = gearThickness / 2;
        const sphereGeo = new SphereGeometry(grooveRadius, RADIAL_SEGMENTS, RADIAL_SEGMENTS);
        sphereGeo.translate(0, -halfGearThickness, 0);
        const sphere = new Mesh(sphereGeo);

        const plane = new BoxGeometry(gearWidth, gearThickness, 1);

        const upPlane = CSG.subtract(new Mesh(plane), sphere).geometry;
        upPlane.translate(0, -gearHoleRadius - halfGearThickness, 0);
        const downPlane = upPlane.clone();
        downPlane.scale(-1, -1, 1);

        if (settings.clippingPlane === ClippingPlaneType.XZ) {
            [upPlane, downPlane].forEach((plane: BoxGeometry) => {
                plane.rotateX(MathUtils.degToRad(-90));
            });
        }

        if (settings.clippingType === ClippingType.Quarter) {
            if (!settings.flipClippingPlane) {
                downPlane.rotateX(MathUtils.degToRad(-90));
            } else {
                upPlane.rotateX(MathUtils.degToRad(-90));
            }
        }

        return BufferGeometryUtils.mergeBufferGeometries([upPlane, downPlane]);
    }

    protected setBufferGeometry(): BufferGeometry {
        return this._createGearGeometry();
    }
}

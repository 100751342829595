import { TransmissionElement } from './transmission-element';

export class InternalSpurGear extends TransmissionElement {
    innerDiameter = 0; // IDTE_DI
    width = 0; // IDTE_B
    workingPitchDiameter = 0; // IDSG_DM
    toothCount = 0; // IDSG_NB_TOOTH_CONTACTS
    helixAngleReferenceDiameter = 0; // IDSG_BETA (in grad)

    constructor() {
        super();
    }
}

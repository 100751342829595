import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PromptResult } from '../prompt/prompt.model';
import { PromptService } from '../prompt/prompt.service';
import { WindowRef } from '../util/window-ref';
import { ModelMismatchPrompt } from './model-mismatch/model-mismatch.prompt';

@Injectable({ providedIn: 'root' })
export class ModelHashService {
    private _hash: string | null = null;

    constructor(private readonly _windowRef: WindowRef, private readonly _promptService: PromptService) {}

    public get hash(): string | null {
        return this._hash;
    }

    public set hash(value: string | null) {
        this._hash = value;
    }

    public reload(): void {
        this._promptService
            .displayPrompt(ModelMismatchPrompt, true)
            .pipe(
                switchMap(result => {
                    if (result === PromptResult.Confirm) {
                        this._windowRef.nativeWindow.location.reload();
                    }
                    return of(null);
                }),
            )
            .subscribe();
        this.hash = null;
    }
}

import { ConeSegment } from '../../cae-model/shaft-segments/cone-segment';
import { ModelElement } from '../../cae-model/model-element';
import { BasicMediator } from './basic-mediator';
import { BearinxStandardBinder } from '../binders/bearinx-standard-binder';
import { BindingType } from '../binders/binding-properties';

export class ConeSegmentMediator extends BasicMediator {
    constructor() {
        super();
        this._createBinder();
    }

    private _createBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [
            { left: 'x', right: 'IDSE_XSECTION1' },
            { left: 'xEnd', right: 'IDSE_XSECTION2' },
            { left: 'innerDiameterLeft', right: 'IDSE_INNERDIAMETER_1' },
            { left: 'innerDiameterRight', right: 'IDSE_INNERDIAMETER_2' },
            { left: 'outerDiameterLeft', right: 'IDSE_OUTERDIAMETER_1' },
            { left: 'outerDiameterRight', right: 'IDSE_OUTERDIAMETER_2' },
            { left: 'length', right: 'IDSE_LENGTH', bindingType: BindingType.ToRight },
        ];
        this.addBinder(binder);
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_CONE_SEGMENT'];
    }

    protected createModelElement(): ModelElement {
        return new ConeSegment();
    }
}

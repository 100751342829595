import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import {
    DEFAULT_FIXED_SCALE_VALUE,
    FIXED_SCALE_ATTRIBUTE,
    FIXED_SCALE_VALUE_ATTRIBUTE,
    SCALE_SPEED,
} from '../elements-view/view-2d-constants';
import { Stage } from 'konva/lib/Stage';
import { Vector2d } from 'konva/lib/types';
import { KonvaEventObject } from 'konva/lib/Node';

function getDistance(p1: Vector2d, p2: Vector2d): number {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
}

function getCenter(p1: Vector2d, p2: Vector2d): Vector2d {
    return {
        x: (p1.x + p2.x) / 2,
        y: (p1.y + p2.y) / 2,
    };
}

function convertClientPosToAbsolute(clientPos: Vector2d, stage: Stage): Vector2d {
    const contentPosition = stage._getContentPosition();
    return {
        x: (clientPos.x - contentPosition.left) / contentPosition.scaleX,
        y: (clientPos.y - contentPosition.top) / contentPosition.scaleY,
    };
}

const MIN_DIST_BY_TOUCH = 0.001;
const MAX_DIST_BY_TOUCH = 100000;

@Injectable()
export class StageScalerService implements OnDestroy {
    private _stage: Stage | null;
    private _stageScale = 1;
    private lastCenter: Vector2d | null = null;
    private _lastDist = 0;
    private _isStageDragable: boolean | null = null;
    private _isRescaled$ = new BehaviorSubject<void>(undefined);

    constructor() {}

    ngOnDestroy(): void {
        this._stage = null;
    }

    set stage(stage: Stage | null) {
        this._stage = stage;
        if (this._stage == null) {
            return;
        }

        this._stage.on('wheel', e => {
            e.evt.preventDefault();
            const scaleMultiplier = e.evt.deltaY < 0 ? SCALE_SPEED : 1 / SCALE_SPEED;
            this._mouseRescale(scaleMultiplier);
        });

        this._stage.on('pinch', e => {
            e.evt.preventDefault();
            this._mouseRescale(e.evt.scale);
        });

        this._stage.on('touchmove', (e: KonvaEventObject<TouchEvent>) => {
            e.evt.preventDefault();
            this._rescaleTouch(e);
        });

        this._stage.on('touchend', () => {
            if (this._isStageDragable != null) {
                this._stage!.draggable(this._isStageDragable);
            }
            this._lastDist = 0;
            this.lastCenter = null;
            this._isStageDragable = null;
        });
    }

    get stage(): Stage | null {
        return this._stage;
    }

    isRescaled(): Observable<void> {
        return this._isRescaled$;
    }

    resetDefaultStageScale() {
        this._stageScale = this._stage?.scaleX() ?? 1;
        this._mouseRescale(1, true);
    }

    updateScale(): void {
        this._mouseRescale(1, true);
    }

    private _mouseRescale(scaleMultiplier: number, isReset = false): void {
        if (this._stage === null) {
            return;
        }
        const stage = this._stage;
        let pointer: Vector2d = { x: 0, y: 0 };
        if (!isReset) {
            const pointerFrom = stage.getPointerPosition();
            if (pointerFrom != null) {
                pointer = pointerFrom;
            }
        }
        const newScale = this._stageScale * scaleMultiplier;

        this._updateScaleAndPosition(newScale, pointer);
        this._isRescaled$.next();
    }

    private _calcNewPos(pointer: Vector2d, newScale: number): Vector2d {
        const stage = this._stage!;

        let pointerX = 0;
        let pointerY = 0;
        if (pointer != null) {
            pointerX = pointer.x;
            pointerY = pointer.y;
        }

        const mousePointTo = {
            x: (pointerX - stage.x()) / stage.scaleX(),
            y: (pointerY - stage.y()) / stage.scaleY(),
        };

        return {
            x: pointerX - mousePointTo.x * newScale,
            y: pointerY - mousePointTo.y * newScale,
        };
    }

    private _updateScaleAndPosition(newScale: number, oldLocalCenter: Vector2d) {
        if (this._stage == null) {
            return;
        }
        const newPos = this._calcNewPos(oldLocalCenter, newScale);
        this._stage.scale({ x: newScale, y: newScale });
        this._stage.position(newPos);

        this._stageScale = newScale;

        this._updatedFixedScaleShapes();
        this._stage.batchDraw();
    }

    private _rescaleTouch(e: KonvaEventObject<TouchEvent>) {
        if (this._stage == null) {
            return;
        }
        if (e.evt.touches.length !== 2) {
            return;
        }

        const touch1 = e.evt.touches[0];
        const touch2 = e.evt.touches[1];

        const stage = this._stage;

        if (!(touch1 && touch2)) {
            return;
        }

        if (this._isStageDragable == null) {
            this._isStageDragable = this._stage.draggable();
        }
        this._stage.draggable(false);

        const p1 = {
            x: touch1.clientX,
            y: touch1.clientY,
        };
        const p2 = {
            x: touch2.clientX,
            y: touch2.clientY,
        };

        if (!this.lastCenter) {
            const centerInClientCS = getCenter(p1, p2);
            this.lastCenter = convertClientPosToAbsolute(centerInClientCS, this._stage);
            return;
        }

        const dist = Math.max(getDistance(p1, p2), MIN_DIST_BY_TOUCH);
        const isDistOk = dist > MIN_DIST_BY_TOUCH && dist < MAX_DIST_BY_TOUCH;
        if (!isDistOk) {
            return;
        }

        if (!this._lastDist) {
            this._lastDist = dist;
        }
        const newScale = this._stageScale * (dist / this._lastDist);

        this._updateScaleAndPosition(newScale, this.lastCenter);

        this._lastDist = dist;
        this._isRescaled$.next();
    }

    private _updatedFixedScaleShapes(): void {
        if (this._stage === null) {
            return;
        }
        const sceneScale = this._stage.scale();
        const fixedScaleShapes = this._stage.find(
            (node: { getAttr: (arg0: string) => boolean }) => node.getAttr(FIXED_SCALE_ATTRIBUTE) === true,
        );
        fixedScaleShapes.forEach(element => {
            const scale = element.getAttr(FIXED_SCALE_VALUE_ATTRIBUTE) ?? DEFAULT_FIXED_SCALE_VALUE;
            element.scale({
                x: scale / sceneScale.x,
                y: scale / sceneScale.y,
            });
        });
    }
}

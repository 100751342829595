import { WMF_ThrustBallBearing } from './wmf-images/wmf-thrust-ball-bearing';
/* eslint-disable max-len */
import { SupportGeometry } from '../../../../views-foundation/geometries/support-geometry';
import { BearingView2D } from './bearing-view-2d';
import { WMFImage } from './wmf-images/wmf-image';
import { ThrustBall } from '../../../../cae-model/supports/bearings/thrust-ball';

export class ThrustBallView2D extends BearingView2D {
    constructor(thrustBall: ThrustBall) {
        super(thrustBall);
    }

    private get _thrustBall(): ThrustBall {
        return this.modelElement as ThrustBall;
    }

    // Create image manually. Overrides basic class.
    protected createWMFImage(_geometry: SupportGeometry): WMFImage {
        const image = new WMF_ThrustBallBearing();
        image.design_ = this._thrustBall.constructionType;
        return image;
    }
}

import { DoubleSide, Material, MeshBasicMaterial, MeshStandardMaterial } from 'three';

export function createDummyMaterial(): Material {
    return new MeshBasicMaterial({ color: 0x00ff00 });
}

export function createSteelMaterial(): Material {
    return new MeshStandardMaterial({
        roughness: 0.4,
        metalness: 0.7,
        transparent: false,
        side: DoubleSide,
        color: 'rgb(255, 255, 255)',
    });
}

export function createShaftSegmentMaterial(): Material {
    return createSteelMaterial();
}

export function createLoadMaterial(): Material {
    return new MeshStandardMaterial({
        color: 'red',
        roughness: 0.2,
        metalness: 0.6,
    });
}

export function createRingMaterial(): Material {
    return new MeshStandardMaterial({
        color: '#00893d',
        roughness: 0.2,
        metalness: 0.6,
    });
}

export function createRollingElementMaterial(): Material {
    return createSteelMaterial();
}

export function createGearMaterial(color = '#AAAAAA'): Material {
    return new MeshStandardMaterial({
        color,
        roughness: 0.3,
        metalness: 0.6,
        side: DoubleSide,
    });
}

export function createSlidingBearingMaterial(): Material {
    return new MeshStandardMaterial({
        color: '#AAAAAA',
        roughness: 0.3,
        metalness: 0.7,
        side: DoubleSide,
    });
}

export function createCounterMomentMaterial(): Material {
    return new MeshStandardMaterial({
        color: '#00ff00',
        roughness: 0.3,
        metalness: 0.6,
        side: DoubleSide,
    });
}

export function createGearAbstractMaterial(): Material {
    return new MeshStandardMaterial({
        color: '#71d871',
        roughness: 0.3,
        metalness: 0.6,
        side: DoubleSide,
    });
}

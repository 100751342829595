import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxFileDropModule } from 'ngx-file-drop';
import { IconModule } from '../icon/icon.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { UtilModule } from '../util/util.module';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ComboBoxComponent } from './combo-box/combo-box.component';
import { FormFieldComponent } from './field/form-field.component';
import { FormFieldDirective } from './field/form-field.directive';
import { InputAddonsDirective, InputContainerDirective } from './input/input-container.directive';
import { InputDirective } from './input/input.directive';
import { PrecisionInputDirective } from './input/precision-input.directive';
import { MultiSelectOverlayComponent } from './multi-select/multi-select-overlay.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { TagsInputComponent } from './tags-input/tagsInput.component';

@NgModule({
    imports: [CommonModule, NgxFileDropModule, IconModule, TranslateModule, UtilModule, PortalModule, OverlayModule, TooltipModule],
    declarations: [
        CheckboxComponent,
        ComboBoxComponent,
        FormFieldDirective,
        InputAddonsDirective,
        InputContainerDirective,
        InputDirective,
        MultiSelectComponent,
        MultiSelectOverlayComponent,
        PrecisionInputDirective,
        TagsInputComponent,
        FormFieldComponent,
    ],
    exports: [
        CheckboxComponent,
        ComboBoxComponent,
        FormFieldDirective,
        InputAddonsDirective,
        InputContainerDirective,
        InputDirective,
        MultiSelectComponent,
        PrecisionInputDirective,
        TagsInputComponent,
        FormFieldComponent,
    ],
})
export class FormControlModule {}

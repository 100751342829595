import {
    BufferGeometry,
    ConeGeometry,
    CylinderBufferGeometry,
    CylinderGeometry,
    Euler,
    Group,
    MathUtils,
    Mesh,
    MeshBasicMaterial,
    MeshStandardMaterial,
    Vector3,
    Box3,
    Object3D,
    Texture,
    LinearFilter,
    RepeatWrapping,
    SpriteMaterial,
    Sprite,
    Material,
    Plane,
} from 'three';
import { CSG } from 'three-csg-ts';
import { UnitSet } from '../../views-foundation/view-foundation-settings';
import { FPS_UNIT_SCALE, SI_UNIT_SCALE } from '../../views-foundation/views-foundation-constants';
import { ClippingType } from '../settings';
import {
    CLIPPING_PLANE_CONSTANT,
    COLOR_AXIS_X,
    COLOR_AXIS_Y,
    COLOR_AXIS_Z,
    COORDINATE_SYSTEM_3D,
    COUNT_SEGMENTS_CYLINDER,
    MIN_CLIPPING_PLANE_CONSTANT,
    TINY_3D,
} from '../settings/view-3d-constants';
import { ClippingPlaneType, View3DSettings } from '../settings/view-3d-settings';
import { createPlaceholderVizStringData, getVisualizationParameters, createVisualizationGeometry } from './create-visualization-geometry';

export interface CylinderWithHoleInterface {
    outerRadiusStart: number;
    outerRadiusEnd: number;
    innerRadiusStart: number;
    innerRadiusEnd: number;
    length: number;
}

export interface ArrowInputInterFace {
    color: string;
    arrowBodyWidth: number;
    arrowBodyHeight: number;
    arrowHeadWidth: number;
    arrowHeadHeight: number;
    arrowPosition?: Vector3;
    shaftId: string;
}

export interface CoordinateInputInterFace {
    arrowBodyWidth: number;
    arrowBodyHeight: number;
    arrowHeadWidth: number;
    arrowHeadHeight: number;
    shaftId: string;
    enableLabel: boolean;
}

interface SpriteTextInputInterFace {
    text: string;
    size: number;
    textColor: string;
    axisType: AxisTypes;
    arrowBodyHeight: number;
    arrowHeadWidth: number;
}

interface ClippingDirectionInput {
    clippingType: ClippingType;
    clippingPlane: ClippingPlaneType;
    flipClippingPlane: boolean;
    isMaterialClipOnPlanes: boolean;
}

export enum AxisTypes {
    X = 'X',
    Y = 'Y',
    Z = 'Z',
}

function createDummyMaterial(): MeshBasicMaterial {
    return new MeshBasicMaterial({ color: 0x00ff00 });
}

export function createCylinderWithHole(input: CylinderWithHoleInterface): BufferGeometry {
    const cylinder = new CylinderBufferGeometry(input.outerRadiusStart, input.outerRadiusEnd, input.length, COUNT_SEGMENTS_CYLINDER);
    if (input.innerRadiusStart <= 0 && input.innerRadiusEnd <= 0) {
        // no hole exist
        return cylinder;
    }
    const dummyMaterial = createDummyMaterial();
    const cylinderMesh = new Mesh(cylinder, dummyMaterial);
    const hole = new CylinderBufferGeometry(input.innerRadiusStart, input.innerRadiusEnd, input.length, COUNT_SEGMENTS_CYLINDER);

    const holeMesh = new Mesh(hole, dummyMaterial);

    const substractedMesh = CSG.subtract(cylinderMesh, holeMesh);
    return substractedMesh.geometry;
}

export function getStartAngle(setting: View3DSettings): number {
    const clippintType = setting.clippingType;
    const clippingPlaneType = setting.clippingPlane;

    let startAngle = 0.0;
    if (clippingPlaneType === ClippingPlaneType.XY) {
        startAngle -= 90;
    }
    if (setting.flipClippingPlane) {
        startAngle -= 180;
    }

    const shift = clippingPlaneType === ClippingPlaneType.XZ ? 180 : 0;
    if (clippintType === ClippingType.Quarter) {
        return startAngle + shift;
    } else if (clippintType === ClippingType.Half) {
        return startAngle + 90 + shift;
    }
    return 0;
}

// return the vector, showing in wich direction clipping should occure
// e.g. (0,0,-1) means we don't show anything in negative z direction (e.g. non-fliped xy half)
// e.g. (0,-1,-1) means we show anything having negative  y and z direction (e.g . non-fliped xy quater)
export function calculateClippingDirections(clippingDirectionInput: ClippingDirectionInput): Vector3 {
    const { clippingType, clippingPlane, flipClippingPlane, isMaterialClipOnPlanes } = clippingDirectionInput;

    if (clippingType === ClippingType.Off) {
        return new Vector3();
    }

    const clippingPlaneType = clippingPlane;
    let y = 0;
    let z = 0;
    if (clippingPlaneType === ClippingPlaneType.XY) {
        z = -1;
    } else {
        y = -1;
    }

    let multiplier = flipClippingPlane ? -1 : 1;
    if (clippingType === ClippingType.Quarter) {
        if (clippingPlaneType === ClippingPlaneType.XY) {
            y = -1;
        } else {
            z = 1;
        }
        if (isMaterialClipOnPlanes) {
            multiplier = multiplier * -1;
        }
    }

    return new Vector3(0, y * multiplier, z * multiplier);
}

// get center of bounding box
export function getBboxCenter(obj: Object3D): Vector3 {
    const bbox = new Box3();
    bbox.setFromObject(obj);
    const center = new Vector3();
    bbox.getCenter(center);
    return center;
}

function isInClippingQuater(clippingDirection: Vector3, geometryCenter: Vector3) {
    if (clippingDirection.x !== 0) {
        throw Error('isInClippingQuater with clipping in x direction is not implemented yet');
    }
    const yProd = clippingDirection.y * geometryCenter.y;
    const zProd = clippingDirection.z * geometryCenter.z;
    return yProd > 0 && zProd > 0;
}

export function isCenterInClippingZone(settings: View3DSettings, mesh: Mesh): boolean {
    const { clippingType, clippingPlane, flipClippingPlane } = settings;
    if (clippingType === ClippingType.Off) {
        return false;
    }
    const clippingDirection = calculateClippingDirections({
        clippingType,
        clippingPlane,
        flipClippingPlane,
        isMaterialClipOnPlanes: false,
    });

    const meshCenter = getBboxCenter(mesh);
    if (clippingType === ClippingType.Half) {
        const scalProduct = clippingDirection.dot(meshCenter);
        return scalProduct > 0;
    } else if (clippingType === ClippingType.Quarter) {
        return isInClippingQuater(clippingDirection, meshCenter);
    } else {
        throw Error('Unknow clipping type');
    }
}

/// calculate displacement of  objects to avoid intersections by clipping
function calculateHidingDisplacement(settings: View3DSettings): Vector3 {
    const { clippingType, clippingPlane, flipClippingPlane } = settings;
    const direction = calculateClippingDirections({
        clippingType,
        clippingPlane,
        flipClippingPlane,
        isMaterialClipOnPlanes: false,
    });
    return direction.multiplyScalar(-TINY_3D);
}

// get size of the hole, generated by clipping, in grad
export function getOpeningAngle(clippintType: ClippingType): number {
    if (clippintType === ClippingType.Quarter) {
        return 90;
    } else if (clippintType === ClippingType.Half) {
        return 180;
    }
    return 0;
}

function createArrow(arrowInput: ArrowInputInterFace): Group {
    const { color, arrowBodyWidth, arrowBodyHeight, arrowHeadWidth, arrowHeadHeight, arrowPosition, shaftId } = arrowInput;
    const arrowGroup: Group = new Group();

    const arrowMaterial = new MeshStandardMaterial({
        color: color,
        roughness: 0.8,
        metalness: 0,
    });

    const bodyArrow = new CylinderGeometry(arrowBodyWidth, arrowBodyWidth, arrowBodyHeight);
    const bodyArrowMesh = new Mesh(bodyArrow, arrowMaterial);
    bodyArrowMesh.name = shaftId;

    const headArrow = new ConeGeometry(arrowHeadWidth, arrowHeadHeight).translate(0, arrowBodyHeight / 2, 0);
    const headArrowMesh = new Mesh(headArrow, arrowMaterial);
    headArrowMesh.name = shaftId;

    arrowGroup.add(bodyArrowMesh);
    arrowGroup.add(headArrowMesh);

    if (arrowPosition) {
        arrowGroup.position.set(arrowPosition.x, arrowPosition.y, arrowPosition.z);
        arrowGroup.updateMatrixWorld();
    }

    return arrowGroup;
}

function getColor(axisType: AxisTypes): string {
    if (axisType === AxisTypes.X) {
        return COLOR_AXIS_X;
    } else if (axisType === AxisTypes.Y) {
        return COLOR_AXIS_Y;
    }
    return COLOR_AXIS_Z;
}

function getRotation(axisType: AxisTypes): Euler {
    if (axisType === AxisTypes.X) {
        return new Euler(0, 0, MathUtils.degToRad(-90));
    } else if (axisType === AxisTypes.Y) {
        return new Euler(0, 0, 0);
    }
    return new Euler(MathUtils.degToRad(90), 0, 0);
}

function getArrowPosition(axisType: AxisTypes, arrowBodyHeight: number): Vector3 {
    if (axisType === AxisTypes.X) {
        return new Vector3(arrowBodyHeight / 2, 0, 0);
    } else if (axisType === AxisTypes.Y) {
        return new Vector3(0, arrowBodyHeight / 2, 0);
    }
    return new Vector3(0, 0, arrowBodyHeight / 2);
}

function getLabelPosition(axisType: AxisTypes, arrowBodyHeight: number, arrowHeadWidth: number): Vector3 {
    const labelGap = 10;
    if (axisType === AxisTypes.X) {
        return new Vector3(arrowBodyHeight, -arrowHeadWidth - labelGap, 0);
    } else if (axisType === AxisTypes.Y) {
        return new Vector3(arrowHeadWidth + labelGap, arrowBodyHeight, 0);
    } else {
        return new Vector3(arrowHeadWidth + labelGap, 0, arrowBodyHeight);
    }
}

function createTextSprite(spriteTextInputInterFace: SpriteTextInputInterFace): Sprite {
    const { text, size, textColor, axisType, arrowBodyHeight, arrowHeadWidth } = spriteTextInputInterFace;
    const canvas: Element = document.createElement('canvas');
    const c: HTMLCanvasElement = <HTMLCanvasElement>canvas;

    c.width = size;
    c.height = size;
    const context = c.getContext('2d')!;

    context.font = '18px sans-serif';
    context.textAlign = 'center';
    context.fillStyle = textColor;
    context.fillText(text, canvas['width'] / 2, canvas['height'] / 2);
    context.strokeStyle = textColor;
    context.strokeText(text, canvas['width'] / 2, canvas['height'] / 2);

    const texture = new Texture(c);
    texture.needsUpdate = true;
    texture.generateMipmaps = true;
    texture.magFilter = LinearFilter;
    texture.minFilter = LinearFilter;
    texture.wrapS = RepeatWrapping;

    const spriteMaterial = new SpriteMaterial({ map: texture });
    const sprite = new Sprite(spriteMaterial);
    sprite.scale.set(size, size, size);
    const position = getLabelPosition(axisType, arrowBodyHeight, arrowHeadWidth);
    sprite.position.set(position.x, position.y, position.z);
    return sprite;
}

export function createCoordinateSystem(coordinateInputInterFace: CoordinateInputInterFace): Group {
    const { shaftId, arrowBodyWidth, arrowBodyHeight, arrowHeadWidth, arrowHeadHeight, enableLabel } = coordinateInputInterFace;
    const coordinateGroup: Group = new Group();
    coordinateGroup.name = COORDINATE_SYSTEM_3D;

    [AxisTypes.X, AxisTypes.Y, AxisTypes.Z].forEach(axisType => {
        const color = getColor(axisType);
        const axis = createArrow({
            color: color,
            arrowBodyWidth,
            arrowBodyHeight,
            arrowHeadWidth,
            arrowHeadHeight,
            arrowPosition: getArrowPosition(axisType, arrowBodyHeight),
            shaftId,
        });

        const rotation = getRotation(axisType);
        axis.rotation.set(rotation.x, rotation.y, rotation.z);
        axis.name = axisType;

        coordinateGroup.add(axis);
        if (enableLabel) {
            coordinateGroup.add(
                createTextSprite({
                    text: axisType,
                    size: 128,
                    textColor: color,
                    axisType,
                    arrowBodyHeight,
                    arrowHeadWidth,
                }),
            );
        }
    });

    return coordinateGroup;
}

export function getAllCornerPointFromBoundingBox(bbox: Box3): Vector3[] {
    return [
        new Vector3(bbox.max.x, bbox.max.y, bbox.max.z),
        new Vector3(bbox.max.x, bbox.min.y, bbox.max.z),
        new Vector3(bbox.max.x, bbox.max.y, bbox.min.z),
        new Vector3(bbox.max.x, bbox.min.y, bbox.min.z),
        new Vector3(bbox.min.x, bbox.min.y, bbox.min.z),
        new Vector3(bbox.min.x, bbox.max.y, bbox.min.z),
        new Vector3(bbox.min.x, bbox.min.y, bbox.max.z),
        new Vector3(bbox.min.x, bbox.max.y, bbox.max.z),
    ];
}

// this function will return the projection point on the plane which is created by pointOnPlane and planeNormalVector
export function getProjectionPointOnPlane(pointToBeProjected: Vector3, pointOnPlane: Vector3, planeNormalVector: Vector3): Vector3 {
    const t: number =
        (planeNormalVector.x * (pointOnPlane.x - pointToBeProjected.x) +
            planeNormalVector.y * (pointOnPlane.y - pointToBeProjected.y) +
            planeNormalVector.z * (pointOnPlane.z - pointToBeProjected.z)) /
        (Math.pow(planeNormalVector.x, 2) + Math.pow(planeNormalVector.y, 2) + Math.pow(planeNormalVector.z, 2));

    return new Vector3(
        pointToBeProjected.x + planeNormalVector.x * t,
        pointToBeProjected.y + planeNormalVector.y * t,
        pointToBeProjected.z + planeNormalVector.z * t,
    );
}

export function createX(): Vector3 {
    return new Vector3(1, 0, 0);
}

export function createY(): Vector3 {
    return new Vector3(0, 1, 0);
}

export function createZ(): Vector3 {
    return new Vector3(0, 0, 1);
}

export function calculateMaxDimension(object3D: Object3D): number {
    const bbox = new Box3();
    bbox.setFromObject(object3D);

    const bboxSize = new Vector3();
    bbox.getSize(bboxSize);

    return Math.max(bboxSize.x, bboxSize.y, bboxSize.z);
}

export function getTranslatedCameraPosition(cameraPosition: Vector3, controlsTarget: Vector3, centerSelectedElement: Vector3): Vector3 {
    const translationVector = new Vector3(
        centerSelectedElement.x - controlsTarget.x,
        centerSelectedElement.y - controlsTarget.y,
        centerSelectedElement.z - controlsTarget.z,
    );

    return new Vector3(
        cameraPosition.x + translationVector.x,
        cameraPosition.y + translationVector.y,
        cameraPosition.z + translationVector.z,
    );
}

export function resolveOverlapDisplayOrCreateDefaultBufferGeometry(
    settings: View3DSettings,
    geometry: BufferGeometry | null,
): BufferGeometry {
    if (geometry == null) {
        return new BufferGeometry();
    }
    const hidingDisplacement = calculateHidingDisplacement(settings);
    geometry.translate(hidingDisplacement.x, hidingDisplacement.y, hidingDisplacement.z);
    return geometry;
}

export function createCylinderGeometry(outerDiameter: number, innerDiameter: number, width: number): BufferGeometry {
    const outerRadius = outerDiameter / 2;
    const innerRadius = innerDiameter / 2;

    const cylinderWithHoleInterface: CylinderWithHoleInterface = {
        outerRadiusStart: outerRadius,
        outerRadiusEnd: outerRadius,
        innerRadiusStart: innerRadius,
        innerRadiusEnd: innerRadius,
        length: width,
    };
    return createCylinderWithHole(cylinderWithHoleInterface);
}

export function getUnitSetScaleValue(unitset: UnitSet): number {
    switch (unitset) {
        case UnitSet.FPS:
            return FPS_UNIT_SCALE;
        case UnitSet.SI:
            return SI_UNIT_SCALE;
        default:
            return 1;
    }
}

export function createPlaceholderGeometry(
    settings: View3DSettings,
    innerDiameter: number,
    outerDiameter: number,
    width: number,
): BufferGeometry {
    const unitScale = getUnitSetScaleValue(settings.unitSet);
    const vizStringData = createPlaceholderVizStringData(innerDiameter, outerDiameter, width, unitScale);
    const vizParameters = getVisualizationParameters(settings, false, vizStringData);
    const visualizationGeometry = createVisualizationGeometry(vizParameters);
    return resolveOverlapDisplayOrCreateDefaultBufferGeometry(settings, visualizationGeometry);
}

export function generateCanvasElement(width: number, height: number): HTMLCanvasElement {
    // mock(HTMLCanvasElement) won't work with WebGLRenderer
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    // we need to do did so that it can get the clientWidth and clientHeight
    document.body.appendChild(canvas);

    return canvas;
}

export function clearObject(object3D: Object3D): void {
    if (object3D.children != null && object3D.children.length > 0) {
        [...object3D.children].forEach((child: Object3D) => {
            clearObject(child);
        });
    }

    if (object3D instanceof Mesh) {
        if (object3D.geometry) {
            object3D.geometry.dispose();
        }

        if (object3D.material) {
            if (object3D.material.length) {
                object3D.material.forEach((material: Material) => {
                    material.dispose();
                });
            } else {
                object3D.material.dispose();
            }
        }
    }

    object3D.clear();
    object3D.removeFromParent();
}

export function determineQuarterClippingPlanes(clipingDirection: Vector3): Plane[] {
    const planes: Plane[] = [];

    planes.push(
        new Plane(new Vector3(0, 0, clipingDirection.z), MIN_CLIPPING_PLANE_CONSTANT),
        new Plane(new Vector3(0, clipingDirection.y, 0), MIN_CLIPPING_PLANE_CONSTANT),
        new Plane(new Vector3(1, 0, 0), -CLIPPING_PLANE_CONSTANT),
    );

    return planes;
}

// Note: this method will not work correctly on calculating the bounding box of the object. For more information,
// please read this thread https://discourse.threejs.org/t/how-to-remove-a-part-of-a-mesh-with-clipping-planes/23718
// Note: The half cutting which change the selection box should be use the old method, clipMeshOnPlane
export function clipMaterialOnPlanes(clippingObjectMaterial: Material, clippingPlanes: Plane[], clipIntersection: boolean): void {
    clippingObjectMaterial.clippingPlanes = clippingPlanes;
    clippingObjectMaterial.clipIntersection = clipIntersection;
    clippingObjectMaterial.clipShadows = true;
}

import { IRect } from 'konva/lib/types';
import { ElementView2D } from '../elements-view/element-view-2d';
import { Dimensions2D } from './dimensions-2d';
import { HorizontalDimensionsInterface, VerticalDimensionsInterface } from './dimensions-utils';

export class StandardDimensions2D extends Dimensions2D {
    constructor(elementView2D: ElementView2D, modelViewClientRect: IRect, fractionDigits: number) {
        super(elementView2D, modelViewClientRect, fractionDigits);
    }

    protected doCreateHorizontalDimensionsInputs(): HorizontalDimensionsInterface[] {
        const elementRect = this.getGroupWithoutChildrenClientRect();
        const objectRadius = elementRect.y + elementRect.height;

        return [
            {
                objectRadius,
                objectPositionX: this.getCoordinatesAbsX(),
            },
        ];
    }

    protected doCreateVerticalDimensionsInput(): VerticalDimensionsInterface[] {
        return [];
    }
}

import { IRect } from 'konva/lib/types';
import { DistributedLoad } from '../../cae-model/loads/distributed-load';
import { Hub } from '../../cae-model/notches/hub';
import { KeySeat } from '../../cae-model/notches/key-seat';
import { ConeSegment } from '../../cae-model/shaft-segments/cone-segment';
import { PipeSegment } from '../../cae-model/shaft-segments/pipe-segment';
import { ElementView2D } from '../elements-view/element-view-2d';
import { ConeSegmentDimensions2D } from './cone-segment-dimensions-2d';
import { Dimensions2D } from './dimensions-2d';
import { DistributedLoadDimensions2D } from './distributed-load-dimensions-2d';
import { HubDimensions2D } from './notches/hub-dimensions-2d';
import { KeySeatDimensions2D } from './notches/key-seat-dimensions-2d';
import { PipeSegmentDimensions2D } from './pipe-segment-dimensions-2d';
import { StandardDimensions2D } from './standard-dimensions-2d';

export class Dimensions2DFactory {
    createDimensions2D(elementView2D: ElementView2D, modelViewClientRect: IRect, fractionDigits: number): Dimensions2D {
        if (elementView2D.modelElement instanceof PipeSegment) {
            return new PipeSegmentDimensions2D(elementView2D, modelViewClientRect, fractionDigits);
        } else if (elementView2D.modelElement instanceof ConeSegment) {
            return new ConeSegmentDimensions2D(elementView2D, modelViewClientRect, fractionDigits);
        } else if (elementView2D.modelElement instanceof DistributedLoad) {
            return new DistributedLoadDimensions2D(elementView2D, modelViewClientRect, fractionDigits);
        } else if (elementView2D.modelElement instanceof Hub) {
            return new HubDimensions2D(elementView2D, modelViewClientRect, fractionDigits);
        } else if (elementView2D.modelElement instanceof KeySeat) {
            return new KeySeatDimensions2D(elementView2D, modelViewClientRect, fractionDigits);
        } else {
            return new StandardDimensions2D(elementView2D, modelViewClientRect, fractionDigits);
        }
    }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

import { BookmarkService } from '../../bookmark/bookmark.service';
import { BookmarkItem } from '../../bookmark/bookmark-item.model';
import { ProjectService } from '../../../app/project/project.service';

@Component({
    selector: 'bx-bookmark-list',
    templateUrl: './bookmark-list.component.html',
    styleUrls: ['./bookmark-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookmarkListComponent {
    private readonly _url: string = 'models/library';
    readonly bookmarks$ = this._bookmarkService.bookmarks$;

    constructor(
        private readonly _router: Router,
        private readonly _projectService: ProjectService,
        private readonly _bookmarkService: BookmarkService,
    ) {}

    trackByFunction(_index: number, item: BookmarkItem) {
        return item.objectId;
    }

    go(url: string): Promise<boolean> {
        return this._router.navigate([url]);
    }

    selectProject(id: string) {
        this.go(this._url).then(() => {
            this._projectService.selectProject(id);
        });
    }
}

import { environment as defaultEnvironment } from './environment.defaults';
import { AuthConfig } from 'angular-oauth2-oidc';

export const environment = {
    ...defaultEnvironment,
    production: false,
    baseUrl: 'https://bearinx-e.schaeffler.com/api/',
    helpBaseUrl: 'https://bearinx-e.schaeffler.com/help/',
    settingsUrl: 'https://bearinx-e.schaeffler.com/settings/v1/appsettings',
    oauth: {
        ...defaultEnvironment.oauth,
        issuer: `${window.location.origin}/auth/realms/caeonline-d`,
    } as AuthConfig,
    tutorials: {
        baseUrl: 'https://bearinx-e.schaeffler.com/tutorials/Bearinx%20Medias%20Subtitles%20v2.mp4',
        calculationFundamentals: 'https://bearinx-e.schaeffler.com/tutorials/Correns_Bearing_fatigue_life.mp4',
    },
    manual: {
        english: 'https://bearinx-e.schaeffler.com/tutorials/Bearinx-Manual.pdf',
        german: 'https://bearinx-e.schaeffler.com/tutorials/Bearinx-Handbuch.pdf',
    },
    news: {
        english: 'https://bearinx-e.schaeffler.com/tutorials/Bearinx-Online_Version_Info_en.pdf',
        german: 'https://bearinx-e.schaeffler.com/tutorials/Bearinx-Online_Version_Info_de.pdf',
    },
};

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';

import { SettingsService } from '../settings/settings.service';
import { environment } from '../../environments/environment';
import { take, map, switchMap } from 'rxjs/operators';

@Injectable()
export class DataModelInterceptor implements HttpInterceptor {
    constructor(private readonly _settingsService: SettingsService) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.startsWith(environment.baseUrl)) {
            return combineLatest([this._settingsService.currentLanguage$, this._settingsService.currentUnitSet$]).pipe(
                take(1),
                map(([language, unitSet]) => ({
                    'x-bearinx-language': language,
                    'x-bearinx-unitset': unitSet,
                })),
                switchMap(setHeaders => next.handle(req.clone({ setHeaders }))),
            );
        }
        return next.handle(req);
    }
}

import { Injectable, OnDestroy } from '@angular/core';
import { SelectedInViewerElementInterface } from '@caeonline/viewer';
import Konva from 'konva';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
/**
 *  2D View
 */
@Injectable()
export class View2dService implements OnDestroy {
    private _layerSubj = new BehaviorSubject<Konva.Layer>(new Konva.Layer());
    private _stageSubj = new BehaviorSubject<Konva.Stage | null>(null);

    public readonly clickEvent$ = new Subject<SelectedInViewerElementInterface>();

    getLayer(): Observable<Konva.Layer> {
        return this._layerSubj;
    }

    updateLayer(layer: Konva.Layer): void {
        this._layerSubj.value.destroy();
        this._layerSubj.next(layer);
    }

    get stage(): Observable<Konva.Stage | null> {
        return this._stageSubj;
    }

    updateStage(stage: Konva.Stage) {
        if (this._stageSubj.value != null) {
            this._stageSubj.value.destroy();
        }
        this._stageSubj.next(stage);
        const windowAny = window as any;
        if (windowAny.Cypress != null) {
            windowAny.stage = stage;
        }
    }

    ngOnDestroy(): void {
        this._stageSubj.next(null);
    }
}

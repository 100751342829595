import { NotchView2D } from './notch-view-2d';
import { TransverseBore } from '../../../cae-model/notches/transverse-bore';
import {
    BACKGROUND_COLOR_2D,
    DASH_STROKE_TRANSVERSE_BORE,
    DASH_STROKE_TRANSVERSE_BORE_CENTER,
    DISPLAYRED,
    STROKE_WIDTH,
    TRANSPARENT,
} from '../view-2d-constants';
import { generateUpperAndLowerParts } from '../../functions/utils-2d';
import { TINY_RADIUS } from '../../../views-foundation/views-foundation-constants';
import { Shape } from 'konva/lib/Shape';
import { Line } from 'konva/lib/shapes/Line';
import { Group } from 'konva/lib/Group';

export class TransverseBoreView2D extends NotchView2D {
    constructor(transverseBore: TransverseBore) {
        super(transverseBore);
    }

    private get _transverseBore(): TransverseBore {
        return this.modelElement as TransverseBore;
    }

    protected updateGroupWithoutChildren(): void {
        super.rerenderViewWithoutChildren('transverse-bore-view-2d-group');
    }

    protected createNotchView(): Group {
        const group = new Group();
        const scale = this.input.unitScaleFactor;
        const diameter = this._transverseBore.diameter < 0.001 / scale ? 100.0 / scale : this._transverseBore.diameter;
        const width = diameter / 2.0;
        const transverseBore: Shape[] = [];
        const dashLine = new Line({
            points: [0, -this.outerRadius, 0, this.outerRadius],
            stroke: DISPLAYRED,
            strokeWidth: STROKE_WIDTH,
            strokeScaleEnabled: false,
            dash: DASH_STROKE_TRANSVERSE_BORE_CENTER,
            name: 'dash-line',
        });
        const transverseBoreCurve = new Shape({
            stroke: DISPLAYRED,
            strokeWidth: STROKE_WIDTH,
            strokeScaleEnabled: false,
            y: -this.outerRadius,
            name: 'transverse-bore-curve',
            sceneFunc: (context, shape) => {
                context.beginPath();

                context.moveTo(-width, 0);

                // Up curve
                context.quadraticCurveTo(0, width / 2.0, width, 0);

                context.fillStrokeShape(shape);
            },
            fill: BACKGROUND_COLOR_2D,
        });
        const transverseBoreCurveTransparent = new Line({
            points: [0, 0, -width, 0, 0, width / 2.0, width, 0],
            stroke: TRANSPARENT,
            strokeWidth: STROKE_WIDTH,
            strokeScaleEnabled: false,
            y: -this.outerRadius,
            fill: TRANSPARENT,
            closed: true,
        });
        transverseBore.push(transverseBoreCurveTransparent);

        if (this.innerRadius > TINY_RADIUS / scale) {
            const innerTransverseBorePart = transverseBoreCurve.clone() as Shape;
            const height = this.outerRadius - this.innerRadius;
            innerTransverseBorePart.sceneFunc((context, shape) => {
                context.beginPath();

                context.moveTo(width, 0);

                // Right line
                context.lineTo(width, height);

                // Down curve
                context.quadraticCurveTo(0, height + width / 2.0, -width, height);

                // Left line
                context.lineTo(-width, 0);

                context.fillStrokeShape(shape);
            });
            transverseBore.push(innerTransverseBorePart);
        } else {
            // Left and right dash line
            [-width, width].forEach(x => {
                const dashLineClone = dashLine.clone();
                dashLineClone.dash(DASH_STROKE_TRANSVERSE_BORE);
                dashLineClone.x(x);
                group.add(dashLineClone);
            });
        }

        transverseBore.push(
            new Line({
                points: [-width + STROKE_WIDTH / scale, -this.outerRadius, width - STROKE_WIDTH / scale, -this.outerRadius],
                stroke: BACKGROUND_COLOR_2D,
                strokeWidth: STROKE_WIDTH,
                strokeScaleEnabled: false,
                name: 'blur-line',
            }),
        );

        transverseBore.push(transverseBoreCurve);

        transverseBore.forEach(part => {
            group.add(generateUpperAndLowerParts(part, part.name()));
        });

        // Center dash line
        group.add(dashLine);

        return group;
    }
}

export interface DrawingData {
    data: string;
    filled: boolean;
}

export function isFillPath(styleString: string | null): boolean {
    if (!!styleString) {
        const unFillColor = styleString.includes('fill:none');
        if (!unFillColor) {
            return true;
        }
    }
    return false;
}

export function getSvgDrawingDataFromSvgString(svgString: string): DrawingData[] {
    const domParser = new DOMParser();
    const svgDocument: Document = domParser.parseFromString(svgString, 'image/svg+xml');
    const paths = Array.from(svgDocument.querySelectorAll('path'));
    return paths.map(pathElement => {
        const data = pathElement.getAttribute('d')!;
        const styleString = pathElement.getAttribute('style');
        return {
            data,
            filled: isFillPath(styleString),
        };
    });
}

import { TransmissionElement } from './transmission-element';

export enum GEOMETRICAL_FORM {
    CONVERGENT = 'CONVERGENT',
    DIVERGENT = 'DIVERGENT',
}

export class BevelGearBase extends TransmissionElement {
    outerDiameter = 0; // IDTE_D
    innerDiameter = 0; // IDTE_DI
    width = 0; // IDTE_B
    workingPitchDiameter = 0; // IDBG_DM
    angleDelta = 0; // IDBG_DELTA
    geometricalForm = ''; // IDBG_GEOMETRICAL_FORM      CONVERGENT/ DIVERGENT
    toothCount = 0; // IDBG_NB_TOOTH_CONTACTS
}

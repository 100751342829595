import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PromptModule } from '../prompt/prompt.module';
import { RoleModule } from '../role/role.module';
import { LockTokenRefreshService } from './lock-token-refresh.service';
import { LockTokenStorageService } from './lock-token-storage.service';
import { LockTokenInterceptor } from './lock-token.interceptor';
import { LockingService } from './locking.service';
import { TokenExpiredPrompt } from './prompts/token-expired/token-expired.prompt';
import { ModelLockedPrompt } from './prompts/model-locked/model-locked.prompt';
import { TokenOverriddenPrompt } from './prompts/token-overridden/token-overridden.prompt';
import { OverrideLockPrompt } from './prompts/override-lock/override-lock.prompt';
import { ReleaseLockPrompt } from './prompts/release-lock/release-lock.prompt';

export const LOCKING_PROVIDERS = [LockingService, LockTokenRefreshService];

@NgModule({
    imports: [CommonModule, TranslateModule, PromptModule, RoleModule],
    declarations: [ModelLockedPrompt, OverrideLockPrompt, ReleaseLockPrompt, TokenExpiredPrompt, TokenOverriddenPrompt],
})
export class LockingModule {
    public static forRoot(): ModuleWithProviders<LockingModule> {
        return {
            ngModule: LockingModule,
            providers: [LockTokenStorageService, { provide: HTTP_INTERCEPTORS, useClass: LockTokenInterceptor, multi: true }],
        };
    }
}

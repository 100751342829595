import { Attribute, Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { HotkeyService } from './hotkey.service';

@Directive({
    selector: '[bxHotkey]',
})
export class HotkeyDirective implements OnInit, OnDestroy {
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() public readonly onHotkey: EventEmitter<string> = new EventEmitter<string>();
    private readonly _nativeElement: HTMLElement;

    constructor(
        @Attribute('bxHotkey') private readonly _hotkey: string,
        private readonly _hotkeyService: HotkeyService,
        elementRef: ElementRef,
    ) {
        this._nativeElement = elementRef.nativeElement;
    }

    public ngOnInit(): void {
        if (!this._hotkey) {
            throw new Error('Hotkey not set.');
        }

        this._hotkeyService.register(this._hotkey, this._nativeElement).subscribe(combo => this.onHotkey.next(combo));
    }

    public ngOnDestroy(): void {
        this._hotkeyService.unregister(this._hotkey, this._nativeElement);
    }
}

import { Injectable } from '@angular/core';
import Hammer from 'hammerjs';
import { BehaviorSubject } from 'rxjs';
import {
    AmbientLight,
    Box3,
    Camera,
    DirectionalLight,
    Object3D,
    PCFSoftShadowMap,
    Scene,
    SpotLight,
    Vector2,
    Vector3,
    WebGLRenderer,
} from 'three';
import { ClickType, SelectedInViewerElementInterface } from '../../views-foundation/interfaces/selected-in-viewer-element-interface';
import { SelectedElementService } from '../../views-foundation/services/selected-element.service';
import { UnitSet } from '../../views-foundation/view-foundation-settings';
import { HammerTouchEvent } from '../../views-foundation/views-foundation-constants';
import { createLabeledGrid } from '../helper/labeled-grid';
import { Picker } from '../helper/picker';
import {
    SPOT_LIGHT_COLOR,
    SPOT_LIGHT_INTENSITY,
    DIRECTIONAL_LIGHT_COLOR,
    DIRECTIONAL_LIGHT_INTENSITY,
    AMBIENT_LIGHT_COLOR,
    AMBIENT_LIGHT_INTENSITY,
    USERDATA_MODEL_ELEMENT_ID,
    MouseClickEvent,
} from '../settings/view-3d-constants';
import { CameraService } from './camera.service';

export interface SelectEvent {
    type: string;
    x: number;
    y: number;
}

const DEFAULT_CLICK_EVENT = {
    clickType: ClickType.LEFT_CLICK,
    elementID: '',
    clientX: 0,
    clientY: 0,
};

@Injectable()
export class View3DVisualizationService {
    private _renderer: WebGLRenderer | null;
    private _scene: Scene | null;
    private _canvas: HTMLCanvasElement;
    private _pickPosition: Vector2 | null;
    private _hammer: HammerManager;

    private _clickEvent = new BehaviorSubject<SelectedInViewerElementInterface>(DEFAULT_CLICK_EVENT);

    constructor(private _cameraService: CameraService, private _selectedElementService: SelectedElementService, private _picker: Picker) {}

    get renderer(): WebGLRenderer | null {
        return this._renderer;
    }

    get scene(): Scene | null {
        return this._scene;
    }

    get clickEvent(): BehaviorSubject<SelectedInViewerElementInterface> {
        return this._clickEvent;
    }

    public init(canvas: HTMLCanvasElement) {
        this._canvas = canvas;
        this._renderer = new WebGLRenderer({
            canvas: canvas,
            antialias: true,
        });
        this._renderer.localClippingEnabled = true;
        this._addsupportOfThreeJsDevTools();
        this._startRendering();
        this.initPickPosition();
        this._picker.initPicker();

        this._clearPickPosition();
        this._bindCanvasEvents();
    }

    public initScene() {
        this._scene = new Scene();
        this._scene.updateMatrixWorld(true);

        const windowAny = window as any;
        if (windowAny.Cypress != null) {
            windowAny.scene = this._scene;
        }
    }

    public initPickPosition(): void {
        if (this._pickPosition == null) {
            this._pickPosition = new Vector2(0, 0);
        }
    }

    public render(camera: Camera) {
        if (this._renderer && this._scene) {
            this._renderer.render(this._scene, camera);

            this._setPreselectedObject();
        }
    }

    public setModelGroupToScene(object3D: Object3D | null, selectionBox: Object3D | null, unitSet: UnitSet): void {
        if (this._scene) {
            this._scene.clear();
            this._createDefaultLight(this._scene, object3D);
            if (object3D !== null) {
                this._scene.add(createLabeledGrid(this._renderer!, this._scene, object3D, unitSet));
                this._scene.add(object3D);

                if (selectionBox != null) {
                    this._scene.add(selectionBox);
                }
            }
        }
    }

    public destroy(): void {
        if (this._scene != null) {
            this._scene = null;
        }

        this._cameraService.destroy();

        if (this._renderer != null) {
            this._renderer.renderLists.dispose();
            this._renderer.dispose();
            this._renderer.clear();
            this._renderer = null;
        }

        this._picker.destroy();

        if (this._pickPosition != null) {
            this._pickPosition = null;
        }

        this._clickEvent.next(DEFAULT_CLICK_EVENT);
    }

    private _addsupportOfThreeJsDevTools() {
        const __THREE_DEVTOOLS__ = window['__THREE_DEVTOOLS__'];
        if (typeof __THREE_DEVTOOLS__ !== 'undefined') {
            __THREE_DEVTOOLS__.dispatchEvent(new CustomEvent('observe', { detail: this._scene }));
            __THREE_DEVTOOLS__.dispatchEvent(new CustomEvent('observe', { detail: this._renderer }));
        }
    }

    private _createDefaultLight(scene: Scene, object3D: Object3D | null) {
        // Add spot light
        const objectSize = new Vector3();
        if (object3D) {
            const bbox = new Box3();
            bbox.setFromObject(object3D);
            bbox.getSize(objectSize);
        }
        const spotLight = new SpotLight(SPOT_LIGHT_COLOR, SPOT_LIGHT_INTENSITY);
        spotLight.castShadow = false;
        spotLight.shadow.bias = 0.1;
        spotLight.position.set(0, -1000 - objectSize.y / 2, -300 - objectSize.z / 2);
        spotLight.name = 'spot-light';
        scene.add(spotLight);

        // Add directional light
        const directionalLight = new DirectionalLight(DIRECTIONAL_LIGHT_COLOR, DIRECTIONAL_LIGHT_INTENSITY);
        directionalLight.castShadow = false;
        directionalLight.shadow.bias = 0.1;
        directionalLight.shadow.camera.near = 1000;
        directionalLight.shadow.mapSize.width = 2048 * 1000;
        directionalLight.shadow.mapSize.height = 2048 * 1000;
        directionalLight.position.set(-250, -1000, -500);
        directionalLight.name = 'directional-light';
        scene.add(directionalLight);

        // Add ambient light
        const ambientLight = new AmbientLight(AMBIENT_LIGHT_COLOR, AMBIENT_LIGHT_INTENSITY);
        ambientLight.name = 'ambient-light';
        scene.add(ambientLight);
    }

    private _setPreselectedObject(): void {
        if (this._pickPosition && this._scene && this._cameraService.camera) {
            const pickedObject: Object3D | null = this._picker.pick(this._pickPosition, this._scene, this._cameraService.camera);
            if (pickedObject == null) {
                this._selectedElementService.setPreselected({ elementID: '' });
                return;
            }

            const selectedId = pickedObject.userData[USERDATA_MODEL_ELEMENT_ID];
            if (selectedId) {
                this._selectedElementService.setPreselected({ elementID: selectedId });
            }
        }
    }

    private _startRendering() {
        if (this._renderer != null) {
            this._renderer.setPixelRatio(devicePixelRatio);
            this._renderer.setSize(this._canvas.clientWidth, this._canvas.clientHeight);

            this._renderer.shadowMap.enabled = true;
            this._renderer.shadowMap.type = PCFSoftShadowMap;
            this._renderer.setClearColor(0xffffff, 1);
            this._renderer.autoClear = true;
        }
    }

    private _bindCanvasEvents(): void {
        this._canvas.addEventListener('mousemove', event => this._setPickPosition(event));
        const clearPickEvents: string[] = ['mouseout', 'mouseleave', 'touchend'];
        clearPickEvents.forEach((eventName: string) => {
            this._canvas.addEventListener(eventName, () => this._clearPickPosition());
        });

        this._canvas.addEventListener(
            'touchstart',
            event => {
                // prevent the window from scrolling
                event.preventDefault();
                this._setPickPosition(event.touches[0]);
            },
            { passive: false },
        );

        this._canvas.addEventListener('touchmove', event => {
            this._setPickPosition(event.touches[0]);
        });

        this._canvas.addEventListener(MouseClickEvent.CONTEXTMENU, event => {
            this._setSelectedElement({
                type: event.type,
                x: event.clientX,
                y: event.clientY,
            });
        });

        this._hammer = new Hammer(this._canvas);
        const touchEvents = [HammerTouchEvent.TAP, HammerTouchEvent.PRESS, HammerTouchEvent.DOUBLE_TAP];
        touchEvents.forEach((eventName: string) => {
            this._hammer.on(eventName, (event: HammerInput) => {
                this._setSelectedElement({
                    type: eventName,
                    x: event.center.x,
                    y: event.center.y,
                });
            });
        });
    }

    private _setSelectedElement(event: SelectEvent): void {
        if (this._pickPosition && this._scene && this._cameraService.camera) {
            const pickedObject: Object3D | null = this._picker.pick(this._pickPosition, this._scene, this._cameraService.camera);
            if (pickedObject == null) {
                return;
            }

            const selectedId = pickedObject.userData[USERDATA_MODEL_ELEMENT_ID];
            if (selectedId) {
                this._clickEvent.next({
                    clickType: this._convertMouseEventTypeToClickType(event.type),
                    elementID: selectedId,
                    clientX: event.x,
                    clientY: event.y,
                });
            }
        }
    }

    private _convertMouseEventTypeToClickType(type: string): ClickType {
        switch (type) {
            case MouseClickEvent.DOUBLE_CLICK:
            case HammerTouchEvent.DOUBLE_TAP:
                return ClickType.DOUBLE_LEFT_CLICK;
            case MouseClickEvent.CONTEXTMENU:
            case HammerTouchEvent.PRESS:
                return ClickType.RIGHT_CLICK;
            case MouseClickEvent.CLICK:
            case HammerTouchEvent.TAP:
            default:
                return ClickType.LEFT_CLICK;
        }
    }

    private _getCanvasRelativePosition(event: MouseEvent) {
        const rect = this._canvas.getBoundingClientRect();
        return {
            x: ((event.clientX - rect.left) * this._canvas.width) / rect.width,
            y: ((event.clientY - rect.top) * this._canvas.height) / rect.height,
        };
    }

    private _setPickPosition(event: any) {
        if (this._pickPosition != null) {
            const pos = this._getCanvasRelativePosition(event);
            this._pickPosition.x = (pos.x / this._canvas.width) * 2 - 1;
            this._pickPosition.y = (pos.y / this._canvas.height) * -2 + 1; // note we flip Y
        }
    }

    private _clearPickPosition() {
        // unlike the mouse which always has a position
        // if the user stops touching the screen we want
        // to stop picking. For now we just pick a value
        // unlikely to pick something
        if (this._pickPosition != null) {
            this._pickPosition.x = -100000;
            this._pickPosition.y = -100000;
        }
    }
}

import { BearingRow } from '../../cae-model/bearing-elements/bearing-row';
import { ModelElement } from '../../cae-model/model-element';
import { BasicMediator } from './basic-mediator';
import { VISUALIZATION_DATA_BX } from '../bearinx-mediator-constants';
import { parseCoordinates } from '../../cae-model/functions/cae-model-utils';

export class BearingRowMediator extends BasicMediator {
    constructor() {
        super();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_BEARING_ROW'];
    }

    protected createModelElement(): ModelElement {
        return new BearingRow();
    }

    // overrides basic function
    protected doConvert() {
        const rawVizData = this.bearinxModelObject[VISUALIZATION_DATA_BX];
        const visData = rawVizData.split(' ');
        this.modelElement.coordinatesRel = parseCoordinates(visData.slice(2));
    }
}

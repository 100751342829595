import { PartType } from '../element-view-2d';
/* eslint-disable no-shadow */
import Konva from 'konva';
import { OPACITY_SHAFT, SEGMENT_COLOR_2D, STROKE_COLOR_2D, STROKE_WIDTH } from '../view-2d-constants';
import { ElementView2D } from '../element-view-2d';
import { ConeTransformer } from '../../2d-controls/cone-transformer';
import { ConeSegment } from '../../../cae-model/shaft-segments/cone-segment';
import { ShaftSegmentCoordinates } from '../../../cae-model/shaft-segments/shaft-segment';

export class ConeSegmentView2D extends ElementView2D {
    private _boundary: Konva.Line;
    private _coneUp: Konva.Line;
    private _coneDown: Konva.Line;
    private _transformer: ConeTransformer;

    constructor(cone: ConeSegment) {
        super(cone);
        this._createShapes();
        this._transformer = new ConeTransformer(
            this._boundary,
            this._cone.outerDiameterLeft,
            this._cone.outerDiameterRight,
            this.groupWithoutChildren,
        );
        this._transformer.onTransform = () => this._onTransform();
    }

    protected doUpdateTransformer(): void {
        this._transformer.appearance = {
            visible: this.isSelected,
            transformable: this.editable,
        };
        this._transformer.unitScaleFactor = this.elementView2DInput.unitScaleFactor;
    }

    protected get draggable() {
        return false;
    }

    protected get isStandardSelector() {
        return false;
    }

    private _onTransform() {
        this._cone.outerDiameterLeft = this._boundary.height();

        const xStartNew = this._cone.x + this._boundary.x();
        const coors: ShaftSegmentCoordinates = {
            xStart: xStartNew,
            xEnd: xStartNew + this._boundary.width(),
        };
        this._cone.setCoordinates(coors);

        this._cone.update();
        this._transformer.update();
    }

    private get _cone(): ConeSegment {
        return this.modelElement as ConeSegment;
    }

    private _createShapes(): void {
        this._coneUp = this._createQuadrilateral();
        this._coneDown = this._createQuadrilateral();
        this._updateCone(PartType.Up);
        this._updateCone(PartType.Down);
        this.groupWithoutChildren.add(this._coneUp);
        this.groupWithoutChildren.add(this._coneDown);

        this._boundary = new Konva.Line();
        this._updateBoundary();
        this.groupWithoutChildren.add(this._boundary);
    }

    protected updateGroupWithoutChildren(): void {
        // override basis class
        this._updateBoundary();
        this._updateCone(PartType.Up);
        this._updateCone(PartType.Down);
        // this._transformer.update();
    }

    private _updateBoundary() {
        const shape = this._boundary;
        const [heightLeft, heightRight] = this._getHeights(this._cone.outerDiameterLeft, this._cone.outerDiameterRight);
        const length = this._cone.length;

        shape.points([0, heightLeft, 0, -heightLeft, length, -heightRight, length, heightRight]);
        shape.closed(true);
        shape.stroke(STROKE_COLOR_2D);
        shape.strokeWidth(STROKE_WIDTH);
        shape.strokeScaleEnabled(false);
        shape.name(this._cone.name + '_' + 'Quadrilareral');
        shape.scaleX(1);
        shape.scaleY(1);
        shape.fillEnabled(false);
    }

    private _updateCone(conePartType: PartType) {
        const isConePartUp = conePartType === PartType.Up ? true : false;
        const conePart = isConePartUp ? this._coneUp : this._coneDown;
        const [heightLeftOuter, heightRightOuter] = this._getHeights(this._cone.outerDiameterLeft, this._cone.outerDiameterRight);
        const [heightLeftInner, heightRightInner] = this._getHeights(this._cone.innerDiameterLeft, this._cone.innerDiameterRight);
        const length = this._cone.length;

        const firstPoint = [0, isConePartUp ? -heightLeftInner : heightLeftInner];
        const secondPoint = [0, isConePartUp ? -heightLeftOuter : heightLeftOuter];
        const thirdPoint = [length, isConePartUp ? -heightRightOuter : heightRightOuter];
        const fourthPoint = [length, isConePartUp ? -heightRightInner : heightRightInner];
        conePart.points([...firstPoint, ...secondPoint, ...thirdPoint, ...fourthPoint]);

        conePart.strokeWidth(this._cone.innerDiameterLeft === 0 && this._cone.innerDiameterRight === 0 ? 0 : STROKE_WIDTH);
        conePart.strokeScaleEnabled(false);
        conePart.opacity(OPACITY_SHAFT);
    }

    private _createQuadrilateral(): Konva.Line {
        const conePart = new Konva.Line();
        conePart.closed(true);
        conePart.stroke(STROKE_COLOR_2D);
        conePart.fill(SEGMENT_COLOR_2D);
        conePart.name(`${this._cone.name}_Quadrilareral`);
        conePart.scaleX(1);
        conePart.scaleY(1);

        return conePart;
    }

    private _getHeights(diameterLeft: number, diameterRight: number): number[] {
        return [diameterLeft / 2, diameterRight / 2];
    }
}

import { Group } from 'konva/lib/Group';
import { Arrow } from 'konva/lib/shapes/Arrow';
import { Line } from 'konva/lib/shapes/Line';
import { Text } from 'konva/lib/shapes/Text';
import {
    ARROW_COMMON_PROPERTIES,
    ARROW_GAP,
    COMMON_PROPERTIES,
    ARROW_HEAD_LENGTH_2D,
    DIMENSIONS_LINE_GAP,
    HORIZONTAL_DIMENSION_LINE_NAME,
    DIMENSION_TEXT_COLOR,
    FONT_FAMILY_2D,
    TEXT_FONT_SIZE,
    HORIZONTAL_DIMENSIONS_GROUP_NAME,
    VERTICAL_DIMENSIONS_GROUP_NAME,
    FIXED_SCALED_VALUE_ZOOM_TO_FIT,
    VERTICAL_LEFT_DIMENSION,
    VERTICAL_RIGHT_DIMENSION,
} from '../elements-view/view-2d-constants';

// interface for the visualization of x-distance between an object and absolute CS.
// Describes a Konva.Group consisting on two vertical lines, horizontal line between themm and a text
export interface HorizontalDimensionsInterface {
    objectRadius: number;
    objectPositionX: number;
}

export interface VerticalDimensionsInterface {
    objectDiameter: number;
    objectPositionX: number;
}

function normalizeDimension(dimensionValue: number, fractionDigits: number): number {
    return parseFloat(Math.abs(dimensionValue).toFixed(fractionDigits));
}

function createSimpleVerticalLine(startY: number, endY: number, lineX: number): Line {
    return new Line({
        ...COMMON_PROPERTIES,
        points: [lineX, startY, lineX, endY],
    });
}

function createSimpleHorizontalLine(startX: number, endX: number, lineY: number): Line {
    return new Line({
        ...COMMON_PROPERTIES,
        points: [startX, lineY, endX, lineY],
    });
}

function createHorizontalDimensionText(bottomY: number, x: number, fractionDigits: number, unitScaleFactor: number): Text {
    const dimensionsText = new Text({
        x: x / 2,
        y: bottomY - ARROW_GAP / unitScaleFactor,
        text: `${normalizeDimension(x, fractionDigits)}`,
        fontSize: TEXT_FONT_SIZE / unitScaleFactor,
        fontFamily: FONT_FAMILY_2D,
        fill: DIMENSION_TEXT_COLOR,
    });
    dimensionsText.setAttr(FIXED_SCALED_VALUE_ZOOM_TO_FIT, true);
    dimensionsText.offsetX(dimensionsText.width() / 2);
    dimensionsText.offsetY(dimensionsText.height());

    return dimensionsText;
}

function createVerticalDimensionText(x: number, diameter: number, fractionDigits: number, unitScaleFactor: number): Text {
    const dimensionsText = new Text({
        x,
        y: 0,
        text: `∅${normalizeDimension(diameter, fractionDigits)}`,
        fontSize: TEXT_FONT_SIZE / unitScaleFactor,
        fontFamily: FONT_FAMILY_2D,
        fill: DIMENSION_TEXT_COLOR,
    });
    dimensionsText.setAttr(FIXED_SCALED_VALUE_ZOOM_TO_FIT, true);
    dimensionsText.rotate(-90);
    dimensionsText.offsetX(dimensionsText.width() / 2);
    dimensionsText.offsetY(dimensionsText.height());

    return dimensionsText;
}

function createHorizontalDimensionsLineAndText(x: number, y: number, fractionDigits: number, unitScaleFactor: number): Group {
    const dimensionsLineGroup = new Group();
    const absX = Math.abs(x);
    const dimensionArrowHead = ARROW_HEAD_LENGTH_2D / unitScaleFactor;
    const arrowGap = ARROW_GAP / unitScaleFactor;

    const dimensionsText = createHorizontalDimensionText(y, x, fractionDigits, unitScaleFactor);

    const dimensionsLine = new Arrow({
        ...COMMON_PROPERTIES,
        ...ARROW_COMMON_PROPERTIES,
        points: [x, y - arrowGap, 0, y - arrowGap],
        name: HORIZONTAL_DIMENSION_LINE_NAME,
    });
    dimensionsLine.pointerLength(dimensionsLine.pointerLength() / unitScaleFactor);
    dimensionsLine.pointerWidth(dimensionsLine.pointerWidth() / unitScaleFactor);
    if (absX <= dimensionArrowHead * 2 || absX <= dimensionsText.width()) {
        const reverseArrow = x < 0 ? -1 : 1;
        const positionY = y - arrowGap;
        dimensionsLine.points([
            0,
            positionY,
            0 - dimensionArrowHead * 2 * reverseArrow,
            positionY,
            x + (dimensionArrowHead * 2 + dimensionsText.width()) * reverseArrow,
            positionY,
            x,
            positionY,
        ]);

        dimensionsText.x(x + (dimensionArrowHead + (dimensionArrowHead + dimensionsText.width()) / 2) * reverseArrow);
    }

    [dimensionsLine, dimensionsText].forEach(dimensionsLinePart => {
        dimensionsLineGroup.add(dimensionsLinePart);
    });

    return dimensionsLineGroup;
}

function getUniqueHorizontalDimensionsData(horizontalDimensions: HorizontalDimensionsInterface[]): HorizontalDimensionsInterface[] {
    const dictionary: { [objectPositionX: number]: HorizontalDimensionsInterface } = {};

    horizontalDimensions.forEach(item => {
        const seen = dictionary[item.objectPositionX];
        if (!seen) {
            dictionary[item.objectPositionX] = item;
        }
    });

    return Object.keys(dictionary)
        .filter((objectPositionX: string) => parseFloat(objectPositionX) !== 0)
        .sort(
            (firstObjectPositionX: string, secondObjectPositionX: string) =>
                Math.abs(parseFloat(firstObjectPositionX)) - Math.abs(parseFloat(secondObjectPositionX)),
        )
        .map(objectPositionX => dictionary[objectPositionX]);
}

function createHorizontalDimensionsGroup(
    horizontalDimensionsData: HorizontalDimensionsInterface,
    textPositionY: number,
    fractionDigits: number,
    unitScaleFactor: number,
): Group {
    const horizontalDimensions = new Group();
    horizontalDimensions.name(HORIZONTAL_DIMENSIONS_GROUP_NAME);
    const { objectRadius, objectPositionX } = horizontalDimensionsData;

    [
        { startY: objectRadius, x: objectPositionX },
        { startY: 0, x: 0 },
    ].forEach(({ startY, x }) => {
        horizontalDimensions.add(createSimpleVerticalLine(startY, textPositionY, x));
    });

    horizontalDimensions.add(createHorizontalDimensionsLineAndText(objectPositionX, textPositionY, fractionDigits, unitScaleFactor));

    return horizontalDimensions;
}

export function createHorizontalDimensions(
    dimensions: HorizontalDimensionsInterface[],
    textPositionY: number,
    fractionDigits: number,
    unitScaleFactor: number,
): Group {
    const horizontalDimensionGroup = new Group();

    const uniqueHorizontalDimensions: HorizontalDimensionsInterface[] = getUniqueHorizontalDimensionsData(dimensions);

    uniqueHorizontalDimensions.forEach((horizontalDimensionsData: HorizontalDimensionsInterface, order: number) => {
        const { objectRadius, objectPositionX } = horizontalDimensionsData;
        const dimensionGap = (DIMENSIONS_LINE_GAP / unitScaleFactor) * order;

        const dimensions = createHorizontalDimensionsGroup(
            {
                objectPositionX,
                objectRadius,
            },
            textPositionY + dimensionGap,
            fractionDigits,
            unitScaleFactor,
        );

        horizontalDimensionGroup.add(dimensions);
    });

    return horizontalDimensionGroup;
}

function findMiddlePositionOfObject(x: number, width: number): number {
    return x + width / 2;
}

export function checkIsRightSide(elementX: number, elementWidth: number, modelViewX: number, modelViewWidth: number): boolean {
    const middleSceneX = findMiddlePositionOfObject(modelViewX, modelViewWidth);
    const middleElementX = findMiddlePositionOfObject(elementX, elementWidth);
    return middleElementX >= middleSceneX;
}

function createVerticalDimensionsLineAndText(x: number, diameter: number, fractionDigits: number, unitScaleFactor: number): Group {
    const group = new Group();
    const absOuterDiameter = Math.abs(diameter);
    const radius = diameter / 2;
    const dimensionArrowHead = ARROW_HEAD_LENGTH_2D / unitScaleFactor;

    const text = createVerticalDimensionText(x, diameter, fractionDigits, unitScaleFactor);

    const diameterLine = new Arrow({
        ...COMMON_PROPERTIES,
        ...ARROW_COMMON_PROPERTIES,
        points: [x, radius, x, -radius],
    });
    diameterLine.pointerLength(diameterLine.pointerLength() / unitScaleFactor);
    diameterLine.pointerWidth(diameterLine.pointerWidth() / unitScaleFactor);
    if (absOuterDiameter <= text.width()) {
        diameterLine.points([
            x,
            radius,
            x,
            radius + dimensionArrowHead * 2,
            x,
            -radius - dimensionArrowHead * 2 - text.width(),
            x,
            -radius,
        ]);

        text.y(-radius - dimensionArrowHead - (dimensionArrowHead + text.width()) / 2);
    }

    [diameterLine, text].forEach(part => {
        group.add(part);
    });

    return group;
}

function isVerticalDimensionGroupInLeftSide(objectPositionX: number, dimensionsPositionX: number) {
    return dimensionsPositionX < objectPositionX;
}

function getUniqueVerticalDimensionsData(
    verticalDimensions: VerticalDimensionsInterface[],
    textPositionX: number,
): VerticalDimensionsInterface[] {
    const dictionary: { [diameter: number]: VerticalDimensionsInterface } = {};

    verticalDimensions.forEach(item => {
        const seen = dictionary[item.objectDiameter];
        const shouldUpdateExisted =
            !seen ||
            (isVerticalDimensionGroupInLeftSide(seen.objectPositionX, textPositionX)
                ? seen.objectPositionX < item.objectPositionX
                : seen.objectPositionX > item.objectPositionX);
        if (item.objectDiameter > 0 && shouldUpdateExisted) {
            dictionary[item.objectDiameter] = item;
        }
    });

    return Object.keys(dictionary)
        .sort((firstDiameter: string, secondDiameter: string) => parseFloat(firstDiameter) - parseFloat(secondDiameter))
        .map(diameter => dictionary[diameter]);
}

function createVerticalDimensionGroup(
    verticalDimensionData: VerticalDimensionsInterface,
    textPositionX: number,
    fractionDigits: number,
    unitScaleFactor: number,
): Group {
    const verticalDimensions = new Group();
    verticalDimensions.name(VERTICAL_DIMENSIONS_GROUP_NAME);
    const { objectPositionX, objectDiameter } = verticalDimensionData;

    if (objectDiameter > 0) {
        const objectRadius = objectDiameter / 2;
        [objectRadius, -objectRadius].forEach(y => {
            verticalDimensions.add(createSimpleHorizontalLine(objectPositionX, textPositionX, y));
        });

        const linePositionX =
            textPositionX +
            (isVerticalDimensionGroupInLeftSide(objectPositionX, textPositionX)
                ? ARROW_GAP / unitScaleFactor
                : -ARROW_GAP / unitScaleFactor);
        verticalDimensions.add(createVerticalDimensionsLineAndText(linePositionX, objectDiameter, fractionDigits, unitScaleFactor));
    }

    return verticalDimensions;
}

export function createVerticalDimensions(
    dimensions: VerticalDimensionsInterface[],
    textPositionX: number,
    fractionDigits: number,
    unitScaleFactor: number,
): Group {
    const shaftSegmentVerticalDimensionsGroup = new Group();

    const uniqueVerticalDimensions = getUniqueVerticalDimensionsData(dimensions, textPositionX);

    uniqueVerticalDimensions.forEach((dimensionsData: VerticalDimensionsInterface, order: number) => {
        const { objectPositionX } = dimensionsData;
        const dimensionGap = (DIMENSIONS_LINE_GAP / unitScaleFactor) * order;

        const isLeftSide = isVerticalDimensionGroupInLeftSide(objectPositionX, textPositionX);
        const calculateTextPositionX = textPositionX + (isLeftSide ? -dimensionGap : dimensionGap);

        const dimensions = createVerticalDimensionGroup(dimensionsData, calculateTextPositionX, fractionDigits, unitScaleFactor);
        dimensions.setAttr(isLeftSide ? VERTICAL_LEFT_DIMENSION : VERTICAL_RIGHT_DIMENSION, true);
        shaftSegmentVerticalDimensionsGroup.add(dimensions);
    });

    return shaftSegmentVerticalDimensionsGroup;
}

export function fixedScaleValueZoomToFit(group: Group, scale: number): void {
    const fixedScaleZoomFitShapes = group.find(
        (node: { getAttr: (arg0: string) => boolean }) => node.getAttr(FIXED_SCALED_VALUE_ZOOM_TO_FIT) === true,
    );
    fixedScaleZoomFitShapes.forEach(element => {
        element.scale({
            x: 1 / scale,
            y: 1 / scale,
        });
    });
}

/**
 * ShaftSystem
 */

import { ModelElement } from './model-element';
import { Shaft } from './shaft';

export class ShaftSystem extends ModelElement {
    private _shafts: Shaft[];

    constructor() {
        super();
        this._shafts = [];
    }

    protected doAdd(child: ModelElement) {
        if (child instanceof Shaft) {
            this._addShaft(child);
        }
    }

    get middleDiameter(): number {
        // return mean value of all middle diameters of shafts
        const shaftsRadii = this._shafts.map(shaft => shaft.middleRadius);
        return (shaftsRadii.reduce((a, b) => a + b, 0) / shaftsRadii.length) * 2;
    }

    private _addShaft(shaft: Shaft): void {
        this._shafts.push(shaft);
        shaft.shaftSystemMiddleDiameter = () => this.middleDiameter;
    }
}

import { ModelElement } from '../../../cae-model/model-element';
import { RectangularGroove } from '../../../cae-model/notches/rectangular-groove';
import { BearinxStandardBinder } from '../../binders/bearinx-standard-binder';
import { NotchMediator } from './notch-mediator';

// mediator, used for the convergence of RectangularGroove
export class RectangularGrooveMediator extends NotchMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_RECT_GROOVE'];
    }

    protected createModelElement(): ModelElement {
        return new RectangularGroove();
    }

    private _createBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [
            { left: 'radius', right: 'IDN_RECT_GROOVE_RADIUS' },
            { left: 'depth', right: 'IDN_RECT_GROOVE_DEPTH' },
            { left: 'width', right: 'IDN_RECT_GROOVE_WIDTH' },
        ];
        this.addBinder(binder);
    }
}

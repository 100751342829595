/* eslint-disable no-shadow */
import Konva from 'konva';
import { Spring } from '../../../../cae-model/supports/spring';
import { SPRING_TOTAL_COILS, STROKE_WIDTH } from '../../view-2d-constants';
import { SupportGeometry } from '../../../../views-foundation/geometries/support-geometry';
import { SupportView2D } from '../support-view-2d';
import { getColor } from '../../../functions/utils-2d';

enum SpringDirection {
    Horizontal,
    Vertical,
}

export class SpringView2D extends SupportView2D {
    private _group: Konva.Group | null = null;

    constructor(spring: Spring) {
        super(spring);
    }

    protected paintElement(group: Konva.Group, geometry: SupportGeometry): void {
        this._updateSpring(group, geometry);
    }

    protected getName(): string {
        return 'spring-view-2d-group';
    }

    private _updateSpring(group: Konva.Group, geometry: SupportGeometry): void {
        const springUpHorizontal = new Konva.Line();
        const springUpVertical = new Konva.Line();
        const springDownHorizontal = new Konva.Line();
        const springDownVertical = new Konva.Line();

        const { sDO, sDI, sX, sB, color, sAxNeg, sAxPos, sTransY } = geometry;
        const springHeight = (sDO - sDI) / 2.0;
        const springWidth = sX + sB / 2.0 - (sX - sB / 2.0);
        const innerDiameterFactory: number = sDI / 2.0;
        const outerDiameterFactory: number = sDO / 2.0;
        const meanOffsetY = -springHeight / 2;
        const horizontalPoints: number[] = [0, 0]
            .concat(this._getSpringPoints(springWidth, SpringDirection.Horizontal, springHeight * 0.1, -springHeight * 0.2))
            .concat([springWidth, 0]);
        const verticalPoints: number[] = [springWidth / 2, 0]
            .concat(this._getSpringPoints(springHeight, SpringDirection.Vertical, springWidth * 0.4, springWidth * 0.7))
            .concat([springWidth / 2, springHeight]);

        [springUpHorizontal, springUpVertical, springDownHorizontal, springDownVertical].forEach(spring => {
            spring.stroke(getColor(color));
            spring.strokeWidth(STROKE_WIDTH);
            spring.strokeScaleEnabled(false);
        });

        [springUpHorizontal, springUpVertical].forEach(spring => {
            spring.y(-outerDiameterFactory);
        });
        springUpHorizontal.points(horizontalPoints);
        springUpHorizontal.offsetY(meanOffsetY);
        springUpVertical.points(verticalPoints);

        [springDownHorizontal, springDownVertical].forEach(spring => {
            spring.y(innerDiameterFactory);
        });
        springDownHorizontal.rotate(180);
        springDownHorizontal.points(horizontalPoints);
        springDownHorizontal.x(springWidth);
        springDownHorizontal.offsetY(-meanOffsetY);
        springDownVertical.points(verticalPoints);

        if (sAxNeg === false || sAxPos === false) {
            // draw horizonal spring / else remove
            group.add(springUpHorizontal);
            group.add(springDownHorizontal);
        }

        if (!sTransY) {
            // draw vertical spring / else remove
            group.add(springUpVertical);
            group.add(springDownVertical);
        }
    }

    private _getSpringPoints(springLength: number, direction: SpringDirection, oddPostion: number, evenPosition: number): number[] {
        const totalPoints = SPRING_TOTAL_COILS * 2 + 2;
        const horizontalSteps = springLength / totalPoints;
        const points: number[] = [];
        for (let index = 1, currentLength = horizontalSteps; index < totalPoints; index++, currentLength += horizontalSteps) {
            if (direction === SpringDirection.Horizontal) {
                points.push(currentLength);
            }
            if (index % 2 === 0) {
                points.push(evenPosition);
            } else {
                points.push(oddPostion);
            }
            if (direction === SpringDirection.Vertical) {
                points.push(currentLength);
            }
        }

        return points;
    }
}

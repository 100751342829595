import { Worm } from './../../../cae-model/transmission-elements/worm';
import { BearinxStandardBinder } from '../../binders/bearinx-standard-binder';
import { BasicMediator } from '../basic-mediator';
import { ModelElement } from '../../../cae-model/model-element';
export class WormMediator extends BasicMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_WORM'];
    }

    protected createModelElement(): ModelElement {
        return new Worm();
    }

    private _createBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [
            { left: 'x', right: 'IDCO_X' },
            { left: 'outerDiameter', right: 'IDTE_D' },
            { left: 'width', right: 'IDTE_B' },
            { left: 'referenceDiameter', right: 'IDWO_DM' },
        ];
        this.addBinder(binder);
    }
}

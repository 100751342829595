import { ChangeDetectorRef, Component, ElementRef, HostBinding, NgZone, OnDestroy, OnInit, SkipSelf, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalHotkeyService } from '../hotkey/global-hotkey.service';
import { WindowRef } from '../util/window-ref';

@Component({
    selector: 'bx-search',
    templateUrl: 'search.component.html',
    styleUrls: ['search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
    @HostBinding('class.active') public isActive: boolean;
    public searchForm: FormGroup;

    @ViewChild('searchField', { static: true }) private readonly _searchFieldRef: ElementRef;

    private _stop$ = new Subject<void>();

    constructor(
        private readonly _globalHotkeyService: GlobalHotkeyService,
        private readonly _formBuilder: FormBuilder,
        private readonly _zone: NgZone,
        @SkipSelf() private readonly _changeDetector: ChangeDetectorRef,
        private readonly _windowRef: WindowRef,
    ) {}

    public ngOnInit(): void {
        this._createForm();

        this._globalHotkeyService.search$.pipe(takeUntil(this._stop$)).subscribe(() => {
            this.toggle();
            this._changeDetector.detectChanges();
        });
    }

    public ngOnDestroy(): void {
        this._stop$.next();
        this._stop$.complete();
    }

    public toggle(): void {
        if (this.isActive) {
            return this.close();
        }

        this.open();
    }

    public close(): void {
        this.isActive = false;
    }

    public open(): void {
        this.isActive = true;
        this.searchForm.setValue({ search: '' });

        this._zone.runOutsideAngular(() => this._windowRef.nativeWindow.setTimeout(() => this._searchFieldRef.nativeElement.focus()));
    }

    public submit() {
        console.log(this.searchForm.value);
        this.close();
    }

    private _createForm(): void {
        this.searchForm = this._formBuilder.group({
            search: ['', Validators.required],
        });
    }
}

import { ContentDialogComponent } from '../components/content-dialog/content-dialog.component';

export type DialogButtonClickHandler = (component: ContentDialogComponent, event: MouseEvent) => void;

export enum DialogButtonPosition {
    Left,
    Right,
}

export type DialogButtonCssClass = 'primary' | string;

export class DialogButton {
    public text: string;
    public cssClass: DialogButtonCssClass;
    public position: DialogButtonPosition;
    public onClick: DialogButtonClickHandler;
    public disabled?: boolean;
}

import { BevelGear } from '../../cae-model/transmission-elements/bevel-gear';
import { CalculateBevelGearGeometryInterface } from '../interfaces/calculate-bevel-gear-geometry-interface';
import { DEFAULT_SEGMENT_DIAMETER, TINY_DIAMETER } from '../views-foundation-constants';
import { BevelGearBaseGeometry, BevelGearDiameterType } from './bevel-gear-base-geometry';

export class BevelGearGeometry extends BevelGearBaseGeometry {
    constructor(bevelGear: BevelGear, unitScale: number) {
        super(bevelGear, unitScale);
    }

    protected setGeometry(): void {
        const bevelGear: BevelGear = this.bevelGear as BevelGear;
        const { x: tX, workingPitchDiameter: tDM, angleDelta: delta, toothCount: tTooth, geometricalForm: tForm } = bevelGear;
        let { width: tB, innerDiameter: tDI, outerDiameter: tDO } = bevelGear;
        let outerDiameter = bevelGear.getShaftCurrentOuterDiameter(tX);
        let isOnShaft = false;

        if (outerDiameter > TINY_DIAMETER / this.unitScale) {
            isOnShaft = true;
        } else {
            outerDiameter = DEFAULT_SEGMENT_DIAMETER / this.unitScale;
            isOnShaft = false;
        }

        const mediumDia = tDM > 0.1 ? tDM + (4.0 / this.unitScale) : outerDiameter + (24.0 / this.unitScale);

        const { ddef, didef, bdef } = this.getDef(tB, tDI, tDO);

        tB = this._calculateBevelWidth({ tB, tDI, tDO, delta, mediumDia, outerDiameter, ddef, didef, bdef });

        const diameter = this._calculateBevelGearDiameter({ tB, tDI, tDO, delta, mediumDia, outerDiameter, ddef, didef, bdef });

        tDO = diameter.tDO;
        tDI = diameter.tDI;

        let tBore = outerDiameter;
        if (tDI < tBore && !didef) {
            tBore = tDI;
        }
        if (tDO < outerDiameter || !isOnShaft) {
            tBore = 0.0;
        }

        this._tB = tB;
        this._tDM = tDM;
        this._tDO = tDO;
        this._tDI = tDI;
        this._tForm = tForm;
        this._tTooth = tTooth;
        this._tBore = tBore;
        this._isOnShaft = isOnShaft;
    }

    private _calculateBevelWidth(calculateInput: CalculateBevelGearGeometryInterface): number {
        const { delta, ddef, didef, bdef } = calculateInput;
        const outerDiameter = calculateInput.outerDiameter!;
        let { tB } = calculateInput;

        if (bdef === true) {
            if (ddef === true && didef === true) {
                tB = this.calculateBevelWidthByDiameter(outerDiameter);
            } else {
                if (delta < TINY_DIAMETER / this.unitScale) {
                    tB = this.calculateBevelWidthByDiameter(outerDiameter);
                } else {
                    tB = this.calculateBevelWidthByDelta(calculateInput);
                }
            }
        }

        return tB;
    }

    private _calculateBevelGearDiameter(calculateInput: CalculateBevelGearGeometryInterface): { tDI: number; tDO: number } {
        let { tDI, tDO } = calculateInput;

        [BevelGearDiameterType.Outer, BevelGearDiameterType.Inner].forEach(diameterType => {
            if (diameterType === BevelGearDiameterType.Outer) {
                tDO = this._calculateBevelGearOuterDiameterWithDelta(calculateInput);
            } else {
                tDI = this._calculateBevelGearInnerDiameterWithDelta(calculateInput);
            }
        });

        return { tDI, tDO };
    }

    private _calculateBevelGearOuterDiameterWithDelta(calculateInput: CalculateBevelGearGeometryInterface): number {
        const { tB, tDI, mediumDia, ddef, didef, bdef } = calculateInput;
        const outerDiameter = calculateInput.outerDiameter!;
        let { delta, tDO } = calculateInput;

        if (ddef === true) {
            if (didef === true) {
                delta = this.calculateDelta(delta);
                tDO = mediumDia + tB * Math.tan(delta * (Math.PI / 180.0));
            } else {
                tDO =
                    delta > TINY_DIAMETER / this.unitScale && !bdef
                        ? (tDO = tDI + 2.0 * tB * Math.tan(delta * (Math.PI / 180.0)))
                        : mediumDia + Math.abs(mediumDia - tDI);
            }
            if (tDO < outerDiameter) {
                tDO = outerDiameter;
            }
        }
        return tDO;
    }

    private _calculateBevelGearInnerDiameterWithDelta(calculateInput: CalculateBevelGearGeometryInterface): number {
        const { tB, tDO, mediumDia, ddef, didef, bdef } = calculateInput;
        const outerDiameter = calculateInput.outerDiameter!;
        let { delta, tDI } = calculateInput;

        if (didef) {
            if (ddef) {
                delta = this.calculateDelta(delta);
                tDI = mediumDia - tB * Math.tan(delta * (Math.PI / 180.0));
            } else {
                tDI =
                    delta > TINY_DIAMETER / this.unitScale && !bdef
                        ? tDO - 2.0 * tB * Math.tan(delta * (Math.PI / 180.0))
                        : (tDI = mediumDia - Math.abs(tDO - mediumDia));
            }
            if (tDI < outerDiameter) {
                tDI = outerDiameter;
            }
        } else {
            if (ddef === false && tDI > tDO) {
                tDI = tDO;
            }
        }
        return tDI;
    }
}

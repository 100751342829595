/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/max-switch-cases */
/* eslint-disable no-bitwise */
import { SHOULDER_ARRANGEMENT } from '../../../../../cae-model/supports/bearings/radial-roller';
import { LEFT_TOP, RIGHT_TOP, LEFT_BOTTOM, RIGHT_BOTTOM } from './wmf-constants';
/* eslint-disable max-len */
import { WMFImage } from './wmf-image';
import { XForm } from './x-form';

const RIGHT_ROLLER = 1;
const LEFT_ROLLER = 2;

function xor(a: boolean, b: boolean) {
    return (a || b) && !(a && b);
}

//  tslint:disable-next-line:class-name
export class WMF_RadialRollerBearing extends WMFImage {
    shoulderArrangement_: string;
    numberOfRows_: number;

    private _withOuterRing = true;
    private _withInnerRing = true;

    constructor() {
        super(1001, 1001);
    }

    protected doPaint() {
        let rollerMask = 0;
        let leftRollerMask = -1;
        let rightRollerMask = -1;
        let leftRollerWasherMask = 0;
        let rightRollerWasherMask = 0;

        switch (this.shoulderArrangement_) {
            case SHOULDER_ARRANGEMENT.LB_WITHOUT_SHOULDERS:
                rollerMask = 0;
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING:
                rollerMask = LEFT_TOP | RIGHT_TOP | LEFT_BOTTOM | RIGHT_BOTTOM; // 1111
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING_AND_LEFT_AT_INNER_RING:
                rollerMask = LEFT_TOP | RIGHT_TOP | LEFT_BOTTOM; // 1011
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING_AND_RIGHT_AT_INNER_RING:
                rollerMask = LEFT_TOP | RIGHT_TOP | RIGHT_BOTTOM; // 0111
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING:
                rollerMask = LEFT_TOP | RIGHT_TOP; // 0011
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_ON_BOTH_SIDES_AT_INNER_RING:
                rollerMask = LEFT_BOTTOM | RIGHT_BOTTOM; // 1100
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_LEFT_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING:
                rollerMask = LEFT_TOP | LEFT_BOTTOM | RIGHT_BOTTOM; // 1110
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_LEFT_AT_OUTER_RING_AND_RIGHT_AT_INNER_RING:
                rollerMask = LEFT_TOP | RIGHT_BOTTOM; // 0110
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_LEFT_AT_OUTER_RING_AND_LEFT_AT_INNER_RING:
                rollerMask = LEFT_TOP | LEFT_BOTTOM; // 1010
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDER_LEFT_AT_OUTER_RING:
                rollerMask = LEFT_TOP; // 0010
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDER_LEFT_AT_INNER_RING:
                rollerMask = LEFT_BOTTOM; // 1000
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_RIGHT_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING:
                rollerMask = RIGHT_TOP | LEFT_BOTTOM | RIGHT_BOTTOM; // 1101
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_RIGHT_AT_OUTER_RING_AND_LEFT_AT_INNER_RING:
                rollerMask = RIGHT_TOP | LEFT_BOTTOM; // 1001
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_RIGHT_AT_OUTER_RING_AND_RIGHT_AT_INNER_RING:
                rollerMask = RIGHT_TOP | RIGHT_BOTTOM; // 0101
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDER_RIGHT_AT_OUTER_RING:
                rollerMask = RIGHT_TOP; // 0001
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDER_RIGHT_AT_INNER_RING:
                rollerMask = RIGHT_BOTTOM; // 0100
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_ON_LEFT_EDGE_AND_RIGHT_EDGE_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING:
                leftRollerMask = LEFT_TOP | LEFT_BOTTOM | RIGHT_BOTTOM; // 1110
                rollerMask = LEFT_BOTTOM | RIGHT_BOTTOM; // 1100
                rightRollerMask = RIGHT_TOP | LEFT_BOTTOM | RIGHT_BOTTOM; // 1101
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_ON_LEFT_EDGE_AND_RIGHT_EDGE_AT_OUTER_RING_AND_LEFT_EDGE_AT_INNER_RING:
                leftRollerMask = LEFT_TOP | LEFT_BOTTOM; // 1010
                rollerMask = 0; // 0000
                rightRollerMask = RIGHT_TOP; // 0001
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_ON_RIGHT_INSIDE_AND_LEFT_INSIDE_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING:
                leftRollerMask = RIGHT_TOP | LEFT_BOTTOM | RIGHT_BOTTOM; // 1101*/
                rollerMask = LEFT_TOP | RIGHT_TOP | LEFT_BOTTOM | RIGHT_BOTTOM; // 1111
                rightRollerMask = LEFT_TOP | LEFT_BOTTOM | RIGHT_BOTTOM; // 1110*/
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_ON_LEFT_EDGE_AT_INNER_RING:
                leftRollerMask = LEFT_BOTTOM; // 1000
                rollerMask = 0; // 0000
                rightRollerMask = 0; // 0000
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_ON_LEFT_EDGE_AND_RIGHT_EDGE_AT_INNER_RING:
                leftRollerMask = LEFT_BOTTOM; // 1000
                rollerMask = 0; // 0000
                rightRollerMask = RIGHT_BOTTOM; // 0100
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_ON_LEFT_EDGE_AND_RIGHT_EDGE_AT_OUTER_RING: // ollie
                leftRollerMask = LEFT_TOP; // 0010
                rollerMask = 0; // 0000
                rightRollerMask = RIGHT_TOP; // 0001
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_ON_LEFT_EDGE_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING:
                leftRollerMask = LEFT_TOP | LEFT_BOTTOM | RIGHT_BOTTOM; // 1110
                rollerMask = LEFT_BOTTOM | RIGHT_BOTTOM; // 1100
                rightRollerMask = LEFT_BOTTOM | RIGHT_BOTTOM; // 1100
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_ON_RIGHT_EDGE_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING:
                leftRollerMask = LEFT_BOTTOM | RIGHT_BOTTOM; // 1100
                rollerMask = LEFT_BOTTOM | RIGHT_BOTTOM; // 1100
                rightRollerMask = RIGHT_TOP | LEFT_BOTTOM | RIGHT_BOTTOM; // 1101
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING_AND_ON_LEFT_EDGE_AT_INNER_RING:
                leftRollerMask = LEFT_TOP | RIGHT_TOP | LEFT_BOTTOM; // 1011
                rollerMask = LEFT_TOP | RIGHT_TOP; // 0011
                rightRollerMask = LEFT_TOP | RIGHT_TOP; // 0011
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING_AND_RIGHT_EDGE_AND_LEFT_EDGE_AT_INNER_RING:
                leftRollerMask = LEFT_TOP | RIGHT_TOP | LEFT_BOTTOM; // 1011
                rollerMask = LEFT_TOP | RIGHT_TOP; // 0011
                rightRollerMask = LEFT_TOP | RIGHT_TOP | RIGHT_BOTTOM; // 0111
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING_AND_LEFT_EDGE_AND_RIGHT_EDGE_BORD_WASHER_AT_INNER_RING:
                leftRollerMask = LEFT_TOP | RIGHT_TOP | LEFT_BOTTOM;
                rollerMask = LEFT_TOP | RIGHT_TOP;
                rightRollerMask = LEFT_TOP | RIGHT_TOP | RIGHT_BOTTOM;
                rightRollerWasherMask = RIGHT_BOTTOM;
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING_AND_RIGHT_EDGE_BORD_WASHER_AT_INNER_RING:
                leftRollerMask = LEFT_TOP | RIGHT_TOP;
                rollerMask = LEFT_TOP | RIGHT_TOP;
                rightRollerMask = LEFT_TOP | RIGHT_TOP | RIGHT_BOTTOM;
                rightRollerWasherMask = RIGHT_BOTTOM;
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING_AND_LEFT_EDGE_BORD_WASHER_AT_INNER_RING:
                leftRollerMask = LEFT_TOP | RIGHT_TOP | LEFT_BOTTOM;
                rollerMask = LEFT_TOP | RIGHT_TOP;
                rightRollerMask = LEFT_TOP | RIGHT_TOP;
                leftRollerWasherMask = LEFT_BOTTOM;
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_LEFT_EDGE_BORD_WASHER_AND_RIGHT_EDGE_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING:
                leftRollerMask = LEFT_TOP | LEFT_BOTTOM | RIGHT_BOTTOM;
                rollerMask = LEFT_BOTTOM | RIGHT_BOTTOM;
                rightRollerMask = RIGHT_TOP | LEFT_BOTTOM | RIGHT_BOTTOM;
                leftRollerWasherMask = LEFT_TOP;
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_LEFT_EDGE_AND_RIGHT_EDGE_BORD_WASHER_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING:
                leftRollerMask = LEFT_TOP | LEFT_BOTTOM | RIGHT_BOTTOM;
                rollerMask = LEFT_BOTTOM | RIGHT_BOTTOM;
                rightRollerMask = RIGHT_TOP | LEFT_BOTTOM | RIGHT_BOTTOM;
                rightRollerWasherMask = RIGHT_TOP;
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_LEFT_EDGE_BORD_WASHER_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING:
                leftRollerMask = LEFT_TOP | LEFT_BOTTOM | RIGHT_BOTTOM;
                rollerMask = LEFT_BOTTOM | RIGHT_BOTTOM;
                rightRollerMask = LEFT_BOTTOM | RIGHT_BOTTOM;
                leftRollerWasherMask = LEFT_TOP;
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_RIGHT_EDGE_BORD_WASHER_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING:
                leftRollerMask = LEFT_BOTTOM | RIGHT_BOTTOM;
                rollerMask = LEFT_BOTTOM | RIGHT_BOTTOM;
                rightRollerMask = RIGHT_TOP | LEFT_BOTTOM | RIGHT_BOTTOM;
                rightRollerWasherMask = RIGHT_TOP;
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_ON_RIGHT_EDGE_AT_OUTER_RING_AND_RIGHT_EDGE_AND_LEFT_EDGE_AT_INNER_RING:
                leftRollerMask = LEFT_BOTTOM;
                rightRollerMask = RIGHT_TOP | RIGHT_BOTTOM;
                break;
            case SHOULDER_ARRANGEMENT.LB_SHOULDERS_ON_LEFT_EDGE_AT_OUTER_RING_AND_RIGHT_EDGE_AND_LEFT_EDGE_AT_INNER_RING:
                leftRollerMask = LEFT_TOP | LEFT_BOTTOM;
                rightRollerMask = RIGHT_BOTTOM;
                break;
            default:
                rollerMask = 0; //
        }

        if (leftRollerMask < 0) {
            leftRollerMask = rollerMask;
        }
        if (rightRollerMask < 0) {
            rightRollerMask = rollerMask;
        }
        if (this.numberOfRows_ === 1) {
            if ((leftRollerMask & RIGHT_TOP) !== 0) {
                leftRollerMask ^= RIGHT_TOP;
            }
            if ((leftRollerMask & RIGHT_BOTTOM) !== 0) {
                leftRollerMask ^= RIGHT_BOTTOM;
            }
            if ((rightRollerMask & LEFT_TOP) !== 0) {
                rightRollerMask ^= LEFT_TOP;
            }
            if ((rightRollerMask & LEFT_BOTTOM) !== 0) {
                rightRollerMask ^= LEFT_BOTTOM;
            }
            leftRollerMask = leftRollerMask | rightRollerMask;
            leftRollerWasherMask = leftRollerWasherMask | rightRollerWasherMask;
        }

        const rowWidth = this.width_ / this.numberOfRows_;
        let nextRollerMask: number;

        for (let i = 0; i < this.numberOfRows_; i++) {
            if (i + 1 === this.numberOfRows_) {
                nextRollerMask = 0;
            } else if (i === this.numberOfRows_ - 2) {
                nextRollerMask = rightRollerMask;
            } else {
                nextRollerMask = rollerMask;
            }

            if (i === 0) {
                this._drawRadialRollerBearingRoller(
                    rowWidth * i,
                    0,
                    rowWidth,
                    this.height_,
                    leftRollerMask,
                    nextRollerMask,
                    this.numberOfRows_ === 1 ? LEFT_ROLLER | RIGHT_ROLLER : LEFT_ROLLER,
                    leftRollerWasherMask,
                    this._withOuterRing,
                    this._withInnerRing,
                );
            } else if (i === this.numberOfRows_ - 1) {
                this._drawRadialRollerBearingRoller(
                    rowWidth * i,
                    0,
                    rowWidth,
                    this.height_,
                    rightRollerMask,
                    nextRollerMask,
                    RIGHT_ROLLER,
                    rightRollerWasherMask,
                    this._withOuterRing,
                    this._withInnerRing,
                );
            } else {
                this._drawRadialRollerBearingRoller(
                    rowWidth * i,
                    0,
                    rowWidth,
                    this.height_,
                    rollerMask,
                    nextRollerMask,
                    0,
                    0,
                    this._withOuterRing,
                    this._withInnerRing,
                );
            }
        }
    }

    private _drawRadialRollerBearingRoller(
        x: number,
        y: number,
        width: number,
        height: number,
        shoulderMask: number,
        nextShoulderMask: number,
        posMask: number,
        washerMask: number,
        withOuterRing: boolean,
        withInnerRing: boolean,
    ) {
        this._drawRadialRollerBearingSegment(
            x,
            y,
            width,
            height,
            (shoulderMask & LEFT_TOP) === LEFT_TOP,
            true,
            true,
            (posMask & LEFT_ROLLER) === LEFT_ROLLER,
            (washerMask & LEFT_TOP) === LEFT_TOP,
            withOuterRing,
            LEFT_TOP,
        );
        const rightTop = (shoulderMask & RIGHT_TOP) === RIGHT_TOP;
        const leftTop = (nextShoulderMask & LEFT_TOP) === LEFT_TOP;
        this._drawRadialRollerBearingSegment(
            x,
            y,
            width,
            height,
            (shoulderMask & RIGHT_TOP) === RIGHT_TOP,
            !xor(rightTop, leftTop),
            true,
            (posMask & RIGHT_ROLLER) === RIGHT_ROLLER,
            (washerMask & RIGHT_TOP) === RIGHT_TOP,
            withOuterRing,
            RIGHT_TOP,
        );
        const rightBottom = (shoulderMask & RIGHT_BOTTOM) === RIGHT_BOTTOM;
        const leftBottom = (nextShoulderMask & LEFT_BOTTOM) === LEFT_BOTTOM;
        this._drawRadialRollerBearingSegment(
            x,
            y,
            width,
            height,
            (shoulderMask & RIGHT_BOTTOM) === RIGHT_BOTTOM,
            !xor(rightBottom, leftBottom),
            false,
            (posMask & RIGHT_ROLLER) === RIGHT_ROLLER,
            (washerMask & RIGHT_BOTTOM) === RIGHT_BOTTOM,
            withInnerRing,
            RIGHT_BOTTOM,
        );
        this._drawRadialRollerBearingSegment(
            x,
            y,
            width,
            height,
            (shoulderMask & LEFT_BOTTOM) === LEFT_BOTTOM,
            true,
            false,
            (posMask & LEFT_ROLLER) === LEFT_ROLLER,
            (washerMask & LEFT_BOTTOM) === LEFT_BOTTOM,
            withInnerRing,
            LEFT_BOTTOM,
        );
    }

    private _drawRadialRollerBearingSegment(
        x: number,
        y: number,
        width: number,
        height: number,
        withShoulder: boolean,
        shoulderNotClosed: boolean,
        top: boolean,
        rounded: boolean,
        washer: boolean,
        withRing: boolean,
        segmentPos: number,
    ) {
        const gc = this.gc;
        const xform = new XForm(
            x,
            y,
            width,
            height,
            100,
            100,
            segmentPos === RIGHT_TOP || segmentPos === RIGHT_BOTTOM,
            segmentPos === LEFT_BOTTOM || segmentPos === RIGHT_BOTTOM,
        );

        if (rounded && withRing) {
            this.roundAngle(
                xform.calculateX(-100),
                xform.calculateY(100),
                xform.calculateX(0),
                xform.calculateY(0),
                xform.calculateHorizontalRadius(14) * (top ? 1 : 0),
                xform.calculateVerticalRadius(14) * (top ? 1 : 0),
            );
        } else if (withRing) {
            gc.moveTo(xform.calculateX(0), xform.calculateY(100));
            gc.lineTo(xform.calculateX(-100), xform.calculateY(100));
        } else if (rounded) {
            gc.moveTo(xform.calculateX(-100), xform.calculateY(100));
            gc.lineTo(xform.calculateX(-100), xform.calculateY(0));
        }
        gc.moveTo(xform.calculateX(0), xform.calculateY(60));
        if (!withRing) {
            /* EMPTY */
        } else if (!withShoulder) {
            gc.lineTo(xform.calculateX(-100), xform.calculateY(60));
        } else {
            gc.moveTo(xform.calculateX(-100), xform.calculateY(30));
            gc.lineTo(xform.calculateX(-80), xform.calculateY(30));
            if (washer) {
                gc.lineTo(xform.calculateX(-80), xform.calculateY(100));
                gc.moveTo(xform.calculateX(-80), xform.calculateY(60));
                gc.lineTo(xform.calculateX(0), xform.calculateY(60));
            }
        }
        if (!shoulderNotClosed && withRing) {
            gc.moveTo(xform.calculateX(-100), xform.calculateY(30));
            gc.lineTo(xform.calculateX(-100), xform.calculateY(60));
        }

        if (withRing) {
            this.roundAngle(
                xform.calculateX(-80),
                xform.calculateY(60),
                xform.calculateX(0),
                xform.calculateY(0),
                xform.calculateHorizontalRadius(14),
                xform.calculateVerticalRadius(14),
            );
        } else {
            this.roundAngle(
                xform.calculateX(-80),
                xform.calculateY(100),
                xform.calculateX(0),
                xform.calculateY(0),
                xform.calculateHorizontalRadius(14),
                xform.calculateVerticalRadius(14),
            );
        }
    }
}

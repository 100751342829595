import Konva from 'konva';
import { BACKGROUND_COLOR_2D, STROKE_WIDTH, TORQUE_CIRCLE_TIMES_NAME } from '../elements-view/view-2d-constants';

export class TorqueCircleTimes {
    private _group: Konva.Group;
    private _circle: Konva.Circle;
    private _circleTimes: Konva.Circle;
    private _firstCrossover: Konva.Line;
    private _secondCrossover: Konva.Line;

    constructor(stroke: string, radius: number, positionX = 0) {
        this._group = this._createShapes(stroke, radius, positionX);
    }

    get group(): Konva.Group {
        return this._group;
    }

    public updateShapes(stroke: string, radius: number, positionX = 0) {
        [this._circle, this._circleTimes, this._firstCrossover, this._secondCrossover].forEach(shapeItem => {
            shapeItem.x(positionX);
            shapeItem.stroke(stroke);
            shapeItem.strokeWidth(STROKE_WIDTH);
            shapeItem.strokeScaleEnabled(false);
        });

        this._circle.radius(radius);

        const circleTimesRadius = (2 / 3) * radius;
        this._circleTimes.radius(circleTimesRadius);

        const y = Math.sin(45 * (Math.PI / 180)) * circleTimesRadius;
        this._firstCrossover.points([-y, -y, y, y]);
        this._secondCrossover.points([y, -y, -y, y]);
    }

    private _createShapes(stroke: string, radius: number, positionX = 0): Konva.Group {
        const group = new Konva.Group({
            name: TORQUE_CIRCLE_TIMES_NAME,
        });

        this._circle = this._createCircle();
        this._circleTimes = this._createCircle();
        this._firstCrossover = new Konva.Line();
        this._secondCrossover = new Konva.Line();

        this.updateShapes(stroke, radius, positionX);

        group.add(this._circle);
        group.add(this._circleTimes);
        group.add(this._firstCrossover);
        group.add(this._secondCrossover);

        return group;
    }

    private _createCircle(): Konva.Circle {
        const circle = new Konva.Circle();
        circle.fill(BACKGROUND_COLOR_2D);
        circle.strokeScaleEnabled(false);
        return circle;
    }
}

import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
    selector: 'bx-app-container',
    templateUrl: 'app-container.component.html',
})
export class AppContainerComponent implements OnInit {
    constructor(private readonly _renderer: Renderer2) {}

    public ngOnInit(): void {
        this._renderer.removeClass(document.documentElement, 'a-html');
        this._renderer.removeClass(document.body, 'a-body');
    }
}

import { Mesh } from 'three';
import { RigidSupport } from '../../../cae-model/supports/rigid-support';
import { createRingMaterial } from '../../settings/default-materials';
import { SupportView3D } from './support-view-3d';

export class RigidSupportView3D extends SupportView3D {
    constructor(rigidSupport: RigidSupport) {
        super(rigidSupport);
    }

    protected setMesh(): Mesh {
        const material = createRingMaterial();
        return new Mesh(undefined, material);
    }
}

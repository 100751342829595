import { generateUpperAndLowerParts } from '../../../functions/utils-2d';
import { BevelGear } from '../../../../cae-model/transmission-elements/bevel-gear';
import { BACKGROUND_COLOR_2D, STROKE_WIDTH } from '../../view-2d-constants';
import { BevelGearBaseView2D } from '../bevel-gear-base-view-2d';
import { Group } from 'konva/lib/Group';
import { Line } from 'konva/lib/shapes/Line';
import { BevelGearGeometry } from '../../../../views-foundation/geometries/bevel-gear-geometry';

export class BevelGearView2D extends BevelGearBaseView2D {
    constructor(private _bevelGear: BevelGear) {
        super(_bevelGear);
    }

    protected setGeometry(): void {
        this.geometry = new BevelGearGeometry(this._bevelGear, this.input.unitScaleFactor);
    }

    protected updateBevelGear(group: Group): void {
        const { tB, tBore, tTooth } = this.geometry;
        const { leftX, rightX, inDia, outDia, color } = super.getBevelGearInput();

        const points: number[] = [];
        points.push(leftX, -(outDia / 2.0));
        points.push(rightX, -(inDia / 2.0));
        points.push(rightX, -(tBore / 2.0));
        points.push(leftX, -(tBore / 2.0));

        const bevelGearPart: Line = new Line({
            points: points,
            stroke: color,
            strokeWidth: STROKE_WIDTH,
            strokeScaleEnabled: false,
            fill: BACKGROUND_COLOR_2D,
            closed: true,
        });

        group.add(generateUpperAndLowerParts(bevelGearPart, 'bevel-gear-part'));

        const scale = this.input.unitScaleFactor;
        if (tTooth > 1 / scale) {
            const x = tB / 2.0 + 3.0 / scale;
            const y = -tBore / 2.0 - 4.0 / scale;
            group.add(super.createText(`${tTooth}`, color, x, y));
        }
    }
}

<bx-title-bar><h4 caption translate>SHARED.PROJECT.RENAME.TITLE</h4></bx-title-bar>

<ng-container *ngIf="project">
    <bx-project-form [formControl]="projectCtrl"></bx-project-form>

    <footer class="footer">
        <div class="button-bar">
            <div class="left">
                <button type="button" class="secondary" (click)="onCancel()">
                    {{ 'GLOBALS.CANCEL' | translate }}
                </button>
            </div>
            <div class="right">
                <button type="button" class="primary" (click)="onConfirm()" [disabled]="disabled">
                    {{ 'GLOBALS.RENAME' | translate }}
                </button>
            </div>
        </div>
    </footer>
</ng-container>

import { Group } from 'konva/lib/Group';
import { IRect } from 'konva/lib/types';
import { ElementView2D } from '../elements-view/element-view-2d';
import { DIMENSIONS_LINE_GAP } from '../elements-view/view-2d-constants';
import {
    checkIsRightSide,
    createHorizontalDimensions,
    createVerticalDimensions,
    HorizontalDimensionsInterface,
    VerticalDimensionsInterface,
} from './dimensions-utils';

export abstract class Dimensions2D {
    constructor(protected elementView2D: ElementView2D, protected modelViewClientRect: IRect, protected fractionDigits: number) {}

    protected abstract doCreateHorizontalDimensionsInputs(): HorizontalDimensionsInterface[];
    protected abstract doCreateVerticalDimensionsInput(): VerticalDimensionsInterface[];

    public createVisualization(): Group {
        return this._doCreateDimensions();
    }

    private _getHorizontalDimensionPositionY(): number {
        return this.modelViewClientRect.height / 2 + DIMENSIONS_LINE_GAP / this.elementView2D.input.unitScaleFactor;
    }

    private _getVerticalDimensionPositionX(): number {
        const elementRect = this.getGroupWithoutChildrenClientRect();
        const isRightSide = checkIsRightSide(
            this.getCoordinatesAbsX(),
            elementRect.width,
            this.modelViewClientRect.x,
            this.modelViewClientRect.width,
        );

        return isRightSide
            ? this.modelViewClientRect.x + this.modelViewClientRect.width + DIMENSIONS_LINE_GAP / this.elementView2D.input.unitScaleFactor
            : this.modelViewClientRect.x - DIMENSIONS_LINE_GAP / this.elementView2D.input.unitScaleFactor;
    }

    protected getCoordinatesAbsX(): number {
        const { modelElement } = this.elementView2D;
        return modelElement.coordinatesAbs.x;
    }

    protected getGroupWithoutChildrenClientRect(): IRect {
        return this.elementView2D.getGroupWithoutChildren().getClientRect({
            skipTransform: true,
            skipStroke: true,
            skipShadow: true,
        });
    }

    private _doCreateDimensions(): Group {
        const dimensionGroup = new Group();

        const horizontalDimensionsInputs = this.doCreateHorizontalDimensionsInputs();
        dimensionGroup.add(
            createHorizontalDimensions(
                horizontalDimensionsInputs,
                this._getHorizontalDimensionPositionY(),
                this.fractionDigits,
                this.elementView2D.input.unitScaleFactor,
            ),
        );

        const verticalDimensionsInput = this.doCreateVerticalDimensionsInput();
        dimensionGroup.add(
            createVerticalDimensions(
                verticalDimensionsInput,
                this._getVerticalDimensionPositionX(),
                this.fractionDigits,
                this.elementView2D.input.unitScaleFactor,
            ),
        );

        return dimensionGroup;
    }
}

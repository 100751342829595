import { Load } from './load';

export class DistributedLoad extends Load {
    xEnd = 0;
    forceXStart = 0;
    forceYStart = 0;
    forceZStart = 0;
    torqueXStart = 0;
    torqueYStart = 0;
    torqueZStart = 0;
    forceXEnd = 0;
    forceYEnd = 0;
    forceZEnd = 0;
    torqueXEnd = 0;
    torqueYEnd = 0;
    torqueZEnd = 0;
}

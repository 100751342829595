import { UnitSet } from '../../views-foundation/view-foundation-settings';

export enum ClippingType {
    Off = 'VIEW_CLIPPING_OFF',
    Half = 'VIEW_CLIPPING_HALF',
    Quarter = 'VIEW_CLIPPING_QUARTER',
}

export enum ClippingPlaneType {
    XY = 'XY',
    XZ = 'XZ',
}

export enum CameraType {
    Perspective = 'PERSPECTIVE',
    Orthographic = 'ORTOGRAPHIC',
}

export interface View3DSettings {
    rotateAroundSelection: boolean;
    clippingType: ClippingType;
    clippingPlane: ClippingPlaneType;
    flipClippingPlane: boolean;
    cameraType: CameraType;
    unitSet: UnitSet;
}

import { Group } from 'konva/lib/Group';
import { Shape } from 'konva/lib/Shape';
import { Arc } from 'konva/lib/shapes/Arc';
import { Line } from 'konva/lib/shapes/Line';
import { WormGear } from '../../../../cae-model/transmission-elements/worm-gear';
import { WormGearGeometry } from '../../../../views-foundation/geometries/worm-gear-geometry';
import { generateUpperAndLowerParts } from '../../../functions/utils-2d';
import { DISPLAYGREEN, DISPLAYRED, STROKE_WIDTH } from '../../view-2d-constants';
import { TransmissionElementView2D } from '../transmission-element-view-2d';

export class WormGearView2D extends TransmissionElementView2D {
    constructor(private _wormGear: WormGear) {
        super(_wormGear);
    }

    private _geometry: WormGearGeometry;
    public get geometry(): WormGearGeometry {
        return this._geometry;
    }

    protected createShapes(): Group {
        return new Group({
            name: 'worm-gear-view-2d-group',
            transformable: true,
        });
    }
    protected paintElement(group: Group): void {
        this._updateWormGear(group);
    }
    protected paintPitchLines(group: Group): void {
        this._updatePitchLine(group);
    }

    protected setGeometry(): void {
        this._geometry = new WormGearGeometry(this._wormGear, this.input.unitScaleFactor);
    }

    private _updateWormGear(group: Group): void {
        const { tDO, tB, isOnShaft, tBore } = this.geometry;
        const arcWidth = tB;
        const arcHeight = Math.min(tDO, tB);
        const leftX = -tB / 2.0;
        const rightX = tB / 2.0;
        const color = isOnShaft ? DISPLAYGREEN : DISPLAYRED;

        const wormGearPart: Shape[] = [];

        wormGearPart.push(
            new Line({
                stroke: color,
                strokeWidth: STROKE_WIDTH,
                strokeScaleEnabled: false,
                points: [leftX, -tDO / 2.0, leftX, -tBore / 2.0, rightX, -tBore / 2.0, rightX, -tDO / 2.0],
            }),
        );

        const arcRadius: number = Math.max(arcWidth, arcHeight) / 2;
        wormGearPart.push(
            new Arc({
                y: -tDO / 2.0,
                stroke: color,
                strokeWidth: STROKE_WIDTH,
                strokeScaleEnabled: false,
                angle: -180.0,
                innerRadius: arcRadius,
                outerRadius: arcRadius,
                scaleX: arcHeight > arcWidth ? arcWidth / arcHeight : 1.0,
                scaleY: arcHeight < arcWidth ? arcHeight / arcWidth : 1.0,
            }),
        );

        wormGearPart.forEach(part => {
            group.add(generateUpperAndLowerParts(part, 'worm-gear-part'));
        });
    }

    private _updatePitchLine(group: Group): void {
        const { tB, isOnShaft, tDM } = this.geometry;
        const width = tB * 1.5;

        super.paintHorizontalPitchLines(group, isOnShaft, width, tDM);
    }
}

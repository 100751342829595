import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class EditPermissionService {
    private _isEditable = new BehaviorSubject<boolean>(false);

    constructor() {}

    isEditable(): Observable<boolean> {
        return this._isEditable;
    }

    setEditable(value: boolean) {
        this._isEditable.next(value);
    }
}

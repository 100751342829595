/**
 * CAEModel
 */

import { ModelElement } from './model-element';

export class CAEModel extends ModelElement {
    constructor() {
        super();
    }
}

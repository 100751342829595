import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Notification, NotificationAction, NotificationType } from './notification.model';
import { NotificationsService } from './notifications.service';

@Component({
    selector: 'bx-notification-area',
    templateUrl: 'notification-area.component.html',
    styleUrls: ['notification-area.component.scss'],
})
export class NotificationAreaComponent implements OnInit, OnDestroy {
    public show = false;

    // TODO remove code duplications
    public unseenCount = 0;
    public import: Notification[] = [];
    public export: Notification[] = [];
    public system: Notification[] = [];

    private _notificationsSubscription = Subscription.EMPTY;

    constructor(private _notificationsService: NotificationsService) {}

    public ngOnInit(): void {
        this._notificationsSubscription = this._notificationsService.notifications$.subscribe(this._updateNotifications.bind(this));
    }

    public ngOnDestroy(): void {
        this._notificationsSubscription.unsubscribe();
    }

    public onAction(notification: Notification, action: NotificationAction): void {
        if (action.confirmationMsg !== void 0 && !confirm(action.confirmationMsg)) {
            return;
        }

        action.actionFn();

        if (action.preventDismiss !== true) {
            this._notificationsService.dismiss(notification.id);
        }
    }

    public toggle(): void {
        this.show = !this.show;
        this._notificationsService.seenAll();
    }

    private _updateNotifications(notifications: Notification[]): void {
        this.unseenCount = notifications.filter(notification => !notification.seen).length;

        this.import = notifications.filter(notification => notification.type === NotificationType.Import);
        this.export = notifications.filter(notification => notification.type === NotificationType.Export);
        this.system = notifications.filter(notification => notification.type === NotificationType.System);
    }

    public hideAll(): void {
        this.hideImports();
        this.hideExports();
        this.hideSystem();
    }

    public hideImports(): void {
        this.import.forEach(notification => this._dismissNotification(notification));
    }

    public hideExports(): void {
        this.export.forEach(notification => this._dismissNotification(notification));
    }

    public hideSystem(): void {
        this.system.forEach(notification => this._dismissNotification(notification));
    }

    private _dismissNotification(notification: Notification): void {
        if (notification.actions && notification.actions?.find(action => action.preventDismiss !== true)) {
            this._notificationsService.dismiss(notification.id);
        }
    }
}

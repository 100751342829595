import { Mesh, BufferGeometry, Material } from 'three';
import { InternalBevelGear } from '../../../cae-model/transmission-elements/internal-bevel-gear';
import { InternalBevelGearGeometry } from '../../../views-foundation/geometries/internal-bevel-gear-geometry';
import { createPlaceholderGeometry, getUnitSetScaleValue } from '../../functions/utils-3d';
import { TransmissionElementView3D } from './transmission-element-view-3d';

export class InternalBevelGearView3D extends TransmissionElementView3D {
    constructor(private _internalBevelGear: InternalBevelGear) {
        super(_internalBevelGear);
    }

    protected setMesh(geometry: BufferGeometry, material: Material): Mesh {
        return new Mesh(geometry, material);
    }

    protected setBufferGeometry(): BufferGeometry {
        const geometry = new InternalBevelGearGeometry(this._internalBevelGear, getUnitSetScaleValue(this.input.settings.unitSet));
        this.isOnShaft = geometry.isOnShaft;

        const { tDO, tDI, tB } = geometry;
        return createPlaceholderGeometry(this.input.settings, tDI, tDO, tB);
    }
}

import { HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Workaround for https://github.com/angular/angular/issues/18261
@Injectable({
    providedIn: 'root',
})
export class UrlParameterEncodingCodec extends HttpUrlEncodingCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }

    encodeValue(value: string): string {
        return encodeURIComponent(value);
    }
}

import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[bxTitle]',
})
export class TitleDirective {
    constructor(private readonly _elementRef: ElementRef<HTMLElement>) {}

    @Input('bxTitle')
    public title: string;

    @Input()
    public referenceElement: HTMLElement;

    @HostBinding('attr.title')
    public titleAttr: string | null;

    @HostListener('mouseenter')
    public onMouseEnter(): void {
        const { offsetWidth, scrollWidth } = this.referenceElement ?? this._elementRef.nativeElement;
        this.titleAttr = offsetWidth < scrollWidth ? this.title : null;
    }
}

import { Ring } from '../../cae-model/bearing-elements/ring';
import { ModelElement } from '../../cae-model/model-element';
import { createVisualizationDataBinder } from '../functions/utils';
import { BasicMediator } from './basic-mediator';

export class RingMediator extends BasicMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return [
            'IDO_RADIAL_BEARING_OUTER_RING',
            'IDO_THRUST_BEARING_OUTER_RING',
            'IDO_RADIAL_BEARING_INNER_RING',
            'IDO_THRUST_BEARING_INNER_RING',
            'IDO_SLEWING_BEARING_OUTER_RING',
            'IDO_SLEWING_BEARING_INNER_RING',
        ];
    }

    protected createModelElement(): ModelElement {
        return new Ring();
    }

    private _createBinder(): void {
        this.addBinder(createVisualizationDataBinder());
    }
}

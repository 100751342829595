import { ConeSegment } from '../../../cae-model/shaft-segments/cone-segment';
import { BufferGeometry } from 'three';
import { createCylinderWithHole, CylinderWithHoleInterface } from '../../functions/utils-3d';
import { ShaftSegmentView3D } from './shaft-segment-view-3d';

export class ConeSegmentView3D extends ShaftSegmentView3D {
    constructor(cone: ConeSegment) {
        super(cone);
    }

    protected createGeometry(): BufferGeometry {
        const outerRadiusLeft = this._cone.outerDiameterLeft / 2;
        const outerRadiusRight = this._cone.outerDiameterRight / 2;
        const innerRadiusLeft = this._cone.innerDiameterLeft / 2;
        const innerRadiusRight = this._cone.innerDiameterRight / 2;
        const cylinderWithHoleInterface: CylinderWithHoleInterface = {
            outerRadiusStart: outerRadiusLeft,
            outerRadiusEnd: outerRadiusRight,
            innerRadiusStart: innerRadiusLeft,
            innerRadiusEnd: innerRadiusRight,
            length: this._cone.length,
        };
        return createCylinderWithHole(cylinderWithHoleInterface);
    }

    private get _cone(): ConeSegment {
        return this.modelElement as ConeSegment;
    }
}

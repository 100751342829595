import { RingMediator } from '../mediators/ring-mediator';
import { BxObjectInterface } from './../interfaces/bx-object-interface';
import { GearAbstractMediator } from './../mediators/transmission-mediators/gear-abstract-mediator';
import { WormMediator } from './../mediators/transmission-mediators/worm-mediator';
import { TransverseBoreMdeiator } from './../mediators/notch-mediators/transverse-bore-mediator';
import { ShoulderMediator } from './../mediators/notch-mediators/shoulder-mediator';
import { UnderCutMediator } from './../mediators/notch-mediators/under-cut-mediator';
import { ThrustBallMediator } from '../mediators/support-mediators/bearing-mediators/thrust-ball-mediator';
import { BevelGearMediator } from './../mediators/transmission-mediators/bevel-gear-mediator';
import { BearingRowMediator } from '../mediators/bearing-row-mediator';
import { BearingMediator } from '../mediators/support-mediators/bearing-mediators/bearing-mediator';
import { SpringMediator } from '../mediators/support-mediators/spring-mediator';
import { PointMassMediator } from '../mediators/point-mass-mediator';
import { Injectable, OnDestroy } from '@angular/core';
import { CAEModel } from '../../cae-model/cae-model';
import { ModelElement } from '../../cae-model/model-element';
import { createModelElement, findObjectById } from '../functions/utils';
import { BearinxModelObject } from '../bearinx-model-object';
import { Subscription } from 'rxjs';
import { BasicMediator } from '../mediators/basic-mediator';
import { ShaftSystemMediator } from '../mediators/shaft-system-mediator';
import { ShaftMediator } from '../mediators/shaft-mediator';
import { PipeSegmentMediator } from '../mediators/pipe-segment-mediator';
import { ConeSegmentMediator } from '../mediators/cone-segment-mediator';
import { PointLoadMediator } from '../mediators/point-load-mediator';
import { ConstantLineLoadMediator } from '../mediators/constant-line-load-mediator';
import { LinearLineLoadMediator } from '../mediators/linear-line-load-mediator';
import { RadialTaperedRollerMediator } from '../mediators/support-mediators/bearing-mediators/radial-tapered-roller-mediator';
import { RigidSupportMediator } from '../mediators/support-mediators/rigid-support-mediator';
import { InternalSpurGearMediator } from '../mediators/transmission-mediators/internal-spur-gear-mediator';
import { SpurGearMediator } from '../mediators/transmission-mediators/spur-gear-mediator';
import { InternalBevelGearMediator } from '../mediators/transmission-mediators/internal-bevel-gear-mediator';
import { RadialBallMediator } from '../mediators/support-mediators/bearing-mediators/radial-ball-mediator';
import { RadialRollerMediator } from '../mediators/support-mediators/bearing-mediators/radial-roller-mediator';
import { SlewingBallMediator } from '../mediators/support-mediators/bearing-mediators/slewing-ball-mediator';
import { ThrustRollerRadialRollerMediator } from '../mediators/support-mediators/bearing-mediators/thrust-roller-radial-roller-mediator';
import { InternalStepMediator } from '../mediators/notch-mediators/internal-step-mediator';
import { RectangularGrooveMediator } from '../mediators/notch-mediators/rectangular-groove-mediator';
import { UGrooveMediator } from '../mediators/notch-mediators/u-groove-mediator';
import { VNotchMediator } from '../mediators/notch-mediators/v-notch-mediator';
import { HubMediator } from '../mediators/notch-mediators/hub-mediator';
import { KeySeatMediator } from '../mediators/notch-mediators/key-seat-mediator';
import { WormGearMediator } from '../mediators/transmission-mediators/worm-gear-mediator';
import { CounterMomentMediator } from '../mediators/transmission-mediators/counter-moment-mediator';
import { RollingElementMediator } from '../mediators/rolling-element-mediator';
import { CylindricalPlainBushMediator } from '../mediators/support-mediators/sliding-bearing-mediators/cylindrical-plain-bush-mediator';
import { SlidingBearingMediator } from '../mediators/support-mediators/sliding-bearing-mediators/sliding-bearing-mediator';
import { RadialSphericalPlainMediator } from '../mediators/support-mediators/sliding-bearing-mediators/radial-spherical-plain-mediator';
import { AngularSphericalPlainMediator } from '../mediators/support-mediators/sliding-bearing-mediators/angular-spherical-plain-mediator';
import { ThrustWasherMediator } from '../mediators/support-mediators/sliding-bearing-mediators/thrust-washer-mediator';

@Injectable()
export class BearinxMediatorService implements OnDestroy {
    private _bearinxModel: any;
    private _loadCase: any;
    private _caeModel: CAEModel | null;
    private _mediators: Map<string, BasicMediator>;
    private _updatedIds = new Set<string>();
    private _caeSubscriptions: Subscription[];

    constructor() {
        this._caeSubscriptions = [];
        this._createMediatorsMap();
    }

    ngOnDestroy(): void {
        this._caeModel = null;
        this._mediators?.clear();
        this._updatedIds?.clear();
        this._caeSubscriptions = [];
        this._bearinxModel = null;
        this._loadCase = null;
    }

    convert(bearinxModel: any, loadCase: number): CAEModel | null {
        if (bearinxModel == null || loadCase < 0) {
            return null;
        }
        if (!this._isLoadCaseExist(bearinxModel, loadCase)) {
            return null;
        }
        this._mediators?.clear();
        this._createMediatorsMap();
        this._loadCase = loadCase;
        this._caeSubscriptions.forEach(subs => subs.unsubscribe());
        this._caeSubscriptions = [];
        this._bearinxModel = bearinxModel;
        this._caeModel = createModelElement(bearinxModel, CAEModel);
        this._caeModel.name = 'CAE Model';
        const rootObject = this._bearinxModel.rootObject as BearinxModelObject;
        const rootModelElement = this._convertObjectAndChildren(rootObject)!;
        this._caeModel.add(rootModelElement);
        return this._caeModel;
    }

    updateBearinxModel(): BxObjectInterface[] {
        const output: BxObjectInterface[] = [];
        this._updatedIds.forEach(id => output.push(this._getUpdate(id)));
        return output;
    }

    private _isLoadCaseExist(bearinxModel: any, loadCase: number): boolean {
        const loadCases = bearinxModel.rootObject.children.filter((data: any) => data.type === 'IDO_SYSTEM_LOADCASE');
        const locadCaseCount = loadCases.length as number;
        return locadCaseCount > loadCase;
    }

    private _getUpdate(id: string): BxObjectInterface {
        const bxObject = findObjectById([this._bearinxModel.rootObject], id) as BearinxModelObject;
        if (this._caeModel == null) {
            throw new Error('_caeModel is required');
        }
        const caeElement = findObjectById([this._caeModel], id) as ModelElement;
        const mediator = this._getObjectMediator(bxObject)!;
        return mediator.getBearinxOjectUpdate(bxObject, caeElement);
    }

    private _addMediator<T extends BasicMediator>(c: new () => T): void {
        const mediator = new c();
        if (typeof mediator.bearinxObjectTypes === 'string') {
            this._mediators.set(mediator.bearinxObjectTypes, mediator);
        } else {
            mediator.bearinxObjectTypes.forEach(bearinxType => this._mediators.set(bearinxType, mediator));
        }
    }

    private _createMediatorsMap() {
        this._mediators = new Map<string, BasicMediator>();
        this._addMediator(ShaftSystemMediator);
        this._addMediator(ShaftMediator);
        this._addMediator(PipeSegmentMediator);
        this._addMediator(ConeSegmentMediator);
        this._addMediator(PointLoadMediator);
        this._addMediator(ConstantLineLoadMediator);
        this._addMediator(LinearLineLoadMediator);
        this._addMediator(PointMassMediator);

        // supports mediators
        this._addMediator(SpringMediator);
        this._addMediator(RigidSupportMediator);

        // bearings mediators
        this._addMediator(BearingMediator);
        this._addMediator(RadialTaperedRollerMediator);
        this._addMediator(RadialBallMediator);
        this._addMediator(RadialRollerMediator);
        this._addMediator(SlewingBallMediator);
        this._addMediator(ThrustBallMediator);
        this._addMediator(ThrustRollerRadialRollerMediator);

        // part of bearings  mediators
        this._addMediator(RingMediator);
        this._addMediator(BearingRowMediator);
        this._addMediator(RollingElementMediator);

        // sliding bearings mediators
        this._addMediator(SlidingBearingMediator);
        this._addMediator(CylindricalPlainBushMediator);
        this._addMediator(RadialSphericalPlainMediator);
        this._addMediator(AngularSphericalPlainMediator);
        this._addMediator(ThrustWasherMediator);

        // notches mediators
        this._addMediator(UnderCutMediator);
        this._addMediator(InternalStepMediator);
        this._addMediator(ShoulderMediator);
        this._addMediator(RectangularGrooveMediator);
        this._addMediator(UGrooveMediator);
        this._addMediator(VNotchMediator);
        this._addMediator(TransverseBoreMdeiator);
        this._addMediator(HubMediator);
        this._addMediator(KeySeatMediator);

        // Transmission Elements mediators
        this._addMediator(SpurGearMediator);
        this._addMediator(InternalSpurGearMediator);
        this._addMediator(BevelGearMediator);
        this._addMediator(InternalBevelGearMediator);
        this._addMediator(WormMediator);
        this._addMediator(WormGearMediator);
        this._addMediator(GearAbstractMediator);
        this._addMediator(CounterMomentMediator);
    }

    private _convertObjectAndChildren(coobject: BearinxModelObject): ModelElement | null {
        const modelElement = this._convertObject(coobject);
        if (modelElement == null) {
            return null;
        }
        this._addSubscription(modelElement);
        const elementChildren = coobject.children;
        elementChildren.forEach(childCoobject => {
            const childModelElement = this._convertObjectAndChildren(childCoobject);
            if (childModelElement != null) {
                modelElement.add(childModelElement);
            }
        });
        return modelElement;
    }

    private _addSubscription(modelElement: ModelElement) {
        const modelId = modelElement.id;
        modelElement.isUpdated().subscribe(updateFlag => {
            if (updateFlag) {
                this._updatedIds.add(modelId);
            }
        });
    }

    private _convertObject(bearinxModelObject: BearinxModelObject): ModelElement {
        const mediator = this._getObjectMediator(bearinxModelObject);
        return mediator?.convert(bearinxModelObject, this._loadCase)!;
    }

    private _getObjectMediator(bearinxModelObject: BearinxModelObject): BasicMediator | null {
        const convertedTypes = bearinxModelObject.subTypes.filter(type => this._mediators.has(type));
        if (convertedTypes.length === 0) {
            return null;
        }
        return this._mediators.get(convertedTypes[convertedTypes.length - 1])!;
    }
}

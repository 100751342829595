import { TransmissionElement } from './transmission-element';

export class SpurGear extends TransmissionElement {
    outerDiameter = 0; // IDTE_D
    width = 0; // IDTE_B
    workingPitchDiameter = 0; // IDSG_DM
    toothCount = 0; // IDSG_NB_TOOTH_CONTACTS
    helixAngleReferenceDiameter = 0; // IDSG_BETA

    constructor() {
        super();
    }
}

/* eslint-disable no-shadow */
export enum LoadType {
    Force,
    Torque,
}

export enum AxisArrowType {
    X,
    Y,
}

export enum ViewIcon {
    View = 'VIEW_VIEWS',
    Top = 'VIEW_TOP',
    Bottom = 'VIEW_BOTTOM',
    Left = 'VIEW_LEFT',
    Right = 'VIEW_RIGHT',
    Front = 'VIEW_FRONT',
    Back = 'VIEW_BACK',
    Perspective = 'VIEW_PERSPECTIVE',
    ZoomToFit = 'VIEW_ZOOM_TO_FIT',
    MeasurementsOn = 'VIEW_HIDE_MEASUREMENT',
    MeasurementsOff = 'VIEW_SHOW_MEASUREMENT',
}

import { SolidInterface } from './../interfaces/solid-interface';
/* eslint-disable sonarjs/cognitive-complexity */
import { CurveStraightLine } from '../curves/curve-straight-line';
import { VisualizationDataParameters } from '../interfaces/visualization-data.parameters';
import { parseVisualizationData } from './visualization-data-utils';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { BufferGeometry, DataTexture, Euler, Float32BufferAttribute, Matrix4, RGBFormat, Shape, ShapeUtils, Vector2, Vector3 } from 'three';
import { getOpeningAngle, getStartAngle } from './utils-3d';
import { View3DSettings } from '../settings';
import { MIN_OUTER_RADIUS_PLACEHOLDER_RING, MIN_WIDTH_PLACEHOLDER_RING } from '../settings/view-3d-constants';

class VisualizationDataBufferGeometry extends BufferGeometry {
    public numberOfMaterials = 0;
    public boundingShape = { xMin: 0, xMax: 0, yMin: 0, yMax: 0 };
    public normalMaps: DataTexture[] = [];

    constructor(public parameters: VisualizationDataParameters, visualData: SolidInterface) {
        super();
        const EPS = 0.000001;

        this.type = 'VisualizationDataBufferGeometry';
        const scope = this;

        let materialIndex = 0;

        const indices: Array<number> = [];
        const vertices: Array<number> = [];
        const normals: Array<number> = [];
        const uvs: Array<number> = [];
        let groupStart = 0;

        function getShape(visualizationData: any) {
            const shpPoints = [];

            for (let i = 0; i < visualizationData.lineSegments.length; i += 1) {
                const slices = visualizationData.lineSegments[i].getNumberOfPoints(parameters.pointsPerUnit) - 1;

                for (let j = 0; j < slices + 1; j += 1) {
                    const t = j / slices;

                    const newPoint: Vector2 = visualizationData.lineSegments[i].getCurvePoint(t);

                    if (i > 0) {
                        if (newPoint.distanceTo(shpPoints[i - 1]) > EPS) {
                            shpPoints.push(visualizationData.lineSegments[i].getCurvePoint(t));
                        }
                    } else {
                        shpPoints.push(visualizationData.lineSegments[i].getCurvePoint(t));
                    }
                }
            }

            return new Shape(shpPoints);
        }

        function getValueNearBy(value: number, delta: number) {
            if (value + delta < 1.0) {
                return value + delta;
            }
            return value - delta;
        }

        function computeVertexAndNormals(func: any, u: number, v: number) {
            const normal = new Vector3();
            const p0 = new Vector3(),
                p1 = new Vector3();
            const pu = new Vector3(),
                pv = new Vector3();

            // vertex
            func(u, v, p0);
            const delta = 1e-10;

            // normal
            // approximate tangent vectors via finite differences

            if (u - EPS >= 0) {
                func(u - EPS, v, p1);
                pu.subVectors(p0, p1);

                // check length
                if (pu.length() < delta) {
                    func(u - EPS, getValueNearBy(v, delta), p1);
                    pu.subVectors(p0, p1);
                }
            } else {
                func(u + EPS, v, p1);
                pu.subVectors(p1, p0);

                // check length
                if (pu.length() < delta) {
                    func(u + EPS, getValueNearBy(v, delta), p1);
                    pu.subVectors(p1, p0);
                }
            }

            if (v - EPS >= 0) {
                func(u, v - EPS, p1);
                pv.subVectors(p0, p1);

                // check length
                if (pv.length() < delta) {
                    func(getValueNearBy(u, delta), v - EPS, p1);
                    pv.subVectors(p0, p1);
                }
            } else {
                func(u, v + EPS, p1);
                pv.subVectors(p1, p0);

                // check length
                if (pv.length() < delta) {
                    func(getValueNearBy(u, delta), v + EPS, p1);
                    pv.subVectors(p1, p0);
                }
            }

            // cross product of tangent vectors returns surface normal
            normal.crossVectors(pu, pv).normalize();

            return [p0, normal];
        }

        function generateParametricBufferGeometry(func: any, slices: number, stackNumber: number, materialIdx: number) {
            const idxOffset: number = vertices.length / 3;
            let groupCount = 0;

            // generate vertices, normals and uvs
            const sliceCount = slices + 1;

            for (let k = 0; k <= stackNumber; k++) {
                const v = k / stackNumber;
                for (let j = 0; j <= slices; j++) {
                    const u = j / slices;
                    const [p, n] = computeVertexAndNormals(func, u, v);
                    vertices.push(p.x, p.y, p.z);

                    normals.push(n.x, n.y, n.z);
                    // uv
                    uvs.push(u, v);
                }
            }

            // generate indices
            for (let m = 0; m < stackNumber; m++) {
                for (let n = 0; n < slices; n++) {
                    const a = m * sliceCount + n + idxOffset;
                    const b = m * sliceCount + n + 1 + idxOffset;
                    const c = (m + 1) * sliceCount + n + 1 + idxOffset;
                    const d = (m + 1) * sliceCount + n + idxOffset;
                    // faces one and two
                    indices.push(a, b, d);
                    indices.push(b, c, d);
                    groupCount += 6;
                }
            }

            scope.addGroup(groupStart, groupCount, materialIdx);
            groupStart += groupCount;
        }

        function generateNormalMap(func: any, slices: number, stackNumber: number, resolutionScale: number) {
            // generate vertices, normals and uvs
            const sliceCount = slices * resolutionScale + 1;
            const stacksCount = stackNumber * resolutionScale + 1;

            const size = sliceCount * stacksCount;
            const data = new Uint8Array(3 * size);

            let i = 0;

            for (let k = 0; k <= stackNumber * resolutionScale; k++) {
                const v = k / (stackNumber * resolutionScale);
                for (let j = 0; j <= slices * resolutionScale; j++) {
                    const u = j / (slices * resolutionScale);
                    const [p, n] = computeVertexAndNormals(func, u, v);

                    const stride = i * 3;

                    data[stride] = n.x * 255;
                    data[stride + 1] = n.y * 255;
                    data[stride + 2] = n.z * 255;

                    i += 1;
                }
            }
            return new DataTexture(data, sliceCount, stacksCount, RGBFormat);
        }

        function addShape(
            shape: Shape,
            curveSegments: any,
            flipNormals: boolean,
            rotate: boolean,
            amount: number,
            planeOrientation: number,
            yAxis: any,
        ) {
            const xValues = shape.getPoints().map(x => x.x);
            const xMin = Math.min.apply(Math, xValues);
            const xMax = Math.max.apply(Math, xValues);

            const yValues = shape.getPoints().map(x => x.y);
            const yMin = Math.min.apply(Math, yValues);
            const yMax = Math.max.apply(Math, yValues);

            const yAxisDirection = new Vector3();
            if (yAxis === 0) {
                yAxisDirection.set(1.0, 0.0, 0.0);
            } else if (yAxis === 1) {
                yAxisDirection.set(0.0, 1.0, 0.0);
            } else {
                yAxisDirection.set(0.0, 0.0, 1.0);
            }

            let i, l, shapeHole;
            let groupCount = 0;

            const indexOffset = vertices.length / 3;
            const points = shape.extractPoints(curveSegments);

            let shapeVertices = points.shape;
            const shapeHoles = points.holes;

            // check direction of vertices

            if (ShapeUtils.isClockWise(shapeVertices) === false) {
                shapeVertices = shapeVertices.reverse();
            }

            for (i = 0, l = shapeHoles.length; i < l; i++) {
                shapeHole = ShapeUtils[i];

                if (ShapeUtils.isClockWise(shapeHole) === true) {
                    ShapeUtils[i] = shapeHole.reverse();
                }
            }

            const faces = ShapeUtils.triangulateShape(shapeVertices, shapeHoles);

            // join vertices of inner and outer paths to a single array

            for (i = 0, l = shapeHoles.length; i < l; i++) {
                shapeHole = shapeHoles[i];
                shapeVertices = shapeVertices.concat(shapeHole);
            }

            // vertices, normals, uvs

            const m = new Matrix4();

            if (rotate) {
                m.makeRotationAxis(yAxisDirection, (amount / 180.0) * Math.PI);
            } else {
                const translation = yAxisDirection.clone().multiplyScalar(amount);
                m.makeTranslation(translation.x, translation.y, translation.z);
            }

            for (i = 0, l = shapeVertices.length; i < l; i++) {
                const vertex = shapeVertices[i];

                const x = vertex.x;
                const y = vertex.y;
                const z = 0.0;
                const v = new Vector3(0, 0, 0);

                if (planeOrientation === 1) {
                    v.set(x, z, y);
                } else if (planeOrientation === 2) {
                    v.set(z, x, y);
                } else {
                    v.set(x, y, z);
                }

                v.applyMatrix4(m);
                vertices.push(v.x, v.y, v.z);

                //  vertices.push( x, y, z );
                normals.push(0, 0, -1);
                uvs.push(1.0 - (vertex.x - xMin) / (xMax - xMin), (vertex.y - yMin) / (yMax - yMin)); // world uvs
            }

            // incides

            for (i = 0, l = faces.length; i < l; i++) {
                const face = faces[i];

                const a = face[0] + indexOffset;
                const b = face[1] + indexOffset;
                const c = face[2] + indexOffset;

                if (flipNormals) {
                    indices.push(a, b, c);
                } else {
                    indices.push(c, b, a);
                }
                groupCount += 3;
            }

            scope.addGroup(groupStart, groupCount, materialIndex);
            groupStart += groupCount;
            materialIndex += 1;
        }

        let amountOffset = parameters.customInitialAmount;
        if (visualData.rotate === true) {
            amountOffset *= Math.PI / 180.0;
        }

        this.boundingShape = visualData.lineSegments[0].getBoundingBox();

        for (let i = 0; i < visualData.lineSegments.length; i += 1) {
            const bShape = visualData.lineSegments[i].getBoundingBox();
            this.boundingShape.xMin = Math.min(this.boundingShape.xMin, bShape.xMin);
            this.boundingShape.xMax = Math.max(this.boundingShape.xMax, bShape.xMax);
            this.boundingShape.yMin = Math.min(this.boundingShape.yMin, bShape.yMin);
            this.boundingShape.yMax = Math.max(this.boundingShape.yMax, bShape.yMax);
        }

        const flipAllFaces = visualData.planeOrientation !== 0;

        function generateBasisGeometry(bounds: any) {
            visualData.lineSegments = [];

            let inner = new CurveStraightLine(new Vector2(bounds.xMin, bounds.yMin), visualData.planeOrientation);
            inner.setEndPoint(new Vector2(bounds.xMax, bounds.yMin));

            let top = new CurveStraightLine(new Vector2(bounds.xMax, bounds.yMin), visualData.planeOrientation);
            top.setEndPoint(new Vector2(bounds.xMax, bounds.yMax));

            let outer = new CurveStraightLine(new Vector2(bounds.xMax, bounds.yMax), visualData.planeOrientation);
            outer.setEndPoint(new Vector2(bounds.xMin, bounds.yMax));

            let bottom = new CurveStraightLine(new Vector2(bounds.xMin, bounds.yMax), visualData.planeOrientation);
            bottom.setEndPoint(new Vector2(bounds.xMin, bounds.yMin));

            if (!flipAllFaces) {
                inner = new CurveStraightLine(new Vector2(bounds.xMax, bounds.yMin), visualData.planeOrientation);
                inner.setEndPoint(new Vector2(bounds.xMin, bounds.yMin));

                top = new CurveStraightLine(new Vector2(bounds.xMax, bounds.yMax), visualData.planeOrientation);
                top.setEndPoint(new Vector2(bounds.xMax, bounds.yMin));

                outer = new CurveStraightLine(new Vector2(bounds.xMin, bounds.yMax), visualData.planeOrientation);
                outer.setEndPoint(new Vector2(bounds.xMax, bounds.yMax));

                bottom = new CurveStraightLine(new Vector2(bounds.xMin, bounds.yMin), visualData.planeOrientation);
                bottom.setEndPoint(new Vector2(bounds.xMin, bounds.yMax));
            }

            visualData.lineSegments.push(inner);
            visualData.lineSegments.push(top);
            visualData.lineSegments.push(outer);
            visualData.lineSegments.push(bottom);
        }

        if (parameters.basicGeometry) {
            // basic geometry
            generateBasisGeometry(this.boundingShape);
        }

        function parametricFunctions(visualizationData: any) {
            const yAxisDirection = new Vector3();
            if (visualizationData.yAxis === 0) {
                yAxisDirection.set(1.0, 0.0, 0.0);
            } else if (visualizationData.yAxis === 1) {
                yAxisDirection.set(0.0, 1.0, 0.0);
            } else {
                yAxisDirection.set(0.0, 0.0, 1.0);
            }

            const fnc = [];

            for (let uu = 0; uu < visualizationData.lineSegments.length; uu += 1) {
                fnc.push({
                    func: function(u: number, v: number, vertex: Vector3) {
                        const curve = visualizationData.lineSegments[uu];
                        const rotAngle = (visualizationData.amount / 180.0) * Math.PI * v;
                        const pnt = curve.getCurvePoint3(1.0 - u);

                        if (visualizationData.rotate) {
                            // rotate
                            pnt.applyMatrix4(new Matrix4().makeRotationAxis(yAxisDirection, amountOffset + rotAngle));
                        } else {
                            // extrude
                            const translation = yAxisDirection.clone().multiplyScalar(amountOffset + visualizationData.amount * v);
                            pnt.applyMatrix4(new Matrix4().makeTranslation(translation.x, translation.y, translation.z));
                        }
                        vertex.x = pnt.x;
                        vertex.y = pnt.y;
                        vertex.z = pnt.z;
                    },
                    len: visualizationData.lineSegments[uu].getCurveLength(),
                });
            }
            return fnc;
        }

        const surfaces = parametricFunctions(visualData);

        // detailed geometry
        let profileLength = 0.0;
        for (let i = 0; i < surfaces.length; i += 1) {
            profileLength += surfaces[i].len;
        }

        let stacks = 1;
        if (visualData.rotate) {
            stacks = visualData.amount * parameters.pointsPerDeg;
        }

        if (!visualData.rotate || visualData.amount < 360.0 - EPS) {
            const shp = getShape(visualData);
            addShape(
                shp,
                12,
                flipAllFaces,
                visualData.rotate,
                parameters.customInitialAmount,
                visualData.planeOrientation,
                visualData.yAxis,
            );
            addShape(
                shp,
                12,
                !flipAllFaces,
                visualData.rotate,
                visualData.amount + parameters.customInitialAmount,
                visualData.planeOrientation,
                visualData.yAxis,
            );
        }

        for (let i = 0; i < surfaces.length; i += 1) {
            const slices = visualData.lineSegments[i].getNumberOfPoints(parameters.pointsPerUnit) - 1;
            generateParametricBufferGeometry(surfaces[i].func, slices, stacks, materialIndex);
            if (parameters.computeNormalMaps) {
                this.normalMaps[materialIndex] = generateNormalMap(surfaces[i].func, slices, stacks, 10);
            }
            materialIndex += 1;
        }

        this.setIndex(indices);
        this.setAttribute('position', new Float32BufferAttribute(vertices, 3));
        this.setAttribute('normal', new Float32BufferAttribute(normals, 3));
        this.setAttribute('uv', new Float32BufferAttribute(uvs, 2));

        this.numberOfMaterials = materialIndex;
    }
}

function createSoligGeometry(parameters: VisualizationDataParameters, visualData: SolidInterface): VisualizationDataBufferGeometry {
    const geometry = new VisualizationDataBufferGeometry(parameters, visualData);
    const coors = visualData.coordinates;
    const euler = new Euler(coors.eps_x, coors.eps_y, coors.eps_z, 'XYZ');
    const mat = new Matrix4().makeRotationFromEuler(euler).transpose();
    geometry.applyMatrix4(mat);

    geometry.translate(coors.x, coors.y, coors.z);
    return geometry;
}

export function getVisualizationParameters(
    settings: View3DSettings,
    startFromOrigin: boolean,
    visualizationData: string,
): VisualizationDataParameters {
    const pointsPerDeg = 15.0 / 90.0;
    const customAmount = 360 - (startFromOrigin ? 0 : getOpeningAngle(settings.clippingType));
    const customInitialAmount = startFromOrigin ? 0 : getStartAngle(settings);
    const pointsPerUnit = 2;

    return {
        visualizationData,
        pointsPerDeg: pointsPerDeg,
        pointsPerUnit: pointsPerUnit,
        customAmount: customAmount,
        customInitialAmount: customInitialAmount,
        useCustomAmount: true,
        basicGeometry: false,
        computeNormalMaps: false,
    };
}

export function createVisualizationGeometry(parameters: VisualizationDataParameters): BufferGeometry | null {
    const visualDataArr = parseVisualizationData(parameters);
    if (visualDataArr.length === 0) {
        return null;
    }
    const geometries = visualDataArr.map(visualData => createSoligGeometry(parameters, visualData));
    return BufferGeometryUtils.mergeBufferGeometries(geometries);
}

export function createPlaceholderVizStringData(innerDiameter: number, outerDiameter: number, width: number, unitSet: number): string {
    const b05 = Math.max(width * 0.5, MIN_WIDTH_PLACEHOLDER_RING / (unitSet * 2));
    const ri = Math.max(innerDiameter * 0.5, 2 / unitSet);
    const ra = Math.max(outerDiameter * 0.5, MIN_OUTER_RADIUS_PLACEHOLDER_RING / unitSet);
    const r = ri * 0.1;

    return (
        '1 0 0 0 0 0 0 0 1 1 0 0 360 1 0 0 0 1 0 0 6 ' +
        '1 ' +
        (b05 - r) +
        ' ' +
        ra +
        ' ' +
        r +
        ' ' + //  => b/2 -r | outerDiameter
        '0 ' +
        b05 +
        ' ' +
        (ra - r) +
        ' ' + //  => b/2 / outerDiameter -r
        '0 ' +
        b05 +
        ' ' +
        ri +
        ' ' + //  => b/2 / innerDiameter
        '0 ' +
        -b05 +
        ' ' +
        ri +
        ' ' + //  => -b/2 / innerDiameter
        '1 ' +
        -b05 +
        ' ' +
        (ra - r) +
        ' ' +
        r +
        ' ' + //  => -b/2 / outerDiameter -r
        '0 ' +
        (-b05 + r) +
        ' ' +
        ra
    ); //  => -b/2 / outerDiameter -r
}

import { NgModule } from '@angular/core';
import { ClickOutsideDirective } from './click-outside.directive';
import { ExpandPipe } from './expand.pipe';
import { ElementResizedDirective } from './element-resized.directive';
import { SortPipe } from './sort.pipe';

@NgModule({
    declarations: [SortPipe, ExpandPipe, ClickOutsideDirective, ElementResizedDirective],
    exports: [SortPipe, ExpandPipe, ClickOutsideDirective, ElementResizedDirective],
})
export class UtilModule {}

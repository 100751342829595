import { InternalSpurGear } from '../../cae-model/transmission-elements/internal-spur-gear';
import { SpurGear } from '../../cae-model/transmission-elements/spur-gear';

export abstract class SpurGearBaseGeometry {
    protected abstract setGeometry(): void;

    protected _tB = 0; // SpurWidth
    protected _tDM = 0; // workingPitchDiameter
    protected _tDO = 0; // outer diameter
    protected _tDI = 0; // inner diameter
    protected _tTooth = 0; // count of tooths in 2D
    protected _tBore = 0; // hole (eq innerDiameter if it's not zero)
    protected _isOnShaft = false;

    public get tB(): number {
        return this._tB;
    }

    public get tDM(): number {
        return this._tDM;
    }

    public get tDO(): number {
        return this._tDO;
    }

    public get tDI(): number {
        return this._tDI;
    }

    public get tTooth(): number {
        return this._tTooth;
    }

    public get tBore(): number {
        return this._tBore;
    }

    public get isOnShaft(): boolean {
        return this._isOnShaft;
    }

    constructor(protected spurGear: SpurGear | InternalSpurGear, protected unitScale: number) {
        this.setGeometry();
    }
}

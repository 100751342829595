<i *ngIf="notification.icon" class="material-icons" [ngClass]="notification.icon?.color">{{ notification.icon?.icon }}</i>
<div class="name">
    {{ notification.caption | translate }}
    <div class="progress" *ngIf="notification.progress && !notification.progress.done">
        {{ pc(notification.progress) }}
    </div>
</div>
<button class="simple creative" *ngFor="let action of notification.actions" [ngClass]="action.color" (click)="onAction(action)">
    {{ action.label | translate }}
</button>

import { Bearing } from '../../../../cae-model/supports/bearings/bearing';
import { ModelElement } from '../../../../cae-model/model-element';
import { BearinxStandardBinder } from '../../../binders/bearinx-standard-binder';
import { SupportMediator } from '../support-mediator';
import { IDO_BEARING } from '../../../bearinx-ids';

// mediator, used for the convergence of bearings (analog to BearingData in bearinx-online)
export class BearingMediator extends SupportMediator {
    constructor() {
        super();
        this._createBearingBinder();
    }

    get bearinxObjectTypes(): string[] {
        return [IDO_BEARING];
    }

    protected createModelElement(): ModelElement {
        return new Bearing();
    }

    private _createBearingBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [
            { left: 'outerDiameter', right: 'IDSU_DA' },
            { left: 'innerDiameter', right: 'IDSU_DI' },
            { left: 'width', right: 'IDSU_B' },

            { left: 'constructionType', right: 'IDL_CONSTRUCTIONTYPE' },
            { left: 'sAxPos', right: 'IDL_AXIAL_POSITIVE_INSTALLATION_CODE' },
            { left: 'sAxNeg', right: 'IDL_AXIAL_NEGATIVE_INSTALLATION_CODE' },
            { left: 'sRad', right: 'IDL_RADIAL_INSTALLATION_CODE_ELASTIC' },
            { left: 'sRot', right: 'IDL_ROTATIVE_INSTALLATION_CODE' },
            { left: 'iDir', right: 'IDL_INSTALLATION_DIRECTION' },
        ];
        this.addBinder(binder);
    }

    // overrides basic function
    protected doConvert() {
        const bearing = this.modelElement as Bearing;
        bearing.bearinxType = this.bearinxModelObject.type;
    }
}

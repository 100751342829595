import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TitleBarModule } from '../title-bar/title-bar.module';
import { PromptContainerComponent } from './prompt-container/prompt-container.component';
import { TextPrompt } from './text.prompt';

@NgModule({
    imports: [CommonModule, TitleBarModule, TranslateModule],
    exports: [PromptContainerComponent],
    declarations: [PromptContainerComponent, TextPrompt],
})
export class PromptModule {}

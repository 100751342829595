import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TenantService } from '../tenant.service';

@Component({
    selector: 'bx-tenant-selection',
    templateUrl: 'tenant-selection.component.html',
    styleUrls: ['tenant-selection.component.scss'],
})
export class TenantSelectionComponent {
    constructor(public readonly tenantService: TenantService, private readonly _router: Router) {}

    public goHomeScreen() {
        this._router.navigate(['/']);
    }
}

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import { AuthConfig } from 'angular-oauth2-oidc';

export const environment = {
    production: false,
    baseUrl: 'https://bearinx-d.schaeffler.com/api/',
    apiVersion: 'v1.3/',
    helpBaseUrl: 'https://bearinx-d.schaeffler.com/help/',
    settingsUrl: 'https://bearinx-d.schaeffler.com/settings/v1/appsettings',
    calculation: {
        logPollingInterval: 1000,
    },
    oauth: {
        issuer: 'https://bearinx-d.schaeffler.com/auth/realms/caeonline-d',
        redirectUri: `${window.location.origin}${window.location.pathname}`,
        sessionChecksEnabled: true,
        clientId: 'bearinx.dev.spa',
        scope: 'openid profile bearinx',
        responseType: 'code',
    } as AuthConfig,
    medias: {
        baseUrl: 'https://medias.schaeffler.de/link/search/searchpage',
        searchParam: 'text',
        params: {},
    },
    tutorials: {
        baseUrl: 'https://bearinx-d.schaeffler.com/tutorials/Bearinx%20Medias%20Subtitles%20v2.mp4',
        calculationFundamentals: 'https://bearinx-d.schaeffler.com/tutorials/Correns_Bearing_fatigue_life.mp4',
    },
    manual: {
        english: 'https://bearinx-d.schaeffler.com/tutorials/Bearinx-Manual.pdf',
        german: 'https://bearinx-d.schaeffler.com/tutorials/Bearinx-Handbuch.pdf',
    },
    news: {
        english: 'https://bearinx-d.schaeffler.com/tutorials/Bearinx-Online_Version_Info_en.pdf',
        german: 'https://bearinx-d.schaeffler.com/tutorials/Bearinx-Online_Version_Info_de.pdf',
    },
};

/**
 * ModelElement
 */

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CoordinatesInterface } from './interfaces/coordinates-interface';

type CoordinatesAbsFunc = () => CoordinatesInterface;

export abstract class ModelElement {
    private _children: ModelElement[];
    private _coordinatesRel: CoordinatesInterface;
    private _updateSubj = new BehaviorSubject<boolean>(false);
    private _coordinatesParentAbsFunc: null | CoordinatesAbsFunc;
    private readonly _destroy$ = new Subject<void>();

    name = '';
    id = '';

    constructor() {
        this._children = [];
        this._coordinatesRel = {
            x: 0,
            y: 0,
            z: 0,
            eps_x: 0,
            eps_y: 0,
            eps_z: 0,
        };
        this._coordinatesParentAbsFunc = null;
    }

    get coordinatesAbs(): CoordinatesInterface {
        if (this._coordinatesParentAbsFunc == null) {
            return this.coordinatesRel;
        }
        const coorParentAbs = this._coordinatesParentAbsFunc();
        if (coorParentAbs.eps_x !== 0 || coorParentAbs.eps_y !== 0 || coorParentAbs.eps_z !== 0) {
            throw new Error('ModelElement  coordinatesAbs  with non-zero rotational coordinates is not implemented yet');
        }
        return {
            x: coorParentAbs.x + this._coordinatesRel.x,
            y: coorParentAbs.y + this._coordinatesRel.y,
            z: coorParentAbs.z + this._coordinatesRel.z,
            eps_x: 0,
            eps_y: 0,
            eps_z: 0,
        };
    }

    get children(): ModelElement[] {
        return this._children;
    }

    set coordinatesParentAbsFunc(newValue: CoordinatesAbsFunc | null) {
        this._coordinatesParentAbsFunc = newValue;
    }

    get coordinatesRel(): CoordinatesInterface {
        return this._coordinatesRel;
    }

    set coordinatesRel(newval: CoordinatesInterface) {
        this._coordinatesRel = newval;
    }

    get x(): number {
        return this._coordinatesRel.x;
    }

    set x(xNew: number) {
        this._coordinatesRel.x = xNew;
    }

    get y(): number {
        return this._coordinatesRel.y;
    }

    set y(yNew: number) {
        this._coordinatesRel.y = yNew;
    }

    get z(): number {
        return this._coordinatesRel.z;
    }

    set z(zNew: number) {
        this._coordinatesRel.z = zNew;
    }

    add(child: ModelElement) {
        if (child == null) {
            return;
        }
        this._children.push(child);
        const coorAbsFunc = () => this.coordinatesAbs;
        child.coordinatesParentAbsFunc = coorAbsFunc;
        this.doAdd(child);
    }

    update() {
        this._updateSubj.next(true);
    }

    isUpdated(): Observable<boolean> {
        return this._updateSubj.pipe(takeUntil(this._destroy$));
    }

    protected doAdd(_child: ModelElement) {}

    onDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
        this.coordinatesParentAbsFunc = null;
        this.children.forEach((child: ModelElement) => child.onDestroy());
    }
}

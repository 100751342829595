import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class BearinxModelProviderService {
    private _subject = new BehaviorSubject<any>(null);
    constructor() {}

    getBearinxModel(): Observable<any> {
        return this._subject;
    }

    set bearinxModel(value: any) {
        this._subject.next(value);
    }
}

import { InternalSpurGear } from './../../cae-model/transmission-elements/internal-spur-gear';
import { SpurGearView3D } from './../elements-view/transmission-elements/spur-gear-view-3d';
import { SpurGear } from './../../cae-model/transmission-elements/spur-gear';
import { BearingRowView3D } from './../elements-view/bearing-parts/bearing-row-view-3d';
import { BearingRow } from './../../cae-model/bearing-elements/bearing-row';
import { RollingElementView3D } from './../elements-view/bearing-parts/rolling-element-view-3d';
import { RollingElement } from './../../cae-model/bearing-elements/rolling-element';
import { RingView3D } from '../elements-view/bearing-parts/ring-view-3d';
import { Ring } from '../../cae-model/bearing-elements/ring';
import { BearingView3D } from '../elements-view/supports/bearing-view-3d';
import { Bearing } from './../../cae-model/supports/bearings/bearing';
import { PointMassView3D } from './../elements-view/loads/point-mass-view-3d';
import { PointMass } from './../../cae-model/loads/point-mass';
import { DistributedLoadView3D } from './../elements-view/loads/distributed-load-view-3d';
import { DistributedLoad } from './../../cae-model/loads/distributed-load';
import { Injectable, OnDestroy } from '@angular/core';
import { ShaftView3D } from '../elements-view/shaft-view-3d';
import { View3DService } from './view-3d.service';
import { ModelElement } from '../../cae-model/model-element';
import { CAEModel } from '../../cae-model/cae-model';
import { Shaft } from '../../cae-model/shaft';
import { ElementView3D } from '../elements-view/element-view-3d';
import { PipeSegmentView3D } from '../elements-view/shaft-segments/pipe-segment-view-3d';
import { PipeSegment } from '../../cae-model/shaft-segments/pipe-segment';
import { ElementView3DInput, View3DSettings } from '../settings';
import { PointLoad } from '../../cae-model/loads/point-load';
import { PointLoadView3D } from '../elements-view/loads/point-load-view-3d';
import { ConeSegment } from '../../cae-model/shaft-segments/cone-segment';
import { ConeSegmentView3D } from '../elements-view/shaft-segments/cone-segment-view-3d';
import { DefaultElementView3D } from '../elements-view/default-element-view-3d';
import { SupportGeometryFactory, SupportGeometryFactoryInput } from '../../views-foundation/services/support-geometry-factory';
import { ModelElementFinder } from '../../views-foundation/services/cae-element-finder.service';
import { SupportView3D } from '../elements-view/supports/support-view-3d';
import { Support } from '../../cae-model/supports/support';
import { ModelElementFinder3DService } from './model-element-finder-3d.service';
import { SelectedInViewerElementInterface } from '../../views-foundation/interfaces/selected-in-viewer-element-interface';
import { SelectedElementService } from '../../views-foundation/services/selected-element.service';
import { SelectedElementInterface } from '../../views-foundation/interfaces/selected-element-interface';
import { SELECTION_BOX_ROOT_OBJECT_NAME, SHAFT_ROOT_OBJECT_NAME } from '../settings/view-3d-constants';
import { ShaftSystem } from '../../cae-model/shaft-system';
import { ShaftSystemView3D } from '../elements-view/shaft-system-view-3d';
import { InternalSpurGearView3D } from '../elements-view/transmission-elements/internal-spur-gear-view-3d';
import { calculateMaxDimension } from '../functions/utils-3d';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SlidingBearingView3D } from '../elements-view/supports/sliding-bearing/sliding-bearing-view-3d';
import { SlidingBearing } from '../../cae-model/supports/sliding-bearings/sliding-bearing';
import { Spring } from '../../cae-model/supports/spring';
import { RigidSupport } from '../../cae-model/supports/rigid-support';
import { BevelGear } from '../../cae-model/transmission-elements/bevel-gear';
import { WormGear } from '../../cae-model/transmission-elements/worm-gear';
import { InternalBevelGear } from '../../cae-model/transmission-elements/internal-bevel-gear';
import { SpringView3D } from '../elements-view/supports/spring-view-3d';
import { RigidSupportView3D } from '../elements-view/supports/rigid-support-view-3d';
import { BevelGearView3D } from '../elements-view/transmission-elements/bevel-gear-view-3d';
import { WormGearView3D } from '../elements-view/transmission-elements/worm-gear-view-3d';
import { InternalBevelGearView3D } from '../elements-view/transmission-elements/internal-bevel-gear-view-3d';
import { CounterMoment } from '../../cae-model/transmission-elements/counter-moment';
import { CounterMomentView3D } from '../elements-view/transmission-elements/counter-moment-view-3d';
import { Worm } from '../../cae-model/transmission-elements/worm';
import { WormView3D } from '../elements-view/transmission-elements/worm-view-3d';
import { GearAbstractView3D } from '../elements-view/transmission-elements/gear-abstract-view-3d';
import { GearAbstract } from '../../cae-model/transmission-elements/gear-abstract';
import { SupportViewInput } from '../../views-foundation/interfaces/support-view-input-interface';

@Injectable()
export class View3DFactoryService implements OnDestroy {
    private _settings: View3DSettings;
    private _maxDimension: number;
    private _clearViews$ = new Subject<void>();

    constructor(
        private _view3dService: View3DService,
        private _modelElementFinderService: ModelElementFinder3DService,
        private _modelElementFinder: ModelElementFinder,
        private _supportGeometryFactory: SupportGeometryFactory,
        private _selectedElementService: SelectedElementService,
    ) {}

    ngOnDestroy(): void {
        this._clearViews$.next();
        this._modelElementFinderService.ngOnDestroy();
        this._view3dService.destroy();
    }

    generateModelView(model: CAEModel, settings: View3DSettings): void {
        this._settings = settings;
        this._clearViews$.next();
        this._modelElementFinderService.clearViews();
        if (model !== null) {
            const modelView = this._createElementAndChildrenView(model);
            this._createGroups(modelView);
            modelView.groupWithChildren.name = SHAFT_ROOT_OBJECT_NAME;
            modelView.selectionBoxGroup.name = SELECTION_BOX_ROOT_OBJECT_NAME;
            this._maxDimension = calculateMaxDimension(modelView.groupWithChildren);
            this._view3dService.updateModelGroup(modelView.groupWithChildren);
            this._view3dService.updateSelectionBoxGroup(modelView.selectionBoxGroup);
        }
        const views = this._modelElementFinderService.views;
        views.forEach(view => {
            view.updateWhenAllViewsGenerated();
        });
    }

    private _createElementAndChildrenView(elementModel: ModelElement): ElementView3D {
        const elementView = this._createView(elementModel);
        elementView.setSelectableProperty();
        if (elementView instanceof SupportView3D) {
            this._setSupportViewInput(elementView);
        }
        const elementChildren = elementModel.children;
        elementChildren.forEach(modelElement => {
            const childView = this._createElementAndChildrenView(modelElement);
            elementView.add(childView);
        });
        return elementView;
    }

    // recursively call createGroupWithChildren for ElementView3D objects
    private _createGroups(parentView: ElementView3D) {
        parentView.createGroupWithChildren(this._getElementView3DInput());
        const children = parentView.children;
        children.forEach(childView => {
            this._createGroups(childView);
            parentView.groupWithChildren.add(childView.groupWithChildren);
            parentView.selectionBoxGroup.add(childView.selectionBoxGroup);
        });
    }

    private _setSupportViewInput(supportView: SupportView3D) {
        const factoryInput: SupportGeometryFactoryInput = {
            findModelElement: (elementId: string) => this._modelElementFinder.findElement(elementId),
        };
        const createGeometryFunc = (support: Support, unitScale: number) =>
            this._supportGeometryFactory.createSupportGeometry(factoryInput, support, unitScale);

        const supportViewInput: SupportViewInput = {
            createGeometry: (support: Support, unitScale: number) => createGeometryFunc(support, unitScale),
        };
        supportView.supportViewInput = supportViewInput;
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    private _createView(modelElement: ModelElement): ElementView3D {
        let view: ElementView3D;
        if (modelElement instanceof ShaftSystem) {
            view = new ShaftSystemView3D(modelElement);
        } else if (modelElement instanceof Shaft) {
            view = new ShaftView3D(modelElement);
        } else if (modelElement instanceof PipeSegment) {
            view = new PipeSegmentView3D(modelElement);
        } else if (modelElement instanceof PointLoad) {
            view = new PointLoadView3D(modelElement);
        } else if (modelElement instanceof PointMass) {
            view = new PointMassView3D(modelElement);
        } else if (modelElement instanceof DistributedLoad) {
            view = new DistributedLoadView3D(modelElement);
        } else if (modelElement instanceof Bearing) {
            view = new BearingView3D(modelElement);
        } else if (modelElement instanceof BearingRow) {
            view = new BearingRowView3D(modelElement);
        } else if (modelElement instanceof Ring) {
            view = new RingView3D(modelElement);
        } else if (modelElement instanceof RollingElement) {
            view = new RollingElementView3D(modelElement);
        } else if (modelElement instanceof ConeSegment) {
            view = new ConeSegmentView3D(modelElement);

            // transimssion elements
        } else if (modelElement instanceof SpurGear) {
            view = new SpurGearView3D(modelElement);
        } else if (modelElement instanceof InternalSpurGear) {
            view = new InternalSpurGearView3D(modelElement);

            // plain bearings
        } else if (modelElement instanceof SlidingBearing) {
            view = new SlidingBearingView3D(modelElement);

            // placeholder
        } else if (modelElement instanceof Spring) {
            view = new SpringView3D(modelElement);
        } else if (modelElement instanceof RigidSupport) {
            view = new RigidSupportView3D(modelElement);
        } else if (modelElement instanceof BevelGear) {
            view = new BevelGearView3D(modelElement);
        } else if (modelElement instanceof InternalBevelGear) {
            view = new InternalBevelGearView3D(modelElement);
        } else if (modelElement instanceof Worm) {
            view = new WormView3D(modelElement);
        } else if (modelElement instanceof WormGear) {
            view = new WormGearView3D(modelElement);
        } else if (modelElement instanceof CounterMoment) {
            view = new CounterMomentView3D(modelElement);
        } else if (modelElement instanceof GearAbstract) {
            view = new GearAbstractView3D(modelElement);
        } else {
            view = new DefaultElementView3D(modelElement);
        }
        view.setElementUpdater();
        this._modelElementFinderService.addView(modelElement, view);
        return view;
    }

    private _getElementView3DInput(): ElementView3DInput {
        return {
            settings: this._settings,
            setSelected: (selectedElementInterface: SelectedInViewerElementInterface) =>
                this._selectedElementService.setSelected(selectedElementInterface),
            getSelectedElement: () => this._selectedElementService.getSelectedElement().pipe(takeUntil(this._clearViews$)),
            setPreselected: (selectedElementInterface: SelectedElementInterface) =>
                this._selectedElementService.setPreselected(selectedElementInterface),
            getPreselectedElement: () => this._selectedElementService.getPreselectedElement().pipe(takeUntil(this._clearViews$)),
            getModelMaxDimension: () => this._maxDimension,
        };
    }
}

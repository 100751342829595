import Konva from 'konva';
import { generateUpperAndLowerParts } from '../../../functions/utils-2d';
import { InternalSpurGear } from '../../../../cae-model/transmission-elements/internal-spur-gear';
import { DISPLAYGREEN, DISPLAYRED, STROKE_WIDTH, BACKGROUND_COLOR_2D } from '../../view-2d-constants';
import { SpurGearBaseView2D } from '../spur-gear-base-view-2d';
import { InternalSpurGearGeometry } from '../../../../views-foundation/geometries/internal-spur-gear-geometry';

export class InternalSpurGearView2D extends SpurGearBaseView2D {
    constructor(private _internalSpurGear: InternalSpurGear) {
        super(_internalSpurGear);
    }

    protected updateSpurGear(group: Konva.Group): void {
        const { tB, tDI, tBore, tTooth, isOnShaft } = this.geometry;
        const leftX = -tB / 2.0;
        const color = isOnShaft === true ? DISPLAYGREEN : DISPLAYRED;

        group.add(
            new Konva.Rect({
                x: leftX,
                y: -tBore / 2.0,
                width: tB,
                height: tBore,
                stroke: color,
                strokeWidth: STROKE_WIDTH,
                strokeScaleEnabled: false,
                fill: BACKGROUND_COLOR_2D,
                name: 'internal-spur-gear-clickable-area',
            }),
        );

        const line = new Konva.Line({
            points: [leftX, -tDI! / 2.0, leftX + tB, -tDI! / 2.0],
            stroke: color,
            strokeWidth: STROKE_WIDTH,
            strokeScaleEnabled: false,
        });

        group.add(generateUpperAndLowerParts(line, 'internal-spur-gear-line'));

        const scale = this.input.unitScaleFactor;
        if (tTooth > 1 / scale) {
            const x = tB / 2.0 + 3.0 / scale;
            const y = -tBore! / 2.0;
            group.add(super.createText(`${tTooth}`, color, x, y));
        }
    }

    protected setGeometry(): void {
        this.geometry = new InternalSpurGearGeometry(this._internalSpurGear, this.input.unitScaleFactor);
    }
}

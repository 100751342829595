import Konva from 'konva';
import { RectangularGroove } from '../../../cae-model/notches/rectangular-groove';
import { TINY_RADIUS } from '../../../views-foundation/views-foundation-constants';
import { generateUpperAndLowerParts } from '../../functions/utils-2d';
import { BACKGROUND_COLOR_2D, DISPLAYRED, STROKE_WIDTH } from '../view-2d-constants';
import { NotchView2D } from './notch-view-2d';

export class RectangularGrooveView2D extends NotchView2D {
    constructor(rectangularGroove: RectangularGroove) {
        super(rectangularGroove);
    }

    private get _rectangularGroove(): RectangularGroove {
        return this.modelElement as RectangularGroove;
    }

    protected updateGroupWithoutChildren(): void {
        super.rerenderViewWithoutChildren('rectangular-groove-view-2d-group');
    }

    protected createNotchView(): Konva.Group {
        const group = new Konva.Group();
        const { xInShaftCS, depth, width, radius } = this._rectangularGroove;
        const innerRadius = this._rectangularGroove.getCurrentInnerRadius(xInShaftCS);
        const scale = this.input.unitScaleFactor;

        const rect = new Konva.Rect({
            x: 0,
            y: -this._rectangularGroove.getCurrentOuterRadius(xInShaftCS),
            width: width,
            height: depth,
            stroke: DISPLAYRED,
            strokeWidth: STROKE_WIDTH,
            strokeScaleEnabled: false,
            fill: BACKGROUND_COLOR_2D,
            cornerRadius: [0, 0, radius, radius],
        });
        group.add(generateUpperAndLowerParts(rect, 'rectangular-notch'));

        const line = new Konva.Line({
            points: [STROKE_WIDTH / scale, -this.outerRadius, width - STROKE_WIDTH / scale, -this.outerRadius],
            stroke: BACKGROUND_COLOR_2D,
            strokeWidth: STROKE_WIDTH,
            strokeScaleEnabled: false,
        });
        group.add(generateUpperAndLowerParts(line, 'blur-line'));

        if (innerRadius < TINY_RADIUS / scale) {
            const startLine = new Konva.Line({
                points: [0, -this.outerRadius, 0, this.outerRadius],
                stroke: DISPLAYRED,
                strokeWidth: STROKE_WIDTH,
                strokeScaleEnabled: false,
                name: 'inner-notch-line-start',
            });
            group.add(startLine);

            const endLine = startLine.clone({
                name: 'inner-notch-line-end',
                x: width,
                scaleX: -1,
            });
            group.add(endLine);
        }

        return group;
    }
}

// This file is a copy of gear.buffer.geometry.ts from geometries repository.

import { BufferGeometry, Float32BufferAttribute, Vector2, Vector3 } from 'three';
import {
    DataOfGear,
    GenerateFaceInput,
    VIdxInterface,
    FacePropertiesInput,
    FaceIndicesInput,
    SegmentData,
    GearTorsoInput,
    ExtrudeToothProfileInput,
    CuttingPlaneInput,
    CuttingPlaneType,
    CuttingPlanesInput,
    GenerateFacesInput,
    ToothWidthInterface,
    VType,
    ToothProfileInterface,
} from '../interfaces/create-gear-geometrys-interface';
import { GearParameters } from '../interfaces/gear.parameters';
import { ClippingType } from '../settings';
import { RING_GEAR_BUFFER_GEOMETRY, TB_TINY } from '../settings/view-3d-constants';
import { ClippingPlaneType } from '../settings/view-3d-settings';

function inv(alpha: number): number {
    return Math.tan(alpha) - alpha;
}

function computeModule(mn: number, beta: number): number {
    return mn / Math.cos(beta);
}

function computeTipDiameter(z: number, mn: number, beta: number, x: number, k: number): number {
    const hap: number = mn * 1.0;
    return z * computeModule(mn, beta) + 2 * x * mn + 2 * hap + 2 * k;
}

function computeAngle(diameter: number, s: number): number {
    return Math.asin(s / diameter);
}

function checkValue(def: number): number {
    if (def > 1) {
        def = 1;
    } else if (def < -1) {
        def = -1;
    } else if (Math.abs(def) < TB_TINY) {
        def = 0;
    }
    return def;
}

function evaluateToothWidth(toothWidthInterface: ToothWidthInterface): number {
    const { dy, s, d, alpha, betaRad } = toothWidthInterface;
    const alphaY: number = Math.acos(checkValue((d / dy) * Math.cos(alpha)));

    /* evaluate tooth width */
    const syn: number = dy * (s / d + inv(alpha) - inv(alphaY));
    return syn / Math.cos(betaRad);
}

function computeRootDiameter(z: number, mn: number, beta: number, x: number): number {
    const hfp: number = mn;
    const c: number = 0.25 * mn;
    return z * computeModule(mn, beta) + 2 * x * mn - 2 * hfp - 2 * c;
}

function calculateUvScale(da: number, halfToothProfile: number[][]): number {
    let uvScale: number = 1 / (halfToothProfile[0][1] + da / 2);
    for (let i = 0; i < halfToothProfile.length; i++) {
        uvScale = Math.min(uvScale, 1 / (halfToothProfile[i][1] + da / 2));
    }
    return uvScale;
}

function calculateRotX(faceWidth: number, helixAngleReferenceDiameter: number, da: number): number {
    return ((faceWidth / Math.sin(Math.PI * 0.5 - helixAngleReferenceDiameter)) * Math.sin(helixAngleReferenceDiameter)) / (da * 0.5);
}

function initDataOfGear(parameters: GearParameters): DataOfGear {
    let slices = 5;

    if (parameters.helixAngleReferenceDiameter === 0) {
        slices = 2;
    }

    const isRingGear = parameters.isRingGear;

    const openingAngleRad: number = (parameters.openingAngle / 180.0) * Math.PI;

    const nPoints = 4;
    const x = 0;
    const alpha: number = (20 * Math.PI) / 180;

    const dk: number = computeTipDiameter(parameters.numberOfTeeth, parameters.normalModule, parameters.helixAngleReferenceDiameter, x, 0);

    const df: number = computeRootDiameter(parameters.numberOfTeeth, parameters.normalModule, parameters.helixAngleReferenceDiameter, x);

    /* pitch diameter */
    const dt: number = (parameters.numberOfTeeth * parameters.normalModule) / Math.cos(parameters.helixAngleReferenceDiameter);
    const s: number = parameters.normalModule * (Math.PI / 2 + 2 * x * Math.tan(alpha));

    const da: number = df;
    const halfToothProfile: Array<Array<number>> = [];

    const vStart = 0.1;
    const vEnd = 0.3;

    return { slices, isRingGear, openingAngleRad, nPoints, x, alpha, dk, df, dt, s, da, halfToothProfile, vStart, vEnd };
}

function calculateHalfToothProfile(vType: VType, toothProfileInterface: ToothProfileInterface): void {
    const {
        isRingGear,
        nPoints,
        df,
        dk,
        vStart,
        vEnd,
        s,
        dt,
        alpha,
        helixAngleReferenceDiameter,
        numberOfTeeth,
        halfToothProfile,
    } = toothProfileInterface;
    const isVStart = vType === VType.vStart;
    let diameter = isVStart ? df : dk;
    if (isRingGear) {
        diameter = isVStart ? dk : df;
    }
    const evaluateDiameter = isRingGear ? dk : df;
    const reverse = isVStart ? 1 : -1;

    for (let i = 0; i < nPoints; i++) {
        const d: number = diameter + (i / (nPoints - 1)) * (isRingGear ? -reverse : reverse) * (dk - df);
        const t: number = isRingGear ? (d * Math.PI) / numberOfTeeth : 0;
        const sd: number = evaluateToothWidth({ dy: d, s, d: dt, alpha, betaRad: helixAngleReferenceDiameter });
        const vGap: number = ((i * (vEnd - vStart)) / (nPoints - 1)) * reverse;
        const v: number = (isVStart ? vStart : vEnd) + vGap;
        const calculateS = isRingGear ? t - sd : sd;
        halfToothProfile.push([computeAngle(d, calculateS) * reverse, d / 2 - evaluateDiameter / 2, v]);
    }
}

function initToothProfile(toothProfileInterface: ToothProfileInterface): void {
    const { isRingGear, df, dk, vEnd, halfToothProfile } = toothProfileInterface;

    calculateHalfToothProfile(VType.vStart, toothProfileInterface);
    const radius = isRingGear ? df / 2 - dk / 2 : dk / 2 - df / 2;
    halfToothProfile.push([0.0, radius, vEnd + 0.1]);

    calculateHalfToothProfile(VType.vEnd, toothProfileInterface);
}

export class GearBufferGeometry extends BufferGeometry {
    private vertices: Array<number> = [];
    private thetaLength: number = (360.0 / 180.0) * Math.PI;
    private uvs: Array<number> = [];
    private indices: Array<number> = [];
    private extrudeVertices: Array<Array<number>> = [];
    private groupStart = 0;
    private _index = 0;

    constructor(public parameters: GearParameters) {
        super();
        this.type = RING_GEAR_BUFFER_GEOMETRY;

        const { slices, isRingGear, openingAngleRad, nPoints, alpha, dk, df, dt, s, da, halfToothProfile, vStart, vEnd } = initDataOfGear(
            parameters,
        );

        initToothProfile({
            isRingGear,
            nPoints,
            df,
            dk,
            vStart,
            vEnd,
            s,
            dt,
            alpha,
            halfToothProfile,
            helixAngleReferenceDiameter: parameters.helixAngleReferenceDiameter,
            numberOfTeeth: parameters.numberOfTeeth,
        });

        const di = isRingGear ? Math.max(parameters.diameter, da * 1.01) : parameters.diameter;
        const uvScale: number = calculateUvScale(da, halfToothProfile);
        const rotX: number = calculateRotX(parameters.faceWidth, parameters.helixAngleReferenceDiameter, da);

        this._generateFaces({
            innerRadius: di / 2,
            outerRadius: da / 2,
            uvScale,
            halfToothProfile,
            rotX,
            offsetDiameter: parameters.offsetDiameter,
        });

        /* generate inner torso */
        this._generateGearTorso({
            radiusBottom: di / 2,
            radiusTop: di / 2,
            helixAngle: parameters.helixAngleReferenceDiameter,
            vTextureStart: 0,
            vTextureEnd: isRingGear ? 0.1 : 1,
            materialIndex: 3,
            rotation: rotX,
            slices,
        });

        this._extrudeToothProfile({
            profiles: this.extrudeVertices,
            width: parameters.faceWidth,
            rotation: rotX,
            steps: this.extrudeVertices.length,
            materialIndex: 0,
            halfToothProfile,
        });

        this._generateCuttingPlanes({
            clippingType: parameters.clippingType,
            di,
            da,
            offsetDiameter: parameters.offsetDiameter,
            width: parameters.faceWidth,
            openingAngleRad,
            clippingPlaneType: parameters.clippingPlaneType,
            flipClippingPlane: parameters.flipClippingPlane,
        });

        this.setIndex(this.indices);
        this.setAttribute('position', new Float32BufferAttribute(this.vertices, 3));
        this.computeVertexNormals();
    }

    private get _startAngleRad(): number {
        return ((this.parameters.startAngle + 90) / 180.0) * Math.PI;
    }

    private _generateFaces(generateFacesInput: GenerateFacesInput): void {
        const { innerRadius, outerRadius, uvScale, halfToothProfile, rotX, offsetDiameter } = generateFacesInput;

        [
            { top: false, outerRadius, rotation: 0 },
            { top: true, outerRadius: outerRadius - (offsetDiameter != null ? offsetDiameter / 2 : 0), rotation: rotX },
        ].forEach(({ top, outerRadius, rotation }) => {
            this._generateFace({
                top,
                innerRadius,
                outerRadius,
                materialIndex: 2,
                rotation,
                uvScale,
                halfToothProfile,
            });
        });
    }

    private _generateFace(faceInput: GenerateFaceInput): void {
        const { top, innerRadius, outerRadius, materialIndex, rotation, uvScale, halfToothProfile } = faceInput;
        const parameters = this.parameters;
        const vertices = this.vertices;
        const thetaSegments: number = parameters.numberOfTeeth * 2;
        const phiSegments = 1;
        const radiusStep: number = (outerRadius - innerRadius) / phiSegments;
        const vertex: Vector3 = new Vector3();
        const uv: Vector2 = new Vector2();

        const idxOffset: number = vertices.length / 3;
        let groupCount = 0;
        const verticesIdx: Array<number> = [];

        /* generate vertices and uvs */
        this._generateFaceProperties({
            top,
            phiSegments,
            thetaSegments,
            rotation,
            faceWidth: parameters.faceWidth,
            vertex,
            radius: innerRadius,
            uv,
            verticesIdx,
            uvScale,
            vertices,
            radiusStep,
        });

        /* indices */
        this._generateFaceIndices({ top, phiSegments, thetaSegments, idxOffset, groupCount });

        const verticesArray: Array<number> = [];
        for (let k = 0; k < parameters.numberOfTeeth; k++) {
            const vIdx: number = verticesIdx[k * 2 + 1];
            let vIdxPre: number = verticesIdx[k * 2];

            verticesArray.push(vIdxPre);
            let vIdxPost: number;

            if (k === parameters.numberOfTeeth - 1) {
                vIdxPost = verticesIdx[0];
            } else {
                vIdxPost = verticesIdx[k * 2 + 2];
            }

            const x: number = vertices[vIdx * 3];
            const y: number = vertices[vIdx * 3 + 1];
            const z: number = vertices[vIdx * 3 + 2];

            const t: Vector3 = new Vector3(0, y, z);
            const r: number = t.length();
            const tn: Vector3 = t.clone().normalize();

            for (let m = 0; m < halfToothProfile.length; m++) {
                const dPhi: number = halfToothProfile[m][0];
                const dR: number = halfToothProfile[m][1];
                const p: Vector3 = tn
                    .clone()
                    .multiplyScalar(r + dR)
                    .applyAxisAngle(new Vector3(1, 0, 0), dPhi);

                vertices.push(x, p.y, p.z);

                uv.x = (p.y * uvScale) / 2 + 0.5;
                uv.y = (p.z * uvScale) / 2 + 0.5;

                this.uvs.push(uv.x, uv.y);

                this._updateFaceIndicesByVIdx(top, { vIdx, vIdxPre, vIdxPost: vertices.length / 3 - 1 });

                groupCount += 3;

                vIdxPre = vertices.length / 3 - 1;
                verticesArray.push(vIdxPre);
            }

            this._updateFaceIndicesByVIdx(top, { vIdx, vIdxPre: vertices.length / 3 - 1, vIdxPost });

            groupCount += 3;
        }

        /* add first point for uv */
        verticesArray.push(verticesArray[0]);
        this.extrudeVertices.push(verticesArray);

        this.addGroup(this.groupStart, groupCount, materialIndex);
        this.groupStart += groupCount;
    }

    private _updateFaceIndicesByVIdx(top: boolean, vIdxInterface: VIdxInterface): void {
        const { vIdx, vIdxPre, vIdxPost } = vIdxInterface;

        if (!top) {
            this.indices.push(vIdxPost, vIdxPre, vIdx);
        } else {
            this.indices.push(vIdx, vIdxPre, vIdxPost);
        }
    }

    private _generateFaceProperties(facePropertiesInput: FacePropertiesInput): void {
        const {
            top,
            phiSegments,
            thetaSegments,
            rotation,
            faceWidth,
            vertex,
            uv,
            verticesIdx,
            uvScale,
            vertices,
            radiusStep,
        } = facePropertiesInput;
        let { radius } = facePropertiesInput,
            segment: number;

        for (let j = 0; j <= phiSegments; j++) {
            for (let i = 0; i <= thetaSegments; i++) {
                segment = this._startAngleRad + (i / thetaSegments) * this.thetaLength + rotation;

                this._updateFaceVertices(top, new Vector3(faceWidth * 0.5, radius * Math.sin(segment), radius * Math.cos(segment)), vertex);

                uv.x = (vertex.y * uvScale) / 2 + 0.5;
                uv.y = (vertex.z * uvScale) / 2 + 0.5;

                this.uvs.push(uv.x, uv.y);

                if (j === phiSegments) {
                    verticesIdx.push(vertices.length / 3 - 1);
                }
            }

            radius += radiusStep;
        }
    }

    private _updateFaceVertices(top: boolean, vertexData: Vector3, vertex: Vector3): void {
        if (!top) {
            vertexData.x = -vertexData.x;
        }
        vertex = vertexData;
        this.vertices.push(vertex.x, vertex.y, vertex.z);
    }

    private _generateFaceIndices(faceIndicesInput: FaceIndicesInput): void {
        const { top, phiSegments, thetaSegments, idxOffset } = faceIndicesInput;
        let segment: number;

        for (let j = 0; j < phiSegments; j++) {
            const thetaSegmentLevel: number = j * (thetaSegments + 1);

            for (let i = 0; i < thetaSegments; i++) {
                segment = i + thetaSegmentLevel;
                const a: number = segment + idxOffset;
                const b: number = segment + thetaSegments + 1 + idxOffset;
                const c: number = segment + thetaSegments + 2 + idxOffset;
                const d: number = segment + 1 + idxOffset;

                this._updateFaceIndices({ a, b, c, d }, top);
                faceIndicesInput.groupCount += 6;
            }
        }
    }

    private _updateFaceIndices(segmentData: SegmentData, top: boolean): void {
        const { a, b, c, d } = segmentData;

        if (!top) {
            this.indices.push(a, d, b);
            this.indices.push(b, d, c);
        } else {
            this.indices.push(a, b, d);
            this.indices.push(b, c, d);
        }
    }

    private _generateGearTorso(gearTorsoInput: GearTorsoInput): void {
        const { radiusBottom, radiusTop, vTextureStart, vTextureEnd, materialIndex, rotation, slices } = gearTorsoInput;

        const vertex: Vector3 = new Vector3();
        const idxOffset: number = this.vertices.length / 3;
        const heightSegments = slices - 1;

        const indexArray: Array<Array<number>> = [];
        const halfHeight: number = this.parameters.faceWidth / 2;
        let groupCount = 0;
        const phiStep: number = rotation / heightSegments;

        /* generate vertices and uvs */
        for (let y = 0; y <= heightSegments; y++) {
            const indexRow: Array<number> = [];
            const v: number = y / heightSegments;

            /* calculate the radius of the current row */
            const radius: number = v * (radiusBottom - radiusTop) + radiusTop;

            for (let x = 0; x <= this.parameters.numberOfTeeth; x++) {
                const u: number = x / this.parameters.numberOfTeeth;
                const theta: number = u * this.thetaLength + this._startAngleRad + phiStep * y;

                const sinTheta: number = Math.sin(theta);
                const cosTheta: number = Math.cos(theta);

                /* vertex */
                vertex.x = radius * sinTheta;
                vertex.y = -v * this.parameters.faceWidth + halfHeight * 2;
                vertex.z = radius * cosTheta;
                this.vertices.push(-vertex.y + halfHeight, vertex.x, vertex.z);

                /* uv */
                const uv = new Vector2(u * (this.thetaLength / (Math.PI * 2.0)), vTextureStart + v * (vTextureEnd - vTextureStart));

                this.uvs.push(uv.x, uv.y);

                /* save index of vertex in respective row */
                indexRow.push(this._index++);
            }

            /* now save vertices of the row in our index array */
            indexArray.push(indexRow);
        }

        /* generate indices */
        for (let x = 0; x < this.parameters.numberOfTeeth; x++) {
            for (let y = 0; y < heightSegments; y++) {
                /* we use the index array to access the correct indices */
                const a: number = indexArray[y][x] + idxOffset;
                const b: number = indexArray[y + 1][x] + idxOffset;
                const c: number = indexArray[y + 1][x + 1] + idxOffset;
                const d: number = indexArray[y][x + 1] + idxOffset;

                /* faces */
                this.indices.push(a, b, d);
                this.indices.push(b, c, d);
                groupCount += 6;
            }
        }

        /* add a group to the geometry. this will ensure multi material support */
        this.addGroup(this.groupStart, groupCount, materialIndex);

        /* calculate new start value for groups */
        this.groupStart += groupCount;
    }

    private _extrudeToothProfile(extrudeToothProfileInput: ExtrudeToothProfileInput): void {
        const { profiles, width, rotation, steps, materialIndex, halfToothProfile } = extrudeToothProfileInput;

        function angle(dx: number, dy: number) {
            let theta = Math.atan2(-dy, -dx); // [0, Ⲡ] then [-Ⲡ, 0]; clockwise; 0° = east
            theta *= 180 / Math.PI; // [0, 180] then [-180, 0]; clockwise; 0° = east
            if (theta < 0) {
                theta += 360;
            }

            return theta;
        }

        const xStart = 0;
        const uCoordinates: Array<number> = [0].concat(
            halfToothProfile.map(function(x) {
                return x[2];
            }),
        );
        let profile = profiles[0];

        const indexArr: Array<Array<number>> = [];

        const axis: Vector3 = new Vector3(1, 0, 0);
        for (let i = 0; i < steps; i++) {
            profile = profiles[i];
            let uIndex = 0;
            const idxTemp: Array<number> = [];
            const x: number = xStart + (width / (steps - 1)) * i;

            /* generate */
            for (let j = 0; j < profile.length; j++) {
                const y: number = this.vertices[profile[j] * 3 + 1];
                const z: number = this.vertices[profile[j] * 3 + 2];
                const v: Vector3 = new Vector3(x, y, z).applyAxisAngle(axis, (-rotation / (steps - 1)) * i);
                this.vertices.push(v.x - this.parameters.faceWidth * 0.5, v.y, v.z);
                idxTemp.push(this.vertices.length / 3 - 1);

                /* uv */
                this.uvs.push(angle(y, z) / 360.0, i / (steps - 1));

                uIndex += 1;
                if (uIndex === uCoordinates.length) {
                    uIndex = 0;
                }
            }
            indexArr.push(idxTemp);
        }

        const groupCount = this._updateIndicesOfToothProfile(indexArr);

        this.addGroup(this.groupStart, groupCount, materialIndex);
        this.groupStart += groupCount;
    }

    private _updateIndicesOfToothProfile(indexArr: number[][]): number {
        let groupCount = 0;

        for (let i = 1; i < indexArr.length; i++) {
            const slice: Array<number> = indexArr[i];
            const preSlice: Array<number> = indexArr[i - 1];

            /* generate */
            for (let j = 0; j < slice.length; j++) {
                const a: number = slice[j];
                const b: number = preSlice[j];

                let c = 0;
                let d = 0;

                if (j === 0) {
                    c = preSlice[preSlice.length - 1];
                    d = slice[slice.length - 1];
                } else {
                    c = preSlice[j - 1];
                    d = slice[j - 1];
                }

                this.indices.push(a, b, c);
                this.indices.push(a, c, d);

                groupCount += 6;
            }
        }

        return groupCount;
    }

    private _generateCuttingPlane(cuttingPlaneInput: CuttingPlaneInput): void {
        const {
            radiusInner,
            radiusOuter,
            width,
            angle,
            materialIndex,
            offsetDiameter,
            cuttingPlaneType,
            clippingPlaneType,
            flipClippingPlane,
        } = cuttingPlaneInput;
        const width05 = width * 0.5;
        const idxOffset: number = this.vertices.length / 3;
        let groupCount = 0;

        // vertex 1
        this.vertices.push(-width05, -Math.sin(angle) * radiusInner, Math.cos(angle) * radiusInner);
        this.uvs.push(0, 0);

        // vertex 2
        this.vertices.push(-width05, -Math.sin(angle) * radiusOuter, Math.cos(angle) * radiusOuter);
        this.uvs.push(0, 1);

        // vertex 3
        const offsetRadius =
            (offsetDiameter != null ? offsetDiameter / 2 : 0) *
            (cuttingPlaneType === CuttingPlaneType.Down ? -1 : 1) *
            (flipClippingPlane ? -1 : 1);
        this.vertices.push(
            width05,
            -Math.sin(angle) * radiusOuter + (clippingPlaneType === ClippingPlaneType.XY ? offsetRadius : 0),
            Math.cos(angle) * radiusOuter - (clippingPlaneType === ClippingPlaneType.XZ ? offsetRadius : 0),
        );
        this.uvs.push(1, 1);

        // vertex 4
        this.vertices.push(width05, -Math.sin(angle) * radiusInner, Math.cos(angle) * radiusInner);
        this.uvs.push(1, 0);

        const a: number = idxOffset;
        const b: number = 1 + idxOffset;
        const c: number = 2 + idxOffset;
        const d: number = 3 + idxOffset;

        /* faces */
        this.indices.push(a, b, d);
        this.indices.push(b, c, d);
        groupCount += 6;

        /* add a group to the geometry. this will ensure multi material support */
        this.addGroup(this.groupStart, groupCount, materialIndex);

        /* calculate new start value for groups */
        this.groupStart += groupCount;
    }

    private _generateCuttingPlanes(cuttingPlanesInput: CuttingPlanesInput): void {
        const { clippingType, di, da, offsetDiameter, width, openingAngleRad, clippingPlaneType, flipClippingPlane } = cuttingPlanesInput;

        if (clippingType === ClippingType.Half) {
            [
                { materialIndex: 4, cuttingPlaneType: CuttingPlaneType.Up, angle: 0 },
                { materialIndex: 5, cuttingPlaneType: CuttingPlaneType.Down, angle: openingAngleRad },
            ].forEach(({ materialIndex, cuttingPlaneType, angle }) => {
                this._generateCuttingPlane({
                    radiusInner: di / 2,
                    radiusOuter: da / 2,
                    width,
                    angle: angle + this._startAngleRad,
                    materialIndex,
                    offsetDiameter,
                    cuttingPlaneType,
                    clippingPlaneType,
                    flipClippingPlane,
                });
            });
        } else if (clippingType === ClippingType.Quarter) {
            this._generateCuttingPlaneOnQuarterMode(cuttingPlanesInput);
        }
    }

    private _generateCuttingPlaneOnQuarterMode(cuttingPlanesInput: CuttingPlanesInput): void {
        const { di, da, offsetDiameter, width, clippingPlaneType, flipClippingPlane } = cuttingPlanesInput;

        this._generateCuttingPlane({
            radiusInner: di / 2,
            radiusOuter: da / 2,
            width,
            angle: Math.PI * ((flipClippingPlane ? 3 : 1) / 2),
            materialIndex: 4,
            offsetDiameter,
            cuttingPlaneType: flipClippingPlane ? CuttingPlaneType.Down : CuttingPlaneType.Up,
            clippingPlaneType: ClippingPlaneType.XY,
            flipClippingPlane: false,
        });
        if (clippingPlaneType === ClippingPlaneType.XY) {
            this._generateCuttingPlane({
                radiusInner: di / 2,
                radiusOuter: da / 2,
                width,
                angle: Math.PI * ((flipClippingPlane ? 0 : 2) / 2),
                materialIndex: 5,
                offsetDiameter,
                cuttingPlaneType: flipClippingPlane ? CuttingPlaneType.Up : CuttingPlaneType.Down,
                clippingPlaneType: ClippingPlaneType.XZ,
                flipClippingPlane: false,
            });
        } else {
            this._generateCuttingPlane({
                radiusInner: di / 2,
                radiusOuter: da / 2,
                width,
                angle: Math.PI * ((flipClippingPlane ? 2 : 0) / 2),
                materialIndex: 5,
                offsetDiameter,
                cuttingPlaneType: flipClippingPlane ? CuttingPlaneType.Down : CuttingPlaneType.Up,
                clippingPlaneType: ClippingPlaneType.XZ,
                flipClippingPlane: false,
            });
        }
    }
}

export function createGearGeometry(parameters: GearParameters): BufferGeometry {
    return new GearBufferGeometry(parameters);
}

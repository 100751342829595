import { SupportGeometry } from '../geometries/support-geometry';
import { Bearing, INSTALLATION_CODE, INSTALLATION_DIRECTION } from '../../cae-model/supports/bearings/bearing';
import { ThrustRollerRadialRoller } from '../../cae-model/supports/bearings/thrust-roller-radial-roller';
import { DEFAULT_SEGMENT_DIAMETER, TINY_DIAMETER } from '../views-foundation-constants';

function getWidthFactor(bearing: Bearing): number {
    // eslint-disable-next-line sonarjs/prefer-immediate-return
    const output = bearing instanceof ThrustRollerRadialRoller ? 4 : 1;
    return output;
}

export function setBearingGeometry(bearing: Bearing, geometry: SupportGeometry, unitScale: number): void {
    geometry.sDI = bearing.innerDiameter;
    geometry.sDO = bearing.outerDiameter;
    geometry.sB = bearing.width;
    geometry.sAxPos = bearing.sAxPos === INSTALLATION_CODE.IC_FREE;
    geometry.sAxNeg = bearing.sAxNeg === INSTALLATION_CODE.IC_FREE;
    geometry.sRad = bearing.sRad === INSTALLATION_CODE.IC_FREE;
    geometry.iDir = bearing.iDir === INSTALLATION_DIRECTION.IC_REVERSE_DIRECTION;

    const tinyDiameter = TINY_DIAMETER / unitScale;
    if (geometry.sDI < tinyDiameter) {
        geometry.sDI = geometry.outerShaftDiameter;
        if (geometry.sDI < tinyDiameter) {
            geometry.sDI = DEFAULT_SEGMENT_DIAMETER / unitScale;
        }
    }
    if (geometry.sDO < tinyDiameter) {
        geometry.sDO = geometry.innerShaftDiameter;
        if (geometry.sDO < tinyDiameter) {
            geometry.sDO = geometry.sDI * 2.0;
        }
        if (geometry.sDO < geometry.sDI) {
            geometry.sB = geometry.sDI * getWidthFactor(bearing);
            geometry.sDI = geometry.sDO;
            geometry.sDO = geometry.sB;
        }
    }

    if (geometry.sB < tinyDiameter) {
        if (geometry.sDO === geometry.sDI) {
            geometry.sB = geometry.sDO;
        } else {
            geometry.sB = ((geometry.sDO - geometry.sDI) / 2.0) * getWidthFactor(bearing);
        }
    }
}

import { ShaftSegment } from './shaft-segment';

export class PipeSegment extends ShaftSegment {
    // PipeSegmentData in bx-online
    // _length: number;
    private _outerDiameter = 0;
    private _innerDiameter = 0;

    get outerDiameter(): number {
        return this._outerDiameter;
    }

    set outerDiameter(outerDiameterNew: number) {
        this._outerDiameter = Math.max(outerDiameterNew, this._innerDiameter);
    }

    get innerDiameter(): number {
        return this._innerDiameter;
    }

    set innerDiameter(innerDiameterNew: number) {
        this._innerDiameter = Math.max(innerDiameterNew, 0);
    }

    protected doGetCurrentOuterRadius(_x: number): number {
        return this.outerDiameter / 2;
    }

    protected doGetCurrentInnerRadius(_x: number): number {
        return this._innerDiameter / 2;
    }
}

import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'bx-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
    @Input() public icon = '';
    @Input() public width = 'auto';
    @Input() public height = 'auto';
    @Input() @HostBinding('class.spin') public spin = false;

    public getIconType(icon: string): 'file' | 'material-icon' {
        if (icon.startsWith('.') || icon.startsWith('/')) {
            return 'file';
        }

        return 'material-icon';
    }
}

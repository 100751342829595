import { IRect } from 'konva/lib/types';
import { PipeSegment } from '../../cae-model/shaft-segments/pipe-segment';
import { ElementView2D } from '../elements-view/element-view-2d';
import { Dimensions2D } from './dimensions-2d';
import { HorizontalDimensionsInterface, VerticalDimensionsInterface } from './dimensions-utils';

export class PipeSegmentDimensions2D extends Dimensions2D {
    constructor(elementView2D: ElementView2D, modelViewClientRect: IRect, fractionDigits: number) {
        super(elementView2D, modelViewClientRect, fractionDigits);
    }

    protected doCreateHorizontalDimensionsInputs(): HorizontalDimensionsInterface[] {
        const { outerDiameter, length } = this.elementView2D.modelElement as PipeSegment;
        const outerRadius = outerDiameter / 2;
        const leftPart: HorizontalDimensionsInterface = {
            objectRadius: outerRadius,
            objectPositionX: this.getCoordinatesAbsX(),
        };

        const rightPart: HorizontalDimensionsInterface = {
            objectRadius: outerRadius,
            objectPositionX: this.getCoordinatesAbsX() + length,
        };
        return [leftPart, rightPart];
    }

    protected doCreateVerticalDimensionsInput(): VerticalDimensionsInterface[] {
        const { innerDiameter, outerDiameter } = this.elementView2D.modelElement as PipeSegment;

        return [innerDiameter, outerDiameter].map<VerticalDimensionsInterface>((diameter: number) => ({
            objectDiameter: diameter,
            objectPositionX: this.getCoordinatesAbsX(),
        }));
    }
}

import { ModelElement } from '../../../../cae-model/model-element';
import { SlidingBearing } from '../../../../cae-model/supports/sliding-bearings/sliding-bearing';
import { SupportMediator } from '../support-mediator';
import { createDirectionalSupportBinder } from '../support-mediators-utils';

export class SlidingBearingMediator extends SupportMediator {
    constructor() {
        super();
        this._createBearingBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_SLIDING_BEARING'];
    }

    protected createModelElement(): ModelElement {
        return new SlidingBearing();
    }

    private _createBearingBinder() {
        const binder = createDirectionalSupportBinder();
        this.addBinder(binder);
    }
}

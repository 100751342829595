import { InternalBevelGear } from '../../cae-model/transmission-elements/internal-bevel-gear';
import { CalculateBevelGearGeometryInterface } from '../interfaces/calculate-bevel-gear-geometry-interface';
import { DEFAULT_SEGMENT_DIAMETER, TINY_DIAMETER } from '../views-foundation-constants';
import { BevelGearBaseGeometry, BevelGearDiameterType } from './bevel-gear-base-geometry';

export class InternalBevelGearGeometry extends BevelGearBaseGeometry {
    constructor(internalBevelGear: InternalBevelGear, unitScale: number) {
        super(internalBevelGear, unitScale);
    }

    protected setGeometry(): void {
        const internalBevelGear: InternalBevelGear = this.bevelGear as InternalBevelGear;
        const { x: tX, workingPitchDiameter: tDM, angleDelta: delta, toothCount: tTooth, geometricalForm: tForm } = internalBevelGear;
        let { width: tB, outerDiameter: tDO, innerDiameter: tDI } = internalBevelGear;

        let outerDiameter = internalBevelGear.getShaftCurrentOuterDiameter(tX);
        let innerDiameter = 0;

        let isOnShaft = false;
        if (outerDiameter > TINY_DIAMETER / this.unitScale) {
            innerDiameter = internalBevelGear.getShaftCurrentInnerDiameter(tX);
            if (innerDiameter > TINY_DIAMETER / this.unitScale) {
                isOnShaft = true;
            }
        } else {
            outerDiameter = DEFAULT_SEGMENT_DIAMETER;
        }

        const mediumDia = this._getMediumDia(tDM, innerDiameter, outerDiameter);

        const { ddef, didef, bdef } = this.getDef(tB, tDI, tDO);

        tB = this._caculateInternalBevelWidth({ tB, tDI, tDO, delta, mediumDia, innerDiameter, outerDiameter, ddef, didef, bdef });

        const diameter = this._calculateInternalBevelGearDiameter({ tB, tDI, tDO, delta, mediumDia, innerDiameter, ddef, didef, bdef });

        tDO = diameter.tDO;
        tDI = diameter.tDI;

        let tBore = innerDiameter;
        if (tBore < 0.01 / this.unitScale) {
            tBore = tDO + 20.0 / this.unitScale;
        }
        if (tDO > tBore) {
            tBore = tDO;
        }

        this._tB = tB;
        this._tDM = tDM;
        this._tDO = tDO;
        this._tDI = tDI;
        this._tForm = tForm;
        this._tTooth = tTooth;
        this._tBore = tBore;
        this._isOnShaft = isOnShaft;
    }

    private _getMediumDia(tDM: number, innerDiameter: number, outerDiameter: number): number {
        return tDM > 0.1
            ? tDM - 4.0 / this.unitScale
            : innerDiameter < 0.01 / this.unitScale
            ? outerDiameter - 24.0 / this.unitScale
            : innerDiameter - 24.0 / this.unitScale;
    }

    private _caculateInternalBevelWidthByDelta(calculateInput: CalculateBevelGearGeometryInterface, dia: number): number {
        let { tB } = calculateInput;

        if (calculateInput.delta < TINY_DIAMETER / this.unitScale) {
            tB = this.calculateBevelWidthByDiameter(dia);
        } else {
            tB = this.calculateBevelWidthByDelta(calculateInput);
        }

        return tB;
    }

    private _caculateInternalBevelWidth(calculateInput: CalculateBevelGearGeometryInterface): number {
        const { outerDiameter, ddef, didef, bdef } = calculateInput;
        const innerDiameter = calculateInput.innerDiameter!;
        let { tB } = calculateInput;

        if (bdef === true) {
            const dia = innerDiameter < TINY_DIAMETER / this.unitScale ? outerDiameter! : innerDiameter;
            if (ddef === true && didef === true) {
                tB = this.calculateBevelWidthByDiameter(dia);
            } else {
                tB = this._caculateInternalBevelWidthByDelta(calculateInput, dia);
            }
        }

        return tB;
    }

    private _calculateInternalBevelGearOuterDiameterWithDelta(calculateInput: CalculateBevelGearGeometryInterface): number {
        const { tB, tDI, mediumDia, ddef, didef, bdef } = calculateInput;
        let { delta } = calculateInput;
        const innerDiameter = calculateInput.innerDiameter!;
        let tDO = calculateInput.tDO!;

        if (ddef === true) {
            if (didef === true) {
                delta = this.calculateDelta(delta);
                tDO = mediumDia + tB * Math.tan(delta * (Math.PI / 180.0));
            } else {
                tDO =
                    delta > TINY_DIAMETER / this.unitScale && !bdef
                        ? tDI + 2.0 * tB * Math.tan(delta * (Math.PI / 180.0))
                        : mediumDia + Math.abs(mediumDia - tDI);
            }

            if (tDO > innerDiameter! && innerDiameter! > 0.01 / this.unitScale) {
                tDO = innerDiameter;
            }
            if (tDO < 0.0) {
                tDO = 0.0;
            }
        }

        return tDO;
    }

    private _calculateInnerDiameterWithDelta(calculateInput: CalculateBevelGearGeometryInterface): number {
        const { tB, tDO, mediumDia, ddef, bdef } = calculateInput;
        const innerDiameter = calculateInput.innerDiameter!;
        let { delta, tDI } = calculateInput;

        if (ddef) {
            delta = this.calculateDelta(delta);
            tDI = mediumDia - tB * Math.tan(delta * (Math.PI / 180.0));
        } else {
            tDI =
                delta > TINY_DIAMETER / this.unitScale && !bdef
                    ? tDI - 2.0 * tB * Math.tan(delta * (Math.PI / 180.0))
                    : mediumDia - Math.abs(tDO - mediumDia);
        }

        if (tDI > innerDiameter) {
            tDI = innerDiameter;
        }
        if (tDI < 0.0) {
            tDI = 0.0;
        }

        return tDI;
    }

    private _calculateInnerDiameter(calculateInput: CalculateBevelGearGeometryInterface): number {
        const { tDO, ddef, didef } = calculateInput;
        let { tDI } = calculateInput;

        if (didef) {
            tDI = this._calculateInnerDiameterWithDelta(calculateInput);
        } else {
            if (tDI < 0.0) {
                tDI = 0.0;
            }
            if (ddef === false && tDI > tDO) {
                tDI = tDO;
            }
        }

        return tDI;
    }

    private _calculateInternalBevelGearDiameter(calculateInput: CalculateBevelGearGeometryInterface): { tDI: number; tDO: number } {
        let { tDI, tDO } = calculateInput;

        [BevelGearDiameterType.Outer, BevelGearDiameterType.Inner].forEach(diameterType => {
            if (diameterType === BevelGearDiameterType.Outer) {
                tDO = this._calculateInternalBevelGearOuterDiameterWithDelta(calculateInput);
            } else {
                tDI = this._calculateInnerDiameter(calculateInput);
            }
        });

        return { tDI, tDO };
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UserLevelComponent } from './userlevel.component';

@NgModule({
    imports: [CommonModule, TranslateModule],
    declarations: [UserLevelComponent],
    exports: [UserLevelComponent],
})
export class UserLevelModule {}

import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessagesService, HttpStatusCode } from '../../../modules/error-handling';
import { ProjectService } from '../project.service';
import { ApiProject } from '../project.model';

@Component({
    selector: 'bx-project-create',
    templateUrl: 'project-create.component.html',
    styleUrls: ['project-create.component.scss'],
})
export class ProjectCreateComponent implements OnInit {
    public projectCtrl = new FormControl();
    public disabled = true;

    private _parentId: string | undefined;

    constructor(
        private readonly _projectService: ProjectService,
        private readonly _router: Router,
        private readonly _errorService: ErrorMessagesService,
        private readonly _translateService: TranslateService,
    ) {}

    ngOnInit() {
        this.projectCtrl.valueChanges.subscribe((value: any) => (this.disabled = !value?.name || value.name.trim().length <= 0));
        this._projectService.currentlySelected.subscribe(selectedProject => (this._parentId = selectedProject?.id));
    }

    public onConfirm(): void {
        const project: ApiProject = this.projectCtrl.value;
        if (this._parentId !== null && this._parentId !== undefined) {
            project.parentId = this._parentId;
        }
        this._projectService.create(project).subscribe(
            _ => {
                this._router.navigate(['models', 'library']);
                this._projectService.selectProject(this._parentId);
            },
            error => {
                if (error.status === HttpStatusCode.Conflict) {
                    const errorMessage = this._translateService.instant('SHARED.PROJECT.NAME.ERROR_MESSAGE_DUPLICATE');
                    this._errorService.displayErrorMessage(errorMessage);
                } else {
                    this._errorService.displayErrorMessage(error.error);
                }
            },
        );
    }

    public onCancel(): void {
        this._router.navigate(['..']);
    }
}

export const COUNT_SEGMENTS_CYLINDER = 60;
export const SHAFT_ROOT_OBJECT_NAME = 'shaft-root-object';
export const SELECTION_BOX_ROOT_OBJECT_NAME = 'selection-box-root-object';

// Light
export const SPOT_LIGHT_COLOR = '#FAFAFA';
export const DIRECTIONAL_LIGHT_COLOR = '#FAFAFA';
export const AMBIENT_LIGHT_COLOR = '#FFF';
export const SPOT_LIGHT_INTENSITY = 0.2;
export const DIRECTIONAL_LIGHT_INTENSITY = 0.55;
export const AMBIENT_LIGHT_INTENSITY = 0.9;

export const TINY_3D = 0.01;
export const ZOOM_PERSPECTIVE_OFFSET = 0.5;
export const ZOOM_ORTHOGRAPHIC_OFFSET = 0.9;
export const DEFAULT_VIEW_DISTANCE = 400;
export const DEFAULT_CAMERA_POSTION_X = -200;
export const DEFAULT_CAMERA_POSTION_Y = -200;
export const DEFAULT_CAMERA_POSTION_Z = -600;

export const EMPTY_VIZ_DATA = '-1';

// Coordinate
export const ARROW_BODY_WIDTH = 0.5;
export const ARROW_BODY_HEIGHT = 4.0;
export const ARROW_HEAD_WIDTH = 1.0;
export const ARROW_HEAD_HEIGHT = 2.25;
export const COLOR_AXIS_X = 'red';
export const COLOR_AXIS_Y = 'green';
export const COLOR_AXIS_Z = 'blue';
export const AXES_ARROW_BODY_WIDTH = 4;
export const AXES_ARROW_BODY_HEIGHT = 100;
export const AXES_ARROW_HEAD_WIDTH = 10;
export const AXES_ARROW_HEAD_HEIGHT = 30;

export const RENDERER_BACKGROUND_COLOR = 0xf0f0f0;
export const MAIN_GRID_Z = -0.0001;
export const SUB_GRID_Z = -0.0001;
export const GRID_NAME = 'grid';

export const MIN_OUTER_RADIUS_PLACEHOLDER_RING = 3;
export const MIN_WIDTH_PLACEHOLDER_RING = 2;

export const COORDINATE_SYSTEM_3D = 'coordinate-system-3d';

// Selection box
export const ANCHOR_SIZE_3D = 2.5;
export const SELECTED_SELECTION_BOX = 'selected-selection-box';
export const PRESELECTED_SELECTION_BOX = 'preselected-selection-box';
export const COLOR_SELECTED_SELECTION_BOX = 'red';
export const COLOR_PRESELECTED_SELECTION_BOX = '#77ea67';
export const USERDATA_ELEMENT_3D_SELECTABLE = 'selectable';
export const USERDATA_MODEL_ELEMENT_ID = 'modelElementId';
export const SHADOW_SIZE = 0.65;
export const SHADOW_COLOR = '#808080';
export const SHADOW_SELECTION_BOX_NAME = 'shadow-selection-box';
export const BASE_ANCHOR_NAME = 'baseAnchor_name';
export const BASE_SHADOW_NAME = 'baseShadow_name';
export const CENTER_BASE_ANCHOR_NAME = 'centerAnchor_name';
export const CENTER_BASE_SHADOW_NAME = 'centerShadow_name';

// Camera
export const DEFAULT_PERSPECTIVE_CAMERA_FOV = 20;
export const DEFAULT_PERSPECTIVE_CAMERA_ASPECT = 1;
export const DEFAULT_PERSPECTIVE_CAMERA_NEAR = 0.1;
export const DEFAULT_PERSPECTIVE_CAMERA_FAR = 5000000;
export const DEFAULT_ORTHOGRAPHIC_CAMERA_NEAR = -5000000;
export const DEFAULT_ORTHOGRAPHIC_CAMERA_FAR = 5000000;

// Controls
export const DEFAULT_CONTROLS_ROTATE_SPEED = 5.0;
export const DEFAULT_CONTROLS_PAN_SPEED = 1.1;
export const DEFAULT_CONTROLS_ZOOM_SPEED = 1.2;

// Mouse click event
export enum MouseClickEvent {
    CLICK = 'click',
    DOUBLE_CLICK = 'dblclick',
    CONTEXTMENU = 'contextmenu',
}

export const MIN_CLIPPING_PLANE_CONSTANT = 0.05;
export const CLIPPING_PLANE_CONSTANT = 10000;

export const RING_GEAR_BUFFER_GEOMETRY = 'RingGearBufferGeometry';
export const TB_TINY = 0.0000001;

// Gear
export const TOOTH_SIZE = 1.7;
export const TOOTH_STEPS_DEFAULT = 11;
export const RADIAL_SEGMENTS = 20;
export const TUBULAR_SEGMENTS = 60;

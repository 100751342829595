import Konva from 'konva';
import { ARROW_HEAD_LENGTH_2D, POINT_LOAD_FORCE_NAME } from '../elements-view/view-2d-constants';
import { createAxisArrow, createAxisCircle } from '../functions/loads-utils';
import { AxisArrowType, LoadType } from '../../bearinx-mediator/functions/loads-properties';
import { AxisInputArrow, AxisInputCircle } from '../../bearinx-mediator/functions/axis-input-interface';
import { PointLoad } from '../../cae-model/loads/point-load';

export class PointLoadForce {
    private _group: Konva.Group;

    constructor(private _pointLoad: PointLoad, private _unitScaleFactor: number) {
        this._group = this._createShapes();
    }

    get group(): Konva.Group {
        return this._group;
    }

    private _createShapes(): Konva.Group {
        const group = new Konva.Group({
            name: POINT_LOAD_FORCE_NAME,
        });
        const arrowLength = this._getArrowLength();
        const { forceX, forceY, forceZ } = this._pointLoad;

        if (forceX !== 0) {
            const xAxis: AxisInputArrow = {
                loadValue: forceX,
                arrowLength: arrowLength,
                axisArrowType: AxisArrowType.X,
                loadType: LoadType.Force,
                positionX: 0,
                unitScaleFactor: this._unitScaleFactor,
            };
            group.add(createAxisArrow(xAxis));
        }
        if (forceY !== 0) {
            const yAxis: AxisInputArrow = {
                loadValue: forceY,
                arrowLength: arrowLength,
                axisArrowType: AxisArrowType.Y,
                loadType: LoadType.Force,
                positionX: 0,
                unitScaleFactor: this._unitScaleFactor,
            };
            group.add(createAxisArrow(yAxis));
        }
        const zAxis: AxisInputCircle = {
            loadValue: forceZ,
            circleRadius: arrowLength * 0.1,
            loadType: LoadType.Force,
            positionX: 0,
            unitScaleFactor: this._unitScaleFactor,
        };
        group.add(createAxisCircle(zAxis));

        return group;
    }

    private _getArrowLength(): number {
        const middleDiameter = this._pointLoad.getShaftSystemMiddleDiameter();
        return Math.max((ARROW_HEAD_LENGTH_2D + 2.0) / this._unitScaleFactor, (middleDiameter * 1.2) / 2.0);
    }
}

import { Mesh } from 'three';
import { SlidingBearing } from '../../../../cae-model/supports/sliding-bearings/sliding-bearing';
import { createSlidingBearingMaterial } from '../../../settings/default-materials';
import { SupportView3D } from '../support-view-3d';

export class SlidingBearingView3D extends SupportView3D {
    constructor(slidingBearing: SlidingBearing) {
        super(slidingBearing);
    }

    protected setMesh(): Mesh {
        const material = createSlidingBearingMaterial();
        return new Mesh(undefined, material);
    }
}

/* eslint-disable max-len */

import { Bearing } from './bearing';

export enum RADIAN_BALL_PROPERTIES {
    LB_INNERRING = 'LB_INNERRING',
    LB_OUTERRING = 'LB_OUTERRING',
    LB_AXIAL_NEGATIVE_DIRECTION = 'LB_AXIAL_NEGATIVE_DIRECTION',
    LB_AXIAL_POSITIVE_DIRECTION = 'LB_AXIAL_POSITIVE_DIRECTION',
}

/**
 * RadialBall
 */
export class RadialBall extends Bearing {
    idlWithPreloadSpring = false;
    idlPreloadRing = '';
    idlPreloadDirection = '';
}

import { SelectedElementInterface } from './selected-element-interface';

export enum ClickType {
    LEFT_CLICK,
    RIGHT_CLICK,
    DOUBLE_LEFT_CLICK,
    DOUBLE_RIGHT_CLICK,
}

export interface SelectedInViewerElementInterface extends SelectedElementInterface {
    clickType: ClickType;
    clientX: number;
    clientY: number;
}

import { BufferGeometry, Mesh } from 'three';
import { Ring } from '../../../cae-model/bearing-elements/ring';
import { createVisualizationGeometry, getVisualizationParameters } from '../../functions/create-visualization-geometry';
import { getUnitSetScaleValue, resolveOverlapDisplayOrCreateDefaultBufferGeometry } from '../../functions/utils-3d';
import { createRingMaterial } from '../../settings/default-materials';
import { BearingElementView3D } from './bearingelement-view-3d';

export class RingView3D extends BearingElementView3D {
    constructor(ring: Ring) {
        super(ring);
        const mesh = this._createMesh();
        this.groupWithoutChildren.add(mesh);
    }

    private _hasVisualization = false;

    protected updateGroupWithoutChildren(): void {
        const geometry = this._setGeometry();
        this.mesh.geometry = geometry;
    }

    private get _ring(): Ring {
        return this.modelElement as Ring;
    }

    private _createMesh(): Mesh {
        const material = createRingMaterial();
        this.mesh = new Mesh(undefined, material);
        return this.mesh;
    }

    get hasVisualization(): boolean {
        return this._hasVisualization;
    }

    private _setGeometry(): BufferGeometry {
        const scale = 1 / getUnitSetScaleValue(this.input.settings.unitSet);
        const geometry = createVisualizationGeometry(getVisualizationParameters(this.input.settings, false, this._ring.visualizationData));
        if (geometry) {
            // This is a dummy model rendering, we will just scale the whole model for now.
            // In the future, we will refactor the visualization for the model, this line will be removed,
            // and move the scale value into createVisualizationGeometry()
            geometry.scale(scale, scale, scale);
        }
        this._hasVisualization = geometry != null;
        return resolveOverlapDisplayOrCreateDefaultBufferGeometry(this.input.settings, geometry);
    }
}

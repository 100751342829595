import { WMFImage } from '../bearing/wmf-images/wmf-image';
import { SlidingBearingView2D } from './sliding-bearing-view-2d';
import { RadialSphericalPlain } from '../../../../cae-model/supports/sliding-bearings/radial-spherical-plain';
import { WMF_RadialSphericalPlainBearing } from '../bearing/wmf-images/wmf-radial-spherical-plain-bearing';

export class RadialSphericalPlainView2D extends SlidingBearingView2D {
    constructor(radialSphericalPlain: RadialSphericalPlain) {
        super(radialSphericalPlain);
    }

    // Create image manually. Overrides basic property.
    protected createWMFImage(): WMFImage {
        return new WMF_RadialSphericalPlainBearing();
    }
}

import { ElementView3D } from '../element-view-3d';
import { BufferGeometry, Material, Mesh, Vector3 } from 'three';
import { clipMesh } from '../../functions/clip-mesh';
import { TransmissionElement } from '../../../cae-model/transmission-elements/transmission-element';
import { calculateClippingDirections, clipMaterialOnPlanes, determineQuarterClippingPlanes } from '../../functions/utils-3d';
import { ClippingType } from '../../settings';
import { clipMeshOnPlane } from '../../functions/clip-mesh-on-plane';
import { createGearMaterial } from '../../settings/default-materials';

export enum ClipMode {
    ClipMesh,
    ClipOnPlane,
}

export abstract class TransmissionElementView3D extends ElementView3D {
    protected clipMode: ClipMode = ClipMode.ClipMesh;
    protected abstract setMesh(geometry: BufferGeometry, material: Material): Mesh;
    protected abstract setBufferGeometry(): BufferGeometry;
    protected isUpdatedWhenAllViewsGenerated = false;
    protected isOnShaft = false;

    constructor(transmissionElement: TransmissionElement) {
        super(transmissionElement);
    }

    protected get clipMeshEnabled(): boolean {
        return false;
    }

    updateWhenAllViewsGenerated() {
        super.updateWhenAllViewsGenerated();
        this.isUpdatedWhenAllViewsGenerated = true;
        this.updateGroupWithoutChildren();
    }

    protected updateGroupWithoutChildren(): void {
        if (!this.isUpdatedWhenAllViewsGenerated) {
            return;
        }
        if (this.mesh != null) {
            this.mesh.visible = false;
        } else {
            this.visualize();
        }
    }

    protected visualize() {
        const geometry = this.setBufferGeometry();
        const material = createGearMaterial(this.isOnShaft ? '#00893d' : 'red');

        if (!this.mesh) {
            this.mesh = this.setMesh(geometry, material);
            this.groupWithoutChildren.add(this.mesh);
        }

        if (this.clipMeshEnabled) {
            switch (this.clipMode) {
                case ClipMode.ClipMesh:
                    this.mesh.geometry = clipMesh(this.mesh, this.input.settings, this.groupWithoutChildren).geometry;
                    break;
                case ClipMode.ClipOnPlane:
                    const { clippingType, clippingPlane, flipClippingPlane } = this.input.settings;
                    const clippingDirections = calculateClippingDirections({
                        clippingType,
                        clippingPlane,
                        flipClippingPlane,
                        isMaterialClipOnPlanes: true,
                    });

                    if (clippingType === ClippingType.Half) {
                        this.mesh.geometry = clipMeshOnPlane(this.mesh, clippingDirections).geometry;
                    } else if (clippingType === ClippingType.Quarter && !Array.isArray(this.mesh.material)) {
                        const clippingPlanes = determineQuarterClippingPlanes(clippingDirections);
                        clipMaterialOnPlanes(this.mesh.material, clippingPlanes, true);
                    }
            }
        }
    }
}

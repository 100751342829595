<bx-app-bar [navigationDrawer]="navigationDrawer" [search]="search"></bx-app-bar>

<div class="bx__content-container">
    <router-outlet></router-outlet>
</div>

<bx-drawer position="left" #navigationDrawer>
    <bx-main-navigation [drawer]="navigationDrawer"></bx-main-navigation>
</bx-drawer>

<bx-search #search></bx-search>

import { Inject, Injectable, InjectionToken } from '@angular/core';

export const LOCAL_STORAGE_BACKEND = new InjectionToken('Local Storage Backend', { factory: () => localStorage });
export const SESSION_STORAGE_BACKEND = new InjectionToken('Session Storage Backend', { factory: () => sessionStorage });

@Injectable({
    providedIn: 'root',
})
export class WebStorageService {
    public static readonly _STORAGE_PREFIX = 'BX_';

    constructor(
        @Inject(LOCAL_STORAGE_BACKEND) private readonly _localStorage: Storage,
        @Inject(SESSION_STORAGE_BACKEND) private readonly _sessionStorage: Storage,
    ) {}

    private _getKey(key: string): string {
        return `${WebStorageService._STORAGE_PREFIX}${key}`;
    }

    public setValue(key: string, value: any, sessionOnly = false): void {
        (sessionOnly ? this._sessionStorage : this._localStorage).setItem(this._getKey(key), JSON.stringify(value));
    }

    public getValue<T>(key: string, sessionOnly = false): T | null {
        const value = (sessionOnly ? this._sessionStorage : this._localStorage).getItem(this._getKey(key));
        if (value !== null) {
            try {
                return JSON.parse(value);
            } catch {
                console.warn(`[WebStorageService] Cannot restore value for key ${key}`, value);
            }
        }
        return null;
    }

    public removeValue(key: string): void {
        this._localStorage.removeItem(this._getKey(key));
    }

    public wipe(): void {
        for (const key in this._localStorage) {
            if (key.startsWith(WebStorageService._STORAGE_PREFIX)) {
                this._localStorage.removeItem(key);
            }
        }
    }
}

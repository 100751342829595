import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SpinnerService {
    private _requestCount = 0;
    private readonly _toggleSpinner = new BehaviorSubject<boolean>(false);

    public readonly toggleSpinner$: Observable<boolean> = this._toggleSpinner.pipe(debounceTime(300));

    public showSpinner(): void {
        if (++this._requestCount === 1) {
            Promise.resolve().then(() => this._toggleSpinner.next(true));
        }
    }

    public hideSpinner(): void {
        if (this._requestCount > 0 && --this._requestCount === 0) {
            Promise.resolve().then(() => this._toggleSpinner.next(false));
        }
    }

    public removeAllSpinners(): void {
        this._requestCount = 0;
        Promise.resolve().then(() => this._toggleSpinner.next(false));
    }
}

/* eslint-disable no-shadow */
import Konva from 'konva';
import { ANCHOR_SIZE_2D, TRANSFORMER_BOUNDING } from '../elements-view/view-2d-constants';
import { ANCHORS_NAMES, TransformerBase } from './transformer-base';

export class RectTransformer extends TransformerBase {
    constructor(private _rect: Konva.Rect, _parent: Konva.Group) {
        super(_rect, _parent);
    }

    protected updateElements() {
        const width = this._rect.width() / 2;
        const height = this._rect.height() / 2;

        const resizeEnabled = true;
        const padding = ANCHOR_SIZE_2D / 2;
        const anchorSize = ANCHOR_SIZE_2D;

        this.batchChangeChild(`.${ANCHORS_NAMES.top_left}`, {
            x: -width,
            y: -height,
            offsetX: anchorSize / 2 + padding,
            offsetY: anchorSize / 2 + padding,
            visible: resizeEnabled,
        });
        this.batchChangeChild(`.${ANCHORS_NAMES.top_center}`, {
            x: 0,
            y: -height,
            offsetX: anchorSize / 2,
            offsetY: anchorSize / 2 + padding,
            visible: resizeEnabled,
        });
        this.batchChangeChild(`.${ANCHORS_NAMES.top_right}`, {
            x: width,
            y: -height,
            offsetX: anchorSize / 2 - padding,
            offsetY: anchorSize / 2 + padding,
            visible: resizeEnabled,
        });
        this.batchChangeChild(`.${ANCHORS_NAMES.middle_left}`, {
            x: -width,
            y: 0,
            offsetX: anchorSize / 2 + padding,
            offsetY: anchorSize / 2,
            visible: resizeEnabled,
        });
        this.batchChangeChild(`.${ANCHORS_NAMES.middle_right}`, {
            x: width,
            y: 0,
            offsetX: anchorSize / 2 - padding,
            offsetY: anchorSize / 2,
            visible: resizeEnabled,
        });
        this.batchChangeChild(`.${ANCHORS_NAMES.bottom_left}`, {
            x: -width,
            y: height,
            offsetX: anchorSize / 2 + padding,
            offsetY: anchorSize / 2 - padding,
            visible: resizeEnabled,
        });
        this.batchChangeChild(`.${ANCHORS_NAMES.bottom_center}`, {
            x: 0,
            y: height,
            offsetX: anchorSize / 2,
            offsetY: anchorSize / 2 - padding,
            visible: resizeEnabled,
        });
        this.batchChangeChild(`.${ANCHORS_NAMES.bottom_right}`, {
            x: width,
            y: height,
            offsetX: anchorSize / 2 - padding,
            offsetY: anchorSize / 2 - padding,
            visible: resizeEnabled,
        });
        this.batchChangeChild(`.${TRANSFORMER_BOUNDING}`, {
            sceneFunc: (
                context: {
                    beginPath: () => void;
                    moveTo: (arg0: number, arg1: number) => void;
                    lineTo: (arg0: number, arg1: number) => void;
                    closePath: () => void;
                    fillStrokeShape: (arg0: any) => void;
                },
                shape: any,
            ) => {
                context.beginPath();

                context.moveTo(-width, -height);
                context.lineTo(width, -height);
                context.lineTo(width, height);
                context.lineTo(-width, height);

                context.closePath();

                context.fillStrokeShape(shape);
            },
            visible: resizeEnabled,
        });
    }
}

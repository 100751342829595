import { NotchView2D } from './notch-view-2d';
import { Shoulder } from '../../../cae-model/notches/shoulder';
import { DISPLAYRED, SEGMENT_COLOR_2D, STROKE_WIDTH, TRANSPARENT } from '../view-2d-constants';
import { generateUpperAndLowerParts } from '../../functions/utils-2d';
import { Context } from 'konva/lib/Context';
import { Shape, ShapeConfig } from 'konva/lib/Shape';
import { Group } from 'konva/lib/Group';
import { Line } from 'konva/lib/shapes/Line';

export class ShoulderView2D extends NotchView2D {
    constructor(shoulder: Shoulder) {
        super(shoulder);
    }

    private get _shoulder(): Shoulder {
        return this.modelElement as Shoulder;
    }

    protected updateGroupWithoutChildren(): void {
        super.rerenderViewWithoutChildren('shoulder-view-2d-group');
    }

    protected createNotchView(): Group {
        const group = new Group();
        const { radius } = this._shoulder;
        const shoulder: Shape[] = [];
        const commonAttributes = {
            strokeWidth: STROKE_WIDTH,
            strokeScaleEnabled: false,
            y: -this.outerRadius,
            scaleX: -1,
            offsetX: radius,
            closed: true,
        };

        const triangle = new Shape({
            ...commonAttributes,
            stroke: SEGMENT_COLOR_2D,
            fill: SEGMENT_COLOR_2D,
            sceneFunc: (context, shape) => {
                this._createDefaultPath(context);
                context.lineTo(radius, 0);
                context.closePath();
                context.fillStrokeShape(shape);
            },
        });
        shoulder.push(triangle);

        const triangleTransparent = new Line({
            ...commonAttributes,
            points: [0, 0, radius, 0, radius, -radius],
            stroke: TRANSPARENT,
            fill: TRANSPARENT,
        });
        shoulder.push(triangleTransparent);

        const curveLine = triangle.clone() as Shape;
        curveLine.stroke(DISPLAYRED);
        curveLine.sceneFunc((context: Context, shape: Shape<ShapeConfig>) => {
            this._createDefaultPath(context);
            context.fillStrokeShape(shape);
        });
        curveLine.fill(TRANSPARENT);
        shoulder.push(curveLine);

        shoulder.forEach(part => {
            group.add(generateUpperAndLowerParts(part, 'shoulderPart'));
        });

        return group;
    }

    private _createDefaultPath(context: Context): void {
        const { radius } = this._shoulder;
        context.beginPath();
        context.moveTo(0, 0);
        context.quadraticCurveTo(radius, 0, radius, -radius);
    }
}

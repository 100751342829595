import { GearAbstract } from '../../cae-model/transmission-elements/gear-abstract';
import { DEFAULT_SEGMENT_DIAMETER, STANDARD_GEAR_WIDTH, TINY_DIAMETER } from '../views-foundation-constants';

export class GearAbstractGeometry {
    private _tDM = 0;
    private _tDO = 0;
    private _tDI = 0;
    private _tXLoad = 0;
    private _tB = 0;
    private _tBore = 0;
    private _isOnShaft = false;

    public get tDM(): number {
        return this._tDM;
    }

    public get tDO(): number {
        return this._tDO;
    }

    public get tDI(): number {
        return this._tDI;
    }

    public get tXLoad(): number {
        return this._tXLoad;
    }

    public get tB(): number {
        return this._tB;
    }

    public get tBore(): number {
        return this._tBore;
    }

    public get isOnShaft(): boolean {
        return this._isOnShaft;
    }

    constructor(private _gearAbstract: GearAbstract, private _unitScale: number) {
        this._setGearAbstractGeometry();
    }

    private _setGearAbstractGeometry(): void {
        const { x: tX, effectiveDiameter: tDM, xOffsetLoad: tXLoad } = this._gearAbstract;
        let { outerDiameter: tDO, width: tB } = this._gearAbstract;
        let outerDiameter = this._gearAbstract.getShaftCurrentOuterDiameter(tX);
        let isOnShaft = false;

        const tinyDiameter = TINY_DIAMETER / this._unitScale;
        if (outerDiameter > tinyDiameter) {
            isOnShaft = true;
        } else {
            outerDiameter = DEFAULT_SEGMENT_DIAMETER / this._unitScale;
        }

        if (tDO < tinyDiameter) {
            if (tDM > 0.1) {
                tDO = tDM + 4.0 / this._unitScale;
            } else {
                tDO = tDM < 160.0 / this._unitScale ? outerDiameter + 20.0 / this._unitScale : outerDiameter + 16.0 / this._unitScale;
            }
        }

        if (tB < tinyDiameter) {
            const gearWidth = STANDARD_GEAR_WIDTH / this._unitScale;
            if (outerDiameter < gearWidth) {
                tB = outerDiameter * 0.5;
            } else {
                tB = 0.25 * (outerDiameter - gearWidth) + gearWidth / 2;
            }
        }

        const tBore = tDO < outerDiameter || !isOnShaft ? 0.0 : outerDiameter;
        const tDI = tBore;

        this._tDM = tDM;
        this._tDO = tDO;
        this._tDI = tDI;
        this._tXLoad = tXLoad;
        this._tB = tB;
        this._tBore = tBore;
        this._isOnShaft = isOnShaft;
    }
}

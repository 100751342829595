import { BearingElementView3D } from './bearingelement-view-3d';
import { BearingRow } from '../../../cae-model/bearing-elements/bearing-row';

export class BearingRowView3D extends BearingElementView3D {
    constructor(bearingRow: BearingRow) {
        super(bearingRow);
    }

    protected updateGroupWithoutChildren(): void {}

    get hasVisualization(): boolean {
        return this.children.some(child => {
            const belement3D = child as BearingElementView3D;
            return belement3D?.hasVisualization;
        });
    }
}

import { IRect } from 'konva/lib/types';
import { DistributedLoad } from '../../cae-model/loads/distributed-load';
import { ElementView2D } from '../elements-view/element-view-2d';
import { Dimensions2D } from './dimensions-2d';
import { HorizontalDimensionsInterface, VerticalDimensionsInterface } from './dimensions-utils';

export class DistributedLoadDimensions2D extends Dimensions2D {
    constructor(elementView2D: ElementView2D, modelViewClientRect: IRect, fractionDigits: number) {
        super(elementView2D, modelViewClientRect, fractionDigits);
    }

    protected doCreateHorizontalDimensionsInputs(): HorizontalDimensionsInterface[] {
        const distributedLoad = this.elementView2D.modelElement as DistributedLoad;
        const elementRect = this.getGroupWithoutChildrenClientRect();
        const objectRadius = elementRect.y + elementRect.height;
        const objectLength = distributedLoad.xEnd - distributedLoad.x;

        const leftPart: HorizontalDimensionsInterface = {
            objectRadius,
            objectPositionX: this.getCoordinatesAbsX(),
        };

        const rightPart: HorizontalDimensionsInterface = {
            objectRadius,
            objectPositionX: this.getCoordinatesAbsX() + objectLength,
        };
        return [leftPart, rightPart];
    }

    protected doCreateVerticalDimensionsInput(): VerticalDimensionsInterface[] {
        return [];
    }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Roles } from '../../modules/role/role.model';
import { ProjectCreateComponent } from './project-create/project-create.component';
import { ProjectRenameComponent } from './project-rename/project-rename.component';

const ROUTES: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'create',
    },
    {
        path: 'create',
        component: ProjectCreateComponent,
        data: {
            requiredRoles: [Roles.ProjectWrite],
        },
    },
    {
        path: ':id/rename',
        component: ProjectRenameComponent,
        data: {
            requiredRoles: [Roles.ProjectWrite],
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(ROUTES)],
    exports: [RouterModule],
})
export class ProjectRoutingModule {}

import { Component, Input } from '@angular/core';

@Component({
    selector: 'bx-prompt-container',
    templateUrl: 'prompt-container.component.html',
    styleUrls: ['prompt-container.component.scss'],
})
export class PromptContainerComponent {
    @Input() public title: string;
}

import { Worm } from '../../cae-model/transmission-elements/worm';
import { DEFAULT_SEGMENT_DIAMETER, STANDARD_GEAR_WIDTH, TINY_DIAMETER } from '../views-foundation-constants';

export class WormGeometry {
    private _tDM = 0;
    private _tDO = 0;
    private _tDI = 0;
    private _tBO = 0;
    private _tBI = 0;
    private _tBore = 0;
    private _isOnShaft = false;

    public get tDM(): number {
        return this._tDM;
    }

    public get tDO(): number {
        return this._tDO;
    }

    public get tDI(): number {
        return this._tDI;
    }

    public get tBO(): number {
        return this._tBO;
    }

    public get tBI(): number {
        return this._tBI;
    }

    public get tBore(): number {
        return this._tBore;
    }

    public get isOnShaft(): boolean {
        return this._isOnShaft;
    }

    constructor(private _worm: Worm, private _unitScale: number) {
        this._setWormGeometry();
    }

    private _setWormGeometry(): void {
        const { x: tX, referenceDiameter: tDM } = this._worm;
        let { outerDiameter: tDO, width: tBO } = this._worm;
        const outerDiameter = this._worm.getShaftCurrentOuterDiameter(tX);
        let tBore = DEFAULT_SEGMENT_DIAMETER;
        let isOnShaft = false;

        const tinyDiameter = TINY_DIAMETER / this._unitScale;
        const gearWidth = STANDARD_GEAR_WIDTH / this._unitScale;
        if (outerDiameter > tinyDiameter) {
            tBore = outerDiameter;
            isOnShaft = true;
        }

        if (tDO < tinyDiameter) {
            if (tDM > 0.1) {
                tDO = tDM + 4.0 / this._unitScale;
            } else {
                tDO = tBore + 10.0 / this._unitScale;
            }
        }

        if (tBO < tinyDiameter) {
            if (tBore < gearWidth) {
                tBO = tBore * 1.2;
            } else {
                tBO = 0.6 * (tBore - gearWidth) + 120.0 / this._unitScale;
            }
        }

        let tDI = tDO * 0.8;
        if (tDO >= tBore && tDI < tBore) {
            tDI = tBore;
        }

        if (tDO < tBore) {
            tBore = 0.0;
        } else {
            tBore = outerDiameter;
        }

        const tBI = tBO * 0.75;

        this._tDM = tDM;
        this._tDO = tDO;
        this._tDI = tDI;
        this._tBO = tBO;
        this._tBI = tBI;
        this._tBore = tBore;
        this._isOnShaft = isOnShaft;
    }
}

export enum SupportGeometryColor {
    DISPLAYGREEN,
    DISPLAYBRIGHTGREEN,
    UNDIFINED,
}

/**
 * Support Geometry (copy of Geometry from  SupportData.java in D:\devOnline\Bearinx-online)
 */
export interface SupportGeometry {
    sX: number; // coordinates of support in ShaftSystem CS
    sDI: number; // inner diameter
    sDO: number; // outer diameter
    sB: number; // width (Breite  bei Darstellung des Supports)
    outerShaftDiameter: number;
    innerShaftDiameter: number;

    sAxPos: boolean; // not supported in positive direction
    sAxNeg: boolean; // not supported in negative direction
    sRad: boolean; // not supported in radial  direction
    sRot: boolean; // allows movement about z or y
    sTransY: boolean; // not supported in y direction
    outerSupported: boolean;
    innerSupported: boolean;
    iDir: boolean; // is reverse direction (geometry.iDir == IdDef.IC_REVERSE_DIRECTION)

    color: SupportGeometryColor;
}

export function createDefaultSupportGeometry(): SupportGeometry {
    return {
        sX: 0,
        sDI: 0,
        sDO: 0,
        sB: 0,
        outerShaftDiameter: 0,
        innerShaftDiameter: 0,

        sAxPos: false,
        sAxNeg: false,
        sRad: false,
        sRot: false,
        sTransY: false,
        outerSupported: false,
        innerSupported: false,
        iDir: false,
        color: SupportGeometryColor.UNDIFINED,
    };
}

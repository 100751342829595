import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BEARING_STROKE_WIDTH_DEFAULT, BEARING_VIEW_2D_GROUP } from '../../view-2d-constants';
import { SupportGeometry } from '../../../../views-foundation/geometries/support-geometry';
import { SupportView2D } from '../support-view-2d';
import { Bearing } from '../../../../cae-model/supports/bearings/bearing';
import { BearingConstructionType, BearingsTypesIdos } from '../../../../bearinx-mediator/bearinx-ids';
import { BearingView2DUrls } from './bearing-view-2d-urls';
import { getSvgDrawingDataFromSvgString } from '../../../../views-foundation/functions/html-utils';
import { WMFImage } from './wmf-images/wmf-image';
import { createBoundingBox, getColor, getSupportDimensions, getSupportGroupFromWMF } from '../../../functions/utils-2d';
import { Group } from 'konva/lib/Group';
import { Path } from 'konva/lib/shapes/Path';

// hierarhy of grous: this.viewWithChildren,  this._group, this.paintCommonSupport, this._getBearingGroups, this._getBearningGroup
export class BearingView2D extends SupportView2D {
    private _hasBorder = false;

    constructor(bearing: Bearing) {
        super(bearing);
    }

    private get _bearing(): Bearing {
        return this.modelElement as Bearing;
    }

    protected get hasBorder(): boolean {
        return this._hasBorder;
    }

    protected paintElement(group: Group, geometry: SupportGeometry): void {
        this.updateElement(group, geometry);
        this._updateBearing(group, geometry);
    }

    protected updateElement(group: Group, geometry: SupportGeometry): void {}

    protected getName(): string {
        return BEARING_VIEW_2D_GROUP;
    }

    private _updateBearing(group: Group, geometry: SupportGeometry): void {
        this._getBearingGroup(geometry)?.subscribe(result => {
            if (result instanceof Group) {
                const konvaGroups = this.getSupportGroups(result, geometry, this._isXInverse(geometry));
                konvaGroups.forEach(konvaGroup => group.add(konvaGroup));
                if (group.getLayer() != null) {
                    group.draw();
                }
            }
        });
    }

    private _isXInverse(geometry: SupportGeometry): boolean {
        let xInverse = false;

        switch (this._bearing.bearinxType) {
            case BearingsTypesIdos.IDO_RADIAL_ANGULAR_CONTACT_BALL_BEARING:
            case BearingsTypesIdos.IDO_THRUST_ANGULAR_CONTACT_BALL_BEARING:
            case BearingsTypesIdos.IDO_RADIAL_ANGULAR_CONTACT_ROLLER_BEARING:
            case BearingsTypesIdos.IDO_THRUST_ANGULAR_CONTACT_ROLLER_BEARING:
            case BearingsTypesIdos.IDO_RADIAL_TAPERED_ROLLER_BEARING:
            case BearingsTypesIdos.IDO_THRUST_TAPERED_ROLLER_BEARING:
                if (geometry.iDir) {
                    xInverse = true;
                }

                if (this._bearing.nbRows === 1) {
                    if (!geometry.sAxPos && geometry.sAxNeg) {
                        xInverse = true;
                    } else if (geometry.sAxPos && !geometry.sAxNeg) {
                        xInverse = false;
                    } else if (geometry.iDir) {
                        xInverse = false;
                    } else {
                        xInverse = true;
                    }
                }
                break;
            case BearingsTypesIdos.IDO_RADIAL_ROLLER_BEARING:
            case BearingsTypesIdos.IDO_THRUST_ROLLER_BEARING:
            case BearingsTypesIdos.IDO_RADIAL_BALL_BEARING:
            case BearingsTypesIdos.IDO_THRUST_BALL_BEARING:
            case BearingsTypesIdos.IDO_RADIAL_SELF_ALIGNING_ROLLER_BEARING:
            case BearingsTypesIdos.IDO_THRUST_SELF_ALIGNING_ROLLER_BEARING:
            case BearingsTypesIdos.IDO_RADIAL_SELF_ALIGNING_BALL_BEARING:
            case BearingsTypesIdos.IDO_THRUST_ROLLER_RADIAL_ROLLER_BEARING:
            case BearingsTypesIdos.IDO_THRUST_BALL_RADIAL_ROLLER_BEARING:
            case BearingsTypesIdos.IDO_THRUST_ANGULAR_CONTACT_BALL_RADIAL_ROLLER_BEARING:
            case BearingsTypesIdos.IDO_THRUST_ANGULAR_CONTACT_BALL_BEARING_WITH_MIXED_ROWS:
            case BearingsTypesIdos.IDO_THRUST_ROLLER_BEARING_WITH_MIXED_ROWS:
            case BearingsTypesIdos.IDO_BALL_SCREW_DRIVE_WITH_TWO_POINT_CONTACT:
            case BearingsTypesIdos.IDO_BALL_SCREW_DRIVE_WITH_FOUR_POINT_CONTACT:
            case BearingsTypesIdos.IDO_SLEWING_BALL_BEARING:
            case BearingsTypesIdos.IDO_SLEWING_ROLLER_BEARING:
            case BearingsTypesIdos.IDO_DOUBLE_DIRECTION_AXIAL_RADIAL_SLEWING_BEARING:
                if (geometry.iDir) {
                    xInverse = true;
                }
        }
        return xInverse;
    }

    private _getImageURL(constructionType: string): string | null {
        const { nbRows } = this._bearing;

        // eslint-disable-next-line sonarjs/max-switch-cases
        switch (constructionType) {
            case BearingConstructionType.LB_RADIAL_PENDEL_KUGEL_LAGER_EINREIHIG:
                return BearingView2DUrls.SVG_PENDELKUGELLAGER_URL;
            case BearingConstructionType.LB_RADIAL_PENDEL_KUGEL_LAGER_ZWEIREIHIG:
                return BearingView2DUrls.SVG_PENDELKUGELLAGER_URL;
            case BearingConstructionType.LB_RADIAL_PENDEL_ROLLENLAGER_EINREIHIG:
                return BearingView2DUrls.SVG_PERO1ROW_URL;
            case BearingConstructionType.LB_RADIAL_PENDEL_ROLLENLAGER_ZWEIREIHIG:
            case BearingConstructionType.LB_RADIAL_PENDEL_ROLLENLAGER_FAG_E1_E1A_LAGER:
            case BearingConstructionType.LB_RADIAL_PENDEL_ROLLENLAGER_FAG_E_EA_LAGER:
            case BearingConstructionType.LB_RADIAL_PENDEL_ROLLENLAGER_FAG_HL_LAGER:
            case BearingConstructionType.LB_RADIAL_PENDEL_ROLLENLAGER_FAG_A_LAGER:
            case BearingConstructionType.LB_RADIAL_PENDEL_ROLLENLAGER_FAG_B_LAGER:
            case BearingConstructionType.LB_RADIAL_PENDEL_ROLLENLAGER_GEMISCHTREIHIG:
                return BearingView2DUrls.SVG_PERO2ROWS_URL;
            case BearingConstructionType.LB_TOROIDAL_ROLLENLAGER:
                return BearingView2DUrls.SVG_TORIODIAL_URL;
            case BearingConstructionType.LB_AXIAL_KREUZROLLENLAGER:
            case BearingConstructionType.LB_CROSSED_ROLLER_SLEWING_BEARING_SINGLE_ROW:
                return BearingView2DUrls.SVG_SX011880_URL;
            case BearingConstructionType.LB_CROSSED_ROLLER_SLEWING_BEARING_DOUBLE_ROW:
                throw new Error('IDMF_KREUZROLLENLAGER_ZWEIREIHIG');
            case BearingConstructionType.LB_RADIAL_KREUZROLLENLAGER:
                return BearingView2DUrls.SVG_SX011880_URL;
            case BearingConstructionType.LB_RADIAL_SCHRAEG_KUGELLAGER_EINREIHIG:
            case BearingConstructionType.LB_RADIAL_ANGULAR_CONTACT_BALL_BEARING_SINGLE_ROW_SPINDLE_TYPE:
                return BearingView2DUrls.SVG_7004BE2RS_URL;
            case BearingConstructionType.LB_RADIAL_SCHRAEG_KUGELLAGER_ZWEIREIHIG_X_ANORDNUNG:
            case BearingConstructionType.LB_RADIAL_SCHRAEG_KUGELLAGER_ZWEIREIHIG_MIT_FUELLNUT_X_ANORDNUNG:
                return BearingView2DUrls.SVG_3309_URL;
            case BearingConstructionType.LB_RADIAL_SCHRAEG_KUGELLAGER_ZWEIREIHIG_O_ANORDNUNG:
            case BearingConstructionType.LB_RADIAL_SCHRAEG_KUGELLAGER_ZWEIREIHIG_MIT_FUELLNUT_O_ANORDNUNG:
                return BearingView2DUrls.SVG_3309_URL;
            case BearingConstructionType.LB_RADIAL_SCHRAEG_KUGELLAGER_ZWEIREIHIG_TANDEM:
                return BearingView2DUrls.SVG_3309_URL;
            case BearingConstructionType.LB_THREE_POINT_CONTACT_BALL_BEARING_WTIH_DOUBLE_INNER_RING_CONTACT:
            case BearingConstructionType.LB_THREE_POINT_CONTACT_BALL_BEARING_WTIH_DOUBLE_OUTER_RING_CONTACT:
            case BearingConstructionType.LB_RADIAL_VIERPUNKTLAGER:
            case BearingConstructionType.LB_RADIAL_FOUR_POINT_CONTACT_BEARING_WITH_EQUILIBRIUM:
                return BearingView2DUrls.SVG_VIERPUNKTLAGER_URL;
            case BearingConstructionType.LB_AXIAL_SCHRAEG_KUGELLAGER_EINREIHIG:
            case BearingConstructionType.LB_THRUST_ANGULAR_CONTACT_BALL_BEARING_SINGLE_ROW_SPINDLE_TYPE:
                return BearingView2DUrls.SVG_7004BE2RS_URL;
            case BearingConstructionType.LB_AXIAL_SCHRAEG_KUGELLAGER_ZWEIREIHIG_X_ANORDNUNG:
                return BearingView2DUrls.SVG_3309_URL;
            case BearingConstructionType.LB_AXIAL_SCHRAEG_KUGELLAGER_ZWEIREIHIG_O_ANORDNUNG:
            case BearingConstructionType.LB_THRUST_ANGULAR_CONTACT_BALL_BEARING_DOUBLE_ROW_O_ARRANGEMENT_SPINDLE_TYPE:
                return BearingView2DUrls.SVG_3309_URL;
            case BearingConstructionType.LB_AXIAL_VIERPUNKTLAGER:
            case BearingConstructionType.LB_THRUST_FOUR_POINT_CONTACT_BEARING_WITH_EQUILIBRIUM:
                return BearingView2DUrls.SVG_7004BE2RS_URL;
            case BearingConstructionType.LB_AXIAL_SCHRAEG_ROLLENLAGER_EINREIHIG:
            case BearingConstructionType.LB_AXIAL_SCHRAEG_NADELLAGER_EINREIHIG:
                return BearingView2DUrls.SVG_AXIAL_SCHRAEGROLLE_EINR_URL;
            case BearingConstructionType.LB_AXIAL_SCHRAEG_ROLLENLAGER_ZWEIREIHIG_O_ANORDNUNG:
            case BearingConstructionType.LB_AXIAL_SCHRAEG_NADELLAGER_ZWEIREIHIG_O_ANORDNUNG:
                return BearingView2DUrls.SVG_AXIAL_SCHRAEGROLLE_O_URL;
            case BearingConstructionType.LB_AXIAL_SCHRAEG_ROLLENLAGER_ZWEIREIHIG_X_ANORDNUNG:
            case BearingConstructionType.LB_AXIAL_SCHRAEG_NADELLAGER_ZWEIREIHIG_X_ANORDNUNG:
                return BearingView2DUrls.SVG_AXIAL_SCHRAEGROLLE_X_URL;
            case BearingConstructionType.LB_RADIAL_SCHRAEG_ROLLENLAGER_EINREIHIG:
            case BearingConstructionType.LB_RADIAL_SCHRAEG_NADELLAGER_EINREIHIG:
            case BearingConstructionType.LB_RADIAL_KEGEL_ROLLENLAGER_EINREIHIG:
                return BearingView2DUrls.SVG_SCHRAEGROLLE_EINR_URL;
            case BearingConstructionType.LB_RADIAL_SCHRAEG_ROLLENLAGER_ZWEIREIHIG_O_ANORDNUNG:
            case BearingConstructionType.LB_RADIAL_SCHRAEG_NADELLAGER_ZWEIREIHIG_O_ANORDNUNG:
            case BearingConstructionType.LB_RADIAL_KEGEL_ROLLENLAGER_ZWEIREIHIG_O_ANORDNUNG:
            case BearingConstructionType.LB_RADIAL_KEGEL_ROLLENLAGER_ZWEIREIHIG_O_ANORDNUNG_DEVIANT_TO_ISO:
                return BearingView2DUrls.SVG_SCHRAEGROLLE_O_URL;
            case BearingConstructionType.LB_RADIAL_SCHRAEG_ROLLENLAGER_ZWEIREIHIG_X_ANORDNUNG:
            case BearingConstructionType.LB_RADIAL_SCHRAEG_NADELLAGER_ZWEIREIHIG_X_ANORDNUNG:
            case BearingConstructionType.LB_RADIAL_KEGEL_ROLLENLAGER_ZWEIREIHIG_X_ANORDNUNG:
            case BearingConstructionType.LB_RADIAL_KEGEL_ROLLENLAGER_ZWEIREIHIG_X_ANORDNUNG_DEVIANT_TO_ISO:
                return BearingView2DUrls.SVG_SCHRAEGROLLE_X_URL;
            case BearingConstructionType.LB_RADIAL_SCHRAEG_ROLLENLAGER_ZWEIREIHIG_TANDEM:
            case BearingConstructionType.LB_RADIAL_SCHRAEG_NADELLAGER_ZWEIREIHIG_TANDEM:
            case BearingConstructionType.LB_RADIAL_KEGEL_ROLLENLAGER_ZWEIREIHIG_TANDEM:
                return BearingView2DUrls.SVG_SCHRAEGROLLE_TANDEM_URL;
            case BearingConstructionType.LB_RADIAL_KEGEL_ROLLENLAGER_DOPPEL_O_ANORDNUNG:
            case BearingConstructionType.LB_RADIAL_KEGEL_ROLLENLAGER_DOPPEL_O_ANORDNUNG_DEVIANT_TO_ISO:
                return BearingView2DUrls.SVG_KEGELROLLE_DOPPEL_O_URL;
            case BearingConstructionType.LB_RADIAL_KEGEL_ROLLENLAGER_DOPPEL_X_ANORDNUNG:
            case BearingConstructionType.LB_RADIAL_KEGEL_ROLLENLAGER_DOPPEL_X_ANORDNUNG_DEVIANT_TO_ISO:
                return BearingView2DUrls.SVG_KEGELROLLE_DOPPEL_X_URL;
            case BearingConstructionType.LB_ZKLN:
                return BearingView2DUrls.SVG_ZKLN90150_2Z_URL;
            case BearingConstructionType.LB_ZKLN_2AP:
                return BearingView2DUrls.SVG_ZKLN2AP_URL;
            case BearingConstructionType.LB_ZKLF:
                return BearingView2DUrls.SVG_ZKLF_URL;
            case BearingConstructionType.LB_ZKLF_2AP:
                return BearingView2DUrls.SVG_ZKLF2AP_URL;
            case BearingConstructionType.LB_ZKLFA:
                return BearingView2DUrls.SVG_ZKLFA_URL;
            case BearingConstructionType.LB_ZARN:
                return BearingView2DUrls.SVG_ZARN_URL;
            case BearingConstructionType.LB_ZARN_L:
                return BearingView2DUrls.SVG_ZARN_L_URL;
            case BearingConstructionType.LB_ZARF:
                return BearingView2DUrls.SVG_ZARF_URL;
            case BearingConstructionType.LB_ZARF_L:
                return BearingView2DUrls.SVG_ZARF_L_URL;
            case BearingConstructionType.LB_NKXR:
                return BearingView2DUrls.SVG_NKXR_URL;
            case BearingConstructionType.LB_NKX:
                return BearingView2DUrls.SVG_NKX_URL;
            case BearingConstructionType.LB_NX:
                return BearingView2DUrls.SVG_NX_URL;
            case BearingConstructionType.LB_YRT:
            case BearingConstructionType.LB_YRT_LRB:
            case BearingConstructionType.LB_YRTS:
            case BearingConstructionType.LB_Y_SERIES_SPLIT_INNER_RING:
            case BearingConstructionType.LB_Y_SERIES_SPLIT_OUTER_RING:
                return BearingView2DUrls.SVG_YRT100_URL;
            case BearingConstructionType.LB_NKIA:
                return BearingView2DUrls.SVG_NKIB_URL;
            case BearingConstructionType.LB_NKIB:
                return BearingView2DUrls.SVG_NKIB_URL;
            case BearingConstructionType.LB_DKLFA:
                return BearingView2DUrls.SVG_DKLFA_URL;
            case BearingConstructionType.LB_BSB2ROWO:
                throw new Error('IDMF_BSB_ZWEIREIHIG_O_WMF');
            case BearingConstructionType.LB_BSB2ROWX:
                throw new Error('IDMF_BSB_ZWEIREIHIG_X_WMF');
            case BearingConstructionType.LB_BSB3ROWO:
                throw new Error('IDMF_BSB_DREIREIHIG_O_WMF');
            case BearingConstructionType.LB_BSB3ROWX:
                throw new Error('IDMF_BSB_DREIREIHIG_X_WMF');
            case BearingConstructionType.LB_BSB4ROWO:
                throw new Error('IDMF_BSB_VIERREIHIG_O_WMF');
            case BearingConstructionType.LB_BSB4ROWX:
                throw new Error('IDMF_BSB_VIERREIHIG_X_WMF');
            case BearingConstructionType.LB_AXIAL_KEGEL_ROLLENLAGER_EINREIHIG:
                return BearingView2DUrls.SVG_AXIALKEGELROLLE_EINR_SYM_URL;
            case BearingConstructionType.LB_AXIAL_KEGEL_ROLLENLAGER_EINREIHIG_FACEPLATE_IS_SHAFT_WASHER:
                return BearingView2DUrls.SVG_AXIALKEGELROLLEBORDGS_URL;
            case BearingConstructionType.LB_AXIAL_KEGEL_ROLLENLAGER_EINREIHIG_FACEPLATE_IS_HOUSING_WASHER:
                return BearingView2DUrls.SVG_AXIALKEGELROLLEBORDWS_URL;
            case BearingConstructionType.LB_AXIAL_KEGEL_ROLLENLAGER_ZWEIREIHIG_X_ANORDNUNG_WS_MITTIG:
            case BearingConstructionType.LB_AXIAL_KEGEL_ROLLENLAGER_ZWEIREIHIG_O_ANORDNUNG_WS_MITTIG:
                return BearingView2DUrls.SVG_AXIALKEGELROLLE_ZWEIR_URL;
            case BearingConstructionType.LB_AXIAL_PENDEL_ROLLENLAGER:
            case BearingConstructionType.LB_AXIAL_PENDEL_ROLLENLAGER_E1:
                return BearingView2DUrls.SVG_AXIALPENDELROLLE_URL;
            case BearingConstructionType.LB_AXIAL_NADELKRANZ_58_EINSEITIG:
                if (nbRows === 1) {
                    return BearingView2DUrls.SVG_AXIAL_NADELKRANZ_EINR_URL;
                }
                break;
            case BearingConstructionType.LB_AXIAL_NADELKRAENZE_58_ZWEISEITIG_GS_MITTIG:
                if (nbRows === 1) {
                    return BearingView2DUrls.SVG_AXIAL_NADELKRAENZE_58_ZWEIS_GS_MITTIG_URL;
                }
                break;
            case BearingConstructionType.LB_AXIAL_NADELKRAENZE_58_ZWEISEITIG_WS_MITTIG:
                if (nbRows === 1) {
                    return BearingView2DUrls.SVG_AXIAL_NADELKRAENZE_58_ZWEIS_WS_MITTIG_URL;
                }
                break;
            case BearingConstructionType.LB_AXIAL_NADELLAGER_EINSEITIG:
                if (nbRows === 1) {
                    return BearingView2DUrls.SVG_AXIAL_NADELLAGER_EINR_URL;
                }
                break;
            case BearingConstructionType.LB_AXIAL_NADELLAGER_ZWEISEITIG_GS_MITTIG:
                if (nbRows === 1) {
                    return BearingView2DUrls.SVG_AXIAL_NADELLAGER_ZWEIS_GSM_URL;
                }
                break;
            case BearingConstructionType.LB_AXIAL_NADELLAGER_ZWEISEITIG_WS_MITTIG:
                if (nbRows === 1) {
                    return BearingView2DUrls.SVG_AXIAL_NADELLAGER_ZWEIS_WSM_URL;
                }
                break;
            case BearingConstructionType.LB_AXIAL_ZYLINDERROLLENLAGER_EINSEITIG:
                return this._getAxialRollenlagerUrl(nbRows);
            case BearingConstructionType.LB_AXIAL_ZYLINDERROLLENLAGER_ZWEISEITIG_GS_MITTIG:
                return this._getAxialRollenlagerGsmUrl(nbRows);
            case BearingConstructionType.LB_AXIAL_ZYLINDERROLLENLAGER_ZWEISEITIG_WS_MITTIG:
                return this._getAxialRollenlagerWsm(nbRows);
            case BearingConstructionType.LB_ROLLENHUELSEN_MIT_KAEFIG:
            case BearingConstructionType.LB_RADIAL_RILLENKUGELLAGER:
            case BearingConstructionType.LB_NADELKRANZ_58HRC:
            default:
                this._hasBorder = true;
                return '';
        }
        this._hasBorder = true;
        return null;
    }

    private _getBearingGroup(geometry: SupportGeometry): Observable<Group | null> {
        const wmfImage = this.createWMFImage(geometry);
        if (wmfImage != null) {
            const imageGroup = getSupportGroupFromWMF(wmfImage, geometry);
            return of(imageGroup);
        } else {
            return this._getBearingGroupFromSVG(geometry);
        }
    }

    private _getBearingGroupFromSVG(geometry: SupportGeometry): Observable<Group | null> {
        const { constructionType } = this._bearing;
        const url: string | null = this._getImageURL(constructionType);
        const { supportHeight: bearingHeight, supportWidth: bearingWidth } = getSupportDimensions(geometry);
        if (!!url) {
            return this.elementView2DInput
                .getSVGImageString(url)
                .pipe(
                    map((svgString: string) =>
                        this._createGroupFromSVGString(svgString, bearingWidth, bearingHeight, getColor(geometry.color)),
                    ),
                );
        } else if (url === '') {
            return of(new Group());
        } else {
            return of(null);
        }
    }

    // Create image manually. Can be overriden.
    protected createWMFImage(_geometry: SupportGeometry): WMFImage | null {
        return null;
    }

    private _getAxialRollenlagerUrl(nbRows: number): string | null {
        if (nbRows === 1) {
            return BearingView2DUrls.SVG_AXIAL_ROLLENLAGER_EINR_URL;
        } else if (nbRows === 2) {
            return BearingView2DUrls.SVG_AXIAL_ROLLENLAGER_ZWEIR_URL;
        }

        return null;
    }

    private _getAxialRollenlagerGsmUrl(nbRows: number): string | null {
        if (nbRows === 1) {
            return BearingView2DUrls.SVG_AXIAL_ROLLENLAGER_EINR_GSM_URL;
        } else if (nbRows === 2) {
            return BearingView2DUrls.SVG_AXIAL_ROLLENLAGER_ZWEIR_GSM_URL;
        }

        return null;
    }

    private _getAxialRollenlagerWsm(nbRows: number): string | null {
        if (nbRows === 1) {
            return BearingView2DUrls.SVG_AXIAL_ROLLENLAGER_EINR_WSM_URL;
        } else if (nbRows === 2) {
            return BearingView2DUrls.SVG_AXIAL_ROLLENLAGER_ZWEIR_WSM_URL;
        }

        return null;
    }

    private _createGroupFromSVGString(svgString: string, bearingWidth: number, bearingHeight: number, color: string): Group {
        const group = new Group();
        const paths: Path[] = [];
        const pathData = getSvgDrawingDataFromSvgString(svgString);
        pathData.forEach(({ data, filled }) => {
            const path: Path = new Path({
                data: data,
                stroke: color,
                strokeWidth: BEARING_STROKE_WIDTH_DEFAULT,
                strokeScaleEnabled: false,
            });
            if (filled) {
                path.fill(color);
            }
            paths.push(path);
            group.add(path);
        });
        const { width, height } = group.getClientRect({
            skipStroke: true,
        });
        // After create Konva.Path object, we should re-calculate the scaleX, scaleY
        // so that we can re-scale to corect size (Konva.Path.width ignore the inputs).
        const scaleX = bearingWidth / width;
        const scaleY = bearingHeight / height;
        group.removeChildren();
        paths.forEach(path => {
            path.strokeWidth(BEARING_STROKE_WIDTH_DEFAULT);
            path.strokeScaleEnabled(false);
            group.add(path);
        });
        group.scaleX(scaleX);
        group.scaleY(scaleY);
        const svgGroup = new Group().add(group);
        svgGroup.add(createBoundingBox(svgGroup));

        return svgGroup;
    }
}

import { Injectable, Injector } from '@angular/core';
import { DATA_PROVIDERS, DataProvider } from './data.provider';

@Injectable({
    providedIn: 'root',
})
export class DataProviderService {
    private _dataProviders: DataProvider[];

    /**
     * C'tor
     * @param _injector Injector service for fetching data providers to avoid circular dependency during application start
     */
    public constructor(private readonly _injector: Injector) {}

    /**
     * Gets a list of data providers supporting the given output format and the optional input formats
     * @param outputFormat Requested output format
     * @param inputFormats Requested input format(s)
     * @returns List of data providers matching the requested output/input formats
     */
    public getDataProviders(outputFormat: string, inputFormats?: string[]): DataProvider[] {
        let dataProviders = this._loadDataProviders().filter(dataProvider => dataProvider.outputFormat === outputFormat);

        if (inputFormats && inputFormats.length > 0) {
            dataProviders = dataProviders.filter(dataProvider =>
                dataProvider.inputFormats.some(inputFormat => inputFormats.indexOf(inputFormat) >= 0),
            );
        }
        return dataProviders;
    }

    private _loadDataProviders(): DataProvider[] {
        if (!this._dataProviders) {
            this._dataProviders = this._injector.get(DATA_PROVIDERS);
        }
        return this._dataProviders;
    }
}

import { IRect } from 'konva/lib/types';
import { ConeSegment } from '../../cae-model/shaft-segments/cone-segment';
import { ElementView2D } from '../elements-view/element-view-2d';
import { Dimensions2D } from './dimensions-2d';
import { HorizontalDimensionsInterface, VerticalDimensionsInterface } from './dimensions-utils';

export class ConeSegmentDimensions2D extends Dimensions2D {
    constructor(elementView2D: ElementView2D, modelViewClientRect: IRect, fractionDigits: number) {
        super(elementView2D, modelViewClientRect, fractionDigits);
    }

    protected doCreateHorizontalDimensionsInputs(): HorizontalDimensionsInterface[] {
        const { outerDiameterLeft, outerDiameterRight, length } = this.elementView2D.modelElement as ConeSegment;
        const objectStartRadius = outerDiameterLeft / 2;
        const leftPart: HorizontalDimensionsInterface = {
            objectRadius: objectStartRadius,
            objectPositionX: this.getCoordinatesAbsX(),
        };
        const objectEndRadius = outerDiameterRight / 2;
        const rightPart: HorizontalDimensionsInterface = {
            objectRadius: objectEndRadius,
            objectPositionX: this.getCoordinatesAbsX() + length,
        };
        return [leftPart, rightPart];
    }

    protected doCreateVerticalDimensionsInput(): VerticalDimensionsInterface[] {
        const { innerDiameterLeft, outerDiameterLeft, innerDiameterRight, outerDiameterRight, length } = this.elementView2D
            .modelElement as ConeSegment;

        const leftParts: VerticalDimensionsInterface[] = [innerDiameterLeft, outerDiameterLeft].map((diameter: number) => ({
            objectDiameter: diameter,
            objectPositionX: this.getCoordinatesAbsX(),
        }));
        const rightParts: VerticalDimensionsInterface[] = [innerDiameterRight, outerDiameterRight].map((diameter: number) => ({
            objectDiameter: diameter,
            objectPositionX: this.getCoordinatesAbsX() + length,
        }));

        return leftParts.concat(rightParts);
    }
}

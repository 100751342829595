import { PipeSegment } from '../../cae-model/shaft-segments/pipe-segment';
import { ModelElement } from '../../cae-model/model-element';
import { BasicMediator } from './basic-mediator';
import { BearinxStandardBinder } from '../binders/bearinx-standard-binder';

export class PipeSegmentMediator extends BasicMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_PIPE_SEGMENT'];
    }

    protected createModelElement(): ModelElement {
        return new PipeSegment();
    }

    private _createBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [
            { left: 'x', right: 'IDSE_XSECTION1' },
            { left: 'xEnd', right: 'IDSE_XSECTION2' },
            { left: 'innerDiameter', right: 'IDSE_INNERDIAMETER' },
            { left: 'outerDiameter', right: 'IDSE_OUTERDIAMETER' },
        ];
        this.addBinder(binder);
    }
}

import { PointLoad } from '../../../cae-model/loads/point-load';
import { ElementView3D } from '../element-view-3d';
import { Mesh, Vector3, BufferGeometry } from 'three';
import { createLoadView, LoadViewInterface } from '../../functions/create-load-view';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { createLoadMaterial } from '../../settings/default-materials';
import { getDiameter } from '../../../view-2d/functions/loads-utils';

export class PointLoadView3D extends ElementView3D {
    constructor(pointLoad: PointLoad) {
        super(pointLoad);
        const mesh = this._createMesh();
        this.groupWithoutChildren.add(mesh);
    }

    protected updateGroupWithoutChildren(): void {
        const geometry = this._createGeometry();
        this.mesh.geometry = geometry;
    }

    private _createGeometry(): BufferGeometry {
        const load = this._pointLoad;
        const outerDiameter = getDiameter(load);

        const force = new Vector3(load.forceX, load.forceY, load.forceZ);
        const loadViewInterfaceForce: LoadViewInterface = {
            outerDiameter: outerDiameter,
            direction: force,
            arrowLength: outerDiameter,
            isTorque: false,
        };
        const partForce = createLoadView(loadViewInterfaceForce);

        const torque = new Vector3(load.torqueX, load.torqueY, load.torqueZ);
        const loadViewInterfaceTorque: LoadViewInterface = {
            outerDiameter: outerDiameter,
            arrowLength: outerDiameter,
            direction: torque,
            isTorque: true,
        };

        const partTorque = createLoadView(loadViewInterfaceTorque);

        const geometries = [partForce, partTorque];

        return BufferGeometryUtils.mergeBufferGeometries(geometries);
    }

    private get _pointLoad(): PointLoad {
        return this.modelElement as PointLoad;
    }

    private _createMesh(): Mesh {
        const geometry = this._createGeometry();
        const material = createLoadMaterial();
        this.mesh = new Mesh(geometry, material);
        return this.mesh;
    }
}

import { ModelElement } from '../../cae-model/model-element';
import { Binder } from './binder';
import { BearinxModelObject } from '../bearinx-model-object';

export class BearinxBinder extends Binder {
    objectLeft: ModelElement;
    objectRight: BearinxModelObject;
    loadCase: number;

    constructor() {
        super();
    }

    protected proveLeftProperty(propertyName: string) {
        if (!(propertyName in this.objectLeft)) {
            const errorStr = 'BearinxBinder error: Property ' + propertyName + ' does not exist for this modelElement';
            throw new Error(errorStr);
        }
    }
}

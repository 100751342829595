import { RollingElement } from '../../cae-model/bearing-elements/rolling-element';
import { ModelElement } from '../../cae-model/model-element';
import { createVisualizationDataBinder } from '../functions/utils';
import { BasicMediator } from './basic-mediator';

export class RollingElementMediator extends BasicMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_ROLLING_ELEMENT'];
    }

    protected createModelElement(): ModelElement {
        return new RollingElement();
    }

    private _createBinder(): void {
        this.addBinder(createVisualizationDataBinder());
    }
}

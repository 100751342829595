import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModelError, ModelErrorType } from './model-error.model';

@Injectable({
    providedIn: 'root',
})
export class ModelErrorTranslatorService {
    constructor(private _translateService: TranslateService) {}

    translate(error: ModelError): string {
        switch (error.type) {
            case ModelErrorType.WrongTenantId:
                return this._translateService.instant('TENANT.WRONG_ID');
            case ModelErrorType.WrongGroupId:
                return this._translateService.instant('GROUP.WRONG_ID');
            case ModelErrorType.WrongId:
                return this._translateService.instant('SHARED.MODEL.WRONG_ID');
            default:
                return this._translateService.instant('ERRORS.GENERAL');
        }
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormControlModule } from '../form-control/form-control.module';
import { IconModule } from '../icon/icon.module';
import { ShareDialogComponent } from './share-dialog.component';

@NgModule({
    imports: [CommonModule, IconModule, FormControlModule, TranslateModule],
    declarations: [ShareDialogComponent],
})
export class ShareModule {}

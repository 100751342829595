import { IRect } from 'konva/lib/types';
import { KeySeat } from '../../../cae-model/notches/key-seat';
import { ElementView2D } from '../../elements-view/element-view-2d';
import { StandardNotchDimensions2D } from './standard-notch-dimensions-2d';

export class KeySeatDimensions2D extends StandardNotchDimensions2D {
    constructor(elementView2D: ElementView2D, modelViewClientRect: IRect, fractionDigits: number) {
        super(elementView2D, modelViewClientRect, fractionDigits);
    }

    protected getNotchWidth(): number {
        return (this.elementView2D.modelElement as KeySeat).width;
    }
}

import { Component } from '@angular/core';
import { ModalContext } from '../../models/modal.context';

@Component({
    selector: 'bx-content-dialog',
    templateUrl: 'content-dialog.component.html',
})
export class ContentDialogComponent {
    constructor(public readonly context: ModalContext) {}
}

import { Injectable } from '@angular/core';
import { ToastService } from '../../toast/toast.service';
import { ToastIconType } from '../../toast/toast.model';
import { PromptService } from '../../prompt/prompt.service';
import { ConnectionErrorPrompt } from '../connection-error/connection-error.prompt';
import { Observable } from 'rxjs';
import { PromptResult } from '../../prompt/prompt.model';

@Injectable({
    providedIn: 'root',
})
export class ErrorMessagesService {
    constructor(private readonly _toastService: ToastService, private readonly _promptService: PromptService) {}

    displayGeneralErrorMessage() {
        this._toastService.show({
            label: 'ERRORS.GENERAL',
            icon: ToastIconType.Error,
            closeIcon: true,
        });
    }

    displayServerUnavailableErrorMessage(): Observable<PromptResult> {
        return this._promptService.displayPrompt(ConnectionErrorPrompt, true);
    }

    displayErrorMessage(message: string) {
        this._toastService.show({
            label: message,
            icon: ToastIconType.Error,
            closeIcon: true,
        });
    }

    hideAllMessages() {
        this._toastService.hideAll();
    }
}

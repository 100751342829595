import { ModelElement } from '../../../../cae-model/model-element';
import { RadialSphericalPlain } from '../../../../cae-model/supports/sliding-bearings/radial-spherical-plain';
import { SlidingBearingMediator } from './sliding-bearing-mediator';

export class RadialSphericalPlainMediator extends SlidingBearingMediator {
    constructor() {
        super();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_RADIAL_SPHERICAL_PLAIN_BEARING'];
    }

    protected createModelElement(): ModelElement {
        return new RadialSphericalPlain();
    }
}

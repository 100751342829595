import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { AppInitializationError } from '../auth/auth.module';
import { DataModelInterceptor } from './data-model.interceptor';
import { DataModelService } from './data-model.service';

export function DataModelServiceFactory(dataModelService: DataModelService): () => Promise<any> {
    return () =>
        dataModelService.prefetch().catch(() => {
            throw new AppInitializationError('Cannot load DataModels (units, ...)');
        });
}

@NgModule()
export class DataModelModule {
    public static forRoot(): ModuleWithProviders<DataModelModule> {
        return {
            ngModule: DataModelModule,
            providers: [
                {
                    provide: APP_INITIALIZER,
                    multi: true,
                    useFactory: DataModelServiceFactory,
                    deps: [DataModelService],
                },
                { provide: HTTP_INTERCEPTORS, multi: true, useClass: DataModelInterceptor },
            ],
        };
    }
}

import Konva from 'konva';
import { Hub } from '../../../cae-model/notches/hub';
import { generateUpperAndLowerParts } from '../../functions/utils-2d';
import { DISPLAYRED, SEGMENT_COLOR_2D, STROKE_WIDTH } from '../view-2d-constants';
import { NotchView2D } from './notch-view-2d';

export class HubView2D extends NotchView2D {
    constructor(hub: Hub) {
        super(hub);
    }

    private get _hub(): Hub {
        return this.modelElement as Hub;
    }

    protected updateGroupWithoutChildren(): void {
        super.rerenderViewWithoutChildren('hub-view-2d-group');
    }

    protected createNotchView(): Konva.Group {
        const group = new Konva.Group();
        const { xInShaftCS, width } = this._hub;
        const outerRadius = this._hub.getCurrentOuterRadius(xInShaftCS);
        const heigth = outerRadius / 8.0;

        const rect = new Konva.Rect({
            x: 0,
            y: -outerRadius - heigth,
            width: width,
            height: heigth,
            stroke: DISPLAYRED,
            strokeWidth: STROKE_WIDTH,
            strokeScaleEnabled: false,
            fill: SEGMENT_COLOR_2D,
        });
        group.add(generateUpperAndLowerParts(rect, 'rectangular-hub'));

        return group;
    }
}

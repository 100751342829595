/* eslint-disable max-len */

import { Bearing } from './bearing';

export enum SHOULDER_ARRANGEMENT {
    LB_WITHOUT_SHOULDERS = 'LB_WITHOUT_SHOULDERS',
    LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING = 'LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING',
    LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING_AND_LEFT_AT_INNER_RING = 'LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING_AND_LEFT_AT_INNER_RING',
    LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING_AND_RIGHT_AT_INNER_RING = 'LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING_AND_RIGHT_AT_INNER_RING',
    LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING = 'LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING',
    LB_SHOULDERS_LEFT_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING = 'LB_SHOULDERS_LEFT_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING',
    LB_SHOULDERS_RIGHT_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING = 'LB_SHOULDERS_RIGHT_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING',
    LB_SHOULDERS_ON_BOTH_SIDES_AT_INNER_RING = 'LB_SHOULDERS_ON_BOTH_SIDES_AT_INNER_RING',
    LB_SHOULDER_LEFT_AT_OUTER_RING = 'LB_SHOULDER_LEFT_AT_OUTER_RING',
    LB_SHOULDER_RIGHT_AT_OUTER_RING = 'LB_SHOULDER_RIGHT_AT_OUTER_RING',
    LB_SHOULDER_LEFT_AT_INNER_RING = 'LB_SHOULDER_LEFT_AT_INNER_RING',
    LB_SHOULDER_RIGHT_AT_INNER_RING = 'LB_SHOULDER_RIGHT_AT_INNER_RING',
    LB_SHOULDERS_LEFT_AT_OUTER_RING_AND_RIGHT_AT_INNER_RING = 'LB_SHOULDERS_LEFT_AT_OUTER_RING_AND_RIGHT_AT_INNER_RING',
    LB_SHOULDERS_RIGHT_AT_OUTER_RING_AND_LEFT_AT_INNER_RING = 'LB_SHOULDERS_RIGHT_AT_OUTER_RING_AND_LEFT_AT_INNER_RING',
    LB_SHOULDERS_LEFT_AT_OUTER_RING_AND_LEFT_AT_INNER_RING = 'LB_SHOULDERS_LEFT_AT_OUTER_RING_AND_LEFT_AT_INNER_RING',
    LB_SHOULDERS_RIGHT_AT_OUTER_RING_AND_RIGHT_AT_INNER_RING = 'LB_SHOULDERS_RIGHT_AT_OUTER_RING_AND_RIGHT_AT_INNER_RING',
    LB_SHOULDERS_ON_LEFT_EDGE_AT_INNER_RING = 'LB_SHOULDERS_ON_LEFT_EDGE_AT_INNER_RING',
    LB_SHOULDERS_ON_LEFT_EDGE_AND_RIGHT_EDGE_AT_INNER_RING = 'LB_SHOULDERS_ON_LEFT_EDGE_AND_RIGHT_EDGE_AT_INNER_RING',
    LB_SHOULDERS_ON_LEFT_EDGE_AT_OUTER_RING_AND_RIGHT_EDGE_AND_LEFT_EDGE_AT_INNER_RING = 'LB_SHOULDERS_ON_LEFT_EDGE_AT_OUTER_RING_AND_RIGHT_EDGE_AND_LEFT_EDGE_AT_INNER_RING',
    LB_SHOULDERS_ON_LEFT_EDGE_AND_RIGHT_EDGE_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING = 'LB_SHOULDERS_ON_LEFT_EDGE_AND_RIGHT_EDGE_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING',
    LB_SHOULDERS_ON_LEFT_EDGE_AND_RIGHT_EDGE_AT_OUTER_RING_AND_LEFT_EDGE_AT_INNER_RING = 'LB_SHOULDERS_ON_LEFT_EDGE_AND_RIGHT_EDGE_AT_OUTER_RING_AND_LEFT_EDGE_AT_INNER_RING',
    LB_SHOULDERS_ON_LEFT_EDGE_AND_RIGHT_EDGE_AT_OUTER_RING = 'LB_SHOULDERS_ON_LEFT_EDGE_AND_RIGHT_EDGE_AT_OUTER_RING',
    LB_SHOULDERS_ON_RIGHT_INSIDE_AND_LEFT_INSIDE_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING = 'LB_SHOULDERS_ON_RIGHT_INSIDE_AND_LEFT_INSIDE_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING',
    LB_SHOULDERS_ON_LEFT_EDGE_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING = 'LB_SHOULDERS_ON_LEFT_EDGE_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING',
    LB_SHOULDERS_ON_RIGHT_EDGE_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING = 'LB_SHOULDERS_ON_RIGHT_EDGE_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING',
    LB_SHOULDERS_ON_RIGHT_EDGE_AT_OUTER_RING_AND_RIGHT_EDGE_AND_LEFT_EDGE_AT_INNER_RING = 'LB_SHOULDERS_ON_RIGHT_EDGE_AT_OUTER_RING_AND_RIGHT_EDGE_AND_LEFT_EDGE_AT_INNER_RING',
    LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING_AND_ON_LEFT_EDGE_AT_INNER_RING = 'LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING_AND_ON_LEFT_EDGE_AT_INNER_RING',
    LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING_AND_RIGHT_EDGE_AND_LEFT_EDGE_AT_INNER_RING = 'LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING_AND_RIGHT_EDGE_AND_LEFT_EDGE_AT_INNER_RING',
    LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING_AND_LEFT_EDGE_AND_RIGHT_EDGE_BORD_WASHER_AT_INNER_RING = 'LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING_AND_LEFT_EDGE_AND_RIGHT_EDGE_BORD_WASHER_AT_INNER_RING',
    LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING_AND_RIGHT_EDGE_BORD_WASHER_AT_INNER_RING = 'LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING_AND_RIGHT_EDGE_BORD_WASHER_AT_INNER_RING',
    LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING_AND_LEFT_EDGE_BORD_WASHER_AT_INNER_RING = 'LB_SHOULDERS_ON_BOTH_SIDES_AT_OUTER_RING_AND_LEFT_EDGE_BORD_WASHER_AT_INNER_RING',
    LB_SHOULDERS_LEFT_EDGE_BORD_WASHER_AND_RIGHT_EDGE_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING = 'LB_SHOULDERS_LEFT_EDGE_BORD_WASHER_AND_RIGHT_EDGE_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING',
    LB_SHOULDERS_LEFT_EDGE_AND_RIGHT_EDGE_BORD_WASHER_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING = 'LB_SHOULDERS_LEFT_EDGE_AND_RIGHT_EDGE_BORD_WASHER_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING',
    LB_SHOULDERS_LEFT_EDGE_BORD_WASHER_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING = 'LB_SHOULDERS_LEFT_EDGE_BORD_WASHER_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING',
    LB_SHOULDERS_RIGHT_EDGE_BORD_WASHER_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING = 'LB_SHOULDERS_RIGHT_EDGE_BORD_WASHER_AT_OUTER_RING_AND_ON_BOTH_SIDES_AT_INNER_RING',
}

/**
 * RadialRoller
 */
export class RadialRoller extends Bearing {
    shoulderArrangement = '';
}

import { ModelElement } from '../model-element';
import { TRANS_TOLERANCE } from '../cae-model-constants';
import { Notch } from '../notches/notch';

export interface ShaftSegmentCoordinates {
    xStart: number;
    xEnd: number;
}

export abstract class ShaftSegment extends ModelElement {
    static readonly minLength = TRANS_TOLERANCE;
    private _xEnd: number;

    get length(): number {
        return this.xEnd - this.x;
    }

    get xEnd(): number {
        return this._xEnd;
    }

    set xEnd(val: number) {
        this._xEnd = val;
    }

    setCoordinatesFunc: (segmentId: string, coord: ShaftSegmentCoordinates) => void;

    protected doAdd(child: ModelElement) {
        if (child instanceof Notch) {
            this._addNotch(child);
        }
    }

    private _addNotch(notch: Notch): void {
        notch.getSegmentXFunc = () => this.x;
        notch.getSegmentXEndFunc = () => this.xEnd;
        notch.updateX();
        notch.getCurrentInnerRadius = (x: number) => this.getCurrentInnerRadius(x);
        notch.getCurrentOuterRadius = (x: number) => this.getCurrentOuterRadius(x);
    }

    setCoordinates(coord: ShaftSegmentCoordinates): void {
        this.setCoordinatesFunc(this.id, coord);
    }

    // returns the current outer radius of the segment,
    // corresponding to this x where x is the x-coordinate in the shaft coordinate system
    getCurrentOuterRadius(xInShaftCS: number): number {
        if (xInShaftCS < this.x || xInShaftCS > this.xEnd) {
            return 0;
        }
        return this.doGetCurrentOuterRadius(xInShaftCS);
    }

    // returns the current inner radius of the segment,
    // corresponding to this x where x is the x-coordinate in the shaft coordinate system
    getCurrentInnerRadius(xxInShaftCS: number): number {
        if (xxInShaftCS < this.x || xxInShaftCS > this.xEnd) {
            return 0;
        }
        return this.doGetCurrentInnerRadius(xxInShaftCS);
    }

    protected abstract doGetCurrentOuterRadius(x: number): number;
    protected abstract doGetCurrentInnerRadius(x: number): number;
}

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, Observable, of, throwError } from 'rxjs';
import { catchError, map, retryWhen, share, switchMap } from 'rxjs/operators';
import { DataModelService } from 'src/modules/data-model/data-model.service';
import { environment } from '../../../environments/environment';
import { PromptResult } from '../../prompt/prompt.model';
import { SpinnerService } from '../../spinner/spinner.service';
import { ErrorMessagesService } from '../error-messages/error-messages.service';
import { LoggingService } from '../logging.service';
import { HttpStatusCode } from './http-status-code.model';

const OFFLINE_STATUS = 0;
const retryCodeList = [OFFLINE_STATUS, HttpStatusCode.ServiceUnavailable, HttpStatusCode.GatewayTimeout];

function confirmRetry<T>(retry$: Observable<boolean>): (observable: Observable<T>) => Observable<T> {
    return retryWhen(errors =>
        errors.pipe(
            switchMap(error => {
                if (retryCodeList.includes(error.status)) {
                    return retry$.pipe(switchMap(retry => (retry ? of(1) : throwError(error))));
                }
                return throwError(error);
            }),
        ),
    );
}

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    private readonly _retry$ = defer(() => {
        this._spinnerService.removeAllSpinners();
        this._errorMessagesService.hideAllMessages();

        return this._errorMessagesService.displayServerUnavailableErrorMessage().pipe(map(result => result === PromptResult.Confirm));
    }).pipe(share());

    constructor(
        private _loggingService: LoggingService,
        private _errorMessagesService: ErrorMessagesService,
        private _spinnerService: SpinnerService,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Allow using the app with offline settings (cannot inject settings service)
        if (request.url === environment.settingsUrl) {
            return next.handle(request);
        }

        // Let initializer requests pass through (TODO use HttpContext in ng13)
        if (request.url.startsWith(environment.oauth.issuer!) || request.url.includes('DataModel/model')) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
            confirmRetry(this._retry$),
            catchError(error => {
                if (error instanceof HttpErrorResponse) {
                    this._loggingService.log(`Error Code: ${error.status}\nMessage: ${error.message}`);
                    this._displayErrorMessage(error);
                }
                return throwError(error);
            }),
        );
    }

    private _displayErrorMessage(error: HttpErrorResponse): void {
        if (error.status === HttpStatusCode.BadGateway || error.status === HttpStatusCode.NotImplemented) {
            this._errorMessagesService.displayGeneralErrorMessage();
        }
        if (error.status === HttpStatusCode.Unauthorized) {
            this._errorMessagesService.displayErrorMessage('ERRORS.LOGIN_EXPIRED');
        }
    }
}

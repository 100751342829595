import { Group } from 'konva/lib/Group';
import { Line } from 'konva/lib/shapes/Line';
import { Worm } from '../../../../cae-model/transmission-elements/worm';
import { WormGeometry } from '../../../../views-foundation/geometries/worm-geometry';
import { generateUpperAndLowerParts } from '../../../functions/utils-2d';
import { BACKGROUND_COLOR_2D, DISPLAYGREEN, DISPLAYRED, STROKE_WIDTH } from '../../view-2d-constants';
import { TransmissionElementView2D } from '../transmission-element-view-2d';

export class WormView2D extends TransmissionElementView2D {
    constructor(private _worm: Worm) {
        super(_worm);
    }

    private _geometry: WormGeometry;

    protected createShapes(): Group {
        return new Group({
            name: 'worm-view-2d-group',
            transformable: true,
        });
    }

    protected paintElement(group: Group): void {
        this._updateWorm(group);
    }

    protected paintPitchLines(group: Group): void {
        this._updatePitchLine(group);
    }

    protected setGeometry(): void {
        this._geometry = new WormGeometry(this._worm, this.input.unitScaleFactor);
    }

    private _updateWorm(group: Group): void {
        const { tBO, tBI, tBore, tDI, tDO } = this._geometry;
        const leftX1 = -tBO / 2.0;
        const leftX2 = -tBI / 2.0;
        const rightX1 = tBO / 2.0;
        const rightX2 = tBI / 2.0;

        const worm = new Line({
            stroke: this._geometry.isOnShaft ? DISPLAYGREEN : DISPLAYRED,
            strokeWidth: STROKE_WIDTH,
            strokeScaleEnabled: false,
            points: [
                leftX1,
                -tBore / 2.0,
                leftX1,
                -tDI / 2.0,
                leftX2,
                -tDO / 2.0,
                rightX2,
                -tDO / 2.0,
                rightX1,
                -tDI / 2.0,
                rightX1,
                -tBore / 2.0,
            ],
            fill: BACKGROUND_COLOR_2D,
            closed: true,
        });

        group.add(generateUpperAndLowerParts(worm, 'worm-part'));
    }

    private _updatePitchLine(group: Group): void {
        const { tBO, tBI, isOnShaft, tDM } = this._geometry;
        const width = tBO + (tBO - tBI) / 2.0;

        super.paintHorizontalPitchLines(group, isOnShaft, width, tDM);
    }
}

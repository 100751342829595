<bx-title-bar><h4 caption translate>TENANT.TITLE</h4></bx-title-bar>

<div class="content">
    <ng-container *ngIf="tenantService.tenants$ | async; else noTenant">
        <p>{{ 'TENANT.INFO' | translate }}:</p>
        <bx-tenant-selector (tenantSelected)="goHomeScreen()"></bx-tenant-selector>
    </ng-container>

    <ng-template #noTenant>
        <h2>{{ 'TENANT.NO_TENANT.TITLE' | translate }}</h2>
        <p>{{ 'TENANT.NO_TENANT.INFO' | translate }}</p>
    </ng-template>
</div>

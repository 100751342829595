import { Group } from 'konva/lib/Group';
import { Line } from 'konva/lib/shapes/Line';
import { GearAbstract } from '../../../../cae-model/transmission-elements/gear-abstract';
import { GearAbstractGeometry } from '../../../../views-foundation/geometries/gear-abstract-geometry';
import { generateUpperAndLowerParts } from '../../../functions/utils-2d';
import { BACKGROUND_COLOR_2D, DISPLAYBLACK, DISPLAYGREEN, DISPLAYRED, STROKE_WIDTH } from '../../view-2d-constants';
import { TransmissionElementView2D } from '../transmission-element-view-2d';

export class GearAbstractView2D extends TransmissionElementView2D {
    constructor(private _gearAbstract: GearAbstract) {
        super(_gearAbstract);
    }

    private _geometry: GearAbstractGeometry;

    protected setGeometry(): void {
        this._geometry = new GearAbstractGeometry(this._gearAbstract, this.input.unitScaleFactor);
    }

    protected createShapes(): Group {
        return new Group({
            name: 'gear-abstract-view-2d-group',
            transformable: true,
        });
    }

    protected paintElement(group: Group): void {
        this._updateGearAbstract(group);
    }
    protected paintPitchLines(group: Group): void {
        this._updatePitchLine(group);
    }

    private _updateGearAbstract(group: Group): void {
        const { isOnShaft, tB, tBore, tXLoad, tDO } = this._geometry;
        group.add(
            generateUpperAndLowerParts(
                new Line({
                    stroke: isOnShaft ? DISPLAYGREEN : DISPLAYRED,
                    strokeWidth: STROKE_WIDTH,
                    strokeScaleEnabled: false,
                    points: [-tB / 2.0, -tBore / 2.0, tXLoad - tB / 2.0, -tDO / 2.0, tXLoad + tB / 2.0, -tDO / 2.0, tB / 2.0, -tBore / 2.0],
                    closed: true,
                    fill: BACKGROUND_COLOR_2D,
                }),
                'gear-abstract-part',
            ),
        );
    }

    private _updatePitchLine(group: Group): void {
        const { tB, tDM, isOnShaft, tXLoad } = this._geometry;
        const width = tB / 8.0;
        const dmHalf = tDM / 2.0;
        const color = isOnShaft ? DISPLAYBLACK : DISPLAYRED;

        const lines: number[][] = [];
        lines.push([tXLoad - width, -dmHalf - width, tXLoad + width, -dmHalf + width]);
        lines.push([tXLoad - width, -dmHalf + width, tXLoad + width, -dmHalf - width]);

        lines.forEach(line => {
            group.add(
                generateUpperAndLowerParts(
                    new Line({
                        stroke: color,
                        strokeWidth: STROKE_WIDTH,
                        strokeScaleEnabled: false,
                        points: line,
                    }),
                    'gear-abstract-pitch-line',
                ),
            );
        });
    }
}

export class XForm {
    private x_ = 0;
    private y_ = 0;
    private width_ = 0;
    private height_ = 0;
    private xBearinx_ = 0;
    private yBearinx_ = 0;
    private xReflect_ = false;
    private yReflect_ = false;

    constructor(
        x: number,
        y: number,
        width: number,
        height: number,
        xBearinx: number,
        yBearinx: number,
        xReflect: boolean,
        yReflect: boolean,
    ) {
        this.x_ = x;
        this.y_ = y;
        this.width_ = width;
        this.height_ = height;
        this.xBearinx_ = xBearinx;
        this.yBearinx_ = yBearinx;
        this.xReflect_ = xReflect;
        this.yReflect_ = yReflect;
    }

    calculateX(x: number): number {
        return this.x_ + this.width_ * Math.abs((x + this.xBearinx_) / (this.xBearinx_ * 2.0) - (this.xReflect_ ? 1.0 : 0.0));
    }

    calculateY(y: number): number {
        return this.y_ + this.height_ * Math.abs(Math.abs(y - this.yBearinx_) / (this.yBearinx_ * 2.0) - (this.yReflect_ ? 1.0 : 0.0));
    }

    calculateHorizontalRadius(r: number): number {
        return (this.width_ / (this.xBearinx_ * 2.0)) * r;
    }

    calculateVerticalRadius(r: number): number {
        return (this.height_ / (this.yBearinx_ * 2.0)) * r;
    }
}

import { ConnectableObservable, Observable, ReplaySubject } from 'rxjs';
import { filter, last, mapTo, multicast, scan, takeWhile } from 'rxjs/operators';
import { LogItem, OrderedLogItem } from './dialogs/dashboard/logbook/log-item.model';

export interface CalculationProgress {
    isFinished: boolean;
    errorMessage: string | null;
    log: OrderedLogItem | null;
    calculationId: string;
}

export class CalculationContext {
    public readonly logs$ = new ReplaySubject<LogItem[]>(1);
    public readonly finished$ = this.logs$.pipe(
        mapTo(void 0),
        last(() => true, void 0),
    );

    constructor(public readonly id: string, progress$: Observable<CalculationProgress>) {
        const logCollector$ = this._makeLogs$(id, progress$).pipe(multicast(() => this.logs$));
        (logCollector$ as ConnectableObservable<LogItem[]>).connect();
    }

    private _makeLogs$(id: string, progress$: Observable<CalculationProgress>): Observable<LogItem[]> {
        return progress$.pipe(
            filter(({ calculationId }) => calculationId === id),
            takeWhile(({ isFinished }) => !isFinished),
            scan((logs, { log }) => logs.concat(log || []), [] as LogItem[]),
        );
    }
}

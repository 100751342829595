<form (ngSubmit)="submit()" [formGroup]="searchForm">
    <i class="material-icons">search</i>

    <input
        #searchField
        type="text"
        placeholder="{{ 'SHARED.SEARCH.GOTO' | translate }}"
        bxHotkey="esc"
        (onHotkey)="close()"
        formControlName="search"
    />

    <div class="results">
        <ul>
            <li>
                <i class="material-icons">note</i>
                <span>VW Tiguan Radlager</span>
            </li>
            <li>
                <i class="material-icons">note</i>
                <span>Porsche 911 PDK Getriebe</span>
            </li>
            <li>
                <i class="material-icons">help</i>
                <span>Radlager Rollabrieb verändern</span>
            </li>
            <li>
                <i class="material-icons">subdirectory_arrow_right</i>
                <span>Radlager Rollabrieb verändern</span>
            </li>
            <li>
                <i class="material-icons">note</i>
                <span>VW Tiguan Radlager</span>
            </li>
            <li>
                <i class="material-icons">note</i>
                <span>Porsche 911 PDK Getriebe</span>
            </li>
            <li>
                <i class="material-icons">help</i>
                <span>Radlager Rollabrieb verändern</span>
            </li>
            <li>
                <i class="material-icons">subdirectory_arrow_right</i>
                <span>Radlager Rollabrieb verändern</span>
            </li>
            <li>
                <i class="material-icons">note</i>
                <span>VW Tiguan Radlager</span>
            </li>
            <li>
                <i class="material-icons">note</i>
                <span>Porsche 911 PDK Getriebe</span>
            </li>
            <li>
                <i class="material-icons">help</i>
                <span>Radlager Rollabrieb verändern</span>
            </li>
            <li>
                <i class="material-icons">subdirectory_arrow_right</i>
                <span>Radlager Rollabrieb verändern</span>
            </li>
        </ul>
    </div>
</form>

import { ModelElement } from '../../../cae-model/model-element';
import { UnderCut } from '../../../cae-model/notches/under-cut';
import { BearinxStandardBinder } from '../../binders/bearinx-standard-binder';
import { NotchMediator } from './notch-mediator';

// mediator, used for the convergence of UnderCut
export class UnderCutMediator extends NotchMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_CLEARANCE_CUT'];
    }

    protected createModelElement(): ModelElement {
        return new UnderCut();
    }

    private _createBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [
            { left: 'radius', right: 'IDN_SHOULDER_RADIUS' },
            { left: 'depth', right: 'IDN_CLEARANCE_CUT_DEPTH' },
            { left: 'width', right: 'IDN_CLEARANCE_CUT_WIDTH' },
            { left: 'cutType', right: 'IDN_TYPE_OF_CLEARANCE_CUT' },
        ];
        this.addBinder(binder);
    }
}

import { ModelElement } from '../../../cae-model/model-element';
import { UGroove } from '../../../cae-model/notches/u-groove';
import { BearinxStandardBinder } from '../../binders/bearinx-standard-binder';
import { NotchMediator } from './notch-mediator';

// mediator, used for the convergence of U-Groove
export class UGrooveMediator extends NotchMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_U_GROOVE'];
    }

    protected createModelElement(): ModelElement {
        return new UGroove();
    }

    private _createBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [{ left: 'radius', right: 'IDN_UGROOVE_RADIUS' }];
        this.addBinder(binder);
    }
}

import { CurrentObjectProviderService } from '../../views-foundation/services/current-object-provider.service';
import { CaeModelProviderService } from '../../views-foundation/services/cae-model-provider.service';
import { BearinxMediatorService } from './bearinx-mediator.service';
import { BearinxModelProviderService } from './bearinx-model-provider.service';
import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';

@Injectable()
export class BearinxConverterService implements OnDestroy {
    private _subscription = Subscription.EMPTY;
    private _bearinxModel: any;
    private _loadcase = 0;
    constructor(
        private _bearinxModelProvider: BearinxModelProviderService,
        private _bearinxMediatorService: BearinxMediatorService,
        private _currentObjectProviderService: CurrentObjectProviderService,
        private _caeModelProviderService: CaeModelProviderService,
    ) {}

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
        this._bearinxMediatorService.ngOnDestroy();
        this._bearinxModel = null;
    }

    init(): void {
        this._subscription = combineLatest([
            this._bearinxModelProvider.getBearinxModel(),
            this._currentObjectProviderService.getLoadCase(),
        ]).subscribe(([model, loadCase]) => {
            if (this._bearinxModel !== model || this._loadcase !== loadCase) {
                this._bearinxModel = model;
                this._loadcase = loadCase;
                this._convertBxModel();
            }
        });
    }

    private _convertBxModel() {
        const caeModel = this._bearinxMediatorService.convert(this._bearinxModel, this._loadcase);
        if (caeModel != null) {
            this._caeModelProviderService.model = caeModel;
        }
    }
}

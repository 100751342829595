import { PointMass } from './../../../cae-model/loads/point-mass';
import { ElementView3D } from '../element-view-3d';
import { Mesh, BufferGeometry, MathUtils } from 'three';
import { createCylinderWithHole, CylinderWithHoleInterface } from '../../functions/utils-3d';
import { createLoadMaterial } from '../../settings/default-materials';
import { clipMesh } from '../../functions/clip-mesh';
import { getDiameter } from '../../../view-2d/functions/loads-utils';

export class PointMassView3D extends ElementView3D {
    constructor(pointMass: PointMass) {
        super(pointMass);
        const mesh = this._createMesh();
        this.groupWithoutChildren.add(mesh);
    }

    protected updateGroupWithoutChildren(): void {
        const geometry = this._setGeometry();
        this.mesh.geometry = geometry;
        this.mesh.geometry = clipMesh(this.mesh, this.input.settings, this.groupWithoutChildren).geometry;
    }

    private get shaftOuterRadius(): number {
        return getDiameter(this._pointLoad) / 2;
    }

    private get _holeRadius(): number {
        return this.shaftOuterRadius * 1.01;
    }

    private get _cyliderLenght(): number {
        return this._holeRadius * 0.1;
    }

    private _setGeometry(): BufferGeometry {
        const cylinderRadius = this.shaftOuterRadius * 1.05;

        const cylinderWithHoleInterface: CylinderWithHoleInterface = {
            outerRadiusStart: cylinderRadius,
            outerRadiusEnd: cylinderRadius,
            innerRadiusStart: this._holeRadius,
            innerRadiusEnd: this._holeRadius,
            length: this._cyliderLenght,
        };
        return createCylinderWithHole(cylinderWithHoleInterface);
    }

    private get _pointLoad(): PointMass {
        return this.modelElement as PointMass;
    }

    private _createMesh(): Mesh {
        const geometry = this._setGeometry();
        const material = createLoadMaterial();
        this.mesh = new Mesh(geometry, material);
        this.mesh.rotation.z = MathUtils.degToRad(90);
        return this.mesh;
    }
}

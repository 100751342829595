<form [formGroup]="form" class="content">
    <bx-form-field>
        <label for="projectNameInput">{{ 'SHARED.PROJECT.NAME.CAPTION' | translate }}</label>
        <input
            id="projectNameInput"
            bxInput
            formControlName="name"
            placeholder="{{ 'SHARED.PROJECT.NAME.PLACEHOLDER' | translate }}"
            (blur)="emitTouched()"
            required
        />
        <span class="error-message" *ngIf="name.invalid && (name.dirty || name.touched)">{{
            'SHARED.PROJECT.NAME.ERROR_MESSAGE' | translate
        }}</span>
    </bx-form-field>
</form>

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SelectedElementInterface } from '../interfaces/selected-element-interface';

@Injectable()
export class SelectedElementService {
    private _selectedElementInterface = new BehaviorSubject<SelectedElementInterface>({
        elementID: '',
    });
    private _preselectedElementInterface = new BehaviorSubject<SelectedElementInterface>({
        elementID: '',
    });

    getSelectedElement(): Observable<SelectedElementInterface> {
        return this._selectedElementInterface;
    }

    setSelected(selectedElementInterface: SelectedElementInterface): void {
        this._selectedElementInterface.next(selectedElementInterface);
    }

    getPreselectedElement(): Observable<SelectedElementInterface> {
        return this._preselectedElementInterface;
    }

    setPreselected(selectedElementInterface: SelectedElementInterface): void {
        this._preselectedElementInterface.next(selectedElementInterface);
    }
}

import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { CameraService, View3DService, View3DSettingsService, View3DAxesRendererService, View3DVisualizationService } from './services';
import { Group, Object3D, Scene } from 'three';
import { takeUntil } from 'rxjs/operators';
import { View3DSettings } from './settings';
import { CameraView } from './interfaces/camera-view.enum';
import { SelectedInViewerElementInterface } from '../views-foundation/interfaces/selected-in-viewer-element-interface';
import { SelectedElementService } from '../views-foundation/services/selected-element.service';
import { getBboxCenter, getTranslatedCameraPosition } from './functions/utils-3d';
import { ModelElementFinder3DService } from './services/model-element-finder-3d.service';

@Component({
    selector: 'bx-app-view-3d',
    templateUrl: './view3D.component.html',
    styleUrls: ['./view3D.component.scss'],
})
export class View3DComponent implements OnInit, AfterViewInit, OnDestroy {
    private _width = 1;
    private _height = 1;
    private _animationFrameId: number;
    private _isFit = false;

    @ViewChild('canvas')
    private _canvasRef: ElementRef;

    @ViewChild('inset')
    private _insetRef: ElementRef;

    @ViewChild('view3DDiv') view3DDiv: ElementRef;

    @Output() event = new EventEmitter<SelectedInViewerElementInterface>();
    @Output() sceneReady = new EventEmitter<Scene>();

    private _modelGroup: Object3D | null;

    private _view3DSettings: View3DSettings;

    private readonly _destroy$ = new Subject<void>();

    constructor(
        private _view3DService: View3DService,
        private _view3DSettingsService: View3DSettingsService,
        private _cameraService: CameraService,
        private _view3DVisualizationService: View3DVisualizationService,
        private _subRendererService: View3DAxesRendererService,
        private _selectedElementService: SelectedElementService,
        private _modelElementFinder3DService: ModelElementFinder3DService,
    ) {
        this._animate();
    }

    ngOnInit() {
        this._view3DVisualizationService.initScene();
        this.sceneReady.emit(this._view3DVisualizationService.scene!);
        this._view3DSettingsService
            .getSettings()
            .pipe(takeUntil(this._destroy$))
            .subscribe((settings: View3DSettings) => (this._view3DSettings = settings));
        combineLatest([this._view3DService.getModelGroup(), this._view3DService.getSelectionBoxGroup()])
            .pipe(takeUntil(this._destroy$))
            .subscribe(([modelGroup, selectionBoxGroup]) => {
                this._setModelGroup(modelGroup, selectionBoxGroup);
            });
    }

    /* LIFECYCLE */
    ngAfterViewInit() {
        this._view3DVisualizationService.init(this._canvas);
        this._cameraService.init(this._canvas, this._view3DSettings.cameraType, this._view3DVisualizationService.renderer!.domElement);
        this._isFit = false;
        this.zoomToFit();
        this._subRendererService.init(this._cameraService.cameraUp, this._insetRef.nativeElement);
        this._view3DVisualizationService.clickEvent
            .pipe(takeUntil(this._destroy$))
            .subscribe((event: SelectedInViewerElementInterface) => this.event.emit(event));

        this._selectedElementService
            .getSelectedElement()
            .pipe(takeUntil(this._destroy$))
            .subscribe(({ elementID }) => {
                if (this._view3DSettings.rotateAroundSelection && this._modelGroup) {
                    const selectedElement = this._modelElementFinder3DService.getView(elementID);

                    if (selectedElement != null) {
                        const { controlsTarget, cameraPosition } = this._cameraService;
                        const centerSelectedElement = getBboxCenter(selectedElement.groupWithChildren);

                        const newCameraPosition = getTranslatedCameraPosition(cameraPosition, controlsTarget, centerSelectedElement);

                        this._cameraService.updateCameraPosition(newCameraPosition);
                        this._cameraService.updateControlsTarget(centerSelectedElement);
                    }
                }
            });
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._canvas.remove();
        this._insetRef.nativeElement.remove();
        this._view3DVisualizationService.destroy();
        this._subRendererService.destroy();
        this._view3DService.destroy();

        if (this._modelGroup != null) {
            this._modelGroup = null;
        }

        cancelAnimationFrame(this._animationFrameId);
    }

    @HostListener('document:keypress', ['$event'])
    public onKeyPress(event: KeyboardEvent) {
        // console.log("onKeyPress: " + event.key);
    }

    // @HostListener('window:resize', ['$event'])
    // public onResize(event: Event) {
    //     this._setSize();
    // }

    private _animate(): void {
        this._animationFrameId = requestAnimationFrame(() => this._animate());
        this._render();
    }

    private _setModelGroup(modelGroup: Group | null, selectionBoxGroup: Group | null) {
        if (modelGroup) {
            this._modelGroup = modelGroup;
            this._view3DVisualizationService.setModelGroupToScene(modelGroup, selectionBoxGroup, this._view3DSettings.unitSet);
            if (!this._isFit) {
                this.zoomToFit();
            }
            this._render();
        }
    }

    private get _canvas(): HTMLCanvasElement {
        return this._canvasRef.nativeElement;
    }

    // private _onModelLoadingCompleted(collada: View3DComponent) {
    //     const modelScene = collada.scene;
    //     this.scene.add(modelScene);
    //     this._render();
    // }

    private _render(): void {
        if (this._cameraService.camera != null) {
            this._cameraService.updateControls();
            if (this._view3DVisualizationService.renderer) {
                this._cameraService.updateCamera();
                this._view3DVisualizationService.render(this._cameraService.camera);
            }
            this._subRendererService.render(
                this._cameraService.cameraPosition,
                this._cameraService.controlsTarget,
                this._cameraService.aspectRatio,
            );
        }
    }

    fitView3DIntoParentContainer(): void {
        const container = this.view3DDiv?.nativeElement;
        if (container == null) {
            return;
        }

        const width = this.view3DDiv.nativeElement.clientWidth;
        const height = this.view3DDiv.nativeElement.clientHeight;
        if (width === this._width && height === this._height) {
            return;
        }
        this._width = width;
        this._height = height;

        this._canvas.style.width = width + 'px'; // '100%';
        this._canvas.style.height = height + 'px';

        this._cameraService.camera!.updateProjectionMatrix();
        this._view3DVisualizationService.renderer!.setSize(this._canvas.clientWidth, this._canvas.clientHeight);
        this._render();
    }

    switchView(viewType: CameraView): void {
        if (this._modelGroup != null) {
            this._cameraService.switchView({
                cameraView: viewType,
                noRotate: viewType === CameraView.PERSPECTIVE ? false : true,
                modelGroup: this._modelGroup,
            });
        }
    }

    zoomToFit(): void {
        if (this._modelGroup != null) {
            this._isFit = true;
            this._cameraService.zoomToFit(this._modelGroup);
        }
    }
}

import Konva from 'konva';
import { CounterMoment } from '../../../../cae-model/transmission-elements/counter-moment';
import { CounterMomentGeometry } from '../../../../views-foundation/geometries/counter-moment-geometry';
import { generateUpperAndLowerParts } from '../../../functions/utils-2d';
import { BACKGROUND_COLOR_2D, DISPLAYGREEN, DISPLAYRED, STROKE_WIDTH } from '../../view-2d-constants';
import { TransmissionElementView2D } from '../transmission-element-view-2d';

export class CounterMomentView2D extends TransmissionElementView2D {
    constructor(private _counterMoment: CounterMoment) {
        super(_counterMoment);
    }

    private _geometry: CounterMomentGeometry;

    protected setGeometry(): void {
        this._geometry = new CounterMomentGeometry(this._counterMoment, this.input.unitScaleFactor);
    }

    protected createShapes(): Konva.Group {
        return new Konva.Group({
            name: 'counter-moment-view-2d-group',
            transformable: true,
        });
    }

    protected paintElement(group: Konva.Group): void {
        this._updateCounterMoment(group);
    }

    protected paintPitchLines(_group: Konva.Group): void {}

    private _updateCounterMoment(group: Konva.Group): void {
        const { isOnShaft, tB, tDO, tDI } = this._geometry;

        group.add(
            generateUpperAndLowerParts(
                new Konva.Rect({
                    stroke: isOnShaft ? DISPLAYGREEN : DISPLAYRED,
                    strokeWidth: STROKE_WIDTH,
                    strokeScaleEnabled: false,
                    x: -tB / 2.0,
                    y: -tDO / 2.0,
                    width: tB,
                    height: (tDO - tDI) / 2.0,
                    fill: BACKGROUND_COLOR_2D,
                }),
                'counter-moment-rect',
            ),
        );

        this._paintArrows(group);
    }

    private _paintArrows(group: Konva.Group): void {
        const { tDO, tDI, tB } = this._geometry;
        const yPos = -tDO / 2.0 + (tDO - tDI) / 4.0;
        const symSize = Math.min(tB, (tDO - tDI) / 2.0) / 4.0;

        group.add(
            generateUpperAndLowerParts(this._createArrows([-symSize, yPos, 0, yPos - symSize, 0, yPos + symSize]), 'counter-moment-arrow'),
        );
        group.add(
            generateUpperAndLowerParts(
                this._createArrows([0, yPos, symSize, yPos - symSize, symSize, yPos + symSize]),
                'counter-moment-arrow',
            ),
        );
    }

    private _createArrows(points: number[]): Konva.Line {
        return new Konva.Line({
            stroke: DISPLAYRED,
            strokeWidth: STROKE_WIDTH,
            strokeScaleEnabled: false,
            points: points,
            closed: true,
            fill: DISPLAYRED,
        });
    }
}

export interface Notification {
    id: number;
    type: NotificationType;
    caption: string;
    seen: boolean;

    icon?: NotificationIcon;
    progress?: NotificationProgress;
    actions?: NotificationAction[];
}

export interface NotificationAction {
    label: string;
    actionFn: () => void;

    color?: NotificationColor;
    confirmationMsg?: string;
    preventDismiss?: boolean;
}

export type NotificationColor = 'success' | 'error' | 'sync';

export interface NotificationIcon {
    icon: string;
    color?: NotificationColor;
}

export interface NotificationProgress {
    current: number;
    total: number;
    done: boolean;
}

export enum NotificationType {
    Import = 'import',
    Export = 'export',
    System = 'system',
}

export class NotificationMutator {
    constructor(private _notification: Notification) {}

    public get(): Notification {
        return this._notification;
    }

    public icon(icon: NotificationIcon): NotificationMutator {
        return new NotificationMutator(this._update('icon', icon));
    }

    public progress(progress: NotificationProgress): NotificationMutator {
        return new NotificationMutator(this._update('progress', progress));
    }

    public seen(): NotificationMutator {
        return new NotificationMutator(this._update('seen', true));
    }

    public done(): NotificationMutator {
        const currentProgress = this._notification.progress || { current: 0, total: 0, done: true };
        return this.progress(Object.assign({}, currentProgress, { done: true }));
    }

    public actions(actions: NotificationAction[]): NotificationMutator {
        return new NotificationMutator(this._update('actions', actions));
    }

    private _update(property: string, value: any): Notification {
        return Object.assign({}, this._notification, { [property]: value });
    }
}

import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ErrorHandlerBaseService } from '../error-handling';
import { SpinnerService } from '../spinner/spinner.service';

@Injectable({
    providedIn: 'root',
})
export class ServerSideErrorHandlerService extends ErrorHandlerBaseService {
    constructor(private _spinnerService: SpinnerService) {
        super();
    }

    protected _handleServerSideError(error: HttpErrorResponse): Observable<any> {
        if (error.status === HttpStatusCode.Forbidden) {
            this._spinnerService.removeAllSpinners();
            return throwError('FORBIDDEN.MESSAGE');
        }
        return throwError(error);
    }
}

import { Injectable } from '@angular/core';
import { Box3, Object3D, OrthographicCamera, PerspectiveCamera, Vector3 } from 'three';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';
import { zoomToFitForOrthographicCamera } from '../functions/orthographic-camera-zoom';
import { zoomToFitForPerspectiveCamera } from '../functions/perspective-camera-zoom';
import { clearObject } from '../functions/utils-3d';
import { CameraView } from '../interfaces/camera-view.enum';
import {
    DEFAULT_CAMERA_POSTION_X,
    DEFAULT_CAMERA_POSTION_Y,
    DEFAULT_CAMERA_POSTION_Z,
    DEFAULT_CONTROLS_PAN_SPEED,
    DEFAULT_CONTROLS_ROTATE_SPEED,
    DEFAULT_CONTROLS_ZOOM_SPEED,
    DEFAULT_ORTHOGRAPHIC_CAMERA_FAR,
    DEFAULT_ORTHOGRAPHIC_CAMERA_NEAR,
    DEFAULT_PERSPECTIVE_CAMERA_ASPECT,
    DEFAULT_PERSPECTIVE_CAMERA_FAR,
    DEFAULT_PERSPECTIVE_CAMERA_FOV,
    DEFAULT_PERSPECTIVE_CAMERA_NEAR,
    DEFAULT_VIEW_DISTANCE,
} from '../settings/view-3d-constants';
import { CameraType } from '../settings/view-3d-settings';

export type View3DCamera = OrthographicCamera | PerspectiveCamera;

interface SwitchViewInterface {
    cameraView: CameraView;
    noRotate: boolean;
    modelGroup: Object3D;
}

@Injectable()
export class CameraService {
    private _camera: View3DCamera | null;
    private _controls: TrackballControls | null;
    private _canvas: HTMLCanvasElement;

    get camera(): View3DCamera | null {
        return this._camera;
    }

    get cameraUp(): Vector3 {
        return this._camera!.up;
    }

    get cameraPosition(): Vector3 {
        return this._camera!.position;
    }

    get controlsTarget(): Vector3 {
        return this._controls!.target;
    }

    get aspectRatio(): number {
        return this._getAspectRatio();
    }

    public init(canvas: HTMLCanvasElement, cameraType: CameraType, rendererDomElement: HTMLCanvasElement) {
        this._canvas = canvas;
        this._camera = this._createCamera(this._getAspectRatio(), cameraType);
        this._controls = this._createControls(rendererDomElement);
    }

    public updateControls(): void {
        if (this._controls) {
            // OrbitControl/TracballControl needs to update each render
            this._controls.update();
        }
    }

    public updateControlsTarget(newTarget: Vector3): void {
        if (this._controls != null) {
            this._controls.target.copy(newTarget);
        }
    }

    public updateCamera(): void {
        const aspect = this._getAspectRatio();
        if (this._camera instanceof OrthographicCamera) {
            this._camera.left = -aspect;
            this._camera.right = aspect;
        } else {
            this._camera!.aspect = aspect;
        }
        this._camera!.updateProjectionMatrix();
    }

    public updateCameraPosition(newPosition: Vector3): void {
        if (this._camera != null) {
            this._camera.position.copy(newPosition);
        }
    }

    public zoomToFit(object3D: Object3D): void {
        if (this._controls == null || this._camera == null) {
            return;
        }

        if (this._camera instanceof OrthographicCamera) {
            zoomToFitForOrthographicCamera(this._camera, this._controls, object3D);
        } else {
            this._controls.update(); // update the data so that PerspectiveCameraZoomHelper can read the latest data from camera
            zoomToFitForPerspectiveCamera(this._camera, this._controls, [object3D]);
        }
        this._camera.updateProjectionMatrix();
        this._controls.update();
    }

    public switchView(switchViewInterface: SwitchViewInterface): void {
        const { cameraView, noRotate, modelGroup } = switchViewInterface;

        if (this._controls != null) {
            this._controls.reset(); // avoid weird angel if rotate before switch View.
            this._controls.noRotate = noRotate; // Perspective view, noRotate is false.
        }
        if (this._camera && modelGroup != null) {
            modelGroup.updateMatrixWorld();

            const modelGroupBbox = new Box3();
            modelGroupBbox.setFromObject(modelGroup);

            const modelGroupSize = new Vector3();
            modelGroupBbox.getSize(modelGroupSize);

            const modelGroupCenter = new Vector3();
            modelGroupBbox.getCenter(modelGroupCenter);

            const newPosition = new Vector3();
            switch (cameraView) {
                case CameraView.LEFT:
                case CameraView.RIGHT:
                    newPosition.set(
                        cameraView === CameraView.LEFT ? -DEFAULT_VIEW_DISTANCE : DEFAULT_VIEW_DISTANCE,
                        modelGroupCenter.y,
                        modelGroupCenter.z,
                    );
                    this._controls!.target.y = modelGroupCenter.y;
                    this._controls!.target.z = modelGroupCenter.z;
                    break;
                case CameraView.FRONT:
                case CameraView.BACK:
                    newPosition.set(
                        modelGroupCenter.x,
                        modelGroupCenter.y,
                        cameraView === CameraView.FRONT ? -DEFAULT_VIEW_DISTANCE : DEFAULT_VIEW_DISTANCE,
                    );
                    this._controls!.target.x = modelGroupCenter.x;
                    this._controls!.target.y = modelGroupCenter.y;
                    break;
                case CameraView.TOP:
                case CameraView.BOTTOM:
                    newPosition.set(
                        modelGroupCenter.x,
                        cameraView === CameraView.TOP ? -DEFAULT_VIEW_DISTANCE : DEFAULT_VIEW_DISTANCE,
                        modelGroupCenter.z,
                    );
                    this._controls!.target.x = modelGroupCenter.x;
                    this._controls!.target.z = modelGroupCenter.z;
                    this._camera.up.z = 1;
                    break;
                default:
                    newPosition.set(DEFAULT_CAMERA_POSTION_X, DEFAULT_CAMERA_POSTION_Y, DEFAULT_CAMERA_POSTION_Z);
                    break;
            }
            this._camera.position.copy(newPosition);
        }
    }

    public destroy(): void {
        if (this._camera != null) {
            clearObject(this._camera);
            this._camera = null;
        }

        if (this._controls != null) {
            this._controls.dispose();
            this._controls = null;
        }
    }

    private _createCamera(aspectRatio: number, cameraType: CameraType): View3DCamera {
        let camera: View3DCamera;

        if (cameraType === CameraType.Perspective) {
            camera = new PerspectiveCamera(
                DEFAULT_PERSPECTIVE_CAMERA_FOV,
                DEFAULT_PERSPECTIVE_CAMERA_ASPECT,
                DEFAULT_PERSPECTIVE_CAMERA_NEAR,
                DEFAULT_PERSPECTIVE_CAMERA_FAR,
            );
        } else {
            camera = new OrthographicCamera(
                -aspectRatio,
                aspectRatio,
                1,
                -1,
                DEFAULT_ORTHOGRAPHIC_CAMERA_NEAR,
                DEFAULT_ORTHOGRAPHIC_CAMERA_FAR,
            );
        }

        // Set position and look at
        camera.position.set(DEFAULT_CAMERA_POSTION_X, DEFAULT_CAMERA_POSTION_Y, DEFAULT_CAMERA_POSTION_Z);
        camera.up.y = -1;

        return camera;
    }

    private _createControls(rendererDomElement: HTMLCanvasElement): TrackballControls {
        const controls = new TrackballControls(this._camera!, rendererDomElement);
        controls.rotateSpeed = DEFAULT_CONTROLS_ROTATE_SPEED;
        controls.panSpeed = DEFAULT_CONTROLS_PAN_SPEED;
        controls.zoomSpeed = DEFAULT_CONTROLS_ZOOM_SPEED;

        return controls;
    }

    private _getAspectRatio(): number {
        if (!this._canvas) {
            return 1;
        }
        const height = this._canvas.clientHeight;
        if (height === 0) {
            return 1;
        }
        return this._canvas.clientWidth / this._canvas.clientHeight;
    }
}

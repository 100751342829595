import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, combineLatest } from 'rxjs';

@Component({
    selector: 'bx-drawer',
    styleUrls: ['drawer.component.scss'],
    templateUrl: 'drawer.component.html',
})
export class DrawerComponent implements OnDestroy {
    private readonly _destroy$ = new Subject<void>();
    private readonly _isActive$ = new BehaviorSubject<boolean>(false);

    @Input()
    position: 'left' | 'right';

    @HostBinding('class.active')
    isActive: boolean;

    public readonly alwaysShowNavigation$ = new BehaviorSubject<boolean>(false);

    public constructor(private readonly _router: Router, private readonly _activatedRoute: ActivatedRoute) {
        this._router.events
            .pipe(
                takeUntil(this._destroy$),
                filter(e => e instanceof NavigationEnd),
                map(() => this._activatedRoute),
                map(route => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                mergeMap(route => route.data),
            )
            .subscribe(data => {
                this.alwaysShowNavigation$.next(data.hasOwnProperty('alwaysShowNavigation') && data.alwaysShowNavigation);
            });

        combineLatest(this.alwaysShowNavigation$, this._isActive$)
            .pipe(
                takeUntil(this._destroy$),
                map(([alwaysShowNavigation, isActive]) => alwaysShowNavigation || isActive),
            )
            .subscribe(isActive => (this.isActive = isActive));
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public toggle(): void {
        this._isActive$.next(!this.isActive);
    }
}

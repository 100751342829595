import { getDimensionDependentProperty, getProperty, setProperty } from '../functions/utils';
import { BearinxBinder } from './bearinx-binder';
import { BindingProperties, BindingType } from './binding-properties';

export class BearinxStandardBinder extends BearinxBinder {
    constructor() {
        super();
        this.leftSetter = this._setLeftProperty;
        this.rightSetter = this._setRightProperty;
    }

    private _setLeftProperty(prop: BindingProperties): void {
        if (prop.bindingType === BindingType.ToRight) {
            return;
        }
        this.proveLeftProperty(prop.left);
        const rightValue =
            prop.dimension == null
                ? getProperty(this.objectRight, prop.right)
                : getDimensionDependentProperty(this.objectRight, prop.right, prop.dimension);
        this.objectLeft[prop.left] = rightValue;
    }

    private _setRightProperty(prop: BindingProperties): void {
        if (prop.bindingType === BindingType.ToLeft) {
            return;
        }
        const leftValue = this.objectLeft[prop.left]; // <number>getProperty(this.objectRight, prop.right, undefined);
        setProperty(this.objectRight, prop.right, leftValue);
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Notification, NotificationAction, NotificationProgress } from './notification.model';

@Component({
    selector: 'bx-notification',
    templateUrl: 'notification.component.html',
    styleUrls: ['notification.component.scss'],
})
export class NotificationComponent {
    @Input() public notification: Notification;
    @Output() public action = new EventEmitter<NotificationAction>();

    public onAction(action: NotificationAction): void {
        this.action.emit(action);
    }

    public pc(progress: NotificationProgress): string {
        const pc = Math.floor(progress.current / progress.total * 100);
        return `${pc}%`;
    }
}

import { Mesh, BufferGeometry, Material } from 'three';
import { Worm } from '../../../cae-model/transmission-elements/worm';
import { WormGeometry } from '../../../views-foundation/geometries/worm-geometry';
import { createPlaceholderGeometry, getUnitSetScaleValue } from '../../functions/utils-3d';
import { TransmissionElementView3D } from './transmission-element-view-3d';

export class WormView3D extends TransmissionElementView3D {
    constructor(private _worm: Worm) {
        super(_worm);
    }

    protected setMesh(geometry: BufferGeometry, material: Material): Mesh {
        return new Mesh(geometry, material);
    }

    protected setBufferGeometry(): BufferGeometry {
        const geometry = new WormGeometry(this._worm, getUnitSetScaleValue(this.input.settings.unitSet));
        this.isOnShaft = geometry.isOnShaft;

        const { tDO, tDI, tBO, tBI } = geometry;
        return createPlaceholderGeometry(this.input.settings, tDI, tDO, tBO + (tBO - tBI) / 2.0);
    }
}

import { ModelElement } from '../../../cae-model/model-element';
import { CounterMoment } from '../../../cae-model/transmission-elements/counter-moment';
import { BearinxStandardBinder } from '../../binders/bearinx-standard-binder';
import { BasicMediator } from '../basic-mediator';

export class CounterMomentMediator extends BasicMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_COUNTER_MOMENT'];
    }

    protected createModelElement(): ModelElement {
        return new CounterMoment();
    }

    private _createBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [
            { left: 'x', right: 'IDCO_X' },
            { left: 'outerDiameter', right: 'IDTE_D' },
            { left: 'innerDiameter', right: 'IDTE_DI' },
            { left: 'width', right: 'IDTE_B' },
        ];
        this.addBinder(binder);
    }
}

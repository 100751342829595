import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { finalize } from 'rxjs/operators';
import { ModelElement } from '../cae-model/model-element';

export abstract class ElementView {
    private readonly _destroy$ = new Subject<void>();
    protected abstract onDestroy(): void;

    constructor(protected modelElement$: ModelElement | null) {
        this.modelElement$!.isUpdated()
            .pipe(
                takeUntil(this._destroy$),
                finalize(() => this.onDestroy()),
            )
            .subscribe(() => this._updateModelElement());
    }

    setElementUpdater() {}

    private _updateModelElement() {
        this.updateGroups();
    }

    protected updateGroups(): void {}

    public get modelElement(): ModelElement {
        return this.modelElement$!;
    }
}

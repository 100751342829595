import { Group } from 'konva/lib/Group';
import { Node } from 'konva/lib/Node';
import { Transformer } from 'konva/lib/shapes/Transformer';
import { ANCHOR_COLOR, ANCHOR_SIZE_2D, DASH_STROKE_TRANSLATIONAL_TRANSFORMER, STROKE_WIDTH } from '../elements-view/view-2d-constants';
import { NOT_IN_STAGE_BOUNDING_BOX } from './../elements-view/view-2d-constants';

// class only translate nodes, without rotation
export class TranslationalTransformer {
    private _transformer: Transformer | null = null;
    constructor(private _nodes: Group[], private _parent: Group) {}

    updateTransformer(value: { visible: boolean; dragable: boolean }) {
        this.destroy();

        if (value.visible) {
            this._createTransformer();
        }
    }

    destroy(): void {
        if (this._transformer) {
            this._transformer.destroy();
            this._transformer = null;
        }
    }

    private _createTransformer() {
        this._transformer = new Transformer({
            centeredScaling: false,
            rotateEnabled: false,
            anchorFill: ANCHOR_COLOR,
            anchorStroke: ANCHOR_COLOR,
            anchorStrokeWidth: STROKE_WIDTH,
            borderStroke: ANCHOR_COLOR,
            anchorSize: ANCHOR_SIZE_2D * 2,
            borderDash: DASH_STROKE_TRANSLATIONAL_TRANSFORMER,
            ignoreStroke: true,
        });
        this._transformer.setAttr(NOT_IN_STAGE_BOUNDING_BOX, true);
        this._parent.add(this._transformer);
        this._lockTransforming();
    }

    private _lockTransforming(): void {
        // TODO: get only first node
        const [first] = this._nodes as Array<Group | undefined>;
        const children = first?.children ?? [];

        const transformableNodes = children.filter((node: Node) => node.attrs.transformable);
        this._transformer!.nodes(transformableNodes);
        this._transformer!.rotateEnabled(false);
        this._transformer!.on('transform', () => this._transformer!.stopTransform());
    }
}

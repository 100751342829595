import { Injectable } from '@angular/core';
import { WindowRef } from '../util/window-ref';

@Injectable({
    providedIn: 'root',
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    useFactory: shareServiceFactory,
    deps: [WindowRef],
})
export abstract class ShareService {
    abstract share(text: string, title?: string, url?: string): Promise<any>;
}

export class NativeShareService extends ShareService {
    constructor(private readonly _windowRef: WindowRef) {
        super();
    }

    public share(text: string, title?: string, url?: string): Promise<any> {
        return this._windowRef.nativeWindow.navigator['share']({ text, title, url });
    }
}

export class MailShareService extends ShareService {
    constructor(private readonly _windowRef: WindowRef) {
        super();
    }

    public share(text: string, title?: string, url?: string): Promise<any> {
        const body = url ? `${text}\n\n${url}` : text;
        const mailtoUri = `mailto:?subject=${encodeURIComponent(title || '')}&body=${encodeURIComponent(body)}`;
        return Promise.resolve().then(() => (this._windowRef.nativeWindow.location.href = mailtoUri));
    }
}

export function shareServiceFactory(windowRef: WindowRef): ShareService {
    return windowRef.nativeWindow.navigator['share'] ? new NativeShareService(windowRef) : new MailShareService(windowRef);
}

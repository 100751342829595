import { TransmissionElement } from './transmission-elements/transmission-element';
import { Load } from './loads/load';
import { ConeSegment } from './shaft-segments/cone-segment';
import { PipeSegment } from './shaft-segments/pipe-segment';
import { strict as assert } from 'assert';
import { ModelElement } from './model-element';
import { ShaftSegment, ShaftSegmentCoordinates } from './shaft-segments/shaft-segment';

export class Shaft extends ModelElement {
    private _segments: ShaftSegment[];
    shaftSystemMiddleDiameter: () => number;

    constructor() {
        super();
        this._segments = [];
    }

    get segments(): ShaftSegment[] {
        return this._segments;
    }

    get middleRadius(): number {
        let middleDiameter1 = 0.0;
        for (const segment of this._segments) {
            if (segment instanceof PipeSegment) {
                middleDiameter1 += segment.outerDiameter;
            } else if (segment instanceof ConeSegment) {
                middleDiameter1 += Math.max(segment.outerDiameterLeft, segment.outerDiameterRight);
            }
        }
        if (this._segments.length > 0) {
            middleDiameter1 /= this._segments.length;
        }
        return middleDiameter1 / 2;
    }

    // returns the current outer radius of the segment,
    // corresponding to this x where x is the x-coordinate in the shaft coordinate system
    getCurrentOuterRadius(x: number): number {
        const curSegment = this._segments.find(segment => segment.x <= x && segment.xEnd >= x);
        if (curSegment == null) {
            return 0;
        }
        return curSegment.getCurrentOuterRadius(x);
    }

    // returns the current inner radius of the segment,
    // corresponding to this x where x is the x-coordinate in the shaft coordinate system
    getCurrentInnerRadius(x: number): number {
        const curSegment = this._segments.find(segment => segment.x <= x && segment.xEnd >= x);
        if (curSegment == null) {
            return 0;
        }
        return curSegment.getCurrentInnerRadius(x);
    }

    protected doAdd(child: ModelElement) {
        if (child instanceof ShaftSegment) {
            this._addSegment(child);
        }
        if (child instanceof Load) {
            this._addLoad(child);
        }
        if (child instanceof TransmissionElement) {
            this._addTransmission(child);
        }
    }

    private _addLoad(load: Load): void {
        load.getShaftSystemMiddleDiameter = () => this.shaftSystemMiddleDiameter();
        load.getCurrentDiameter = (x: number) => this.getCurrentOuterRadius(x) * 2;
    }

    private _addTransmission(transmission: TransmissionElement): void {
        transmission.getShaftCurrentOuterDiameter = (x: number) => this.getCurrentOuterRadius(x) * 2;
        transmission.getShaftCurrentInnerDiameter = (x: number) => this.getCurrentInnerRadius(x) * 2;
    }

    private _addSegment(segment: ShaftSegment): void {
        this._segments.push(segment);
        segment.setCoordinatesFunc = (segmentId: string, coord: ShaftSegmentCoordinates) => this._setSegmentCoordinates(segmentId, coord);
    }

    private _setSegmentCoordinates(segmentId: string, coorInp: ShaftSegmentCoordinates) {
        const segmentIndex = this._segments.findIndex(segment => segmentId === segment.id);
        assert(segmentIndex >= 0);
        const curSegment = this._segments[segmentIndex];

        const segmentCoor = Object.assign({}, coorInp);
        const length = segmentCoor.xEnd - segmentCoor.xStart;
        if (length < ShaftSegment.minLength) {
            /// change coordinates to guarantee the minimal length of the segment
            const changeXStart = Math.abs(curSegment.x - coorInp.xStart);
            const changeXEnd = Math.abs(curSegment.xEnd - coorInp.xEnd);
            if (changeXStart > changeXEnd) {
                // < we wanted to move left side
                segmentCoor.xStart = segmentCoor.xEnd - ShaftSegment.minLength;
            } else {
                // < we wanted to move right side
                segmentCoor.xEnd = segmentCoor.xStart + ShaftSegment.minLength;
            }
        }

        let prevSegment: ShaftSegment | null = null;
        if (segmentIndex > 0) {
            prevSegment = this._segments[segmentIndex - 1];
            const xMin = prevSegment.x + ShaftSegment.minLength;
            if (coorInp.xStart < xMin) {
                /// tried tp move left side -> don't change length.
                segmentCoor.xStart = xMin;
                segmentCoor.xEnd = curSegment.xEnd;
            }
        }
        let nextSegment: ShaftSegment | null = null;
        if (segmentIndex < this._segments.length - 1) {
            nextSegment = this._segments[segmentIndex + 1];
            const xMax = nextSegment.xEnd - ShaftSegment.minLength;
            if (coorInp.xEnd > xMax) {
                /// tried tp move right side -> don't change x.
                segmentCoor.xStart = curSegment.x;
                segmentCoor.xEnd = xMax;
            }
        }

        curSegment.x = segmentCoor.xStart;
        curSegment.xEnd = segmentCoor.xEnd;
        curSegment.update();

        // update next and prev segments
        if (prevSegment !== null) {
            prevSegment.xEnd = segmentCoor.xStart;
            prevSegment.update();
        }
        if (nextSegment !== null) {
            nextSegment.x = segmentCoor.xEnd;
            nextSegment.update();
        }
    }
}

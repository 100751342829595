import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { View3DSettings } from '../settings';

@Injectable()
export class View3DSettingsService {
    private _settings = new BehaviorSubject<View3DSettings | null>(null);

    getSettings(): Observable<View3DSettings | null> {
        return this._settings;
    }

    setSettings(settings: View3DSettings): void {
        if (JSON.stringify(this._settings.value) !== JSON.stringify(settings)) {
            this._settings.next(settings);
        }
    }
}

import { BevelGear } from './../../../cae-model/transmission-elements/bevel-gear';
import { BasicMediator } from '../basic-mediator';
import { createBevelGearBaseBinder } from './transmission-mediators-utils';
import { ModelElement } from '../../../cae-model/model-element';
export class BevelGearMediator extends BasicMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_BEVEL_GEAR'];
    }

    protected createModelElement(): ModelElement {
        return new BevelGear();
    }

    private _createBinder() {
        const binder = createBevelGearBaseBinder();
        this.addBinder(binder);
    }
}

import { Box3, BoxGeometry, Group, Material, MathUtils, Mesh, MeshStandardMaterial, Object3D, Vector3 } from 'three';
import {
    BASE_ANCHOR_NAME,
    BASE_SHADOW_NAME,
    CENTER_BASE_ANCHOR_NAME,
    CENTER_BASE_SHADOW_NAME,
    COLOR_PRESELECTED_SELECTION_BOX,
    COLOR_SELECTED_SELECTION_BOX,
    PRESELECTED_SELECTION_BOX,
    SELECTED_SELECTION_BOX,
    SHADOW_COLOR,
    SHADOW_SELECTION_BOX_NAME,
    SHADOW_SIZE,
} from '../settings/view-3d-constants';

export interface AnchorData {
    selectorSize: number;
    barLength: number;
    xBarVisible: boolean;
    yBarVisible: boolean;
    zBarVisible: boolean;
    xCornerSize: number;
    yCornerSize: number;
    zCornerSize: number;
    bbox: Box3;
    bboxCenter: Vector3;
    bboxSize: Vector3;
}

export class SelectionBox extends Group {
    constructor(
        private _selectedObject: Object3D,
        _name = 'selection-box',
        private _color: string,
        private _maxAnchorSize: number,
        private _modelMaxDimension: number,
        private _unitScale: number,
    ) {
        super();
        this.name = _name;
        this._drawSelectionBox();
    }

    private _drawSelectionBox() {
        if (this._selectedObject) {
            const bbox = new Box3();
            bbox.applyMatrix4(this._selectedObject.matrix);
            bbox.setFromObject(this._selectedObject);

            const bboxSize = new Vector3();
            bbox.getSize(bboxSize);

            if (
                bbox.max.x === Infinity ||
                bbox.max.y === Infinity ||
                bbox.max.z === Infinity ||
                bbox.min.x === Infinity ||
                bbox.min.y === Infinity ||
                bbox.min.z === Infinity
            ) {
                return;
            }

            const color = this._color;

            const selectionBoxBase = this._createSelectionBoxBase(color, bbox);
            const selectionBoxCenter = this._getSelectionBoxCenter(bbox, color);

            // Anchor
            const anchorGroup = selectionBoxBase.baseAnchor;

            // Left
            const leftAnchors: Group[] = [];

            const anchorLeftTopMinZ = anchorGroup;
            anchorLeftTopMinZ.position.set(bbox.min.x, bbox.min.y, bbox.min.z);
            leftAnchors.push(anchorLeftTopMinZ);
            const anchorLeftTopMaxZ = anchorGroup.clone();
            anchorLeftTopMaxZ.position.set(bbox.min.x, bbox.min.y, bbox.max.z);
            anchorLeftTopMaxZ.scale.z = -1;
            leftAnchors.push(anchorLeftTopMaxZ);
            const anchorLeftBottomMinZ = anchorGroup.clone();
            anchorLeftBottomMinZ.position.set(bbox.min.x, bbox.max.y, bbox.min.z);
            anchorLeftBottomMinZ.scale.y = -1;
            leftAnchors.push(anchorLeftBottomMinZ);
            const anchorLeftBottomMaxZ = anchorGroup.clone();
            anchorLeftBottomMaxZ.position.set(bbox.min.x, bbox.max.y, bbox.max.z);
            anchorLeftBottomMaxZ.scale.z = -1;
            anchorLeftBottomMaxZ.scale.y = -1;
            leftAnchors.push(anchorLeftBottomMaxZ);

            // Right
            const rightAnchors: Group[] = [];

            const anchorRightTopMinZ = anchorLeftTopMinZ.clone();
            anchorRightTopMinZ.position.set(bbox.max.x, bbox.min.y, bbox.min.z);
            rightAnchors.push(anchorRightTopMinZ);
            const anchorRightTopMaxZ = anchorLeftTopMaxZ.clone();
            anchorRightTopMaxZ.position.set(bbox.max.x, bbox.min.y, bbox.max.z);
            rightAnchors.push(anchorRightTopMaxZ);
            const anchorRightBottomMinZ = anchorLeftBottomMinZ.clone();
            anchorRightBottomMinZ.position.set(bbox.max.x, bbox.max.y, bbox.min.z);
            rightAnchors.push(anchorRightBottomMinZ);
            const anchorRightBottomMaxZ = anchorLeftBottomMaxZ.clone();
            anchorRightBottomMaxZ.position.set(bbox.max.x, bbox.max.y, bbox.max.z);
            rightAnchors.push(anchorRightBottomMaxZ);

            rightAnchors.forEach(group => {
                group.scale.x = -1;
            });

            // Center
            const centerAnchors = selectionBoxCenter.centerAnchors;

            [...leftAnchors, ...rightAnchors, ...centerAnchors].forEach((group: Mesh | Group) => {
                this.add(group);
            });

            // Shadow
            const shadowElements: Object3D[] = this._creatShadowGroup(bbox, selectionBoxBase.baseShadow, selectionBoxCenter.centerShadows);

            if (shadowElements) {
                const shadowGroup = new Group();
                shadowGroup.name = `${this.name}:${SHADOW_SELECTION_BOX_NAME}`;
                shadowElements.forEach((group: Object3D) => {
                    shadowGroup.add(group);
                });
                shadowGroup.position.setY(this._modelMaxDimension);
                this.add(shadowGroup);
            }
        }
    }

    private _createSelectionBoxBase(
        color: string,
        bbox: Box3,
    ): {
        baseAnchor: Group;
        baseShadow: Group;
    } {
        const { selectorSize, xCornerSize, yCornerSize, zCornerSize } = this._getAnchorData(bbox);

        const anchorSize = selectorSize * 0.8;

        const baseAnchor = new Group();
        baseAnchor.name = BASE_ANCHOR_NAME;
        const baseShadow = new Group();
        baseShadow.name = BASE_SHADOW_NAME;

        const anchorGeometry = new BoxGeometry(anchorSize, anchorSize, anchorSize);
        const anchorMesh = new Mesh(anchorGeometry, this._createSelectionBoxMaterial(color));

        const anchorBackGeometry = new BoxGeometry(anchorSize, anchorSize, selectorSize * zCornerSize);
        const anchorBackMesh = new Mesh(anchorBackGeometry, this._createSelectionBoxMaterial(color));
        anchorBackMesh.translateZ((selectorSize * zCornerSize) / 2 + anchorSize / 2);

        const anchorBottomGeometry = new BoxGeometry(anchorSize, selectorSize * yCornerSize, anchorSize);
        const anchorBottomMesh = new Mesh(anchorBottomGeometry, this._createSelectionBoxMaterial(color));
        anchorBottomMesh.translateY((selectorSize * yCornerSize) / 2 + anchorSize / 2);

        const anchorSideGeometry = new BoxGeometry(selectorSize * xCornerSize, anchorSize, anchorSize);
        const anchorSideMesh = new Mesh(anchorSideGeometry, this._createSelectionBoxMaterial(color));
        anchorSideMesh.translateX((selectorSize * xCornerSize) / 2 + anchorSize / 2);

        baseAnchor.add(anchorMesh);
        baseAnchor.add(anchorBackMesh);
        baseAnchor.add(anchorBottomMesh);
        baseAnchor.add(anchorSideMesh);

        const shadowGeometry = new BoxGeometry(anchorSize, SHADOW_SIZE / this._unitScale, anchorSize);
        const shadowMesh = new Mesh(shadowGeometry, this._createSelectionBoxMaterial(SHADOW_COLOR));

        const shadowBackGeometry = new BoxGeometry(anchorSize, SHADOW_SIZE / this._unitScale, selectorSize * zCornerSize);
        const shadowBackMesh = new Mesh(shadowBackGeometry, this._createSelectionBoxMaterial(SHADOW_COLOR));
        shadowBackMesh.translateZ((selectorSize * zCornerSize) / 2 + anchorSize / 2);

        const shadowSideGeometry = new BoxGeometry(selectorSize * xCornerSize, SHADOW_SIZE / this._unitScale, anchorSize);
        const shadowSideMesh = new Mesh(shadowSideGeometry, this._createSelectionBoxMaterial(SHADOW_COLOR));
        shadowSideMesh.translateX((selectorSize * xCornerSize) / 2 + anchorSize / 2);

        baseShadow.add(shadowMesh);
        baseShadow.add(shadowBackMesh);
        baseShadow.add(shadowSideMesh);

        return { baseAnchor, baseShadow };
    }

    private _getSelectionBoxCenter(
        bbox: Box3,
        color: string,
    ): {
        centerAnchors: Mesh[];
        centerShadows: Mesh[];
    } {
        const anchorData = this._getAnchorData(bbox);
        const { selectorSize, bboxCenter, xBarVisible, yBarVisible, zBarVisible, barLength } = anchorData;

        const size: number = selectorSize * 0.8;
        const anchorCenterGeometry = new BoxGeometry(selectorSize * barLength, size, size);
        const anchorCenterMesh = new Mesh(anchorCenterGeometry, this._createSelectionBoxMaterial(color));
        anchorCenterMesh.name = CENTER_BASE_ANCHOR_NAME;

        const shadowCenterGeometry = new BoxGeometry(selectorSize * barLength, SHADOW_SIZE / this._unitScale, size);
        const shadowCenterMesh = new Mesh(shadowCenterGeometry, this._createSelectionBoxMaterial(SHADOW_COLOR));
        shadowCenterMesh.name = CENTER_BASE_SHADOW_NAME;

        const centerAnchors: Mesh[] = [];
        const centerShadows: Mesh[] = [];

        const getPositionByAxis = (axis1: string, axis2: string): [number, number][] => [
            [bbox.min[axis1], bbox.min[axis2]],
            [bbox.min[axis1], bbox.max[axis2]],
            [bbox.max[axis1], bbox.min[axis2]],
            [bbox.max[axis1], bbox.max[axis2]],
        ];

        if (xBarVisible) {
            getPositionByAxis('y', 'z').forEach(([y, z]) => {
                const centerAnchor = anchorCenterMesh.clone();
                centerAnchor.position.set(bboxCenter.x, y, z);
                centerAnchors.push(centerAnchor);
            });

            [bbox.min.z, bbox.max.z].forEach(z => {
                const centerShadow = shadowCenterMesh.clone();
                centerShadow.position.set(bboxCenter.x, 0, z);
                centerShadows.push(centerShadow);
            });
        }

        if (yBarVisible) {
            getPositionByAxis('x', 'z').forEach(([x, z]) => {
                const centerAnchor = anchorCenterMesh.clone();
                centerAnchor.position.set(x, bboxCenter.y, z);
                centerAnchor.rotateZ(MathUtils.degToRad(90));
                centerAnchors.push(centerAnchor);
            });
        }

        if (zBarVisible) {
            getPositionByAxis('x', 'y').forEach(([x, y]) => {
                const centerAnchor = anchorCenterMesh.clone();
                centerAnchor.position.set(x, y, bboxCenter.z);
                centerAnchor.rotateY(MathUtils.degToRad(90));
                centerAnchors.push(centerAnchor);

                [bbox.min.x, bbox.max.x].forEach(x => {
                    const centerShadow = shadowCenterMesh.clone();
                    centerShadow.position.set(x, 0, bboxCenter.z);
                    centerShadow.rotateY(MathUtils.degToRad(90));
                    centerShadows.push(centerShadow);
                });
            });
        }

        return { centerAnchors, centerShadows };
    }

    private _getAnchorData(bbox: Box3): AnchorData {
        const bboxCenter = new Vector3();
        bbox.getCenter(bboxCenter);

        const bboxSize = new Vector3();
        bbox.getSize(bboxSize);

        const xSize = bboxSize.x;
        const ySize = bboxSize.y;
        const zSize = bboxSize.z;
        const size = [xSize, ySize, zSize].sort((a, b) => a - b);
        const avgSize = size[1];
        const minSize = size[0];

        const visibleSizeRatio = 2.0;
        const barLength = 4.0;

        const xBarVisible = Math.abs(xSize / minSize) > visibleSizeRatio;
        const yBarVisible = Math.abs(ySize / minSize) > visibleSizeRatio;
        const zBarVisible = Math.abs(zSize / minSize) > visibleSizeRatio;

        const selectorSize = Math.min(0.04 * avgSize, this._maxAnchorSize);

        const { x: xMin, y: yMin, z: zMin } = bbox.min;
        const { x: xMax, y: yMax, z: zMax } = bbox.max;

        const dx = xMax - xMin;

        const dy = yMax - yMin;

        const dz = zMax - zMin;
        const deltas = [dx, dy, dz].sort((a, b) => a - b);
        const deltaMax = deltas[1] * 3.0;

        const xCornerSize = ((40.0 * Math.min(dx, deltaMax)) / selectorSize) * 0.0015;
        const yCornerSize = ((40.0 * Math.min(dy, deltaMax)) / selectorSize) * 0.0015;
        const zCornerSize = ((40.0 * Math.min(dz, deltaMax)) / selectorSize) * 0.0015;

        return {
            selectorSize: selectorSize * 1.5,
            barLength,
            xBarVisible,
            yBarVisible,
            zBarVisible,
            xCornerSize,
            yCornerSize,
            zCornerSize,
            bbox,
            bboxCenter,
            bboxSize,
        };
    }

    private _createSelectionBoxMaterial(color: string): Material {
        return new MeshStandardMaterial({
            color: color,
            roughness: 0.6,
            metalness: 0.2,
        });
    }

    private _creatShadowGroup(bbox: Box3, baseShadow: Group, centerShadows: Mesh[]): Object3D[] {
        const shadowGroup = baseShadow;

        // Left
        const leftShadows: Group[] = [];

        const shadowAnchorLeftMinZ = shadowGroup;
        shadowAnchorLeftMinZ.position.set(bbox.min.x, 0, bbox.min.z);
        leftShadows.push(shadowAnchorLeftMinZ);
        const shadowAnchorLeftMaxZ = shadowGroup.clone();
        shadowAnchorLeftMaxZ.position.set(bbox.min.x, 0, bbox.max.z);
        shadowAnchorLeftMaxZ.scale.z = -1;
        leftShadows.push(shadowAnchorLeftMaxZ);

        // Right
        const rightShadows: Group[] = [];

        const shadowAnchorRightMinZ = shadowAnchorLeftMinZ.clone();
        shadowAnchorRightMinZ.position.set(bbox.max.x, 0, bbox.min.z);
        rightShadows.push(shadowAnchorRightMinZ);
        const shadowAnchorRightMaxZ = shadowAnchorLeftMaxZ.clone();
        shadowAnchorRightMaxZ.position.set(bbox.max.x, 0, bbox.max.z);
        rightShadows.push(shadowAnchorRightMaxZ);

        rightShadows.forEach(group => {
            group.scale.x = -1;
        });

        return [...leftShadows, ...rightShadows, ...centerShadows];
    }
}

export function createPreselectedSelectionBox(
    selectedObject: Object3D,
    gridWidth: number,
    modelMaxDimension: number,
    unitScale: number,
): SelectionBox {
    return new SelectionBox(
        selectedObject,
        PRESELECTED_SELECTION_BOX,
        COLOR_PRESELECTED_SELECTION_BOX,
        gridWidth,
        modelMaxDimension,
        unitScale,
    );
}

export function createSelectedSelectionBox(
    selectedObject: Object3D,
    gridWidth: number,
    modelMaxDimension: number,
    unitScale: number,
): SelectionBox {
    return new SelectionBox(selectedObject, SELECTED_SELECTION_BOX, COLOR_SELECTED_SELECTION_BOX, gridWidth, modelMaxDimension, unitScale);
}

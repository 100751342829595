import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Version } from './version.model';

@Injectable({
    providedIn: 'root',
})
export class VersionService {
    private readonly _frontendVersion: string = require('../../../package.json').version;
    private readonly _baseUrl = `${environment.baseUrl}version`;
    private _versions$ = new ReplaySubject<Version[]>(1);
    readonly versions$ = this._versions$.asObservable().pipe(
        map(versions => [
            ...versions,
            {
                name: 'Frontend',
                version: this.getFrontendVersion(),
            },
        ]),
    );

    constructor(private _httpClient: HttpClient) {
        this._getVersions();
    }

    private _getVersions() {
        this._httpClient.get<Version[]>(this._baseUrl).subscribe(this._versions$);
    }

    public getFrontendVersion(): string {
        return this._frontendVersion;
    }
}
